import React from "react";

import "./Spinner.scss";

const Spinner = ({small, extraSmall}) => {
  return (
    <div className={`spinner ${small ? 'small' : ''} ${extraSmall ? 'extraSmall' : ''}`}>
      <div className={`dbl-spinner ${small ? 'small' : ''} ${extraSmall ? 'extraSmall' : ''}`}></div>
      <div className={`dbl-spinner dbl-spinner--2 ${small ? 'small' : ''} ${extraSmall ? 'extraSmall' : ''}`}></div>
    </div>
  );
};

export default Spinner;
