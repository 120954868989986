import React from "react";

import logo from "../../../style/images/auth-logo.svg";

import "./ErrorBoudary.scss";

export default class ErrorBoundary extends React.Component {
  state = {
    errorCatch: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorCatch: true,
    });
  }

  render() {
    if (this.state.errorCatch) {
      return (
        <div className="error-wrapper">
          <img className="error-logo" src={logo} alt="DobroDoc logo" />
          <h1 className="error-title">
            Извините, произошла ошибка, пожалуйста перезагрузите страницу. Если
            это не поможет, обратитесь в службу поддержки по телефону 0800330036.
          </h1>
        </div>
      );
    }
    return this.props.children;
  }
}
