import * as types from "./types";
import createReducer from "../../utils/createReducer";
import { combineReducers } from "redux";

const initialState = null;

const loading = createReducer(false)({
  [types.CONSULTATIONS_REQUEST]: (_, __) => true,
  [types.GET_ALL_CONSULTATIONS]: (_, __) => false,
  [types.GET_FILTERED_CONSULTATIONS]: (_, __) => false,
  [types.GET_TODAY_CONSULTATIONS]: (_, __) => false,
  [types.GET_TOMORROW_CONSULTATIONS]: (_, __) => false,
  [types.GET_WEEK_CONSULTATIONS]: (_, __) => false,
  [types.SET_FILTER_MODE_MONITORING]: (_, __) => false,
  [types.GET_CURRENT_CONSULTATION]: (_, __) => false,
  [types.RESET_CURR_CONSULTATION]: (_, __) => false,
  [types.RESET_IS_ITEMS_FILTERED]: (_, __) => false,
  [types.GET_RANDOM_DOCTOR_IN_HOSPITAL]: (_, __) => false,
  [types.UPDATE_CONSULTATION_STATUS]: (_, __) => false,
})

const anamnesis = createReducer([])({
  [types.GET_CONSULTATION_ANAMNESIS_QUESTIONS]: (_, { payload }) => payload,
  [types.RESET_ANAMNESIS]: () => [],
})

const cardOptions = createReducer({})({
  [types.GET_DATA_CARD_OPTIONS]: (_, {payload}) => payload
})

const newConsultEntity = createReducer(null)({
  [types.CREATE_CONSULTATION]: (_, {payload}) => {
    console.log(payload);
    return payload
  },
  [types.UPDATE_CONSULTATION]: (_, {payload}) => {
    console.log(payload);
    return payload
  },
})

const consultations = createReducer(initialState)({
  [types.GET_ALL_CONSULTATIONS]: (
    state,
    {
      filterMode,
      count,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    count,
    loading: false,
    isItemsFiltered: false,
    ...props,
  }),

  [types.GET_FILTERED_CONSULTATIONS]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      ...payload.data,
      isItemsFiltered: true,
      loading: false,
    };
  },

  [types.GET_TODAY_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    loading: false,
    ...props,
  }),

  [types.GET_TOMORROW_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    loading: false,
    ...props,
  }),

  [types.GET_WEEK_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    loading: false,
    ...props,
  }),

  [types.SET_FILTER_MODE_MONITORING]: (state, { filterMode }) => ({
    ...state,
    filterMode,
    isItemsFiltered: false,
    loading: false,
  }),
  [types.GET_CURRENT_CONSULTATION]: (state, { currentConsultation }) => ({
    ...state,
    currentConsultation,
    isItemsFiltered: false,
    consultLoading: false,
    loading: false,
  }),
  [types.RESET_CURR_CONSULTATION]: () => {
    // delete state.currentConsultation;
    return {
      currentConsultation: null,
      isItemsFiltered: false,
      loading: false,
    };
  },
  [types.RESET_IS_ITEMS_FILTERED]: (state) => {
    return {
      ...state,
      isItemsFiltered: false,
      loading: false,
    }
  },
  [types.GET_RANDOM_DOCTOR_IN_HOSPITAL]: (state, { type, ...data }) => ({
    ...state,
    ...data,
    loading: false
  }),
  [types.UPDATE_CONSULTATION_STATUS]: (state, _) => ({
    ...state,
    loading: false,
  }),
  [types.CURRENT_CONSULT_REQUEST]: (state, _) => ({
    ...state,
    consultLoading: true
  }),
});



export default combineReducers({
  consultations,
  loading,
  newConsultEntity,
  anamnesis,
  cardOptions,
});
