import api from "../../api/index";
import * as types from "./types";

export const getAllOnboarding = () => (dispatch) =>
  api.onboarding.getAll().then((data) =>
    dispatch({
      type: types.GET_ALL_ONBOARDING_TIPS,
      ...data,
    })
  );

export const createNewOnboarding = (data) => (dispatch) =>
  api.onboarding
    .createNew({
      onboarding: {
        isActive: data.isActive,
        text: data.text,
      },
    })
    .then((res) => dispatch({ type: types.CREATE_NEW_ONBOARDING, res }));

export const updateOnboarding = (id, data) => (dispatch) =>
  api.onboarding
    .update(id, {
      onboarding: {
        isActive: data.isActive,
        text: data.text,
      },
    })
    .then((res) => dispatch({ type: types.UPDATE_ONBOARDING, res }));

export const deleteOnboarding = (id) => (dispatch) =>
  api.onboarding
    .delete(id)
    .then((res) => dispatch({ type: types.DELETE_ONBOARDING, res }));

export const uploadOnboardingPhoto = (id, file) => (dispatch) => {
  return api.onboarding
    .upload(id, file)
    .then((res) => dispatch({ type: "ONBOADING_PHOTO_UPLOAD", res }));
};
