export const parserForm = (evt) => {
  const fieldsList = [...evt.target]
    .filter(elm => elm.value && elm.name)
    .reduce((cash, elm) => ({ ...cash, [elm.name]: elm.value }), {});
    
  return fieldsList;
}

export const getDatePattern = (pattren = 'DD.MM.YYYY') => {
  const _pattern = pattren
    .replace(/DD/, '[1-3][0-9]')
    .replace(/MM/, '[0-1][0-9]')
    .replace(/YYYY/, '[0-9]{4}')
    .replace(/\./g, '.');

    return new RegExp(`^${_pattern}$`);
}

export const getCorrectDate = (date, pattern) => {
  const chunksDate = date.split(/\W/);
  if (pattern === 'DD.MM.YYYY') {

    return date
      .replace(/^[0-9]{1,2}\W/, `${chunksDate[1]}.`)
      .replace(/\W[0-9]{1,2}\W/, `.${chunksDate[0]}.`);
  }

  return date;
}

export const isValidDate  = ({ period = 'isBefore', type = 'year', number = 0 }, date) => {
  const chunksDate =  new Date().toDateString().split(/\s/);

  if (period  === "isBefore") {
    if (type === 'year') {
      chunksDate[3] = Number(chunksDate[3]) - number;
      const minDate = new Date(chunksDate.join(' ')).getTime();
      
      return minDate > date.getTime() ? true : false;  
    }
  }

  return false;
}

export const getIncorectField = (evt) => {
  const isInvalidField = [...evt.target]
    .find(elm => {
      if (elm.required && elm.value.trim() === '') {
       return true;
      }

      // if (!elm.required && elm.value.trim() === '') {
      //   return false
      // }
      // if (elm.pattern && !elm.required) {
      //   if (elm.value.trim() === '') {
      //     return false
      //   }
      // }

      if (elm.pattern) {
        const regExp = new RegExp(elm.pattern);
        return !regExp.test(elm.value);
      }

      

      return false;
    });

  return isInvalidField;
}