import { getChunkDate, getChatDate } from "./date";
import moment from "moment";

export const genereteKey = () =>
  Math.floor(Math.random() * Math.floor(100000000));

export const getListsWithDates = (list) => {
  if (!list) return [];
  const _list = list.reduce((cashe, _) => {
    const date = getChunkDate(_.start);
    if (!cashe[date]) {
      const newList = [_];
      cashe[date] = newList;
      return cashe;
    }

    cashe[date].push(_);
    return cashe;
  }, {});

  return Object.keys(_list).map((key) => [key, _list[key]]);
};

export const getTimestampOnlyDate = (date) =>
  new Date(date.split(/[A-Z]/)[0]).getTime();

export const parseMessagesList = (messagesList, oldList) => {
  const _oldList = oldList.reduce((list, item) => [...list, ...item.list], []);
  const _list = [..._oldList, ...messagesList];
  const result = _list.reduce((mapper, item) => {
    const date = moment(item.dateSent).format("l");
    const timestamp = getTimestampOnlyDate(item.dateSent);
    const list = mapper[timestamp] || { list: [], date };
    list.list.push(item);
    list.list.sort((a, b) =>
      new Date(a.dateSent).getTime() < new Date(b.dateSent).getTime() ? -1 : 1
    );
    mapper[timestamp] = list;
    return mapper;
  }, {});
  return Object.keys(result)
    .sort((a, b) => (a < b ? -1 : 1))
    .map((key) => result[key]);
};
