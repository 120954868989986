import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ConsultateList from "../../components/ConsultateList/ConsultateList";
import Filter from "../../components/Filter/Filter";
import NewStatusForm from "../../forms/NewStatusForm/NewStatusForm";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import infoRed from "../../../style/icons/InfoSign/info-sign-red.svg";
import close_icon from "../../../style/icons/Chat/close-icon.svg";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom"
import { getConsultationsWithParams } from "../../../redux/consultations/actions";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

const Monitoring = ({
  getAllSpecializations,
  role,
  patientCard,
  warning = null,
  getConsultationsWithParams,
}) => {
  const [filterLoading, setFilterLoading] = useState(false)
  const [specializations, setSpecializations] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null)
  const [edited, setEdited] = useState(false)
  const [isOpenHelpModal, toggleHelpModal] = useState(false);
  const [homeWarning, setHomeWarning] = useState(null)
  const [filter, setFilter] = useState("All");
  const timerIdRef = React.useRef(null);
  const { t } = useTranslation()
  const history = useHistory()
  const page = new URLSearchParams(history.location.search).get("page")

  const urlQueries = {
    type: new URLSearchParams(history.location.search).get("type"),
    status: new URLSearchParams(history.location.search).get("status"),
    specialty: new URLSearchParams(history.location.search).get("spec"),
    from: new URLSearchParams(history.location.search).get("from"),
    to: new URLSearchParams(history.location.search).get("to"),
    patientId: new URLSearchParams(history.location.search).get("patientId"),
    period: new URLSearchParams(history.location.search).get("period"),
    from: new URLSearchParams(history.location.search).get("from"),
    to: new URLSearchParams(history.location.search).get("to"),
  }

  const refreshMonitoring = () => {
    setFilterLoading(true)
    getConsultationsWithParams(role, urlQueries, page)
      .finally(() => setFilterLoading(false))
  }


  useEffect(refreshMonitoring, [])
  useUpdateEffect(
    refreshMonitoring,
    [
      page,
      urlQueries.period,
      urlQueries.status,
      urlQueries.type,
      urlQueries.patientId,
      urlQueries.from,
      urlQueries.to,
    ]
  )

  useEffect(() => {
    timerIdRef.current = setInterval(refreshMonitoring, 60000);
    return () => {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    };
  }, [page, urlQueries.period, urlQueries.status, urlQueries.type, urlQueries.patientId]);

  const handleEdit = (id) => {
    setId(id)
    setEdit(!edit)
  }

  useEffect(() => {
    getAllSpecializations(50).then(({ items }) => setSpecializations(items));
  }, []);

   useEffect(() => {
    setHomeWarning(warning)
    toggleHelpModal(true)
  }, [warning])

  useEffect(() => {
    if (homeWarning) {
      setHomeWarning(null)
    }
  }, [isOpenHelpModal])
  
  return (
    <div className="monitoringContainer flex justifyBeetwen">
      <ConsultateList
        period={urlQueries.period}
        urlParams={urlQueries}
        setFilter={setFilter}
        filter={filter}
        edited={edited}
        setEdited={setEdited}
        handleEdit={handleEdit}
        edit={edit}
        role={role}
        filterParams={filterParams}
        currentPage={page}
        // loading={loading}
        // setLoading={setLoading}
      />
      {!edit ?
        <Filter
          setFilterLoading={setFilterLoading}
          filterLoading={filterLoading}
          filter={filter}
          mode="monitoring"
          specializations={specializations}
          role={role}
          setFilterParams={setFilterParams}
          loading={loading}
          setLoading={setLoading}
          paramsFromURL={urlQueries}
          period={urlQueries.period}
          dateFrom={urlQueries.from}
          dateTo={urlQueries.to}
        />
        : <NewStatusForm
          setEdited={setEdited}
          edit={edit}
          setEdit={setEdit}
          id={id}
        />}
      {/* HIDE NOT TO SHOW IN RELEASE */}
      {/* {isOpenHelpModal && homeWarning && (
        <ModalWindow
          isOpen={isOpenHelpModal}
          toggleOpen={() => toggleHelpModal(false)}
        >
          <HelpModalContent
            setHomeWarning={setHomeWarning}
            homeWarning={homeWarning}
            handleClose={() => toggleHelpModal(false)}
            t={t} />
        </ModalWindow>
      )} */}
    </div>
  );
};
const HelpModalContent = (props) => {
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
      props.setHomeWarning(null)
    }
  };
  const { t } = props;
  return (
    <div className="warning-modal">
      <div className="warning-modal-header">
        <div
          className="icon-button warning-modal-icon-button"
          onClick={handleClose}
        >
          <img width='16' src={close_icon} alt="Закрыть" />
        </div>
      </div>
      <div className="warning-modal-content">
        <img width='60' src={infoRed} alt="red-info-icon" className="red-info-icon" />
        <p className="warning-modal-title">{t("warning")}</p>
        <div className='modal-text-container'>
          <p className="warning-modal-text">
            {props.homeWarning}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({monitoring}) => ({
  warning: monitoring?.consultations?.warning,
})

const enhance = compose(withRouter, connect(mapStateToProps, { getAllSpecializations, getConsultationsWithParams }));

export default enhance(Monitoring);
