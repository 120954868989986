import React, { useEffect, useState } from "react";
import DatePicker from "react-datetime";
import moment from "moment";
import IMask from "imask";

import { formatDate } from "../../../../helpers/values";

import cm from "classnames";
import s from "./datePicker.scss";

import "moment/locale/uk";
import "moment/locale/ru";

import "react-datetime/css/react-datetime.css";

import { IMaskInput } from "react-imask";

const DatePickerUI = ({
  name,
  disabled,
  value,
  onChange,
  visited,
  error,
  placeholder,
  validDate,
  closeOnSelect = true,
  touched,
  date,
  setDate,
  isValidDate,
  maxDate,
  ...props
}) => {
  const [isOpen, SetIsOpen] = useState(false);
  useEffect(() => {
    // console.log(localStorage.getItem('lang'));
    moment.locale(localStorage.getItem("lang"));
  }, []);

  // console.log(isOpen);

  // const checkIfIsOpen = (e) => {
  //   console.log(e.target.id);
  // }

  // useEffect(() => {
  //   window.addEventListener('click', checkIfIsOpen)
  //   return () => {
  //     window.removeEventListener('click', checkIfIsOpen)
  //   }
  // }, [])

  // useEffect(() => {
  //   // if (value.length > 10) {
  //   //   onChange(moment(value).format("DD/MM/YYYY"));
  //   // }
  //   console.log("useEffect value: ", value);
  //   SetIsOpen(false);
  // }, [value]);

  var adulthood =
    validDate && moment().subtract(validDate.number, validDate.type);

  const valid = (current) =>
    validDate.period
      ? current[validDate.period](adulthood)
      : validDate(current);

  const formatDate = (date) => {
    if (!date) return;
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return [day, month, year].join("/");
  };

  const parseToDate = (str) => {
    if (!str) return;
    const [day, month, year] = str.split("/");
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  return (
    <>
      <DatePicker
        className={cm("datePicker", {
          error: visited && error,
        })}
        id='datePickerComponent'
        value={value}
        name={name}
        timeFormat={false}
        closeOnSelect={closeOnSelect}
        dateFormat="DD/MM/YYYY"
        onChange={(value) => {
          console.log("in children OnChange", value);
          onChange(value);
        }}
        inputProps={{ placeholder }}
        // isValidDate={validDate && valid}
        isValidDate={isValidDate}
        viewDate={validDate ? new Date(adulthood) : new Date()}
        disabled={disabled}
        // open={isOpen}
        closeOnClickOutside={true}
        renderInput={(props, openCalendar) => {
          console.log(props);
          return (
            { ...props },
            (
              <IMaskInput
                mask={Date}
                value={value}
                lazy={false}
                onInput={(e) => {
                  console.log(e.target.value);
                  onChange(e.target.value);
                }}
                onClick={(e) => openCalendar()}
                onFocus={(e) => openCalendar()}
                pattern={"d{/}m{/}Y"}
                blocks={{
                  d: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 31,
                  },
                  m: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 12,
                  },
                  Y: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: maxDate,
                  },
                }}
                format={formatDate}
                parse={parseToDate}
              />
            )
          );
        }}
        {...props}
      />
      {/* {console.log("children", props.children)} */}

      {props.children && props.children && (
        <div className="children flex">{props.children}</div>
      )}
    </>
  );
};

export default DatePickerUI;
