import React from "react";

function useOnClickOutside(ref, handler, exception) {
  React.useEffect(
    () => {
      const listener = (event) => {
        event.stopPropagation()
        console.log(event.target.classList);
        if ([...event.target.classList].includes("addressText")) {
          return
        }
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        setTimeout(() => {
          handler(event);
        }, 0)
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export default useOnClickOutside;