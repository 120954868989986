import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { sendChatMessage } from "../../../redux/chat/actions";
import "./ConsultationCard.scss";
import {
  canselConsultation,
  fillingCard,
  finishConsultation,
  getCardDataOptions,
  getConsultationAnamnesis,
  getCurrentConsultation,
  postConsultationAnamnesis,
  resetAnamnesis, resetCurrentConsultation,
} from "../../../redux/consultations/actions";
import { getConsultAnamnesis, getCardOptions, getCurrentConsultCardData } from "../../../redux/consultations/selectors";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { getSpecialtySelect } from "../../../redux/specializations/selectors";
import { parserForm } from "../../../utils/parserForm";

import Anamnez from "../Anamnez/NewAnamnez";
import CardData from "../CardData/newCardData";
import { SimpleForm } from "../formComponent";
import Toggle from "../formComponent/Toggle/NewToggle";
import { modes, modesValue, statuses } from "./values";

const ConsultationCardNew = ({
  chat,
  anamnesis,
  cardOptions,
  getCardDataOptions,
  getConsultationAnamnesis,
  specializations,
  getAllSpecializations,
  postConsultationAnamnesis,
  fillingCard,
  sendChatMessage,
  finishConsultation,
  canselConsultation,
  closedCardData,
  resetAnamnesis,
  reset,
  status,
  videoMode = false,
}) => {
  const [mode, setMode] = React.useState(modesValue.anamnez)
  const [disease, setDisease] = React.useState([])
  const [diagnostic, setDiagnostic] = React.useState([])
  const [specialties, setSpecialties] = React.useState([])
  const [analysis, setAnalysis] = React.useState([])
  const [drugs, setDrugs] = React.useState([])
  ///BUTTON SPINNERS
  const [saveLoading, setSaveLoading] = React.useState(false)
  const [cancelLoading, setCancelLoading] = React.useState(false)

  const history = useHistory()
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const consultId = new URLSearchParams(history.location.search).get("consultId");
  const patientId = new URLSearchParams(history.location.search).get("user");

  React.useEffect(() => {
    if(videoMode) return;

    if (consultId) {
      getCardDataOptions()
      getAllSpecializations()
      getConsultationAnamnesis(consultId)
    }
    return () => {
      dispatch(resetCurrentConsultation())
      resetAnamnesis()
    }
  }, [])


  ///AUTOFILLING FORM WHEN CONSULT IS CLOSED
  React.useEffect(() => {
    if (status === statuses.CLOSED && closedCardData) {
      setDisease(closedCardData.disease)
      setDrugs(closedCardData.drugs)
      setSpecialties(closedCardData.specializations)
      setDiagnostic(closedCardData.diagnostic)
      setAnalysis(closedCardData.analysis)
    }
    return () => {
      setDisease([])
      setDrugs([])
      setSpecialties([])
      setDiagnostic([])
      setAnalysis([])
    }
  }, [status, closedCardData])

  ///ANAMNEZ || CARD DATA
  const onModeChange = (mode) =>
    setMode(mode)
  
  
  ///SAVING CONSULTATION
  const formSubmit = async (event, resetForm) => {
    event.preventDefault();
    const { ...fields } = parserForm(event)
    const confirm = window.confirm(t("submitConfirm"))
    if (!confirm) return

    setSaveLoading(true)

    const fillCardData = {
      id: consultId,
      data: {
        appointmentCard: {
          complaintsText: fields.complaintsText,
          currentAnamnesisText: fields.currentAnamnesisText,
          allergicText: fields.allergicText,
          pastIllnessText: fields.pastIllnessText,
          treatmentPlanText: fields.treatmentPlanText,
          conclusionText: fields.conclusionText,
          analysis: analysis.map(analis => analis.id),
          diagnostic: diagnostic.map(diagnostic => diagnostic.id),
          disease: disease.map(disease => disease.id),
          drugs: drugs.map(drug => drug.id),
          specialtyRecommend: specialties.map(specialty => specialty.id),
        }
      }
    }

    const chatMessageData = {
      type: "message",
      text: `${t("finishConsultationMessage")}${fields.conclusionText}`,
      some: "param",
      appointmentId: consultId,
      userId: patientId,
    }

    const anamnezData = {
      appointment_id: consultId,
      anamnezField: anamnesis,
      values: fields,
    }

    try {
      await fillingCard(fillCardData)
      sendChatMessage(chatMessageData)
      await postConsultationAnamnesis(anamnezData)
      await finishConsultation({ id: consultId })
      resetForm()
      setSaveLoading(false)
      history.push("/home/doctor/monitoring?page=1&period=All")
    } catch (error) {
      setSaveLoading(false)
      console.log(error.message);
    }
  }

  const handleCancelConsult = async () => {
    const confirmed = window.confirm(t("cancelConfirm"))
    if (!confirmed) return
    try {
      setCancelLoading(true)
      await canselConsultation({ id: consultId })
      setCancelLoading(false)
      history.push("/home/doctor/monitoring?page=1&period=All")
    } catch (error) {
      console.log(error.message);
      setCancelLoading(false)
    }
  }

  ///PROPS FOR FilterSelect Component
  const inputProps = {
    disease: {
      onChange: (diseases) => setDisease(diseases),
      value: disease,
    },
    specialties: {
      onChange: (specialties) => setSpecialties(specialties),
      value: specialties
    },
    diagnostic: {
      onChange: (diagnostics) => setDiagnostic(diagnostics),
      value: diagnostic,
    },
    analysis: {
      onChange: (analysis) => setAnalysis(analysis),
      value: analysis
    },
    drugs: {
      onChange: (drugs) => setDrugs(drugs),
      value: drugs
    },
  }
  
  const classNameConsultCard = chat && chat.mode ? "consultCard fullscreen" : "consultCard";
  const anamnezClassName = `dd1 ${mode === modesValue.anamnez ? "" : "none"}` 
  const cardDataClassName = `dd2 ${mode === modesValue.cardData ? "" : "none"}` 
  
  return (
    <div className={classNameConsultCard}>
      <p className="medCardTitle">{t("consultationCard")}</p>
      <SimpleForm
        handleSubmit={formSubmit}
        autoComplete="off"
        novalidate
      >
        <Toggle
          value={mode}
          onChange={onModeChange}
          options={modes}
        />
        <div className="consultCartFormContent">
          <div className="formContent">
            <div className={anamnezClassName}>
              <Anamnez status={status} fields={anamnesis} />
            </div>
            <div className={cardDataClassName}>
              <CardData
                status={status}
                closedCardData={closedCardData}
                saveLoading={saveLoading}
                cancelLoading={cancelLoading}
                handleCancelConsult={handleCancelConsult}
                diseaseOptions={cardOptions.disease}
                specializationsOptions={specializations}
                diagnosticOptions={cardOptions.diagnostic}
                analysisOptions={cardOptions.analysis}
                drugsOptions={cardOptions.drugs}
                inputProps={inputProps}
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </div>
  );
}

const mapDispatchToProps = {
  getCardDataOptions,
  getAllSpecializations,
  getConsultationAnamnesis,
  fillingCard,
  canselConsultation,
  postConsultationAnamnesis,
  finishConsultation,
  getCurrentConsultation,
  sendChatMessage,
  resetAnamnesis,
};

const mapStateToProps = (state) => ({
  specializations: getSpecialtySelect(state),
  appointments: state.patients && state.patients.appointments,
  status: state.monitoring?.consultations?.currentConsultation?.status,
  closedCardData: getCurrentConsultCardData(state),
  anamnesis: getConsultAnamnesis(state),
  cardOptions: getCardOptions(state),
  chat: state.chat,
});

const ConsultationCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default ConsultationCard(ConsultationCardNew);
