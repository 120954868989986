import React, { useEffect, useState } from "react";
import { getFormValues } from "redux-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import "moment/locale/uk";
import "moment/locale/ru";

import {
  filterSet,
  getAllShifts,
  getDistricts,
  getProvinces,
  setFilterMode,
  setPagination,
} from "../../../redux/shedule/actions";
import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";
import { getDoctorsByFilter, getDoctorsByFilterPrimary } from "../../../redux/employees/actions";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import greyPlus from "../../../style/icons/PlusInCircle/plus-grey.svg";
import supportOnline from "../../../style/icons/Headphones/headphones_26x26.svg";
import supportHome from "../../../style/icons/Home/home_28x28.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";

import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";
import Button from "../ui/Button/Button";

import "./sheduleList.scss";
import { getSheduleListsByDate } from "../../../redux/shedule/selectors";

const SheduleList = ({
  loading,
  setLoading,
  shedule,
  role,
  getAllShifts,
  setInitalValues,
  filterMode,
  filterItemsCount = {
    countAll: "",
    countToday: "",
    countTomorrow: "",
    countWeek: "",
  },
  setFilterMode,
  setCreatingTrue,
  items = [],
  create,
  setEdit,
  edit,
  specializations,
  getDoctorsByFilter,
  getDoctorsByFilterPrimary,
  page_count = 0,
  current_page,
  setPagination,
  formInitialValues,
  resetInitialValues,
  filterParams,
  isItemsFiltered,
  filterSet,
  monitoringFilterValues,
  newItems,
  getProvinces,
  getDistricts,
}) => {
  const [dateList, setDateList] = useState({});
  const [sortDateArr, setSortDateArr] = useState([]);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    setLoading(true);
    if (Object.keys(filterParams).length >= 1) {
      filterSet({ ...filterParams, filterMode }).then(() => setLoading(false));
    } else {
      getAllShifts(filterMode).then(() => setLoading(false));
    }
  }, [filterMode]);

  useEffect(() => {
    getProvinces()
  }, [])
  
  //TODO ==> separate all async features from React Components
  const handleEdit = async (item) => {
    let doc;
    let spec = specializations.find(
      (el) => el.title === item.specialty);
    await getDoctorsByFilterPrimary([spec.id]).then(({ data }) => {
      let x = data.items.find(
        (employ) =>
          employ.employee.fullName === item.fullName);
      if (x) {
        return (doc = {
          imgUrl: x.employee.photoUrl,
          title: x.employee.fullName,
          id: x.id,
          specialty: x.specialty,
        });
      } else {
        return (doc = null);
      }
    });
    if (doc) {
      console.log(item);
      setEdit(true);
      setInitalValues({
        ...item,
        ...item.period,
        district: item.districtScope == 1 ? item.district.map(el => ({...el, imgUrl: home})) : 'Все районы',
        itemId: item.id,
        specialty: spec,
        doctor: doc,
        serviceFormat:
          item && item.period.type === 0
            ? {
              imgUrl: support,
              title: "Онлайн",
              type: 0,
            }
            : {
              imgUrl: home,
              title: "Визит на дом",
              type: 1,
            },
        period: moment(item.period.startDate).format("DD/MM/YYYY"),
        startDate: moment(item.period.startDate).format("HH:mm"),
        endDate: moment(item.period.endDate).format("HH:mm"),
        deleteEditPeriodId: item.period.id,
        scheduleItem: {
          ...items.find((el) => el.id === item.id),
          doc,
        },
        fillType: { value: "hand", text: "Вручную" },
        duration: `${item.period.duration}`,
      });
    }
  }
  return (
    <div className="sheduleListContainer">
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}

      <>
        <div className="titleWithFilters">
          <div className="title">{t("scheduleListTitle")}</div>
          <div className="filters">
            <div
              className={`filter ${
                filterMode === "all" ? "active" : ""
              }`}
              onClick={() => !loading && setFilterMode("all")}
            >
              {t("filterAll") + " " + filterItemsCount.countAll}
            </div>
            <div
              className={`filter ${
                filterMode === "today" ? "active" : ""
              }`}
              onClick={() => !loading && setFilterMode("today")}
            >
              {`${t("filterToday")} ${filterItemsCount.countToday}`}
            </div>
            <div
              className={`filter ${
                filterMode === "tomorrow" ? "active" : ""
              }`}
              onClick={() => !loading && setFilterMode("tomorrow")}
            >
              {`${t("filterTomorrow")} ${filterItemsCount.countTomorrow}`}
            </div>
            <div
              className={`filter ${
                filterMode === "week" ? "active" : ""
              }`}
              onClick={() => !loading && setFilterMode("week")}
            >
              {`${t("filterWeek")} ${filterItemsCount.countWeek}`}{" "}
            </div>
          </div>

          <Button
            type="button"
            className="btn alignCenter flex noBackground"
            secondary
            withIcon
            icon={create || edit ? greyPlus : plus}
            text={t("addSchedule")}
            onClick={() => setCreatingTrue()}
          />
        </div>
        <div
          className={`sheduleItemsContainer ${
            items && items.length > 0 ? "" : "empty"
          }`}
        >
          {!loading && newItems && Object.keys(newItems).map((el, i) => (
            <div className="shedulePeriod" key={i}>
              <div className="date">
                  {el}
              </div>
              {newItems[el].map((item, i) => (
                <div
                  key={i}
                  className={`sheduleItem ${edit &&
                      (item.period && item.period.id) ===
                      (formInitialValues &&
                        formInitialValues.deleteEditPeriodId)
                      ? "editItem"
                      : edit
                        ? "editMode"
                        : ""
                    }`}
                  onClick={() => handleEdit(item)}
                >
                  <div className="id">
                    <div className="title">ID</div>
                    <div className="idInfo">#{item.id}</div>
                  </div>
                  <div className="doc">
                    <div className="title">{t("specializationTitle")}</div>
                    <div className="docInfo">
                      <img src={item.photo || docIcon} alt="doc-avatar" />
                      <div className="details">
                        <span className="name">
                          {item && item.fullName}
                        </span>
                        <span className="spec">
                          {item && item.specialty}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="shift">
                    <div className="title">{t("appointmentsTime")}</div>
                    <div className="shiftInfo">
                      {`${t("from")} ${moment(item.period.startDate).format(
                        'HH:mm'
                      )}
                           ${t("to")} ${moment(item.period.endDate).format(
                        "HH:mm"
                      )}`}
                    </div>
                  </div>
                  <div className="duration">
                    <div className="title">{t("durationTitle")}</div>
                    <div className="durationInfo">
                      {`${item.period.duration} ` + t("min")}
                    </div>
                  </div>
                  <div className="action">
                    <img
                      src={
                        item.period && item.period.type === 0
                          ? supportOnline
                          : supportHome
                      }
                      alt="action-icon"
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
          {!loading && !newItems && (
            <p className="sheduleEmptyMessage">{t("emptyList")}</p>
          )}
          {/* {!loading &&
          dateList &&
          dateList &&
          Object.keys(dateList) &&
          Object.keys(dateList).length &&
          sortDateArr &&
          sortDateArr.length ? (
            sortDateArr &&
            sortDateArr.length &&
            sortDateArr.map((el, i) => (
              <div className="shedulePeriod" key={i}>
                <div className="date">
                  {el && moment(el).format("DD MMMM YYYY")}
                </div>
                {dateList &&
                  dateList &&
                  Object.keys(dateList) &&
                  Object.keys(dateList).length > 0 &&
                  dateList[el] &&
                  dateList[el].map((item, index) => (
                    <div
                      key={index}
                      className={`sheduleItem ${
                        edit &&
                        (item.period && item.period.id) ===
                          (formInitialValues &&
                            formInitialValues.deleteEditPeriodId)
                          ? "editItem"
                          : edit
                          ? "editMode"
                          : ""
                      }`}
                      onClick={() => handleEdit(item)}
                    >
                      <div className="id">
                        <div className="title">ID</div>
                        <div className="idInfo">#{item.id}</div>
                      </div>
                      <div className="doc">
                        <div className="title">{t("specializationTitle")}</div>
                        <div className="docInfo">
                          <img src={item.photo || docIcon} alt="doc-avatar" />
                          <div className="details">
                            <span className="name">
                              {item && item.fullName}
                            </span>
                            <span className="spec">
                              {item && item.specialty}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="shift">
                        <div className="title">{t("appointmentsTime")}</div>
                        <div className="shiftInfo">
                          {`${t("from")} ${moment(item.period.startDate).format(
                            "HH:mm"
                          )}
                           ${t("to")} ${moment(item.period.endDate).format(
                            "HH:mm"
                          )}`}
                        </div>
                      </div>
                      <div className="duration">
                        <div className="title">{t("durationTitle")}</div>
                        <div className="durationInfo">
                          {`${item.period.duration} ` + t("min")}
                        </div>
                      </div>
                      <div className="action">
                        <img
                          src={
                            item.period && item.period.type === 0
                              ? supportOnline
                              : supportHome
                          }
                          alt="action-icon"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <>
              {!loading && (
                <p className="sheduleEmptyMessage">{t("emptyList")}</p>
              )}
            </>
          )} */}
        </div>
        {!loading && page_count !== 0 && sortDateArr && (
          <Paginator
            current_page={current_page}
            role={role}
            monitoringFilterValues={{
              allOrInWork:
                monitoringFilterValues &&
                monitoringFilterValues.allOrInWork &&
                monitoringFilterValues.allOrInWork.value,
              specialization:
                monitoringFilterValues && monitoringFilterValues.specialization,
              dateStart:
                monitoringFilterValues &&
                monitoringFilterValues.dateStart &&
                monitoringFilterValues.dateStart,
              dateEnd:
                monitoringFilterValues &&
                monitoringFilterValues.dateEnd &&
                monitoringFilterValues.dateEnd,
              status: monitoringFilterValues && monitoringFilterValues.status,
            }}
            setPagination={setPagination}
            setLoading={setLoading}
            filterMode={filterMode}
            isItemsFiltered={isItemsFiltered}
            page_count={page_count}
            scrollElement="sheduleItemsContainer"
          />
        )}
      </>
    </div>
  );
};

const mapStateToProps = ({ shedule, formInitialValues, ...state }) => ({
  shedule,
  newItems: getSheduleListsByDate(shedule),
  filterMode: shedule?.shedule?.filterMode,
  filterItemsCount: shedule && shedule?.shedule.count,
  defaultFilter: shedule?.shedule?.defaultFilter,
  items: shedule && shedule?.shedule?.items,
  page_count: shedule?.shedule?.page_count,
  current_page: shedule?.shedule?.current_page,
  isItemsFiltered: shedule && shedule?.shedule?.isItemsFiltered,
  monitoringFilterValues: getFormValues("monitoringFilters")(state),
  formInitialValues,
});

export default connect(mapStateToProps, {
  getAllShifts,
  setFilterMode,
  filterSet,
  setInitalValues,
  resetInitialValues,
  getDoctorsByFilter,
  getDoctorsByFilterPrimary,
  setPagination,
  getProvinces,
  getDistricts,
})(SheduleList);
