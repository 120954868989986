import React, { useEffect, useRef } from "react";
import clearField from "../../../../style/icons/PlusInCircle/field-clear.svg";
import "./input.scss";

const Input = ({
  input,
  disabled,
  placeholder,
  noLabel,
  meta: { touched, error, visited },
  meta,
  icon,
  type,
  label,
  clearInput,
  handleError,
  mask,
  defaultValue,
  time,
  priceFormat,
  erorrValidate,
  errorRequire,
  twoSectionMode,
  title = "",
  allowSpaces,
}) => {
  const inputEl = useRef(null);

  const clearInputField = () => {
    input.onChange("");
  };

  function isValidNumber(string) {
    return new RegExp(/^\d*(\.\d{0,2})?$/).test(string)
      ? string
      : string.substring(0, string.length - 1);
  }

  useEffect(() => {
    if (defaultValue && (!input.value || input.value === "")) {
      input.onChange(defaultValue);
    }
    if (defaultValue && input.value) {
      console.log(input.value.trim());
      input.onChange(input.value.trim());
    }
  }, [defaultValue]);

  useEffect(() => {
    if (priceFormat) {
      input.onChange(isValidNumber(input.value));
    }
  }, [input.value]);

  const isInputValue = !!input.value;
  const isEmpty = input.value.length < 1;
  return (
    <>
      {!twoSectionMode && (
        <div className="formField">
          <div className={touched && error ? "fieldInput error" : "fieldInput"}>
            {!noLabel &&
              placeholder &&
              (meta.active || touched || isInputValue) &&
              isInputValue &&
              !error &&
              !handleError && (
                <label className="inputLabel">{placeholder}</label>
              )}
            {type === "time" && isEmpty && (
              <label className="inputLabel">{placeholder}</label>
            )}
            <input
              className={`${visited && error ? "withError" : ""}`}
              type={type || "text"}
              min={time && "00:00"}
              max={time && "23:59"}
              ref={inputEl}
              {...input}
              value={input.value}
              onBlur={() => {
                if (!input.value) {
                  inputEl.current.style.padding = "15px";
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              onChange={(e) =>
                input.onChange(
                  !allowSpaces ? e.target.value.trim() : e.target.value
                )
              }
              // onKeyDown={() => {
              //   inputEl.current.style.padding = "24px 15px 14px 15px";
              // }}
              onKeyUp={mask && ((e) => mask(e, input))}
            />
            {isInputValue && clearInput && (
              <img
                className="clearInputFieldBtn"
                src={clearField}
                alt="clear-field"
                onClick={clearInputField}
              />
            )}
          </div>
          {error && visited && errorRequire && !isInputValue && (
            <p className="formItemError">{errorRequire}</p>
          )}
          {error && isInputValue && erorrValidate && (
            <p className="formItemError">{erorrValidate}</p>
          )}
          {!handleError && error && isInputValue && (
            <p className="formItemError">{error}</p>
          )}
          {handleError && <p className="formItemError">{handleError}</p>}
        </div>
      )}
      {twoSectionMode && (
        <div className="formFieldTwoSection">
          <div className="inputTitle">{title}</div>
          <input
            className={`${visited && error ? "withError" : ""}`}
            type={type || "text"}
            min={time && "00:00"}
            max={time && "23:59"}
            ref={inputEl}
            {...input}
            onBlur={() => {
              if (!input.value) {
                inputEl.current.style.padding = "15px";
              }
            }}
            disabled={disabled}
            placeholder={placeholder}
            onKeyUp={mask && ((e) => mask(e, input))}
          />
        </div>
      )}
    </>
  );
};

export default Input;
