import React, { useState } from "react";
import NewBannerForm from "../../forms/NewBannerForm/NewBannerForm";
import DashBoard from "../../components/DashBoard/DashBoard";

const Banner = () => {
  const [edit, setEdit] = useState(false);
  const [addingImg, setAddingImg] = useState(false);
  const [blob, setBlob] = useState(null);

  return (
    <div className="bannersContainer flex justifyBeetwen">
      <DashBoard
        edit={edit}
        setEdit={() => setEdit(true)}
        setAddingImg={setAddingImg}
        setBlob={setBlob}
      />
      <NewBannerForm
        edit={edit}
        setEditingOff={() => setEdit(false)}
        blob={blob}
        setBlob={setBlob}
        addingImg={addingImg}
        setAddingImg={setAddingImg}
      />
    </div>
  );
};

export default Banner;
