import axios from "axios";
import { setHeader, TEST_API } from "../index";

export default {
  signin: (credentials) =>
    axios.post(`${TEST_API}/admin/login_check`, credentials).then((res) => {
      return res;
    }),

  me: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/employees/profile`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  sendPassLinkOnEmail: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/employees/${id}/password/send`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  setPass: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/employees/password`,
      // headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  sendFirebaseToken: (token) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices`,
      headers: setHeader(),
      data: JSON.stringify({ token }),
    };

    return axios(config)
      .then((res) => {
        console.log("Firebase token sent successfully");
      })
      .catch((err) => console.error("Send Firebase token Error:", err));
  },

  logOut: () => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/logout`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
};
