import { createSelector } from "reselect";
import i18n from "../../i18n"
import clinic from "../../style/icons/Clinic/Clinic.svg";

const defaultState = {};

const getHospitals = (state = defaultState) =>
  state.hospitals;

export const getHospitalsSelector = createSelector(
  [getHospitals],
  ({hospitals}) => hospitals ? hospitals.map(el => ({...el, imgUrl: clinic})) : []
);

export const getDepartmentsSelector = createSelector(
  [getHospitals],
  ({departments}) => departments ? departments.map(el => ({...el, title: el.addressText, imgUrl: clinic})) : []
);