import moment from "moment"

export const getServerTimeFormat = (dateArr) => {
  return dateArr.map(date => date && date !== 'Invalid date' ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
}

export const changeServerDateFormat = (date) => {
  return date && date !== "Invalid date" ? moment(date, "YYYY-MM-DD").format('DD/MM/YYYY') : ''
}

export const getDateFormat = (date, pattern) => {
  return date ? moment(date).format(pattern) : ""
}

export const getServerMoment = ({from, add }) => {
  const currentDate = moment(new Date()).format("YYYY-MM-DD")
  if (add && !from) {
    return moment(new Date()).add(1, add).format("YYYY-MM-DD")
  }
  if (add && from) {
    return moment(from, "YYYY-MM-DD").add(1, add).format("YYYY-MM-DD")
  }
  return currentDate
}