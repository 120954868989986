import React, { useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import { postConsultationAnamnesis } from "../../../../redux/consultations/actions";
import { getThePatientAnamnesis } from "../../../../redux/patients/actions";
import Input from "../../formComponent/Input/Input";
import Button from "../../ui/Button/Button";
import Spinner from "../../ui/Spinner/Spinner";

import "./editLifeAnamnezForm.scss";

const EditAnamnezForm = ({
  fields,
  handleSubmit,
  edit,
  setEdit,
  postConsultationAnamnesis,
  getThePatientAnamnesis,
}) => {
  const [loading, setLoading] = useState(false);

  const match = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const consultId = new URLSearchParams(history.location.search).get(
    "consultId"
  );

  // console.log({ fields });
  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit((values) => {
      // console.log(values);
      postConsultationAnamnesis({
        appointment_id: consultId,
        anamnezField: fields,
        values: values.lifeAnamnesis,
      }).then((res) => {
        getThePatientAnamnesis(match.params.patient, match.params.card);
        setLoading(false);
        setEdit(false);
      }).finally(() => setLoading(false));
    })();
  };
  return (
    <>
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {!loading && (
        <Form
          onSubmit={formSubmit}
          autoComplete="off"
          className="editLifeAnamnezForm"
        >
          {fields &&
            fields.map((el) => (
              <Field
                key={el.id}
                name={`lifeAnamnesis.${el.id}`}
                component={Input}
                placeholder={el.title}
                //   label={
                //     formValues[`anamnezField.${i}`] === "" ||
                //     formValues[`anamnezField.${i}`] === undefined
                //       ? ""
                //       : item.title
                //   }
                defaultValue={
                  (el && el.anamnesis && el.anamnesis.content) || ""
                }
                disabled={!edit}
                //   disabled={status === "cancelled" || status === "closed"}
              />
            ))}
          {edit && (
            <Button
              loading={loading}
              className="btn"
              type="submit"
              text={t("saveButton")}
            />
          )}
        </Form>
      )}
    </>
  );
};

const mapDispatchToProps = {
  //   setInitalValues,
  //   resetInitialValues,
  //   updatePatientCard,
  //   getPatientCards,
  //   getThePatient,
  getThePatientAnamnesis,
  postConsultationAnamnesis,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("anamnesis_edit")(state),
  relations: state.patients && state.patients.relations,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "anamnesis_edit", enableReinitialize: true })
);

export default enhance(EditAnamnezForm);
