import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevroRight from "../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import emptyImage from "../../../style/icons/EmptyImg/emptyImg.svg";

import { Form, Field, getFormValues, reduxForm } from "redux-form";
import { voidRequired } from "../../../helpers/validate";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import Button from "../../components/ui/Button/Button";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";
import Input from "../../components/formComponent/Input/Input";
import Checkbox from "../../components/formComponent/Checkbox/Checkbox";

import "./onboardingForm.scss";
import {
  createNewOnboarding,
  updateOnboarding,
  deleteOnboarding,
  uploadOnboardingPhoto,
  getAllOnboarding,
} from "../../../redux/onboarding/actions";

const NewOnboardingForm = ({
  edit,
  setEditingOff,
  resetInitialValues,
  setInitalValues,
  reset,
  handleSubmit,
  formValues,
  invalid,
  initialValues,
  createNewOnboarding,
  updateOnboarding,
  deleteOnboarding,
  uploadOnboardingPhoto,
  getAllOnboarding,
  blob,
  setBlob,
  addingImg,
  setAddingImg,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    handleSubmit((values) => {
      if (edit && !deleting) {
        updateOnboarding(values.id, values).then(
          ({
            res: {
              entity: { id },
            },
          }) => {
            console.log("succes upd in id = ", id);
            if (blob) {
              uploadOnboardingPhoto(values.id, blob).then(() =>
                getAllOnboarding()
              );
            }
            getAllOnboarding();
          }
        )
        .finally(() => setLoading(false));
      }
      if (edit && deleting) {
        deleteOnboarding(values.id)
          .then(() => getAllOnboarding())
          .finally(() => setLoading(false));
      }
      if (!edit) {
        createNewOnboarding(values).then(
          ({
            res: {
              entity: { id },
            },
          }) => {
            console.log({ id });
            if (blob) {
              uploadOnboardingPhoto(id, blob).then(
                (
                  {
                    res: {
                      entity: { id },
                    },
                  },
                  ...res
                ) => {
                  console.log(res);
                  console.log("upload pfoto in onboarding item with id: ", id);
                  getAllOnboarding();
                }
              );
            }
          }
        )
        .finally(() => setLoading(false))
      }
      resetInitialValues();
      reset();
      setEditingOff();
    })();
  };

  return (
    <div className="wrappNewOnboardingForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
    ${edit ? t("edit") : t("newOnBoard")}`}
        {edit && (
          <img
            src={close}
            alt="arrow-icon"
            onClick={() => {
              setEditingOff();
              resetInitialValues();
              reset();
            }}
          />
        )}
      </div>
      <Form className="newOnboardingForm" onSubmit={formSubmit}>
        <div className="formContent">
          <div className={`addingImg ${addingImg ? "show" : ""}`}>
            <div className="formTitle">
              <img
                src={arrowLeft}
                alt="arrow-icon"
                onClick={() => {
                  setAddingImg(false);
                  setEditingOff();
                }}
              />
              {t("addImage")}
            </div>
            <div className="photoUploadFieldContainer">
              <Field
                name="photo"
                // validate={[voidRequired]}
                component={FileUploader}
                previewTrashIcon={trash}
                uploadReq={() => console.log("action")}
                close={() => setAddingImg(false)}
                setBlob={setBlob}
                editingImgUrl={
                  initialValues &&
                  initialValues.photo &&
                  initialValues.photo.cropImg
                }
                setEditingOff={setEditingOff}
                t={t}
              />
            </div>
          </div>
          {formValues && formValues.photo && formValues.photo.cropImg ? (
            <div className="croppImage">
              <div
                className="trashIcon"
                onClick={() => {
                  reset();
                  setInitalValues({
                    ...formValues,
                    photo: null,
                  });
                }}
              >
                <img src={trash} alt="" />
              </div>
              <div className="background">
                <img
                  src={
                    formValues && formValues.photo && formValues.photo.cropImg
                  }
                  alt=""
                />
              </div>
              <div className="fileName">
                {(formValues &&
                  formValues.photo &&
                  formValues.photo.fileName) ||
                  emptyImage}
              </div>
            </div>
          ) : (
            <Button
              secondary
              withIcon
              icon={plus}
              shevron={shevroRight}
              type="button"
              className="btn"
              text={t("addImage")}
              onClick={() => setAddingImg(true)}
            />
          )}

          <Field
            name="text"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("placeholderText")}
            allowSpaces
          />

          <Field
            id="isActive"
            name="isActive"
            // validate={[voidRequired]}
            component={Checkbox}
            label={t("showInList")}
          />
        </div>

        <div className={`formFooter ${edit ? "edit" : ""}`}>
          {!edit ? (
            <Button
              className="btn"
              disabled={invalid}
              type="submit"
              text={t("addButton")}
              loading={loading}
            />
          ) : (
            <>
              <Button
                className="btn"
                disabled={invalid}
                type="submit"
                text={t("saveButton")}
                loading={loading}
              />
              <Button
                className="btn"
                disabled={invalid}
                danger
                type="submit"
                text={t("deleteButton")}
                onClick={() => setDeleting(true)}
                loading={loading}  
              />
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  createNewOnboarding,
  updateOnboarding,
  deleteOnboarding,
  uploadOnboardingPhoto,
  getAllOnboarding,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("banner")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "banner", enableReinitialize: true })
);

export default enhance(NewOnboardingForm);
