import axios from "axios";
import { setHeader, TEST_API } from "../index";

export default {
  getAll: () =>
    axios({
      baseURL: `${TEST_API}/admin/onboarding`,
      method: "GET",
      headers: setHeader(),
    }).then((res) => res && res.data),

  createNew: (data) =>
    axios({
      baseURL: `${TEST_API}/admin/onboarding`,
      method: "POST",
      headers: setHeader(),
      data,
    }).then((res) => res && res.data),

  update: (id, data) =>
    axios({
      baseURL: `${TEST_API}/admin/onboarding/${id}`,
      method: "PUT",
      headers: setHeader(),
      data,
    }).then((res) => res && res.data),

  upload: (id, file) => {
    let formData = new FormData();
    formData.append("srcFile", file);
    return axios
      .post(`${TEST_API}/admin/onboarding/${id}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  delete: (id) =>
    axios({
      baseURL: `${TEST_API}/admin/onboarding/${id}`,
      method: "DELETE",
      headers: setHeader(),
    }).then((res) => res && res.data),
};
