import { combineReducers } from "redux";
import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = {};

const hospitalsReducer = createReducer(initialState)({
  [types.GET_ALL_HOSPITALS]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
  [types.GET_DEPARTMENT_BY_HOSPITAL_ID]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
});

const hospitals = createReducer([])({
  [types.GET_ALL_HOSPITALS]: (_, { type, ...data }) => data?.items || []
});

const departments = createReducer([])({
  [types.GET_DEPARTMENT_BY_HOSPITAL_ID]: (_, { type, ...data }) => data?.items || []
});

export default combineReducers({
  defaultHospitals: hospitalsReducer,
  hospitals,
  departments,
});
