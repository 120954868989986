import React, { useEffect } from "react";

import CheckboxUI from "../../ui/Checkbox/CheckBox";

import "./checkbox.scss";

const Checkbox = ({ input, name, id, disabled, defaultValue, label }) => {
  useEffect(() => {
    input.value === "" && input.onChange(false);
  });

  return (
    <div className={"wrapper_checkbox"}>
      <CheckboxUI
        {...input}
        name={name}
        id={id}
        label={label}
        disabled={disabled}
      />
    </div>
  );
};

export default Checkbox;
