import { createSelector } from "reselect";
import i18n from "../../i18n"
import docIcon from "../../style/icons/DocIcon/doc.svg";

const defaultState = {};

const getDoctors = (state = defaultState) =>
  state.employees;

export const getEmployeeItems = (state) => state.employees.items
export const getEmployeeCount = (state) => state.employees.total_item_count
export const getDoctorsCount = (state) => state.employees.countDoctors
export const getOperatorsCount = (state) => state.employees.countOperators
export const getPageCount = (state) => state.employees.page_count
export const getCurrentPage = (state) => state.employees.current_page
export const getAdminsCount = (state) => state.employees.countAdmins
export const getInsuranceCompaniesSelector = (state) => state.employees.insuranceCompanies || []

export const getDoctorsSelector = createSelector(
  [getDoctors],
  (employees) => {
    console.log(employees);
    const items = employees?.items ? employees.items.map((doctor) => ({
      imgUrl: doctor?.employee?.photoUrl || docIcon,
      title: doctor?.employee?.fullName,
      id: doctor.id,
      specialty: doctor.specialty,
    })) : []
    if (items.length > 0) {
      items.unshift({
        randomDoc: true,
        imgUrl: docIcon,
        specialty: [],
        title: i18n.t("randomDoc"),
      })
    }
    return items
  }
);

export const getRandomDoctorSelector = createSelector(
  [getDoctors],
  (employees) => employees?.randomDoctor ? ({
    imgUrl: employees?.randomDoctor?.employee?.photoUrl || docIcon,
    title: employees?.randomDoctor?.employee.fullName,
    id: employees?.randomDoctor.id,
    specialty: employees?.randomDoctor.specialty,
  }) : null
);