import * as types from "./types";
import createReducer from "../../utils/createReducer";
import {v4} from 'uuid'
const initialState = [];

//popup example
// const popup = {
//   id: v4(),
//   type: "ERROR" || "SUCCESS",
//   message: "message",
// } 

const popups = createReducer(initialState)({
  [types.ADD_NOTIFICATION]: (state, { notification }) => [...state, notification],
  [types.REMOVE_NOTIFICATIONS]: (state, {id}) => [...state.filter(field => field.id == id)],
});


export default popups