import * as types from "./types";
import api from "../../api";

export const getSymptomsBySpecializationId = (id) => (dispatch) => {
  return api.symptoms.getSymptomsBySpecializationId(id).then((res) => {
    return dispatch({
      type: types.GET_SYMPTOMS_BY_SPECIALIZATION_ID,
      ...(res && res.data),
    });
  });
};
