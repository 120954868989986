import { createSelector } from "reselect";

const defaultState = {
  items: []
}

const getSpecialties = (state) =>
  state.specialization || defaultState

export const getSpecialtiesSelector = createSelector(
  [getSpecialties],
  ({items}) => items ? items : []
);