import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Form, Field, getFormValues } from "redux-form";
import Toggle from "../formComponent/Toggle/Toggle";
import Button from "../ui/Button/Button";
import { useTranslation } from "react-i18next";

import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import DatePicker from "../formComponent/DatePicker/DatePicker";

import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import clinic from "../../../style/icons/Doc/doc-blue-in-grey.svg";

import "./sheduleFilter.scss";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllShifts, filterSet } from "../../../redux/shedule/actions";
import Spinner from "../ui/Spinner/Spinner";

const SheduleFilter = ({
  loading,
  setLoading,
  formValues = {},
  setInitalValues,
  resetInitialValues,
  mode,
  specializations,
  role,
  filterMode,
  handleSubmit,
  filterSet,
  getAllShifts,
  reset,
  setFilterParams,
  language: { lang },
}) => {
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const { t } = useTranslation();

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit((values) => {
      if (mode === "shedule") {
        setButtonLoading(true)
        setFilterParams({ ...values });
        filterSet({ filterMode, ...values }).finally(() => setButtonLoading(false));
      }
    })();
  };

  var chosenDateStart = formValues.dateStart;
  var valid = function (current) {
    return current.isSameOrAfter(chosenDateStart);
  };

  const resetFormHandler = () => {
    if (Object.keys(formValues).length >= 2) {
      getAllShifts();
      reset();
      resetInitialValues();
    }
    setFilterParams({});
  };

  return (
    <div className="wrappFilterForm">
      {loading ? (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="formTitle">
              {t("filterFormTitle")}
              <div onClick={resetFormHandler} className="resetButtonBox">
                <div className="resetText">{t("reset")}</div>
                <img
                  style={{margin: "0px"}}
                  className="resetForm"
                  title="Очистить форму"
                  src={close}
                  alt="closeIcon"
                />  
            </div>
            {/* <div className="resetButton">
              <div className="resetText">Сбросить</div>
              <img
                className="resetForm"
                title="Очистить форму"
                onClick={resetFormHandler}
                src={close}
                alt="closeIcon"
              />  
            </div> */}
          </div>
          <Form className="monitoringFilters" onSubmit={formSubmit}>
            <div className="formContent">
              {mode === "shedule" ? (
                <>
                  <Field
                    name="allOrActive"
                    component={Toggle}
                    options={[
                      { value: "all", text: t("filterAllSchedules") },
                      { value: "active", text: t("filterActiveSchedules") },
                    ]}
                  />
                </>
              ) : (
                <Field
                  name="allOrInWork"
                  component={Toggle}
                  options={[
                    { value: "all", text: t("filterAllSchedules") },
                    { value: "inWork", text: t("filterActiveSchedules") },
                  ]}
                />
              )}

              <div className="dateRange">
                <Field
                  name={`dateStart`}
                  inputProps={{
                    name: "dateStart",
                    // required: required,
                  }}
                  // validate={[required, date, maxDateNow]}
                  component={DatePicker}
                  placeholder="--/--/----"
                  disabled={filterMode !== "all"}
                />
                <Field
                  name={`dateEnd`}
                  inputProps={{
                    name: "dateEnd",
                    // required: required,
                  }}
                  // validate={[required, date, maxDateNow]}
                  component={DatePicker}
                  validDate={valid}
                  placeholder="--/--/----"
                  disabled={filterMode !== "all"}
                />
                <img
                  src={close}
                  alt="close"
                  onClick={async () => {
                    await setInitalValues({
                      ...formValues,
                      dateStart: null,
                      dateEnd: null,
                    });
                    let pickers = document.getElementsByClassName(
                      "form-control"
                    );
                    for (let i = 0; i < pickers.length; i++) {
                      const element = pickers[i];
                      element.value = "";
                    }
                  }}
                />
                <img src={calendar} alt="calendar" />
              </div>

              {mode !== "shedule" && role !== "doctor" && (
                <Field
                  name="specialization"
                  component={FilterSelect}
                  placeholder={t("placeholderSpecialties")}
                  options={specializations}
                  withSearch
                  multiple
                  withPreview
                  withIconInOptions
                />
              )}

              {mode === "shedule" && (
                <>
                  <Field
                    name="specialization"
                    component={FilterSelect}
                    placeholder={t("placeholderSpecialties")}
                    options={specializations}
                    withPreview={formValues && formValues.specialization}
                    previewIcon={plus}
                    icon={plus}
                    withIcon
                    withIconInOptions
                  />

                  <Field
                    name="serviceFormat"
                    component={FilterSelect}
                    placeholder={t("filterTypeOfService")}
                    options={[
                      {
                        imgUrl: support,
                        title: t("optionsServiceTypeOnline"),
                        type: 0,
                      },
                      {
                        imgUrl: home,
                        title: t("optionsServiceTypeAtHome"),
                        type: 3,
                      },
                      {
                        imgUrl: clinic,
                        title: t("optionsServiceTypeInHospital"),
                        type: 4,
                      },
                    ]}
                    withPreview
                    withIconInOptions
                    previewIcon={plus}
                    icon={plus}
                    withIcon
                  />
                </>
              )}
              {role === "doctor" && (
                <Field
                  name="serviceFormat"
                  component={FilterSelect}
                  placeholder={t("filterTypeOfService")}
                  options={[
                    { imgUrl: support, title: t("optionsServiceTypeOnline") },
                    { imgUrl: home, title: t("optionsServiceTypeAtHome") },
                  ]}
                  withPreview
                  withIconInOptions
                />
              )}
            </div>
            <div className="formFooter">
              <Button
                className="btn"
                disabled={
                  (!formValues.specialization ||
                    formValues.specialization === []) &&
                  !formValues.filterServiceFormat &&
                  !formValues.dateStart &&
                  !formValues.dateEnd
                }
                type="submit"
                text={t("acceptButton")}
                loading={buttonLoading}
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  filterSet,
  getAllShifts,
  resetInitialValues,
};

const mapStateToProps = ({ language, ...state }) => ({
  formValues: getFormValues("monitoringFilters")(state),
  filterMode: state?.shedule?.shedule.filterMode,
  language: language,
  // initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "monitoringFilters", enableReinitialize: true })
);

export default enhance(SheduleFilter);
