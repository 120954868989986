import i18n from "../../../i18n";
import chat from "../../../style/icons/Message/message3.svg";
import call from "../../../style/icons/Call/call2.svg";
import camera from "../../../style/icons/Camera/camera2.svg";
import visitAtHome from "../../../style/icons/Home/home.svg";
import clinic from "../../../style/icons/Clinic/Clinic.svg";
import noPayment from "../../../style/icons/NoPayment/noPayment.svg";
import moment from "moment"

export const getDateTimeFormat = ({day, hours}) => {
  return moment(`${day} ${hours}`, "YYYY-MM-DD HH:mm")
    .format("YYYY-MM-DD T HH:mm Z").split(" ").join('')
}

export const getExactDayAndHours = (date) => {
  if(!date) return {}
  const day = moment(date, "YYYY-MM-DD T HH:mm Z").format("YYYY-MM-DD")
  const hours = moment(date, "YYYY-MM-DD T HH:mm Z").format("HH:mm")
  return {day, hours}
}

export const paymentModes = [{ title: i18n.t("noPayment"), imgUrl: noPayment }]

export const consultationFormats =
  [
    {
      value: "TYPE_CHAT",
      title: i18n.t("optionsServiceTypeInChat"),
      imgUrl: chat,
      num_type: 0,
      method: 0,
    },
    {
      value: "TYPE_PHONE",
      title: i18n.t("optionsServiceTypeCall"),
      imgUrl: call,
      num_type: 0,
      method: 1,
    },
    {
      value: "TYPE_VIDEO",
      title: i18n.t("optionsServiceTypeInVideo"),
      imgUrl: camera,
      num_type: 0,
      method: 2,
    },
    {
      value: "TYPE_HOME",
      title: i18n.t("optionsServiceTypeAtHome"),
      imgUrl: visitAtHome,
      num_type: 1,
      method: 3,
    },
    {
      value: "TYPE_HOSPITAL",
      title: i18n.t("optionsServiceTypeInHospital"),
      imgUrl: clinic,
      num_type: 2,
      method: 4,
    },
  ]