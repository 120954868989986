import { createSelector } from "reselect";
import patientIcon from "../../style/icons/Subscription/patient_active.svg";
const defaultState = {};

const getSpecialties = (state = defaultState) =>
  state.specialization;

export const getSpecialtySelect = createSelector(
  [getSpecialties],
  (specialization) => specialization?.items ? specialization.items.map((spec) => ({
    imgUrl: spec.imageUrl || patientIcon,
    title: spec.title,
    id: spec.id,
  })) : []
);