import { createSelector } from "reselect";

const defaultState = { popups: []};

const getPopup = (state = defaultState) =>
  state.popups;

export const getPopups = createSelector(
  [getPopup],
  (popups) => popups
);

