import React, { useState, useEffect } from "react";

import DatePickerUI from "../../ui/DatePicker/DatePicker";

import deleteIconRed from "../../../../style/icons/CloseRed/close-red.svg";

import { formatDate } from "../../../../helpers/values";

import "../formComponents.scss";
import moment from "moment";

const DatePicker = ({
  label,
  input,
  meta: { touched, error, visited },
  placeholder,
  disabled,
  inputProps,
  validDate,
  tagsMode,
  locale,
  readOnly,
  isValidDate = () => true,
  maxDate = 2030,
  ...props
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [date, setDate] = useState([""]);
  const [stopSelecting, setStopSelecting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (selectedDates.length && tagsMode) {
      input.onChange({
        selectedDates: selectedDates,
      })
    }
  }, [selectedDates, tagsMode])


  useEffect(() => {
    console.log("input", input);
  }, [input]);

  useEffect(() => {
    if (input.value === "") {
      setSelectedDates([]);
    }
  }, [input.value]);

  const deleteDate = (index) => {
    setSelectedDates((selectedDates) =>
      selectedDates.filter((el, i) => (i !== index ? el : ""))
    );
  };

  // const normalizeDigits = (dateValue) => {
  //   if (typeof dateValue === "string" && !isDeleting) {
  //     const date = dateValue.replace(/[^\d/]/g, "");
  //     console.log(date);
  //     if (date.length === 2 && date !== "/") {
  //       return date + "/";
  //     }
  //     if (date.length === 5 && date !== "/") {
  //       return date + "/";
  //     }
  //     return date.slice(0, 10);
  //   } else {
  //     return dateValue;
  //   }
  // };

  const getIsSelected = (value) =>
    selectedDates.some((date) => date === value);

  const getCurrentValue = () => {
    const length = input?.value?.selectedDates?.length || 0
    return length
      ? input.value.selectedDates[length - 1]
      : input.value
  }
  return (
    <div className={"formField picker"}>
      {label ? <label>{label}</label> : ""}

      <DatePickerUI
        {...input}
        date={date}
        setDate={setDate}
        readOnly={readOnly}
        name={inputProps && inputProps.name}
        value={getCurrentValue()}
        // value={
        //   (input && input.value && input.value.value) || (input && input.value)
        // }
        onBlur={() => input.onBlur(input.value)}
        onChange={
          (value) => {
            console.log("in Parrent OnChange", value);
            console.log(moment(value).format("DD/MM/YYYY"));
            if (moment(value).format("DD/MM/YYYY") !== "Invalid date") {
              if (typeof value === "object") {
                console.log("type obj", moment(value._d).format("DD/MM/YYYY"));
                input.onChange(moment(value._d).format("DD/MM/YYYY"));
                if (tagsMode) {
                  setSelectedDates(prev => [...prev, moment(value._d).format("DD/MM/YYYY")])
                }
                return;
              }
              if (typeof value === 'string' && tagsMode) {
                input.onChange(value);
                if(getIsSelected(value)) return
                setSelectedDates(prev => [...prev, value])
              }
            }
            input.onChange(value);
          }
        }
        placeholder={placeholder}
        touched={touched}
        error={error}
        visited={visited}
        disabled={disabled}
        tagsMode={tagsMode}
        validDate={validDate}
        selectedDates={selectedDates}
        locale={locale || localStorage.getItem("lang")}
        isValidDate={isValidDate}
        maxDate={maxDate}
        {...props}
      >
        {selectedDates.length > 0 && (
          <div className="selectedDates">
            {selectedDates &&
              selectedDates.map((item, index) => (
                <span className="date" key={index}>
                  {item}
                  <img
                    src={deleteIconRed}
                    alt=""
                    onClick={() => deleteDate(index)}
                  />
                </span>
              ))}
          </div>
        )}
      </DatePickerUI>
      {disabled && <div className="hiddingEl" />}
      {visited && error && <span className={"formItemError"}>{error}</span>}
    </div>
  );
};

export default DatePicker;
