import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { createHistoryPush } from "../../../helpers/functions";
import { getAllDivisions } from "../../../redux/сompanies/actions";
import { usePaginationStyles } from "../../pages/PromoCodes/styles";
import Spinner from "../../components/ui/Spinner/Spinner";

import "./DivisionList.scss";
import { getDivisionsCurrentPageSelector, getDivisionsPageCountSelector, getDivisionsSelector } from "../../../redux/сompanies/selectors";

const DivisionList = ({
  companies,
  page_count,
  getAllDivisions,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = usePaginationStyles();
  const page = new URLSearchParams(history.location.search).get("page")
  
  const refreshDivisions = () => {
    setLoading(true)
    getAllDivisions(page)
      .then(() => setLoading(false))
  }

  useEffect(refreshDivisions, [page])

  const handleChangePage = (_, newPage) => {
    createHistoryPush({
      history,
      page: newPage,
    })
  }

  const DivisionItem = ({ title }) => (
    <div className="item-container">
      <p>{title}</p>
    </div>
  );

  return (
    <>
      <div className="page-title">
        <span className="title">{t("divisions")}</span>
      </div>
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="companiesListContainer">
          {companies && companies.map((company) => (
            <DivisionItem title={company.title} key={company.id} />
          ))}
        </div>
      )}
      <div className={classes.root}>
        <Pagination
          classes={{ ul: classes.ul }}
          count={page_count}
          color="primary"
          page={+page}
          defaultPage={1}
          size="large"
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  companies: getDivisionsSelector(state),
  current_page: getDivisionsCurrentPageSelector(state),
  page_count: getDivisionsPageCountSelector(state),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { getAllDivisions })
)

export default enhance(DivisionList)
