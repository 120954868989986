import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const resources = {
  ru: {
    translation: {
      deleteShiftConfirm: "Вы дейстительно хотите удалить смену?",
      shiftDurationError: "Д-сть консультации больше чем д-сть смены",
      usesLeft: "Осталось использований",
      onlyIntegers: "только целые числа",
      usesTotal: "Кол-во использований",
      office: "Офис",
      companyName: "Название компании",
      newMessage: "Новое сообщения",
      in: "в",
      fromTo: "от",
      reload: "Перезагрузить",
      newVersionText: "Вышла новая версия приложения. Пожалуйста, перезагрузите страницу",
      discount: "Скидка",
      resetChanges: "Отменить изменение",
      floor: "Этаж",
      entrance: "Подъезд",
      selectAll: "Выбрать все",
      removeAll: "Убрать все",
      reset: "Сбросить",
      inputAddress: "Введите адрес",
      activePeriod: "Срок действия",
      chooseDistrict: "Выберите район",
      chooseCity: "Выберите город",
      shiftTimeDifferenceError: "Время начала и конца одинаковое",
      photo: "Фото",
      province: "Город",
      confirmButton: "Подтвердить",
      changeAnamnezData: "Изменить данные анамнеза",
      divisions: "Филиалы",
      newDivisions: "Новый филиал",
      divisionsName: "Название филиала",
      //validates
      downloadCSV: "Скачать csv",
      requiredLoginValidate: "Введите логин",
      dd_mm_yyyyValidate: "Дата должна быть в формате хх/хх/хххх",
      phoneFormatValidate: "Номер телефона должен быть в формате +380ХХХХХХХХХ",
      durationTimeInMinValidate: "Допустимое значение 20, 30 или 60 минут",
      durationTimeInMinValidateHome: "Допустимое значение 90 или 180 минут",
      timeInMinValidate: "Время должно быть в формате 23ч:59 мин",
      emailValidate: "Неправильный формат e-mail",
      requiredPasswordValidate: "Введите пароль",
      requiredValidate: "Это обязательное поле",
      notNegativeValidate: "Не может быть отрицательным числом",
      createShiftButton: "Создать смену",
      cancelOfConsultation: "Отменить консультацию",
      consultationDate: "Время консультации",
      pushNotificationPageTitle: "Отправка PUSH уведомления",
      pushAgeFrom: "Возраст от",
      pushAgeTo: "Возраст до",
      pushTitle: "Заголовок уведомления",
      createPassword: "Установите пароль для вашего аккаунта",
      month: "Месяца",
      months: "Месяцев",
      newSubscription: "Новая подписка",
      percentileDiscount: "Процент скидки",
      priceDiscount: "Цена подписки",
      company: "Компания",
      subscriptionCreated: "Подписка создана",
      activateSubscription: "Активировать подписку",
      renewSubscription: 'Обновить подписку',
      addPatient: "Добавить пациента",
      isAdmin: "Администратор компании",
      //sendPDFFile
      exportButton: "Экспортировать",
      sendToEmail: "Отправить на эл. почту",
      sendPDF:
        "Пожалуйста, выберите страховую компанию, куда желаете отправить файл",
      sendSuccess: "Успешно отправлено",
      //Companies
      fileUploaded: "Файл успешно загружен",
      fileUploadError: "Ошибка при загрузке файла",
      subscriptionEdited: "Компания успешно обновлена",
      description: "Описание",
      shortDescription: "Краткое описание",
      noDescription: "Описания пока нет",
      companies: "Компании",
      noInsuranceCompany: "Без страховой компании",
      createCompany: "Создать компанию",
      deleteSuccess: "Компания успешно удалена",
      deleteSubText: "Вы действительно хотите удалить подписчика?",
      deleteSubSuccess: "Подписчик был успешно удален",
      //Success
      successOperatorUpdate: "Оператор успешно обновлён",
      successDoctorUpdate: "Доктор успешно обновлён",
      successOperatorDelete: "Оператор успешно удалён",
      successDoctorDelete: "Доктор успешно удалён",
      successBlockUser: "Сотрудник успешно заблокирован",
      successUnblockUser: "Сотрудник успешно разблокирован",
      successOperatorCreate: "Оператор успешно создан",
      successDoctorCreate: "Доктор успешно создан",
      // ERRORS
      failedBlockUser: "У сотрудника есть консультации",
      submitConfirm:
        "Вы уверены, что хотите сохранить изменения и завершить текущую консультацию?",
      cancelConfirm:
        "Вы уверены, что хотите закрыть и отменить текущую консультацию?",
      birthDateInFuture: "Невозможно родиться в будущем",
      templateTitle: "Шаблоны",
      min: "мин",
      from: "с",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону",
      medQuestion: "Мед. вопрос",
      techSupport: "Техподдержка",
      infoMedQueation: "B случае, если хотите уточнить медицинский вопрос",
      missedCall: "Пропущенный звонок",
      canceledCall: "Отменённый вызов",
      canceledConsultationMessage:
        "Уважаемый пациент, ваша консультация была отменена врачом.",
      finishConsultationMessage:
        "Ваша консультация завершена. Заключение врача: ",
      //ConsultConfirmation
      confirm: "Подтвердить",
      consultConfirmation: "Консультация назначена на ",
      doctorConfirmation: "Вы записаны к доктору ",
      at: "на",
      Января: "Января",
      Февраля: "Февраля",
      Марта: "Марта",
      Апреля: "Апреля",
      Мая: "Мая",
      Июня: "Июня",
      Июля: "Июля",
      Августа: "Августа",
      Сентября: "Сентября",
      Октября: "Октября",
      Ноября: "Ноября",
      Декабря: "Декабря",
      //Sider
      shifts: "Расписание",
      employees: "Сотрудники",
      specializations: "Специализации",
      subscriptions: "Подписки",
      rates: "Тарифы",
      patients: "Пациентов",
      promocodes: "Промокоды",
      onboarding: "Онбоардинг",
      client: "Клиент",
      //=====
      //MODAL
      helpModalText_1:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону:",
      helpModalText_2: "В случае, если хотите уточнить медицинский вопрос:",
      techSupportButton: "Техподдержка",
      medQuestionButton: "Мед. вопрос",
      help: "Помощь",
      //NetworkErrorPopup
      errorCode: "Ошибка. Код:",
      errorDescription: "Описание ошибки:",
      //UserTool
      notifications: "Уведомления",
      //Validate
      addressValidate: "Все поля с * должны быть заполнены",
      //Address Component
      address: "Адрес",
      city: "Город",
      district: "Район",
      street: "Улица",
      building: "Дом",
      apartment: "Квартира",
      codeOrNote: "Код или примечание",
      //СardData
      noPayment: "Не требует оплаты",
      currentDiseaseHistory: "Анамнез текущего заболевания",
      allergicDiseases: "Аллергические реакции",
      medicalHistory: "Перенесённые заболевания",
      treatmentPlan: "План лечения",
      recommendations: "Рекомендации",
      //Client
      cityIsIncorrect: "Город некорректный",
      cityNameRequire: "Город обязательное поле",
      thoroughfareIsIncorrect: "Улица некорректная",
      thoroughfareRequire: "Улица обязательное поле",
      buildingIsIncorrect: "Номер некоректный",
      buildingIsRequire: "Дом обязательный",
      apartmentIsIncorrect: "Номер некорректный",
      apartmentIsRequire: "Квартира обязательная",
      commentIsIncorrect: "Код некорректный",
      commentIsRequire: "Код обязательное поле",
      personalPatient: "Личные пациенты",
      isFavourite: "Пациент уже в списке",
      addToFavourite: "Добавить в список личных пациентов",
      warning: "Внимание!",
      consultationIsNotPayed: "Косультация не была оплачена",
      consultationCard: "Карта консультации",
      cardData: "Данные карты",
      //HistoryItemDetails
      consultationDetails: "Детали консультации",
      onlineConsultation: "Онлайн консультация",
      consultationWithDoctor: "Консультация с доктором",
      complaints: "Жалобы",
      presumptiveDiagnosis: "Предположительный диагноз (по МКБ-10)",
      TreatmentPlan: "План лечения",
      conclusion: "Вывод",
      medications: "Препараты",
      examinations: "Исследования",
      tests: "Анализы",
      //History
      january: "января",
      february: "февраля",
      march: "марта",
      april: "апреля",
      may: "мая",
      june: "июня",
      july: "июля",
      august: "августа",
      september: "сентября",
      october: "октября",
      november: "ноября",
      december: "декабря",
      //Anamnez
      medCardTitle: "Медицинская карта",
      anamnez: "Анамнез",
      history: "История",
      deleteConfirm: "Вы уверены, что хотите удалить карту?",
      //MedCard
      sex: "Пол",
      maleSex: "Мужской",
      femaleSex: "Женский",
      //Monitoring
      monitoring: "Мониторинг",
      patient: "Пациент",
      cause: "Причина обращения",
      status: "Статус",
      new: "Новая",
      newConsultation: "Новая",
      filled: "Заполнена",
      filledConsultation: "Заполнена",
      closed: "Завершена",
      finishSaveButton: "Завершить и сохранить",
      closedConsultation: "Завершенная",
      in_process: "В процессе",
      inProgressConsultation: "В процессе",
      cancelled: "Отменена",
      cancelledConsultation: "Отменена",
      order: "платежа",
      //Specializations
      newSpecialty: "Новая специализация",
      addSpecialty: "Добавить специализацию",
      title: "Наименование",
      priceOnline: "Стоимость online-консультации",
      priceHome: "Стоиомость вызова на дом",
      priority: "Приоритет",
      showOnMainScreen: "Показывать на главном экране",
      showOnDoctorsList: "Показывать в списке врачей",
      //Banners
      banners: "Баннеры",
      newBanner: "Новый баннер",
      //Subscribers
      subscribers: "Подписанные пациенты",
      fullName: "Ф.И.О.",
      policeStartDate: "Время начала действия полиса",
      policeExpireDate: "Время окончания действия полиса",
      email: "Электронный адрес",
      birthDate: "Дата рождения",
      insuranceCompany: "Название страховой компании",
      authorization: "Авторизация",
      scheduleListTitle: "Смены",
      specializationTitle: "Доктор",
      appointmentsTime: "Смена",
      durationTitle: "Длительность консультации",
      filterToday: "На сегодня",
      filterTomorrow: "На завтра",
      filterWeek: "На неделю",
      filterAll: "Все",
      filterFormTitle: "Фильтр",
      filterAllSchedules: "Все смены",
      filterActiveSchedules: "Активные смены",
      filterAllConsultations: "Все консультации",
      filterActiveConsultations: "Активные консультации",
      filterTypeOfService: "Желаемый формат",
      common: "Общее",
      name: "Имя",
      lastName: "Фамилия",
      patronymic: "Отчество",
      gender: "Пол",
      experience: "Опыт работы",
      old: "Лет",
      specializationDescription: "Описание",
      education: "Образование",
      typeEducation: "Вид образования",
      phoneNumber: "Номер телефона",
      newEmployee: "Новый сотрудник",
      // Specialty
      cost: "Стоимость",
      randomDoc: "Любой врач",
      priceType: "Онлайн-консультация",
      callOnHome: "Вызов на дом",
      doctor: "Доктор",
      addSchedule: "Добавить смену",
      placeholderSpecialties: "Специализации",
      placeholderSpecialty: "Специализация",
      placeholderSearch: "Поиск",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Визит на дом",
      optionsServiceTypeInVideo: "Видеозвонок",
      optionsServiceTypeCall: "Звонок",
      optionsServiceTypeInChat: "В чате",
      optionsServiceTypeInHospital: "Визит в клинику",
      newScheduleFilterFormTitle: "Новая смена",
      newScheduleFilterFormTitleEdit: "Редактирование",
      manually: "Вручную",
      fromFile: "Из файла",
      duration: "Длительность консультации",
      date: "Дата смены",
      timeStart: "Время начала",
      timeEnd: "Время окончания",
      fileUploaderTitle: "Загрузка файла .xlsx",
      // Employees
      employeesFlowTitle: "Сотрудники",
      experienceIsRequire: "Опыт обязателен",
      experienceIsIncorrect: "Опыт превышает максимально возможный",
      doctors: "Наши доктора",
      operators: "Операторы",
      operator: "Оператор",
      operatorAndAdmin: "Оператор / Админ",
      phone: "Телефон",
      //Auth
      login: "Вход",
      loginError: "Неверный логин",
      passwordError: "Неверный пароль",
      //onBoarding
      onBoarding: "Онбоардинг",
      edit: "Редактировать",
      newOnBoard: "Новый онбординг",
      addImage: "Добавить изображение",
      saveBanner: "Сохранить баннер",
      addBanner: "Добавить баннер",
      showInList: "Показать в списке",
      //PromoCodes Page
      promocodeStatus: "Статус промокода",
      promocodeStatus_0: "Не использован",
      promocodeStatus_1: "Использован",
      promocodeType: "Тип промокода",
      promocodeType_0: "Подписка",
      promocodeType_1: "Консультация",
      promocodeGenerateSuccess: "Промокоды успешно созданы",
      promocodesCount: "Всего промокодов:",
      promocodesActivateCount: "Промокодов активировано:",
      promoCodes: "Промокоды",
      addPromoCodes: "Добавить промокоды",
      promocodeName: "Промокод",
      promocodeCreated: "Создан",
      promocodeExpityDate: "Действителен до",
      promocodeExpityDateFromTo: "Действителен (от-до)",
      promocodeCount: "Кол-во промокодов",
      promocodeFrom: "Выдан",
      deletePromocode: "Удалить",
      //Symptoms Page
      addSymptom: "Добавьте симптом",
      chooseSpecialty: "Выберите специальность",
      symptomsAndSpeciality: "Специализации и симптомы",
      symptoms: "Симптомы",
      emptyList: "Список пуст",
      existingSymptoms: "Существующие симптомы",
      deleteSymptom: "Удалить симптом",
      //Placeholders
      placeholderLogin: "Логин",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторите пароль",
      placeholderAddSymptom: "Добавить симптом",
      placeholderText: "Текст",
      placeholderNumber: "Введите число от 0 до 100",
      placeholderLink: "Ссылка",
      placeholderTitle: "Заголовок",
      //Feedbacks
      placeholderFeedbacks: "Отзывы",
      //Buttons
      addMedicalCard: "Добавить медкарту",
      addButton: "Добавить",
      changeButton: "Изменить",
      saveButton: "Сохранить",
      deleteButton: "Удалить",
      editButton: "Редактировать",
      closeButton: "Закрыть",
      createButton: "Создать",
      createUserProfileButton: "Создать профиль пациента",
      lockButton: "Заблокировать",
      unLockButton: "Разблокировать",
      acceptButton: "Применить",
      // OPERATOR
      requestCreated: "Заявка создана",
      newPacient: "Новый пациент",
      mainCard: "Главная медкарта",
      relations: "Степень родства",
      adress: "Адрес",
      policeNumber: "№ полиса",
      insurance: "Компания/страховка",
      registration: "Оформление",
      //Specializations
      priceTitle: "Стоимость",
      //Placeholders
      success: "Успех",
      successCreatingPatient: "Пациент успешно создан",
      patientNotFound: "Пациент не найден",
      patientCardEmpty: "Медкарты отсутствуют",
      placeholderMedCard: "Медкарта",
      placeholderUser: "Пользователь",
      bookDate: "Дата бронирования",
      paymentType: "Способ оплаты",
      hospitals: "Клиники",
      hospitalDepartments: "Отделения клиники",
      //Buttons
      arrangeButton: "Оформить консультацию",
      goodButton: "Хорошо",
      cancelButton: "Отменить",
      placeholderChatMessage: "Введите сообщение",
      placeholderImageOrVideo: "Выберите изображение или видеозапись",
      placeholderDocument: "Выберите документ",
      placeholderStartConsultation: "Начать консультацию",
      chat: "Чат",
      changeLang: "Язык",
      exitButton: "Выйти",
      firstNameIsIncorrect: "Некорректное имя",
      lastNameIsIncorrect: "Некорректная фамилия",
      patronymicIsIncorrect: "Некорректное отчество",
      phoneIsIncorrect: "Номер телефона +380*********",
      phoneIsRequire: "Введите номер телефона",
      experienceIsRequire: "Введите опыт",
      experienceIsIncorrect: "Максимально возможный опыт:",
      overstatedExperience: "Завышен опыт",
      emailIsIncorrect: "Некорректно набран email",
      firstNameRequire: "Введите имя",
      lastNameIsRequire: "Введите фамилию",
      patronymicIsRequire: "Введите отчество",
      emailIsRequire: "Введите email",
      birthDateIsIncorrect: "Некорректная дата рождения",
      birthDateIsRequire: "Введите дату рождения",
      divisionsNameRequire: "Введите название филиала",
      divisionsNameIsIncorrect: "Некорректное название филиала",
      noConnectToServer: "Нет соединения с сервером",
      coonectingChatStatus: "Подключение к серверу",
      changeLoginHeader: 'Смена Логина',
      changeLoginButton: 'Сменить логин',
      changeLoginCurrent: 'Текущий логин: ',
      changeLoginWarning: 'Введенный логин зарегистрирован на ',
      changeLoginButtonSuccess: 'Изменить',
      changeLoginButtonMerge: 'Объединить',
      changeLoginButtonDecline: 'Закрыть',
      changeLoginSuccess: 'Логин пользователя изменен',
      changeLoginError: 'Ошибка. Логин пользователя не изменен',
      changeLoginPhone: 'Новый номер телефона',
      deletedPatientCard: 'Медкарта удалена',
      subscribeProcess: 'Подписка юзера',
      importPromoCodeProcess: 'Импорт промокода',
      pushNotificationProcess: 'Отправка пуш уведомлений',
      titleProcess: 'Фоновые задачи'
    },
  },
  uk: {
    translation: {
      deleteShiftConfirm: "Ви дійсно хочете видалити зміну?",
      shiftDurationError: "Тривалість консультації більша за зміну",
      usesLeft: "Залишилось використань",
      onlyIntegers: "тільки цілі числа",
      usesTotal: "К-сть використань",
      office: "Офіс",
      companyName: "Назва компанії",
      newMessage: "Нове повідомлення",
      fromTo: "від",
      in: "в",
      reload: "Перезавантажити",
      newVersionText: "Вийшла нова версія програми. Будь ласка, перезавантажте сторінку",
      discount: "Знижка",
      resetChanges: "Відмінити зміну",
      entrance: "Під'Їзд",
      floor: "Поверх",
      removeAll: "Прибрати всі",
      selectAll: "Вибрати всі",
      reset: "Очистити",
      inputAddress: "Введіть адресу",
      activePeriod: "Період дії",
      chooseDistrict: "Виберіть район",
      chooseCity: "Виберіть місто",
      photo: "Фото",
      shiftTimeDifferenceError: "Час початку та кінця зміни однаковий",
      province: "Місто",
      confirmButton: "Пiдтвердити",
      changeAnamnezData: "Змінити дані анамнезу",
      finishSaveButton: "Завершити і зберегти",
      divisions: "Філії",
      newDivisions: "Нова філія",
      divisionsName: "Назва філії",
      //validates
      downloadCSV: "Завантажити csv",
      requiredLoginValidate: "Введіть логін",
      dd_mm_yyyyValidate: "Дата повинна бути в форматі хх/хх/хххх",
      phoneFormatValidate:
        "Номер телефону повинен бути в форматі +380ХХХХХХХХХ",
      durationTimeInMinValidate: "Допустиме значення 20, 30 або 60 хвилин",
      durationTimeInMinValidateHome: "Допустиме значення 90 або 180 хвилин",
      timeInMinValidate: "Час має бути в форматі 23ч:59хв",
      emailValidate: "Неправильный формат e-mail",
      requiredPasswordValidate: "Введіть пароль",
      requiredValidate: "Це обов'язкове поле",
      notNegativeValidate: "Не може бути негативним числом",
      createShiftButton: "Створити зміну",
      cancelOfConsultation: "Скасувати консультацію",
      consultationDate: "Час консультації",
      pushNotificationPageTitle: "Відправлення PUSH нотифікації",
      pushAgeFrom: "Вік від",
      pushAgeTo: "Вік до",
      pushTitle: "Тема повідомлення",
      createPassword: "Призначте пароль для вашого акаунту",
      month: "Місяці",
      months: "Місяців",
      newSubscription: "Нова підписка",
      percentileDiscount: "Відсоток знижки",
      priceDiscount: "Ціна підписки",
      noInsuranceCompany: "Без страхової компанії",
      company: "Компанія",
      companySuccessCreated: "Підписка створена",
      activateSubscription: "Активувати підписку",
      renewSubscription: 'Оновити підписку',
      addPatient: "Додати пацієнта",
      isAdmin: "Админістратор компанiї",
      //sendPDFFile
      exportButton: "Єкспортувати",
      sendToEmail: "Надіслати на ел. адресу",
      sendPDF: "Будь ласка, оберіть компанію, до якої бажаєте надіслати файл",
      sendSuccess: "Успішно надіслано",
      //Companies
      fileUploaded: "Файл успішно загрузився",
      fileUploadError: "Помилка при завантаженні файлу",
      description: "Опис",
      shortDescription: "Короткий опис",
      subscriptionEdited: "Компания успішно оновлена",
      noDescription: "Опису поки немає",
      companies: "Компанії",
      createCompany: "Стоворити компанію",
      deleteSubText: "Вы дійсно хочете видалити підписника?",
      deleteSubSuccess: "Підписник був успішно видалений",
      //Success
      successOperatorUpdate: "Оновлення оператора успішне",
      successDoctorUpdate: "Оновлення доктора успішне",
      successOperatorDelete: "Оператора успішно видалено",
      successDoctorDelete: "Доктора успішно видалено",
      successBlockUser: "Працівника було заблоковано",
      successUnblockUser: "Працівника було розблоковано",
      successOperatorCreate: "Оператора успішно створено",
      successDoctorCreate: "Доктора успішно створено",
      // ERRORS
      failedBlockUser: "У працівника є консультації",
      submitConfirm:
        "Ви впевнені, що бажаєте зберегти та завершити консультацію?",
      cancelConfirm:
        "Ви впевнені, що бажаєте закрити та скасувати консультацію?",
      birthDateInFuture: "Неможливо народитись у майбутньому",
      templateTitle: "Шаблони",
      min: "хв",
      from: "з",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "У разі, якщо потрібна технічна підтримка - прохання зателефонувати",
      medQuestion: "Мед. питання",
      techSupport: "Техпідтримка",
      infoMedQueation: "B разі, якщо хочете уточнити медичне питання",
      missedCall: "Пропущений дзвінок",
      canceledCall: "Скасований виклик",
      canceledConsultationMessage:
        "Шановний пацієнт, ваша консультацiя була вiдмiнена лiкарем.",
      finishConsultationMessage:
        "Ваша консультація завершена. Висновок лікаря:",
      //ConsultConfirmation
      confirm: "Підтвердити",
      consultConfirmation: "Консультація призначена на ",
      doctorConfirmation: "Ви записані до доктора",
      at: "о",
      Января: "Січня",
      Февраля: "Лютого",
      Марта: "Березня",
      Апреля: "Квітня",
      Мая: "Травня",
      Июня: "Червня",
      Июля: "Липня",
      Августа: "Серпня",
      Сентября: "Вересня",
      Октября: "Жовтня",
      Ноября: "Листопада",
      Декабря: "Грудня",
      //Sider
      shifts: "Розклад",
      employees: "Співробітники",
      specializations: "Спеціалізації",
      subscriptions: "Підписки",
      promocodes: "Промокоди",
      rates: "Тариф",
      patients: "Пацієнтів",
      onboarding: "Онбоардінг",
      client: "Клієнт",
      //=====
      //MODAL
      help: "Допомога",
      helpModalText_1:
        "У випадках, якщо потрібна технічна допомога - будь-ласка наберіть за телефоном:",
      helpModalText_2: "Якщо ви бажаєте, задати медичне питання",
      techSupportButton: "Технічна допомога",
      medQuestionButton: "Медична допомога",
      //NetworkErrorPopup
      errorCode: "Помилка. Код:",
      errorDescription: "Опис помилки:",
      //UserTool
      notifications: "Повідомлення",
      //Validate
      addressValidate: "Усі поля з * повинні бути заповнені",
      //Address Component
      address: "Адреса",
      city: "Місто",
      district: "Район",
      street: "Вулиця",
      building: "Будинок",
      apartment: "Квартира",
      codeOrNote: "Код або примітка",
      //СardData
      noPayment: "Не потребує оплати",
      currentDiseaseHistory: "Анамнез поточного захворювання",
      allergicDiseases: "Алергічні реакції",
      medicalHistory: "Перенесенні захворювання",
      treatmentPlan: "План лікування",
      recommendations: "Рекомендації",
      //Client
      cityIsIncorrect: "Некорректне місто",
      cityNameRequire: "Місто обов'язкове",
      thoroughfareIsIncorrect: "Некорректне вулиця",
      thoroughfareRequire: "Вулиця обов'язкова",
      buildingIsIncorrect: "Некорректний номер",
      buildingIsRequire: "Будинок обов'язковий",
      apartmentIsIncorrect: "Некорректний номер",
      apartmentIsRequire: "Квартира обов'язкова",
      commentIsIncorrect: "Некорректний код",
      commentIsRequire: "Код обов'язковий",
      personalPatient: "Особисті пацієнти",
      isFavourite: "Пацієнт вже у списку",
      addToFavourite: "Додати в список особистих пацієнтів",
      warning: "Увага!",
      consultationIsNotPayed: "Косультація не була сплачена",
      consultationCard: "Картка консультації",
      cardData: "Дані картки",
      //HistoryItemDetails
      consultationDetails: "Деталі консультації",
      onlineConsultation: "Онлайн консультація",
      consultationWithDoctor: "Консультація з лікарем",
      complaints: "Скарги",
      presumptiveDiagnosis: "Можливий діагноз (по МКХ-10)",
      TreatmentPlan: "План лікування",
      conclusion: "Висновок",
      medications: "Препарати",
      examinations: "Iнструментальні дослідження",
      tests: "Аналізи",
      //History
      january: "січня",
      february: "лютого",
      march: "березня",
      april: "квітня",
      may: "травня",
      june: "червня",
      july: "липня",
      august: "серпня",
      september: "вересня",
      october: "жовтня",
      november: "листопада",
      december: "грудня",
      //Anamnez
      medCardTitle: "Медична карта",
      anamnez: "Анамнез",
      history: "Історія",
      deleteConfirm: "Ви впевнені, що бажаете видалити картку?",
      //MedCard
      sex: "Стать",
      maleSex: "Чоловіча",
      femaleSex: "Жіноча",
      //Monitoring
      monitoring: "Моніторинг",
      patient: "Пацієнт",
      cause: "Причина звернення",
      status: "Статус",
      new: "Нова",
      newConsultation: "Нова",
      filled: "Заповнена",
      filledConsultation: "Заповнена",
      closed: "Завершена",
      closedConsultation: "Завершена",
      in_process: "У процесі",
      patientNotFound: "Пацієнта не знайдено",
      patientCardEmpty: "Медкарти відсутні",
      inProgressConsultation: "У процесі",
      cancelled: "Скасована",
      cancelledConsultation: "Скасована",
      order: "платежу",
      //Specializations
      acceptButton: "Застосувати",
      requestCreated: "Заявка створена",
      newPacient: "Новий пацієнт",
      mainCard: "Головна медкарта",
      relations: "Відношення до сім'ї",
      adress: "Адреса",
      policeNumber: "№ полісa",
      insurance: "Компанія/страховка",
      registration: "Оформлення",
      //Specializations
      newSpecialty: "Нова спеціалізація",
      title: "Найменування",
      priceTitle: "Вартість",
      priceOnline: "Вартість online-консультаціі",
      priceHome: "Вартість виклику додому",
      priority: "Пріоритет",
      showOnMainScreen: "Показати на головному екрані",
      showOnDoctorsList: "Показати в списку лікарів",
      //Banners
      banners: "Банери",
      newBanner: "Новий банер",
      //Subscribers
      subscribers: "Підписані пацієнти",
      fullName: "П.І.Б.",
      policeStartDate: "Час початку дії полісу",
      policeExpireDate: "Час закінчення дії полісу",
      insuranceCompany: "Назва страхової компанії",
      authorization: "Авторизація",
      scheduleListTitle: "Зміни",
      specializationTitle: "Лікар",
      appointmentsTime: "Зміна",
      durationTitle: "Тривалість консультації",
      filterToday: "На сьогодні",
      filterTomorrow: "На завтра",
      filterWeek: "На тиждень",
      filterAll: "Усі",
      filterFormTitle: "Фільтр",
      filterAllSchedules: "Всі зміни",
      filterActiveSchedules: "Активні зміни",
      filterAllConsultations: "Всі консультації",
      filterActiveConsultations: "Активні консультації",
      filterTypeOfService: "Бажаний формат",
      // Specialty
      cost: "Вартість",
      randomDoc: "Будь-який лікар",
      priceType: "Онлайн консультація",
      callOnHome: "Виклик додому",
      // =====
      common: "Загальне",
      name: "Ім'я",
      lastName: "Прізвище",
      patronymic: "По батькові",
      birthDate: "Дата народження",
      gender: "Стать",
      experience: "Досвід роботи",
      old: "Років",
      email: "Електронна адреса",
      specializationDescription: "Опис",
      education: "Освіта",
      typeEducation: "Вид освіти",
      addSpecialty: "Додати спеціалізацію",
      saveBanner: "Зберегти банер",
      addBanner: "Додати банер",
      doctor: "Лікар",
      phoneNumber: "Номер телефону",
      newEmployee: "Новий співпрацівник",
      createButton: "Створити",
      createUserProfileButton: "Створити профіль пацієнта",
      lockButton: "Заблокувати",
      unLockButton: "Розблокувати",
      cancelButton: "Скасувати",
      addSchedule: "Додати зміну",
      placeholderSpecialties: "Спеціальності",
      placeholderSpecialty: "Спеціалізація",
      placeholderSearch: "Пошук",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Виклик додому",
      optionsServiceTypeInHospital: "Візит до клініки",
      optionsServiceTypeInVideo: "Відеодзвінок",
      optionsServiceTypeCall: "Дзвінок",
      optionsServiceTypeInChat: "У чаті",
      newScheduleFilterFormTitle: "Нова зміна",
      newScheduleFilterFormTitleEdit: "Редагування",
      manually: "Вручну",
      fromFile: "З файлу",
      duration: "Тривалість консультації",
      date: "Дата зміни",
      timeStart: "Час початку",
      timeEnd: "Час закінчення",
      fileUploaderTitle: "Завантаження файлу .xlsx",
      // Employees
      employeesFlowTitle: "Співпрацівники",
      experienceIsIncorrect: "Досвід перевищує максимально можливий",
      experienceIsRequire: "Досвід обов'язковий",
      doctors: "Наші лікарі",
      operators: "Оператори",
      operator: "Оператор",
      operatorAndAdmin: "Оператор / Адмін",
      phone: "Телефон",
      //Auth
      login: "Вхід",
      loginError: "Невірний логін",
      passwordError: "Невірний пароль",
      //onBoarding
      onBoarding: "Онбоардінг",
      edit: "Редагувати",
      newOnBoard: "Новий онбоардінг",
      addImage: "Додати зображеня",
      showInList: "Показати у списку",
      //PromoCodes Page
      promocodeStatus: "Статус промокоду",
      promocodeStatus_0: "Невикористаний",
      promocodeStatus_1: "Використаний",
      promocodeType: "Тип промокоду",
      promocodeType_0: "Підписка",
      promocodeType_1: "Консультація",
      promocodeGenerateSuccess: "Промокоди успішно створено",
      promocodesCount: "Усього промокодів:",
      promocodesActivateCount: "Промокодів активовано:",
      promoCodes: "Промокоди",
      addPromoCodes: "Додати промокод",
      promocodeName: "Промокод",
      promocodeCreated: "Створено",
      promocodeExpityDate: "Дійсний до",
      promocodeExpityDateFromTo: "Дійсний (від-до)",
      promocodeCount: "Кількість промокодів",
      promocodeFrom: "Виданий",
      deletePromocode: "Видалити",
      //Feedbacks
      placeholderFeedbacks: "Відгуки",

      //Symptoms Page
      addSymptom: "Додайте симптом",
      chooseSpecialty: "Виберіть спеціальність",
      symptomsAndSpeciality: "Симптоми і спеціалізації",
      symptoms: "Симптоми",
      emptyList: "Пустий список",
      existingSymptoms: "Існуючі симптоми",
      deleteSymptom: "Видалити симптом",
      //Placeholders
      success: "Успіх",
      successCreatingPatient: "Пацієнта успішно створено!",
      placeholderLogin: "Логін",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторіть пароль",
      placeholderAddSymptom: "Додайте симптоми",
      placeholderText: "Текст",
      placeholderNumber: "Введіть число від 0 до 100",
      placeholderLink: "Посилання",
      placeholderTitle: "Заголовок",
      placeholderMedCard: "Медкартка",
      placeholderUser: "Користувач",
      bookDate: "Дата бронювання",
      paymentType: "Спосіб оплати",
      hospitals: "Клініки",
      hospitalDepartments: "Відділи клінік",
      //Buttons
      addButton: "Додати",
      addMedicalCard: "Додати медкарту",
      changeButton: "Змінити",
      saveButton: "Зберегти",
      deleteButton: "Видалити",
      editButton: "Редагувати",
      closeButton: "Закрити",
      arrangeButton: "Оформити консультацію",
      goodButton: "Добре",
      placeholderChatMessage: "Введіть повідомлення",
      placeholderImageOrVideo: "Оберіть зображення або відеозапис",
      placeholderDocument: "Оберіть документ",
      placeholderStartConsultation: "Почати консультацію",
      chat: "Чат",
      changeLang: "Мова",
      exitButton: "Вийти",
      firstNameIsIncorrect: "Некорректне ім'я",
      lastNameIsIncorrect: "Некорректне прізвище",
      patronymicIsIncorrect: "Некорректне ім'я по батькові",
      overstatedExperience: "Завищений досвід",
      emailIsIncorrect: "Некорректний email",
      firstNameRequire: "Введіть ім'я",
      lastNameIsRequire: "Введіть прізвище",
      patronymicIsRequire: "Введіть ім'я по батькові",
      emailIsRequire: "Введіть email",
      phoneIsIncorrect: "Номер телефону має бути +380*********",
      phoneIsRequire: "Введіть номер телефону",
      experienceIsRequire: "Введіть досвід",
      experienceIsIncorrect: "Максимально можливий досвід:",
      birthDateIsIncorrect: "Некорректна дата народження",
      birthDateIsRequire: "Введіть дату народження",
      divisionsNameRequire: "Введіть назву філії",
      divisionsNameIsIncorrect: "Некоректна назва філії",
      noConnectToServer: "Немає з'єднання з сервером",
      coonectingChatStatus: "Підключення до сервера",
      //Change Login
      changeLoginHeader: 'Зміна Логіну',
      changeLoginButton: 'Змінити логін',
      changeLoginCurrent: 'Поточний логін: ',
      changeLoginWarning: 'Введений логін зареєстрований на',
      changeLoginButtonSuccess: 'Змінити',
      changeLoginButtonMerge: 'Обʼєднати',
      changeLoginButtonDecline: 'Відхилити',
      changeLoginSuccess: 'Логін користувача змінено',
      changeLoginError: 'Помилка. Логін користувача не змінено',
      changeLoginPhone: 'Новий номер телефону',
      deletedPatientCard: 'Медкарта видалена',
      subscribeProcess: 'Підписка юзера',
      importPromoCodeProcess: 'Імпорт промокоду',
      pushNotificationProcess: 'Відправка пуш повідомлень',
      titleProcess: 'Фонові задачі'
    },
  },
  en: {
    translation: {
      deleteShiftConfirm: "Do you really want to delete a shift?",
      shiftDurationError: "The duration of the consultation is more than a shift",
      usesLeft: "Remaining uses",
      onlyIntegers: "only integers",
      usesTotal: "Number of uses",
      office: "Office",
      companyName: "Company name",
      newMessage: "New message",
      fromTo: "from",
      in: "at",
      reload: "Reload",
      newVersionText: "A new version of the application has been released. Please reload the page",
      discount: "Discount",
      resetChanges: "Reset changing",
      floor: "Floor",
      entrance: "Entrance",
      removeAll: "Remove all",
      selectAll: "Select all",
      reset: "Reset",
      inputAddress: "Enter address",
      activePeriod: "Validity period",
      chooseCity: "Choose city",
      chooseDistrict: "Choose district",
      shiftTimeDifferenceError: "Start and end time are equal",
      photo: "Photo",
      province: "City",
      confirmButton: "Confirm",
      changeAnamnezData: "Change case history data",
      divisions: "Divisions",
      newDivisions: "New division",
      divisionsName: "Division name",
      //validates
      downloadCSV: "Download csv",
      requiredLoginValidate: "Enter login",
      dd_mm_yyyyValidate: "Date must be in хх/хх/хххх",
      phoneFormatValidate:
        "The phone number must be in the format +380ХХХХХХХХХ",
      durationTimeInMinValidate: "Acceptable value is 20, 30 or 60 minutes",
      durationTimeInMinValidateHome: "Acceptable value is 90 or 180 minutes",
      timeInMinValidate: "Time must be  in the format 23h:59m",
      emailValidate: "Invalid e-mail format",
      requiredPasswordValidate: "Enter password",
      requiredValidate: "This is a required field",
      notNegativeValidate: "Cannot be negative",
      createShiftButton: "Create a shift",
      cancelOfConsultation: "Cancel the consultation",
      consultationDate: "Consulation time",
      pushNotificationPageTitle: "Send PUSH notification",
      pushAgeFrom: "Age from",
      pushAgeTo: "Age to",
      pushTitle: "Notification header",
      createPassword: "Create password to your account",
      month: "month",
      months: "month",
      newSubscription: "New subscription",
      percentileDiscount: "Discount percentage",
      priceDiscount: "Discount price",
      company: "Company",
      noInsuranceCompany: "Without an insurance company",
      companySuccessCreated: "Subscription created",
      activateSubscription: "Activate subscription",
      renewSubscription: 'Renew subscription',
      addPatient: "Add patient",
      isAdmin: "Company admin",
      //sendPDFFile
      exportButton: "Export",
      sendToEmail: "Send to email",
      sendPDF:
        "Please, select insurance company, witch you want to send the file",
      sendSuccess: "Success",
      //Companies
      fileUploaded: "File has been successfully uploaded",
      fileUploadError: "File upload error",
      noDescription: "No description yet",
      subscriptionEdited: "Company edited successfully",
      description: "Description",
      shortDescription: "Short description",
      companies: "Companies",
      createCompany: "Create company",
      deleteSubText: "Do you really want to delete subscriber?",
      deleteSubSuccess: "The Subscriber was deleted successfully",
      //Success
      successOperatorUpdate: "Operator update was successful",
      successDoctorUpdate: "Doctor update was successful",
      successOperatorDelete: "Operator was deleted successful",
      successDoctorDelete: "Doctor was deleted successful",
      successBlockUser: "Employee was blocked successfully",
      successUnblockUser: "Employee was unblocked successfully",
      successOperatorCreate: "Operator was created successful",
      successDoctorCreate: "Doctor was created successful",
      // ERRORS
      failedBlockUser: "The Employee has consultation",

      submitConfirm: "Do you want to close the consultation and finish it?",
      cancelConfirm: "Do you want to cancel the consultation and reject it?",
      birthDateInFuture: "You can`t be born in future",
      templateTitle: "Templates",
      min: "min",
      from: "from",
      to: "to",
      empty: "Empty",
      // Chat - CALL
      infoText: "If you need technical support - please call",
      medQuestion: "Medical question",
      techSupport: "Support",
      infoMedQueation: "If you want to clarify a medical question",
      missedCall: "Missed call",
      canceledCall: "Canceled call",
      canceledConsultationMessage:
        "Dear patient, your consultation has been canceled by a doctor.",
      finishConsultationMessage:
        "Your consultation is now complete. Doctor's conclusion:",
      //ConsultConfirmation
      confirm: "confirm",
      consultConfirmation: "The consultation is scheduled on the ",
      doctorConfirmation: "You are registered with a doctor ",
      at: "at",
      Января: "January",
      Февраля: "February",
      Марта: "March",
      Апреля: "April",
      Мая: "May",
      Июня: "June",
      Июля: "July",
      Августа: "August",
      Сентября: "September",
      Октября: "October",
      Ноября: "November",
      Декабря: "December",
      //Sider
      shifts: "Shifts",
      employees: "Employees",
      specializations: "Specializations",
      subscriptions: "Subscriptions",
      promocodes: "Promocodes",
      rates: "Rate",
      patients: "Patients",
      onboarding: "Onboarding",
      client: "Client",
      //=====
      //MODAL
      help: "Help",
      helpModalText_1: "If you need a technical help - please call: ",
      helpModalText_2: "If you have a medical question: ",
      techSupportButton: "Technical help",
      medQuestionButton: "Medical help",
      //NetworkErrorPopup
      errorCode: "Error. Code:",
      errorDescription: "Error description:",
      //UserTool
      notifications: "Notification",
      //Validate
      addressValidate: "All fields with * need to be fulled",
      //Address Component
      address: "Address",
      city: "City",
      district: "District",
      street: "Street",
      building: "Building",
      apartment: "Apartment",
      codeOrNote: "Code or note",
      //CardData
      noPayment: "Payment not required",
      currentDiseaseHistory: "Current disease history",
      allergicDiseases: "Allergic reactions",
      medicalHistory: "Medical history",
      treatmentPlan: "Treatment plan",
      recommendations: "Recommendations",
      //Client
      cityIsIncorrect: "City is incorrect",
      cityNameRequire: "City is required",
      thoroughfareIsIncorrect: "Street is incorrect",
      thoroughfareRequire: "Street is required",
      buildingIsIncorrect: "number is incorrect",
      buildingIsRequire: "House is required",
      apartmentIsIncorrect: "number is incorrect",
      apartmentIsRequire: "Flat is required",
      commentIsIncorrect: "Code is incorrect",
      commentIsRequire: "Code is required",
      personalPatient: "Рersonal patients",
      addToFavourite: "Add to personal clients list",
      isFavourite: "The patient is already on the list",
      warning: "Warning",
      consultationIsNotPayed: "Consultation is not payed",
      consultationCard: "Card of consultation",
      cardData: "Card data",
      //HistoryItemDetails
      consultationDetails: "Consultations detail",
      onlineConsultation: "Online consultation",
      consultationWithDoctor: "Consultation with a doctor",
      complaints: "Complaints",
      presumptiveDiagnosis: "Presumptive diagnosis (per ICD-10)",
      TreatmentPlan: "Treatment plan",
      conclusion: "Conclusion",
      medications: "Medications",
      examinations: "Examinations",
      tests: "Tests",
      //History
      january: "of january",
      february: "of february",
      march: "of march",
      april: "of april",
      may: "of may",
      june: "of june",
      july: "of july",
      august: "of august",
      september: "of september",
      october: "of october",
      november: "of november",
      december: "of december",
      //Anamnez
      medCardTitle: "Medical Card",
      anamnez: "Case history",
      history: "History",
      deleteConfirm: "Are you sure, you want to delete a medical card?",
      //MedCard
      sex: "Sex",
      maleSex: "Male",
      femaleSex: "Female",
      //Monitoring
      monitoring: "Monitoring",
      patient: "Patient",
      cause: "Reason for visit",
      status: "Status",
      new: "New",
      newConsultation: "New",
      filled: "Filled",
      filledConsultation: "Filled",
      closed: "Completed",
      closedConsultation: "Completed",
      in_process: "In progress",
      patientNotFound: "Patient not found",
      patientCardEmpty: "No patient cards found",
      inProgressConsultation: "In progress",
      cancelled: "Canceled",
      cancelledConsultation: "Canceled",
      newSpecialty: "New specialty",
      title: "Name",
      priceOnline: "Online consultation fee",
      priceHome: "Home visit fee",
      priority: "Priority",
      showOnMainScreen: "Show on main screen",
      showOnDoctorsList: "Show in doctors list",
      order: "payment",
      //Banners
      banners: "Banners",
      newBanner: "New banner",
      //Subscribers
      subscribers: "Subscribed patients",
      fullName: "Name",
      policeStartDate: "Policy start date",
      policeExpireDate: "Policy expiry date",
      email: "Email",
      birthDate: "Date of birth",
      insuranceCompany: "Insurance company",
      authorization: "Authorization",
      scheduleListTitle: "Shifts",
      specializationTitle: "Doctor",
      appointmentsTime: "Shift",
      durationTitle: "Consultation duration",
      filterToday: "For today",
      filterTomorrow: "Tomorrow",
      filterWeek: "For the week",
      filterAll: "All",
      filterFormTitle: "Filter",
      filterAllSchedules: "All shifts",
      filterActiveSchedules: "Active shifts",
      filterAllConsultations: "All consultations",
      filterActiveConsultations: "Active consultations",
      filterTypeOfService: "Desired format",
      // Specialty
      cost: "Price",
      randomDoc: "Random Doctor",
      priceType: "Online consultation",
      callOnHome: "Call on home",
      // =====
      // =====
      finishSaveButton: "Finish and save",
      common: "General",
      name: "First name",
      lastName: "Last name",
      patronymic: "Patronymic",
      gender: "Sex",
      experience: "Experience",
      old: "Years",
      specializationDescription: "Description",
      education: "Education",
      typeEducation: "Education type",
      addSpecialty: "Add a specialization",
      saveBanner: "Save banner",
      addBanner: "Add banner",
      doctor: "Doctor",
      phoneNumber: "Tel. number",
      newEmployee: "New employee",
      createButton: "Create",
      createUserProfileButton: "Create user profile",
      lockButton: "Block",
      unLockButton: "Unlock",
      addSchedule: "Add a shift",
      cancelButton: "Cancel",
      placeholderSpecialties: "Specialties",
      placeholderSpecialty: "Specialty",
      placeholderSearch: "Search",
      optionsServiceTypeOnline: "Online",
      optionsServiceTypeAtHome: "Visit at home",
      optionsServiceTypeInHospital: "Visit at hospital",
      optionsServiceTypeInVideo: "Video call",
      optionsServiceTypeCall: "Call",
      optionsServiceTypeInChat: "In chat",
      newScheduleFilterFormTitle: "New shift",
      newScheduleFilterFormTitleEdit: "Edit",
      manually: "Manually",
      fromFile: "From a file",
      duration: "Duration",
      date: "Shift date",
      timeStart: "Start time",
      timeEnd: "End time",
      fileUploaderTitle: "Upload file .xlsx",
      // Employees
      experienceIsIncorrect: "experience is not correct",
      employeesFlowTitle: "Employees",
      experienceIsRequire: "Experience is required",
      doctors: "Our doctors",
      operators: "Operators",
      operator: "Operator",
      operatorAndAdmin: "Operator / Admin",
      phone: "Telephone",
      //Auth
      login: "Login",
      loginError: "Incorrect login",
      passwordError: "Incorrect password",
      //onBoarding
      onBoarding: "Onboarding",
      edit: "Edit",
      newOnBoard: "New onboarding",
      addImage: "Add an image",
      showInList: "Show on the list",
      //PromoCodes Page
      promocodeStatus: "Promocode status",
      promocodeStatus_0: "Unused",
      promocodeStatus_1: "Used",
      promocodeType: "Promocode type",
      promocodeType_0: "Subscription",
      promocodeType_1: "Consultation",
      promocodeGenerateSuccess: "Promocode generate success",
      promocodesCount: "All promocodes:",
      promocodesActivateCount: "Promocodes activated:",
      promoCodes: "Promocodes",
      addPromoCodes: "Add promocodes",
      promocodeName: "Promocode",
      promocodeCreated: "Created",
      promocodeExpityDate: "Expiry Date",
      promocodeExpityDateFromTo: "Expiry Date (from-to)",
      promocodeCount: "Promocodes count",
      promocodeFrom: "Issued by",
      deletePromocode: "delete",
      //Feedbacks
      placeholderFeedbacks: "Feedbacks",
      //Symptoms Page
      addSymptom: "Add a symptom",
      chooseSpecialty: "Choose specialty",
      symptomsAndSpeciality: "Symptoms and specialty",
      symptoms: "Symptoms",
      emptyList: "The list is empty",
      existingSymptoms: "Existing symptoms",
      deleteSymptom: "Delete a symptom",
      //Placeholders
      placeholderLogin: "Login",
      placeholderPassword: "Password",
      placeholderRepeatPassword: "Repeat password",
      placeholderAddSymptom: "Add a symptom",
      placeholderText: "Text",
      placeholderNumber: "Write number from 0 to 100",
      placeholderLink: "Link",
      placeholderTitle: "Title",
      //Buttons
      addButton: "Add",
      addMedicalCard: "Add a medical card",
      changeButton: "Change",
      saveButton: "Save",
      deleteButton: "Delete",
      editButton: "Edit",
      closeButton: "Close",
      acceptButton: "Apply",
      // OPERATOR
      requestCreated: "Request created",
      newPacient: "New patient",
      mainCard: "Main medical card",
      relations: "Relationship",
      adress: "Address",
      policeNumber: "Policy number",
      insurance: "Company/insurance",
      registration: "Registration",
      priceTitle: "Price",
      // Specialty
      //Placeholders
      success: "Success",
      successCreatingPatient: "Patient has been created successful!",
      placeholderMedCard: "Medical cards",
      placeholderUser: "User",
      bookDate: "Booking date",
      paymentType: "Payment method",
      hospitals: "Hospitals",
      hospitalDepartments: "Hospital departments",
      //Buttons
      arrangeButton: "Arrange",
      goodButton: "Good",
      placeholderChatMessage: "Type message",
      placeholderImageOrVideo: "Chose image or video",
      placeholderDocument: "Chose document",
      placeholderStartConsultation: "Start consultation",
      chat: "Chat",
      changeLang: "Lang",
      exitButton: "Log out",
      firstNameIsIncorrect: "First name is incorrect",
      lastNameIsIncorrect: "Last name is incorrect",
      patronymicIsIncorrect: "Patronymic is incorrect",
      overstatedExperience: "Overstated experience",
      emailIsIncorrect: "Email is incorrect",
      firstNameRequire: "Enter first name",
      lastNameIsRequire: "Enter last name",
      patronymicIsRequire: "Enter patronymic",
      emailIsRequire: "Enter email",
      phoneIsIncorrect: "Phone will be +380*********",
      phoneIsRequire: "Enter phone",
      experienceIsIncorrect: "Max possible experience:",
      experienceIsRequire: "Enter experience",
      birthDateIsIncorrect: "Birthdate is incorrect",
      birthDateIsRequire: "Enter birthdate ",
      divisionsNameRequire: "Enter the name of the branch",
      divisionsNameIsIncorrect: "Incorrect branch name",
      noConnectToServer: "No server connection",
      coonectingChatStatus: "Connecting to server",
      //Change Login
      changeLoginHeader: 'Change Login',
      changeLoginButton: 'Change login',
      changeLoginCurrent: 'Current login: ',
      changeLoginWarning: 'The login entered is registered to',
      changeLoginButtonSuccess: 'Confirm',
      changeLoginButtonMerge: 'Merge',
      changeLoginButtonDecline: 'Cancel',
      changeLoginSuccess: 'User login changed',
      changeLoginError: 'Error. User login has not been changed',
      changeLoginPhone: 'New Phone Number',
      deletedPatientCard: 'Patient card is deleted',
      subscribeProcess: 'User subscribe',
      importPromoCodeProcess: 'Promo code import',
      pushNotificationProcess: 'Send push notification',
      titleProcess: 'Background processes'
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
