import rootReducer from ".";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import socketMiddleware from "./socketMiddleware";
import newSocketMiddleware from "./chat/socket/middleware"

const middleware = [thunk, newSocketMiddleware];

export const configureStore = () => {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
};
