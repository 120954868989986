import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalWindow from '../ModalWindow/ModalWindow';
import Button from "../ui/Button/Button"
import ReportIcon from '@material-ui/icons/Report';
import "./versionCheck.scss"
import { getIsOldVersion } from '../../../redux/version/selectors';
import { toggleVersionCheck } from '../../../redux/version/actions';
import { useTranslation } from 'react-i18next';
import { APP_VERSION } from '../../../api';

// const isOLDVERSION = true
const Versioncheck = () => {
  const isOldVersion = useSelector(getIsOldVersion)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  // if (!isOldVersion) return null
  
  const handleReloadPage = () => {
    dispatch(toggleVersionCheck(false))
    window.location.reload()
  }

  const handleCancelReload = () => {
    dispatch(toggleVersionCheck(false))
  }

  return (<>
    <ModalWindow isOpen={isOldVersion}>
      <div className="versionCheckBox">
        <div className="iconBox">
          <ReportIcon style={{color: "#4b9bf5", width: "50px", height: "50px"}}/>
        </div>
        <div className="text">{t("newVersionText")}</div>
        <div className="buttonBox">
          <Button
            onClick={handleReloadPage}
            className="btn"
            text={t("reload")}
            type="button"
          />
          <Button
            onClick={handleCancelReload}
            className="btn"
            danger
            text={t("cancelButton")}
            type="button"
          />
        </div>
      </div>
    </ModalWindow>
    <div className="currentAppVersion">{`Version: ${APP_VERSION}`}</div>
  </>);
}

export default Versioncheck;
