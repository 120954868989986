import React from 'react';
import { useTranslation } from 'react-i18next';
import { statuses } from '../ConsultattionCard/values';
import { SimpleTextarea } from "../formComponent";
import Spinner from '../ui/Spinner/Spinner';

const NewAnamnez = ({
  fields = [],
  status = "",
}) => {
  const isDisabled = status === statuses.CANCELLED || status === statuses.CLOSED
  return (
    <>
      {fields.length < 1 && (
        <div className="absoluteCenter">
          <Spinner small />
        </div>
      )}
      {fields.length > 0 && fields.map((field) => {
        return (
          <SimpleTextarea
            key={field.id}
            name={field.id}
            placeholder={field.title}
            label={field.title}
            disabled={isDisabled}
            defaultValue={field.anamnesis && field.anamnesis.content}
          />
        )
      })}
    </>
  );
}

export default React.memo(NewAnamnez);
