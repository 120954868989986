export const GET_SHEDULE = "GET_SHEDULE";
export const ADD_NEW_SHEDULE = "ADD_NEW_SHEDULE";
export const SET_FILTER_MODE_SHEDULE = "SET_FILTER_MODE_SHEDULE";
export const GET_FILTERED_SHEDULE = "GET_FILTERED_SHEDULE";
export const GET_FREE_DOC_TIME_IN_HOSPITAL = "GET_FREE_DOC_TIME_IN_HOSPITAL";
export const GET_FREE_DOC_TIME = "GET_FREE_DOC_TIME";
export const GET_PROVINCES = 'GET_PROVINCES'
export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_DISTRICTS_PRICES = 'GET_DISTRICTS_PRICES'
export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST'
export const RESET_PERIODS = 'RESET_PERIODS'