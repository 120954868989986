import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../ui/Button/Button";

import ShevronRight from "../../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";

import "./Address.scss";
import { SimpleForm } from "../SimpleForm/SimpleForm";
import { SimpleInput, SimpleSelect } from "..";
import { parserForm } from "../../../../utils/parserForm";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { getDistricts } from "../../../../redux/shedule/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDistrictsSelector, getProvincesSelector } from "../../../../redux/shedule/selectors";

const Address = ({
  placeholder,
  disabled,
  input: { onChange, value },
  blur,
  input,
  onlyTextFields,
  defaultValues,
}) => {
  console.log(input);
  const dispatch = useDispatch()
  const provinces = useSelector(getProvincesSelector)
  const districts = useSelector(getDistrictsSelector)
  const { t } = useTranslation();
  const [showOptions, setOptions] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [showAddress, setShowAddress] = useState(false);
  const [canceled, setCanceled] = useState(false)
  const [province, setProvince] = useState(null)
  // console.log(selectedOption);
  const handleOnChange = (e) => {
    setSelectedOption({
      ...selectedOption,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (input.value === "") {
      setSelectedOption({});
      setShowAddress(false);
    }
    return () => console.log(1111111);
  }, [input.value]);

  useUpdateEffect(() => {
    if (province) {
      console.log(2222);
      dispatch(getDistricts(province))
    }
  }, [province])

  useEffect(() => {
    if (defaultValues && provinces && districts) {
      console.log(defaultValues);
      onChange({
        ...defaultValues,
        province: defaultValues?.province?.id || null,
        district: defaultValues?.district?.id || null,
      })
      setSelectedOption({
        ...defaultValues,
        province: defaultValues?.province?.id || null,
        district: defaultValues?.district?.id || null,
      });
    }
  }, [defaultValues, provinces, districts])
  // const handleResetForm = (evt, resetForm) => {
  //   // const { ...fields } = parserForm(evt)
  //   resetForm()
  //   setShowAddress(true);
  //   setError(false);
  //   setOptions(false);
  // }
  const submitAddressForm = (e) => {
    e.stopPropagation()
    // e.preventDefault()
    const { ...fields } = parserForm(e)
    setSelectedOption(fields)
    console.log(fields);
    console.log(1);
    onChange(fields)
    setShowAddress(true);
    setError(false);
    setOptions(false);
  }

  const  defaultDistrict = input.value.district
  console.log(defaultDistrict);
  return (
    <div className="addressWrapper">
      <Button
        className={`btn ${blur ? "blur" : ""}`}
        secondary
        text={
          Object.keys(selectedOption).length !== 0
            ? `
            ${ !onlyTextFields && selectedOption.province ? `г. ${provinces.find(el => el.id == selectedOption.province)?.title}, ` : ""}
            ${ !onlyTextFields && selectedOption.district ? `${districts.find(el => el.id == selectedOption.district)?.title}, ` : ""}
            ${selectedOption.thoroughfare
              ? `ул. ${selectedOption.thoroughfare}, `
              : ""
            }
            ${selectedOption.houseNumber
              ? `дом. ${selectedOption.houseNumber}, `
              : ""
            }
          ${selectedOption.flatNumber
              ? `кв. ${selectedOption.flatNumber}, `
              : ""
            }
          ${selectedOption.comment ? `код. ${selectedOption.comment}, ` : ""}
          `
            : placeholder
        }
        // ${selectedOption.district ? `р-н. ${selectedOption.district}, ` : ""}
        shevron={ShevronRight}
        type="button"
        onClick={() => setOptions(true)}
        disabled={disabled}
      />
      <div className={`optionSelectingContainer ${showOptions ? "show" : ""}`}>
        <div className="formTitle">
          <img
            src={arrowLeft}
            alt="icon"
            onClick={(e) => {
              setCanceled(true)
              // confirmChanges(e);
              setShowAddress(true);
              setError(false);
              setOptions(false);
            }}
          />
          {placeholder}
        </div>
        <div className="inputsContainer">
          <SimpleForm
            className="newCardFormWrapper"
            handleSubmit={submitAddressForm}
            autoComplete="off"
            novalidate
          >
            {!onlyTextFields && (<>
              <SimpleSelect
                name="province"
                required
                placeholder={t("chooseCity")}
                setSelectedValue={setProvince}
                options={provinces.map(el => ({ label: el.title, value: el.id, }))}
              />
              <SimpleSelect
                required
                defaultValue={input.value.district}
                setSelectedValue={() => {}}
                // defaultValue={{label: currentDistrict?.title, value: currentDistrict?.id}}
                // value={input.value.district}
                name="district"
                placeholder={t("chooseDistrict")}
                options={districts.map(el => ({ label: el.title, value: el.id, }))}
              />
            </>)}
            {!onlyTextFields && (
              <SimpleInput
                name="city"
                pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ | -]{1,}$"
                errorMessage={t('cityIsIncorrect')}
                errorRequire={t('cityNameRequire')}
                placeholder={`${t("city")}*`}
                label={`${t("city")}*`}
                // defaultValue={defaultValues?.thoroughfare}
                required
              />
            )}
          
            <SimpleInput
              // onChange={handleOnChange}
              name="thoroughfare"
              pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ | -]{1,}$"
              errorMessage={t('thoroughfareIsIncorrect')}
              errorRequire={t('thoroughfareRequire')}
              placeholder={`${t("street")}*`}
              label={`${t("street")}*`}
              defaultValue={defaultValues?.thoroughfare}
              required
            />
            <div className={"inlineInputs"}>
              <SimpleInput
                // onChange={handleOnChange}
                name="houseNumber"
                pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ0-9_-]{1,16}$"
                errorMessage={t('buildingIsIncorrect')}
                errorRequire={t('buildingIsRequire')}
                placeholder={`${t("building")}*`}
                defaultValue={defaultValues?.houseNumber}
              />
              <SimpleInput
                // onChange={handleOnChange}
                name="flatNumber"
                // pattern="^[0-9]+$"
                // errorMessage={t('apartmentIsIncorrect')}
                // errorRequire={t('apartmentIsRequire')}
                // placeholder={t("policeNumber")}
                placeholder={`${t("apartment")}`}
                label={`${t("apartment")}`}
                defaultValue={defaultValues?.flatNumber}
                // required
              />
            </div>
            <div className={"inlineInputs"}>
              <SimpleInput
                // onChange={handleOnChange}
                name="entrance"
                // pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ0-9_-]{1,16}$"
                // errorMessage={t('buildingIsIncorrect')}
                // errorRequire={t('buildingIsRequire')}
                placeholder={`${t("entrance")}*`}
                label={`${t("entrance")}`}
                defaultValue={defaultValues?.entrance}
              />
              <SimpleInput
                // onChange={handleOnChange}
                name="floor"
                // pattern="^[0-9]+$"
                // errorMessage={t('apartmentIsIncorrect')}
                // errorRequire={t('apartmentIsRequire')}
                // placeholder={t("policeNumber")}
                placeholder={`${t("floor")}`}
                label={`${t("floor")}`}
                defaultValue={defaultValues?.floor}
                // required
              />
            </div>
            <SimpleInput
              name="comment"
              // pattern="^[0-9]+$"
              // errorMessage={t('commentIsIncorrect')}
              // errorRequire={t('commentIsRequire')}
              placeholder={`${t("codeOrNote")}`}
              // onChange={handleOnChange}
              label={`${t("codeOrNote")}`}
              defaultValue={defaultValues?.comment}
            />
            <Button
              type='submit'
              // onClick={submitAddressForm}
              className='btn'
              text={t("confirm")}
            />
          </SimpleForm>
          <span className={error ? "errorAddress shown" : "errorAddress"}>
            {t("addressValidate")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Address;
