import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getSymptomsBySpecializationId: async (id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/symptoms?filter[specialty]=${id}`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },
  addSymptom: async (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/symptoms`,
      headers: setHeader(),
      data,
    };

    const res = await axios(config);
    return res && res.data;
  },
  editSymptom: async (data, id) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/symptoms/${id}`,
      headers: setHeader(),
      data,
    };

    const res = await axios(config);
    return res && res.data;
  },
  deleteSymptom: async (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/symptoms/${id}`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },
};
