import * as types from "./types";
import createReduser from "../../utils/createReducer";

const initialState = {};

const specializationsMappingReducer = createReduser(initialState)({
  [types.GET_ALL_SPECIALIZATIONS_FROM_ELEKS]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
});

export default specializationsMappingReducer;
