import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import SheduleFilter from "../../components/SheduleFilter/SheduleFilter";
import SheduleList from "../../components/SheduleList/SheduleList";
import NewShiftForm from "../../forms/NewShiftForm/NewShiftForm";

import { getAllSpecializations } from "../../../redux/specializations/actions";
import { getDistrictsSelector, getProvincesSelector } from "../../../redux/shedule/selectors";

const Shedule = ({
  getAllSpecializations,
  role,
  provinces,
  districts,
}) => {
  const [creatingNew, setCreating] = useState(false);
  const [edit, setEdit] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [doctors, setDoctors] = useState();
  const [filterParams, setFilterParams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllSpecializations(50).then(({ items }) => setSpecializations(items));
  }, []);

  return (
    <div className="sheduleConauner flex justifyBeetwen">
      {specializations && specializations.length >= 1 && (
        <>
          <SheduleList
            setEdit={() => setEdit(true)}
            setCreatingTrue={() => setCreating(true)}
            create={creatingNew}
            edit={edit}
            specializations={specializations}
            role={role}
            filterParams={filterParams}
            loading={loading}
            setLoading={setLoading}
          />
          <SheduleFilter
            mode="shedule"
            specializations={specializations}
            setFilterParams={setFilterParams}
            loading={loading}
            setLoading={setLoading}
          />
          <NewShiftForm
            create={creatingNew}
            edit={edit}
            setEditingOff={() => setEdit(false)}
            setCreatingOff={() => setCreating(false)}
            specializations={specializations}
            doctors={doctors}
            provinces={provinces}
            districts={districts}
            setDoctors={setDoctors}
          />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  provinces: getProvincesSelector(state),
  districts: getDistrictsSelector(state),
})
const mapDispatchToProps = { getAllSpecializations };
export default connect(mapStateToProps, mapDispatchToProps)(Shedule);
