import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getAllBanners: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/pictures`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  addNewBanner: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/pictures`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  updateBanner: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/pictures/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  upload: (id, file) => {
    let formData = new FormData();
    formData.append("srcFile", file);
    return axios
      .post(`${TEST_API}/admin/pictures/${id}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  deleteBanner: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/pictures/${id}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
};
