import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getAllOnboarding } from "../../../redux/onboarding/actions";
import { setInitalValues } from "../../../redux/formInitialValues/actions";

import emptyImage from "../../../style/icons/EmptyImg/emptyImg.svg";
import editPen from "../../../style/icons/Pen/pen.svg";

import Spinner from "../ui/Spinner/Spinner";
import Button from "../ui/Button/Button";

import "./onboardingList.scss";

const OnboardingList = ({
  getAllOnboarding,
  items,
  setInitalValues,
  setEdit,
  token,
  setAddingImg,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getAllOnboarding().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    getAllOnboarding();
  }, [token]);

  return (
    <div className="onboardingDashBoardContainer">
      <div className="title">{t("onBoarding")}</div>
      <div
        className={`onboardingItemsContainer ${
          items && items.length ? "" : "empty"
        }`}
      >
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
        {!loading && items && items.length
          ? items.map((onboardingItem) => (
              <div key={onboardingItem.id} className="onboardingItem">
                <Button
                  className="edit"
                  withIcon
                  icon={editPen}
                  onClick={() => {
                    setEdit();
                    setAddingImg(false);
                    setInitalValues({
                      photo: { cropImg: onboardingItem.srcUrl },
                      ...onboardingItem,
                    });
                  }}
                />
                <img src={onboardingItem.srcUrl || emptyImage} alt="" />
              </div>
            ))
          : !loading &&
            items &&
            (items.length === 0 || items === []) && <p>{t("emptyList")}</p>}
      </div>
    </div>
  );
};

const mapStateToProps = ({ onboarding, user }) => ({
  token: user && user.token,
  items: onboarding && onboarding.items,
  current_page: onboarding && onboarding.current_page,
  page_count: onboarding && onboarding.page_count,
  items_per_page: onboarding && onboarding.items_per_page,
  total_item_count: onboarding && onboarding.total_item_count,
});
export default connect(mapStateToProps, { getAllOnboarding, setInitalValues })(
  OnboardingList
);
