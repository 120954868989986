import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import api from "../../../api";

import { getInsuranceCompanies } from "../../../redux/employees/actions";
import { checkIsSuitable } from "../../../utils/length";

import Input from "../../components/formComponent/Input/Input";
import Select from "../../components/formComponent/Select/Select";
import TextArea from "../../components/formComponent/TextArea/TextArea";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";

import "./PushNotificationForm.scss";
import i18n from "../../../i18n";

const PushNotificationsForm = ({
  insuranceCompanies = [String],
  reset,
  formValues = { Object },
  loading = true,
  setLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInsuranceCompanies({
      title: i18n.t("noInsuranceCompany"),
      id: 0,
    }));
    setLoading(false);
  }, []);

  if(!insuranceCompanies.length) return null;

  const insuranceOptions = insuranceCompanies?.map((item) => item.title) || [];

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formValues.gender) {
      formValues.gender =
        String(formValues.gender) === String(t("maleSex")) ? 0 : 1;
    }
    if (formValues.insuranceCompany) {
      insuranceCompanies &&
        insuranceCompanies.map((item) => {
          if (item.title === formValues.insuranceCompany) {
            formValues.insuranceCompany = item.id;
          }
        });
    }
    api.subscriptions
      .sendPushNotifications({ topicPush: { ...formValues } })
      .then(() => dispatch(reset("pushNotifications")))
      .then(() => setLoading(false));
  };

  return (
    <div className="pushNotificationsFormWrapper">
      <p className="pageTitle">{t("pushNotificationPageTitle")}</p>
      {loading ? (
        <div className="center">
          <Spinner />
        </div>
      ) : (
        <Form className="pushNotificationsForm" onSubmit={formSubmit}>
          <Field
            name="gender"
            options={[t("maleSex"), t("femaleSex")]}
            component={Select}
            placeholder={t("gender")}
            autoComplete='off'
          />
          <Field
            name="ageFrom"
            component={Input}
            type="number"
            placeholder={t("pushAgeFrom")}
          />
          <Field
            name="ageTo"
            component={Input}
            type="number"
            placeholder={t("pushAgeTo")}
          />
          <Field
            name="insuranceCompany"
            options={insuranceOptions}
            component={Select}
            placeholder={t("insuranceCompany")}
            autoComplete='off'
          />
          <Field
            component={Input}
            name="title"
            placeholder={t("pushTitle")}
            allowSpaces
          />
          <Field
            component={TextArea}
            name="body"
            placeholder={t("placeholderChatMessage")}
            withCounters
            maxLength={300}
          />
          <Button
            className="btn"
            type="submit"
            loading={loading}
            text={t("pushNotificationPageTitle")}
            disabled={!formValues || !formValues.title || !formValues.body || !checkIsSuitable(formValues.body.length)}
          />
        </Form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("pushNotifications")(state),
  insuranceCompanies: state.employees && state.employees.insuranceCompanies,
});

const enhance = compose(
  connect(mapStateToProps, null),
  reduxForm({ form: "pushNotifications", enableReinitialize: true })
);

export default enhance(PushNotificationsForm);
