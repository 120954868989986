import { setHeader, TEST_API } from "../index";
import axios from "axios";
import { defineActive } from "../../helpers/functions";

export default {
  getAllConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }${numberOfPage ? `?page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  getAllFeedbacks: (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${`?filter[withReview]`}${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res.data);
    // .filter(({ review }) => review !== null))
  },

  getFilteredConsultations: async ({
    allOrInWork,
    role = "",
    specialization = {
      id: "",
    },
    dateStart = "",
    dateEnd = "",
    serviceFormat = {
      type: "",
    },
    status = {
      value: "",
    },
    numberOfPage = 1,
    filterMode,
  }) => {
    console.log('specialization', specialization);
    // TO DO: FIX THIS IFS
    if (serviceFormat.type === 3) serviceFormat.type = 1;
    if (serviceFormat.type === 4) serviceFormat.type = 2;
    const multipleSpecs =
      Array.isArray(specialization) &&
      specialization.reduce((acc, el) => (acc.push(el.id), acc), []).join(",");

    const isActive = defineActive(allOrInWork?.value);
    console.log('muliSpecs', multipleSpecs);
    if (role === "operator" || role === "insurance-operator") {
      const URL =
        filterMode !== "all"
          ? `${TEST_API}/admin/employee/appointment/monitoring${
              role === "insurance-operator" ? "/insurance" : ""
            }?filter[${filterMode}]=true${
              multipleSpecs ? `&filter[specialty]=${multipleSpecs}` : ""
            }&filter[status]=${status.value}&filter[type]=${
              serviceFormat.type
            }${numberOfPage ? `&page=${numberOfPage}` : ""}
      `
          : `${TEST_API}/admin/employee/appointment/monitoring${
              role === "insurance-operator" ? "/insurance" : ""
            }?filter[from]=${
              isActive ? isActive : dateStart
            }&filter[to]=${dateEnd}${
              multipleSpecs ? `&filter[specialty]=${multipleSpecs}` : ""
            }&filter[status]=${status.value}&filter[type]=${
              serviceFormat.type
            }${numberOfPage ? `&page=${numberOfPage}` : ""}`;
      let config = {
        method: "GET",
        baseURL: URL,
        headers: setHeader(),
      };

      const res = await axios(config);
      return res && res.data;
    } else if (role === "doctor") {
      const isActive = defineActive(allOrInWork.value);
      console.log(filterMode);
      console.log(serviceFormat);
      let config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[${filterMode}]=true&filter[from]=${
          isActive ? isActive : dateStart
        }&filter[to]=${dateEnd}&filter[status]=${status.value}&filter[type]=${
          serviceFormat.type
        }${numberOfPage ? `&page=${numberOfPage}` : ""}`,
        headers: setHeader(),
      };

      const res = await axios(config);
      return res && res.data;
    }
  },

  getConsultationsWithFilters: (role, filterString) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/${role !== 'doctor' ? 'employee' : role}/appointment/monitoring${role === "insurance-operator" ? "/insurance" : ""}?${filterString}`,
      headers: setHeader(),
    }
    return axios(config).then((res) => res && res.data);
  },

  getConsultationPrimary: ({
    role,
    filter,
    page,
    filterParams,
  }) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/${role !== 'doctor' ? 'employee' : role}/appointment/monitoring${role === "insurance-operator" ? "/insurance" : ""}?${[
        `page=${page}`,
        `filter[${filter && filter.toLowerCase()}]=true`,
        `filter[status]=${filterParams?.status ?? ''}`,
        `filter[type]=${filterParams?.type ?? ''}`,
        `filter[from]=${filterParams?.inWork ? filterParams.inWork : ''}`,
        `${(role === 'operator' && filterParams?.specialization?.length > 0) ? `filter[specialty]=${filterParams?.specialization}` : ''}`
      ].join("&")}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  getAllDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getConsultationsByPationId: ({ patientId, page, filter }) => {
    console.log({ patientId, page, filter });
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/${patientId}/appointments?${[
        `page=${page}`,
        `filter[${filter && filter.toLowerCase()}]=true`,
      ].join("&")}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTodayDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[today]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTodayConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[today]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTomorrowDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[tomorrow]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTomorrowConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[tomorrow]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getWeekDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[week]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getWeekConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[week]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  createConsultationAnamnesis: ({ appointment_id, data }) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${appointment_id}/anamnesis`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  createConsultation: (id, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/specialty/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  updateConsultation: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/appointments/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  updateConsultationStatus: (id, status) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/status/${status}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getConsultationAnamnesis: (appointment_id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/appointments/${appointment_id}/anamnesis`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  fillingCard: (id, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/fillCard`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },
  finish: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/done`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
  startConsultation: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/start`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  canselConsultation: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/cancel`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getCardOptions: (searchURL, page = 1) => {
    const URL = searchURL
      ? `${TEST_API}${searchURL}&page=${page}`
      : `${TEST_API}/admin/appointments/card/entities`;
    let config = {
      method: "GET",
      baseURL: URL,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getCurrentConsultation: (id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/appointments/${id}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  getRandomDoctor: (specialty, type, district = '') => {
    let url = `${TEST_API}/admin/consultations/home/doctor/specialty/${specialty}${district ? `/district/${district}` : ''}`
    if (!district) {
      url = `${TEST_API}/admin/consultations/doctor/specialty/${specialty}/type/${type}`
    }
    let config = {
      method: "GET",
      baseURL: url,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  getRandomDoctorInHospital: (hospital, specialty) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/consultations/hospital/${hospital}/doctor/specialty/${specialty}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
};
