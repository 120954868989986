import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { roleIs } from "../../helpers/functions";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const pathname = useHistory().location.pathname.split("/");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          user &&
          user.isLogged &&
          user.roles &&
          user.roles.some((el) => roleIs(el) === pathname[2])
        ) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};
const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(PrivateRoute);
