import React, { useState } from "react";

import "./ModalWindow.scss";

const ModalWindow = (props) => {
  const isOpen = typeof props.isOpen === "boolean" ? props.isOpen : false;

  const handleBackdropClick = () => {
    if (typeof props.toggleOpen === "function") {
      props.toggleOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-window">
      <div className="modal-backdrop" onClick={handleBackdropClick} />
      <div className="modal-content">{props.children}</div>
    </div>
  );
};

export default ModalWindow;
