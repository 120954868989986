import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, getFormValues, reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { useTranslation } from "react-i18next";

import patient from "../../../style/icons/Subscription/patient.svg";
// import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import company from "../../../style/icons/Subscription/company.svg";
import company_active from "../../../style/icons/Subscription/company_active.svg";
import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import arrowRight from "../../../style/icons/ShevronRight/shevron.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import moment from "moment"
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { setSubs } from "../../../redux/subscriptions/actions";
import {
  setNetworkError,
  setPopUpSuccess,
} from "../../../redux/networkError/actions";

import api from "../../../api";
import { voidRequired } from "../../../helpers/validate";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import ToggleImg from "../../components/formComponent/ToggleImg/ToggleImg";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import Input from "../../components/formComponent/Input/Input";
import DatePicker from "../../components/formComponent/DatePicker/DatePicker";
import FilterSelect from "../../components/formComponent/FilterSelect/FilterSelect";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import "./NewSubscriptionForm.scss";
import { getServerTimeFormat } from "../../../helpers/dateFormats";

const NewSubscriptionForm = ({
  handleEdit,
  handleCloseEdit,
  edit,
  editItem,
  specializations,
  getAllSpecializations,
  token,
  setSubs,
  formValues = {},
  setInitalValues,
  resetInitialValues,
  setNetworkError,
  setPopUpSuccess,
  reset,
}) => {
  const { t } = useTranslation();
  const [buttonDisable, isButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [isImg, toggleSetImg] = useState(false);
  // const [file, setFile] = useState(null);


  useEffect(() => {
    if (edit && editItem && formValues) {
      setLoading(true)
      formValues.title = editItem.title ? editItem.title : "Название компании"
      formValues.validFrom = moment(editItem.subscription.validFrom).format("DD/MM/YYYY")
      formValues.validTill = moment(editItem.subscription.validTill).format("DD/MM/YYYY")
      formValues.price = editItem.subscription.price
      formValues.activePeriod.value = editItem.subscription.activePeriod
      formValues.discount = editItem.subscription.discount
      formValues.specialty = editItem.subscription.specialtyScope === 0
        ? [...specializations]
        : editItem.subscription.specialtyMap.map((el) => {
        for (let i = 0; i < specializations.length; i++){
          if (el.specialty.title === specializations[i].title) {
            return specializations[i]
          }
        }
      })
      
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }, [edit])

  useEffect(() => {
    if (
      formValues &&
      formValues.validFrom &&
      formValues.validTill &&
      formValues.discount &&
      formValues.price &&
      formValues.activePeriod &&
      formValues.specialty
    ) {
      isButtonDisable(false);
    } else {
      isButtonDisable(true);
    }
  }, [formValues]);

  useEffect(() => {
    getAllSpecializations(50);
  }, [token]);

  const closeEdit = () => {
    setLoading(true)
    if (formValues && formValues.validFrom) {
      handleCloseEdit()
      reset()
      setInitalValues({
        value: "company",
        text: "Компания",
        imgActive: company_active,
        imgInactive: company,
        title: "Название компании"
      });
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }
  const toClose = () => {
    setLoading(false);
    isButtonDisable(true);
    resetInitialValues();
    setSubs();
    setInitalValues({
      value: "company",
      text: "Компания",
      imgActive: company_active,
      imgInactive: company,
      title: "Название компании",
    });
    
    if (edit) {
      handleCloseEdit()
    }
  };

  const createSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    //----------CREATE USER SUBSCRIPTION
    const [startFormat, endFormat] = getServerTimeFormat([formValues.validFrom, formValues.validTill])
    if (
      formValues &&
      formValues.subType &&
      formValues.subType.value === "patient"
    ) {
      const body = JSON.stringify({
        subscription: {
          title: formValues.title ?? "Название компании" ,
          validFrom: startFormat,
          validTill: endFormat,
          activePeriod: formValues.activePeriod,
          scope: "self",
          specialtyScope:
            //1 На отдельные специальности, 0 - Это на все специальности
            formValues &&
            formValues.specialty &&
            formValues.specialty.length === specializations.length
              ? 0
              : 1,
          specialtyMap: formValues.specialty,
          discount: formValues.discount,
        },
      });
      api.subscriptions.createSub(body).then(() => toClose());
    }
    if (
      formValues &&
      formValues.subType &&
      formValues.subType.value === "company"
    ) {
      //----------CREATE COMPANY SUBSCRIPTION
      if (
        formValues &&
        formValues.specialty &&
        formValues.specialty.length === specializations.length
      ) {
        //----------CREATE COMPANY IF ALL SPECIALIZATIONS PICKED
        const body = {
          company: {
            title: formValues.title ?? "Название компании",
            subscription: {
              title: formValues.title ?? "Название компании",
              description: "0",
              price: formValues.price,
              validFrom: startFormat,
              validTill: endFormat,
              activePeriod:
                formValues.activePeriod && formValues.activePeriod.value,
              scope: "self",
              specialtyScope: 0,
              discount: formValues.discount,
            },
          },
        };
        return !edit ? api.subscriptions
          .createCompany(JSON.stringify(body)) : api.subscriptions.editCompany(editItem.id, body)
            .then(async (res) => {
            console.log(res);
            if (res.code) {
              setLoading(false);
              setNetworkError({
                response: {
                  status: t("warning"),
                  statusText: res.message,
                },
              });
            } else {
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: !edit ? t("subscriptionCreated") : t("subscriptionEdited"),
                  },
                },
              });
            }
          })
          .then(() => toClose())
          .catch((e) => console.log({e}))
      } else {
        //----------CREATE SUBSCRIPTION IF CUSTOM SPECIALTY PICK
        const specialties = formValues.specialty.map((specialty) => {
          return { specialty: specialty.id, discount: formValues.discount };
        });

          const body = {
            company: {
              title: formValues.title ?? "Название компании",
              subscription: {
                title: formValues.title ?? "Название компании",
                description: "0",
                price: formValues.price,
                validFrom: startFormat,
                validTill: endFormat,
                activePeriod:
                  formValues.activePeriod && formValues.activePeriod.value,
                scope: "self",
                specialtyScope: 1,
                specialtyMap: specialties,
                discount: formValues.discount,
              },
            },
        }
        if (!edit) {
          try {
            await api.subscriptions.createCompany(JSON.stringify(body))
            setLoading(false);
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: !edit ? t("subscriptionCreated") : t("subscriptionEdited"),
                },
              },
            });
            toClose()
          } catch (e) {
            setLoading(false)
            setNetworkError({
              response: {
                status: t("warning"),
                statusText: e.message,
              },
            });
            toClose()
          }
        }
        if (edit) {
          try {
            await api.subscriptions.editCompany(editItem.id, body)
            setLoading(false);
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: !edit ? t("subscriptionCreated") : t("subscriptionEdited"),
                },
              },
            });
            toClose()
          } catch (e) {
            setLoading(false);
            setNetworkError({
              response: {
                status: t("warning"),
                statusText: e.message,
              },
            });
            toClose()
          }
        }
      }
      // formValues.photo.canvasObj.toBlob((file) => setFile(file));
      // .then((entity) => entity.entity.id)
      // .then((id) =>
      //   api.subscriptions
      //     .addCompanyPhoto(id, file)
      //     .then((res) => setSubs())
      //     .catch(() => console.error("ERROR_MESSAGE"))
      // );
    }
    // e.stopPropagation();
  };

  return (
    <div className="newSubscriptionForm">
      {edit ? 
        <div className={`formTitleEdit ${edit ? "edit" : ""}`}>
          {`     
            ${edit ? t("newScheduleFilterFormTitleEdit") : t("newEmployee")} `}
          {edit && (
            <img src={close} alt="arrow-icon" onClick={closeEdit} />
            )}
        </div>
        : <p className="formTitle">{t("newSubscription")}</p>
      }
      <Form className="subscriptionForm" onSubmit={createSubscription}>
        {loading ? (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="formContent">
              <Field
                component={Input}
                name={"title"}
                validate={[voidRequired]}
                placeholder={t("title")}
                value={editItem && editItem.title}
                allowSpaces  
              />
              <Field
                name="subType"
                component={ToggleImg}
                options={[
                  // {
                  //   value: "patient",
                  //   text: "Пациент",
                  //   imgActive: patient_active,
                  //   imgInactive: patient,
                  // },
                  {
                    value: "company",
                    text: t("company"),
                    imgActive: company_active,
                    imgInactive: company,
                  },
                ]}
              />
              {/*{formValues && formValues.subType === "company" ? (*/}
              {/*  formValues.photo && formValues.photo.cropImg ? (*/}
              {/*    <div className="specAvatar">*/}
              {/*      <div className="flex alignCenter">*/}
              {/*        <img*/}
              {/*          className="cropPhoto"*/}
              {/*          src={formValues.photo.cropImg}*/}
              {/*          alt="avatar-icon"*/}
              {/*        />*/}
              {/*        <div className="fileName">*/}
              {/*          {formValues &&*/}
              {/*            formValues.photo &&*/}
              {/*            formValues.photo.fileName}*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        className="trashIcon"*/}
              {/*        onClick={() => {*/}
              {/*          setInitalValues({*/}
              {/*            ...formValues,*/}
              {/*            photo: null,*/}
              {/*          });*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <img src={trash} alt="" />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    <Button*/}
              {/*      secondary*/}
              {/*      withIcon*/}
              {/*      icon={plus}*/}
              {/*      shevron={arrowRight}*/}
              {/*      type="button"*/}
              {/*      className="btn"*/}
              {/*      text="Изображение"*/}
              {/*      onClick={() => toggleSetImg(true)}*/}
              {/*    />*/}
              {/*  )*/}
              {/*) : (*/}
              {/*  ""*/}
              {/*)}*/}
              {/*<div className={`addingImg ${isImg ? "show" : ""}`}>*/}
              {/*  <div className="formTitle">*/}
              {/*    <img*/}
              {/*      src={arrowLeft}*/}
              {/*      alt="arrow-icon"*/}
              {/*      onClick={() => toggleSetImg(false)}*/}
              {/*    />*/}
              {/*    Добавить изображение*/}
              {/*  </div>*/}
              {/*  <div className="photoUploadFieldContainer">*/}
              {/*    <Field*/}
              {/*      name="photo"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={FileUploader}*/}
              {/*      previewTrashIcon={trash}*/}
              {/*      close={() => toggleSetImg(false)}*/}
              {/*      editingImgUrl={*/}
              {/*        initialValues &&*/}
              {/*        initialValues.photo &&*/}
              {/*        initialValues.photo.cropImg*/}
              {/*      }*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              <Field
                name="activePeriod"
                component={Toggle}
                options={[
                  { value: "P3M", text: `3 ${t("month")}` },
                  { value: "P6M", text: `6 ${t("months")}` },
                  { value: "P9M", text: `9 ${t("months")}` },
                  { value: "P12M", text: `12 ${t("months")} ` },
                ]}
              />
              <div className="dateRange">
                <Field
                  name={`validFrom`}
                  inputProps={{
                    name: "validFrom",
                    // required: required,
                  }}
                  // validate={[required, date, maxDateNow]}
                  component={DatePicker}
                  placeholder="--/--/----"
                  // locale={language.lang}
                />
                <Field
                  name={`validTill`}
                  inputProps={{
                    name: "validTill",
                    // required: required,
                  }}
                  // validate={[required, date, maxDateNow]}
                  component={DatePicker}
                  // validDate={valid}
                  placeholder="--/--/----"
                />
                <img
                  src={close}
                  alt="close"
                  onClick={async () => {
                    await setInitalValues({
                      ...formValues,
                      validFrom: null,
                      validTill: null,
                    });
                    let picker = document.getElementsByClassName(
                      "form-control"
                    );
                    for (let i = 0; i < picker.length; i++) {
                      const element = picker[i];
                      element.value = "";
                    }
                  }}
                />
                <img src={calendar} alt="calendar" />
              </div>

              <Field
                name="specialty"
                validate={[voidRequired]}
                component={FilterSelect}
                placeholder={t("placeholderSpecialty")}
                options={specializations}
                multiple
                multipleInTitle
                withPreview={formValues && formValues.specialty}
                previewIcon={docAvatar}
              />
              <Field
                name="discount"
                type="number"
                min="0"
                max="100"
                validate={[voidRequired]}
                component={Input}
                placeholder={t("percentileDiscount")}
                withPreview
                selectedOption={formValues && formValues.discount}
              />
              <Field
                name="price"
                type="number"
                min="0"
                max="50000"
                validate={[voidRequired]}
                component={Input}
                placeholder={t("priceDiscount")}
                withPreview
                selectedOption={formValues && formValues.discount}
              />
            </div>
            <div className="formFooter">
              <Button
                loading={loading}
                className="btn active-button"
                type="submit"
                text={edit ? t("editButton") : t("createButton")}
                disabled={!edit ? buttonDisable : false}
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  getAllSpecializations,
  setSubs,
  setInitalValues,
  resetInitialValues,
  setNetworkError,
  setPopUpSuccess,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("subscription")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  token: state.user.token,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "subscription", enableReinitialize: true })
);

export default enhance(NewSubscriptionForm);


