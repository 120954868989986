import React, { useState, useEffect } from "react";
import close_icon from "../../../../style/icons/Chat/close-icon.svg";
import Spinner from "../../ui/Spinner/Spinner";

export const ImageModalContent = (props) => {
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => setLoader(false), 500);
  }, []);

  return (
    <div className="templates-modal-content image-modal">
      {loader ? (
        <Spinner />
      ) : (
        <>
          <div className="templates-header modal-header image-header">
            <div
              className="icon-button help-modal-icon-button"
              onClick={handleClose}
            >
              <img src={close_icon} alt="Закрыть" />
            </div>
          </div>
          <div className="templates-content image-modal-content">
            <img src={props.imgUrl} alt="userImage" />
          </div>
        </>
      )}
    </div>
  );
};
