import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = {
  current_page: 1,
  items: [],
  total_item_count: 0,
  page_count: 0,
  items_per_page: 20,
};

const companiesReducer = createReducer(initialState)({
  [types.GET_ALL_COMPANIES]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
});

export default companiesReducer;
