import * as types from "./type";
import createReducer from "../../utils/createReducer";

const initialState = {
  isLogged: false,
  token: undefined,
};

const authReducer = createReducer(initialState)({
  [types.LOG_IN]: (state, { token }) => {
    return {
      isLogged: true,
      token,
    };
  },

  [types.GET_USER]: (state, { user }) => ({
    ...state,
    ...user,
  }),

  [types.SET_REFRESH_TOKEN]: (state, token) => ({ ...state, ...token }),

  [types.SIGN_OUT]: () => null,
});

export default authReducer;
