import * as types from "./types";

const getNetworkError = () => ({
  type: types.GET_NETWORK_ERROR,
});

export const setNetworkError = (error) => async (dispatch) => {
  await dispatch(getNetworkError());
  await dispatch({ type: types.SET_NETWORK_ERROR, payload: error.response });
};

export const setPopUpSuccess = (obj) => async (dispatch) => {
  await dispatch(getNetworkError());
  await dispatch({ type: types.SET_NETWORK_ERROR, payload: obj.response });
};
