import api from "../../api";
import { setPopUpSuccess } from "../networkError/actions";
import * as types from "./types";

export const setSubs = () => (dispatch) => {
  return api.subscriptions.getSubs().then((res) =>
    dispatch({
      type: types.GET_ALL_SUBSCRIPTIONS,
      subscriptions: res.data,
    })
  );
};

export const getSubscribers = (id) => (dispatch) => {
  return api.subscriptions.getSubscribers({ id }).then((res) => {
    dispatch({
      type: types.GET_SUBSCRIPTED_USERS,
      ...res.data,
    });
  });
};

export const setSubscribersPagination = ({ id, numberOfPage }) => (
  dispatch
) => {
  return api.subscriptions.getSubscribers({ id, numberOfPage }).then((res) => {
    dispatch({
      type: types.GET_SUBSCRIPTED_USERS,
      ...res.data,
    });
  });
};

export const setPagination = ({ numberOfPage }) => (dispatch) => {
  return api.subscriptions.getSubs(numberOfPage).then((res) => {
    dispatch({
      type: types.GET_ALL_SUBSCRIPTIONS,
      subscriptions: res.data,
    });
  });
};

export const createSub = (body) => {
  return api.subscriptions.createSub(body).then((res) => setSubs());
};

export const deleteSub = (obj) => dispatch => {
  api.subscriptions.deleteSub(obj).then((res) => {
    dispatch({ type: types.DELETE_SUBSCRIBER, id: obj.subscriber })
  })
}

export const deleteCompany = (id) => dispatch => {
  return api.subscriptions.deleteCompany(id)
    .then(() => dispatch({ type: types.DELETE_COMPANY, id }))
}
