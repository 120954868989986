import React, { useState } from "react";

import SymptomsList from "../../components/SymptomsList/SymptomsList";
import NewSymptomsForm from "../../forms/NewSymptomsForm/NewSymptomsForm";

const Symptoms = () => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <SymptomsList edit={edit} setEdit={() => setEdit(true)} />
      <NewSymptomsForm edit={edit} setEditingOff={() => setEdit(false)} />
    </>
  );
};

export default Symptoms;
