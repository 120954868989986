import React from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from '../../../hooks/useOutsideClick';
import "./DetailAddressModal.scss"

const DetailAddressModal = ({ options, handleClose }) => {
  const modalRef = React.useRef(null)

  useOnClickOutside(modalRef, handleClose)

  const {t} = useTranslation()
  return (
    <div ref={modalRef} className="addressModal">
      {Object.keys(options).map(el => {
        return (
          <div className="addressOption">
            <div className="optionName">{t(el)}:</div>
            <div className="optionValue">{options[el] ? options[el] : " - "}</div>
          </div>
        )
      })}
    </div>
  );
}

export default DetailAddressModal;
