import React from "react";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import plus from "../../../../style/icons/PlusInCircle/plus-big.svg";

import "./photoPassUploader.scss";
import Button from "../../ui/Button/Button";
import { toBase64, convertBase64ToFile } from "../../../../helpers/functions";

class FileUploader extends React.Component {
  state = {
    prev: "",
    fileList: [],
    drag: false,
    file: undefined,
  };

  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.changeHandler({ file: e.dataTransfer.files });
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener("dragenter", this.handleDragIn);
      div.removeEventListener("dragleave", this.handleDragOut);
      div.removeEventListener("dragover", this.handleDrag);
      div.removeEventListener("drop", this.handleDrop);
    }
    this.setState(null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.prev && this.props.editingImgUrl) {
      this.setState({ ...this.state, prev: "" });
    } /* else {
      this.setState({ ...this.state, prev: prevState.prev });
    } */
  }

  changeHandler = (e) => {
    const file = (e && e.target && e.target.files[0]) || (e.file && e.file[0]);
    e.persist();

    this.setState({ fileList: [file] });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (event) => {
      this.setState({ prev: event.target.result, file: file });
      e.target.value = [];
    };
  };

  saveFile = async () => {
    toBase64(
      this.cropper.getCroppedCanvas().toDataURL(),
      async (base64) => {
        const file = await convertBase64ToFile(base64);
        this.props.input.onChange({
          fileName: this.state.fileList[0].name,
          cropImg: this.cropper.getCroppedCanvas().toDataURL(),
          // canvasObj: this.cropper.getCroppedCanvas(),
        });
        // console.log("Фото успeшно загруженно");

        this.setState({ file: file });
      },
      "image/jpeg"
    );
    setTimeout(() => {
      this.cropper
        .getCroppedCanvas()
        .toBlob((blob) => this.props.setBlob(blob));
      this.props.close();
      this.setState({ prev: undefined });
    }, 100);
  };

  clear = () => {
    this.props.input.onChange(null);
    this.setState({ prev: undefined });
  };

  render() {
    const { input, value, editingImgUrl, previewTrashIcon } = this.props;
    const { prev } = this.state;
    const { t } = this.props;

    return (
      <>
        {!editingImgUrl && (
          <>
            <label
              ref={this.dropRef}
              className={prev ? "inputFile hidden" : "inputFile"}
            >
              <img className="btn-icon" src={plus} alt="icon" />
              <input
                {...input}
                accept="image/*"
                onChange={this.changeHandler}
                type="file"
                value={value}
              />
            </label>
          </>
        )}
        {prev && !editingImgUrl && (
          <div className="preview">
            <Cropper
              ref={(ref) => (this.cropper = ref)}
              src={prev}
              aspectRatio={140 / 140}
              className="cropper"
            />
          </div>
        )}
        {/* {console.log(prev)} */}
        {editingImgUrl && (
          <div className="croppImage inputFile">
            <div className="trashIcon">
              <img src={previewTrashIcon} alt="" />
            </div>
            <img src={editingImgUrl} alt="" />
            <div className="fileName"></div>
          </div>
        )}
        <div className="uploadFooter">
          <Button
            className="btn"
            type="button"
            text={t && t("changeButton")}
            onClick={this.clear}
            disabled={!prev}
          />
          <Button
            disabled={!prev}
            type="button"
            className="btn"
            text={t && t("addButton")}
            onClick={this.saveFile}
          />
        </div>
      </>
    );
  }
}

export default FileUploader;
