import React, { useState, useEffect } from "react";

export const PaginatorPrimary = ({
  currentPage = 1,
  changePage = () => { },
  totalPage = 0,
}) => {
  const [activeItems, setActiveItems] = useState([1, 2, 3]);

  const setPaginationPage = (numberOfPage) => {
    changePage(numberOfPage);
  };

  useEffect(() => {
    if (totalPage < 6) {
      setActiveItems(Array(totalPage).fill(0).map((_, i) => i + 1))
    }
  }, [totalPage])

  useEffect(() => {
    const prevElems = currentPage - 4 > 0 ? currentPage - 4 : 1;
    const nextElems = currentPage + 4 < totalPage ? currentPage + 4 : totalPage;
    const pagesList = new Array(totalPage)
      .fill(0)
      .map((_, i) => i + 1)
      .slice(prevElems, nextElems);
    setActiveItems(pagesList);
  }, [currentPage]);

  // useEffect(() => {
  //   const pagesList = new Array(totalPage)
  //     .fill(0)
  //     .map((_, i) => i + 1)
  //     .slice(1, 6);
  //   setActiveItems(pagesList);
  // }, []);

  if (totalPage === 0) {
    return null;
  }
  console.log('ACTIVE ITEMS', activeItems);

  return (
    <div className="paginator">
      {currentPage > 1 && (
        <div
          className={`paginationItem`}
          onClick={() => {
            setPaginationPage(currentPage - 1);
          }}
        >
          {"<"}
        </div>
      )}
      {!activeItems.some(el => el === 1) && (
        <div
          className={`paginationItem ${currentPage === 1 ? "active" : ""}`}
          onClick={() => {
            setPaginationPage(1);
          }}
        >
          {1}
        </div>
      )}

      {totalPage >= 7 && currentPage >= 5 && (
        <div className="paginationItem">...</div>
      )}
      {activeItems.map((_, i) => {
        return (
          <div
            key={_}
            className={`paginationItem${currentPage === _ ? " active" : ""}`}
            onClick={() => setPaginationPage(_)}
          >
            {_}
          </div>
        )
      })}
      {totalPage >= 7 && currentPage < totalPage - 2 && (
        <>
          {currentPage < totalPage - 3 && (
            <div className="paginationItem">...</div>
          )}
          <div
            className="paginationItem"
            onClick={() => setPaginationPage(totalPage)}
          >
            {totalPage}
          </div>
        </>
      )}

      {currentPage < totalPage && (
        <div
          className={`paginationItem`}
          onClick={() => {
            setPaginationPage(currentPage + 1);
          }}
        >
          {">"}
        </div>
      )}
    </div>
  );
};
