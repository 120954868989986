import * as types from "./types";

export const addNotification = (notification) => ({
  type: types.ADD_NOTIFICATION,
  notification,
});

export const removeNotification = (notificationId) => ({
  type: types.REMOVE_NOTIFICATIONS,
  id: notificationId,
});

