import { createSelector } from "reselect";
import patientIcon from "../../style/icons/Subscription/patient_active.svg";
const defaultState = {
  patients: {
    favoritePatients: {
      items: [],
      current_page: 0,
      page_count: 0,
      total_items_count: 0,
    }
  }
};

const getPatients = (state = defaultState) => state.patients;

export const getFavouritePatientsList = (state = defaultState) => state.patients.favoritePatients.items
export const getFavouritePatientsPage = (state = defaultState) => state.patients.favoritePatients.current_page
export const getFavouritePatientsPageCount = (state = defaultState) => state.patients.favoritePatients.page_count
export const getFavouritePatientsCount = (state = defaultState) => state.patients.favoritePatients.total_items_count

export const getPatientsRelations = createSelector(
  [getPatients],
  ({relations}) => relations || []
);

export const getPatientSelector = createSelector(
  [getPatients],
  ({patient}) => patient
);

export const getPatientsSelectCard = createSelector(
  [getPatients],
  (patients) => patients?.cards?.items ? patients.cards.items.map((card) => ({
    imgUrl: card.photoUrl || patientIcon,
    title: card.fullName,
    id: card.id,
    specialty: card.specialty,
  })) : []
);