import React, { useState } from "react";

import SpecializationList from "../../components/SpecializationList/SpecializationList";
import NewSpecializationForm from "../../forms/NewSpecializationForm/NewSpecializationForm";

const Specialization = () => {
  const [edit, setEdit] = useState(false);
  const [blob, setBlob] = useState(null);
  const [addingImg, setAddingImg] = useState(false);
  const [filters, setFilters] = useState([]);

  return (
    <div className="specializationContainer flex justifyBeetwen">
      <SpecializationList
        edit={edit}
        setEdit={() => setEdit(true)}
        setBlob={setBlob}
        setAddingImg={setAddingImg}
        filters={filters}
        setFilters={setFilters}
      />
      <NewSpecializationForm
        edit={edit}
        setEditingOff={() => setEdit(false)}
        blob={blob}
        setBlob={setBlob}
        addingImg={addingImg}
        setAddingImg={setAddingImg}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
};

export default Specialization;
