import React, { useState } from "react";
import OnboardingList from "../../components/OnboardingList/OnboardingList";
import NewOnboardingForm from "../../forms/NewOnboardingForm/NewOnboardingForm";

const Onboarding = () => {
  const [edit, setEdit] = useState(false);
  const [addingImg, setAddingImg] = useState(false);
  const [blob, setBlob] = useState(null);
  return (
    <div className="onboardingContainer flex justifyBeetwen">
      <OnboardingList
        setEdit={() => setEdit(true)}
        setAddingImg={setAddingImg}
      />
      <NewOnboardingForm
        edit={edit}
        setEditingOff={() => setEdit(false)}
        blob={blob}
        setBlob={setBlob}
        addingImg={addingImg}
        setAddingImg={setAddingImg}
      />
    </div>
  );
};

export default Onboarding;
