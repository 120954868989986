import React from "react";

import "./checkbox.scss";
const CheckboxUI = ({ name, id, disabled, label, value, ...props }) => (
  <div className={"wrap_checkbox"}>
    <input
      type="checkbox"
      name={name}
      id={id}
      disabled={disabled}
      className={"checkbox"}
      value={value}
      checked={value}
      {...props}
    />

    <label htmlFor={id}>{label}</label>
  </div>
);

export default CheckboxUI;
