import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';

import api from '../../../api';
import { getAllSpecializationsFromEleks } from '../../../redux/specializationMapping/actions';
import { getAllSpecializations } from '../../../redux/specializations/actions';

import plus from '../../../style/icons/PlusInCircle/plus.svg';

import FilterSelect from '../../components/formComponent/FilterSelect/FilterSelect';
import Button from '../../components/ui/Button/Button';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/ui/Spinner/Spinner';

import './SpecializationMapping.scss';

const SpecializationMapping = ({ getAllSpecializationsFromEleks, formValues = {}, getAllSpecializations }) => {
  const [ eleksSpecOptions, setEleksSpecOptions ] = useState([]);
  const [ specOptions, setSpecOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    setLoading(true);
    //Getting all dr. Eleks specializations:
    getAllSpecializationsFromEleks()
      .then(({ items }) => {
        const optItems = items.map(elem => ({
          title: elem.externalTitle,
          id: elem.id,
        }));
        setEleksSpecOptions([ ...optItems ]);
      })
      .then(() => setLoading(false));
    //Getting all specializations:
    getAllSpecializations(100)
      .then(({ items }) => {
        setSpecOptions([ ...items ]);
      })
      .then(() => setLoading(false));
  }, []);
  
  const formSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const data = {
      entityMapId: formValues.specializationsFromEleks.id,
      internalId: formValues.specializations.id,
    };
    api.specializationMapping.mapSpecialization(data).then(() => setLoading(false));
  };
  
  return (
    <div className="specializationMappingWrapper">
      <p className="pageTitle">{t('mappingPageTitle')}</p>
      <Form className="specializationMappingContainer" onSubmit={formSubmit}>
        {loading ? (
          <Spinner/>
        ) : (
          <>
            <div className="buttonMultiChoiceContainer">
              <div className="buttonMultiChoiceArea">
                <Field
                  name="specializationsFromEleks"
                  component={FilterSelect}
                  placeholder={t('mappingSpecializationFromEleks')}
                  options={eleksSpecOptions}
                  withPreview={formValues && formValues.specializationsFromEleks}
                  withSearch
                  withIcon
                  icon={plus}
                  multiple={false}
                  apiSearchURL="/admin/clinic-integration/entity-map/1?filter[title]="
                />
              </div>
              <div className="buttonMultiChoiceArea">
                <Field
                  name="specializations"
                  component={FilterSelect}
                  placeholder={t('specializations')}
                  options={specOptions}
                  withPreview={formValues && formValues.specializations}
                  withIcon
                  icon={plus}
                  multiple={false}
                />
              </div>
            </div>
            <Button
              className="btn"
              text={t('saveButton')}
              type="submit"
              disabled={!formValues.specializationsFromEleks || !formValues.specializations}
            />
          </>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = ({ specializationMapping, ...state }) => ({
  formValues: getFormValues('specializationMappingForm')(state),
});

const SpecializationMap = compose(
  connect(mapStateToProps, {
    getAllSpecializationsFromEleks,
    getAllSpecializations,
  }),
  reduxForm({ form: 'specializationMappingForm', enableReinitialize: true }),
  withRouter,
);

export default SpecializationMap(SpecializationMapping);
