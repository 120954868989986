import React from 'react'
import FeedbacksList from '../../components/FeedbacksList/FeedbacksList'
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { connect } from "react-redux";

const Feedbacks = ({role}) => {
    return (
        <div className='feedbacksContainer flex justifyBeetwen'>
            <FeedbacksList role={role}/>
        </div>
    )
}

const mapDispatchToProps = { getAllSpecializations };
export default connect(null, mapDispatchToProps)(Feedbacks);