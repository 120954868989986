import React, { useState } from "react";

import NewSubscriptionForm from "../../forms/NewSubscriptionForm/NewSubscriptionForm";
import SubscriptionList from "../../components/SubscriptionList/SubscriptionList";
import BannerForm from '../../forms/BannerForm/BannerForm'
import "./Subscription.scss";
import { connect } from "react-redux";
import { Form } from "redux-form";
import { useTranslation } from "react-i18next";
import { parserForm } from "../../../utils/parserForm";
import moment from "moment";

const Subscription = () => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [item, setItem] = useState(null)
  const [id, setId] = useState(null)
  const [editBannerOpen, setEditBannerOpen] = useState(false)
  const [addingImg, setAddingImg] = useState(false);
  const [blob, setBlob] = useState(null);
  const [bannerInfo, setBannerInfo] = useState(null)
  const handleCloseEdit = () => {
    setEdit(false)
    setItem(null)
    setId(null)
  }
  const handleEdit = (item) => {
    setEdit(!edit)
    setItem({
      ...item,
    })
    setId(item && item.id)
  }

  const handleEditBannerInfo = (bannerInfo) => {
    setBannerInfo(bannerInfo)
    setEditBannerOpen(true)
  }

  return (
    <div className="subscription flex justifyBeetwen">
      <SubscriptionList
        editBannerOpen={editBannerOpen}
        handleEditBannerInfo={handleEditBannerInfo}
        setEditBannerOpen={setEditBannerOpen}        
        handleEdit={handleEdit}
        edit={edit}
        editId={id}
        bannerLoading={loading}
      />
      <NewSubscriptionForm
        handleEdit={handleEdit}
        edit={edit && edit}
        editItem={item && item}
        handleCloseEdit={handleCloseEdit}
      />
      {editBannerOpen && (
        <BannerForm
          loading={loading}
          setLoading={setLoading}
          bannerInfo={bannerInfo && bannerInfo}
          setEditBannerOpen={setEditBannerOpen}
          addingImg={addingImg}
          setAddingImg={setAddingImg}
          blob={blob}
          setBlob={setBlob}
        />
      )}
    </div>
  );
};


export default Subscription;