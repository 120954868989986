import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({percent = 100, mt, mb, stopAnimation = false}) => {

  return (
    <div className='progress-bar__container' style={{
      marginTop: `${mt}`,
      marginBottom: `${mb}`
    }}>
      <div className='progress-bar__body' style={{
        width: `${percent}%`,
      }}>
        <div className={`progress-bar__body_value${stopAnimation ? '' : '_active'}`}/>
      </div>
    </div>
  );
};

export default ProgressBar;