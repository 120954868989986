import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  openModal: false,
  list: [],
  total: 0,
  currentPage: 0,
  loading: false,
  pageCount: 1,
  fromButtons: false,
  unreadedCount: 0,
};

const notificationsReducer = createReducer(initialState)({
  [types.TOGGLE_MODAL]: (state, { openModal }) => {
    return {
      ...state,
      openModal,
    };
  },
  [types.UPDATE_READED_NOTIFICATIONS]: (state, { idsList }) => {
    const unreaded = state.unreadedCount;
    const newUnreadedCount =
      unreaded >= idsList.length ? unreaded - idsList.length : 0;
    return {
      ...state,
      unreadedCount: newUnreadedCount,
      list: state.list.map((notification) =>
        idsList.includes(notification.id)
          ? {
              ...notification,
              status: 1,
            }
          : notification
      ),
    };
  },
  [types.GET_NOTIFICATIONS_LIST]: (
    state,
    { list, total, currentPage, pageCount, fromButtons, unreadedCount }
  ) => {
    if (currentPage > 0 && !fromButtons) {
      return {
        ...state,
        list: state.list.concat(list),
        total,
        currentPage,
        pageCount,
        loading: false,
        unreadedCount,
      };
    }
    return {
      ...state,
      list,
      total,
      currentPage,
      pageCount,
      loading: false,
      unreadedCount,
    };
  },
  [types.ADD_ITEM_TO_LIST]: (state, { item }) => {
    return {
      ...state,
      list: state.list.concat(item),
    };
  },
  [types.GET_REQUEST]: (state, _) => {
    return {
      ...state,
      loading: true,
    };
  },
});

export default notificationsReducer;
