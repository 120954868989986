import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getAllSpecializationsMappingItems: async () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/clinic-integration/entity-map/1`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },
  mapSpecialization: async (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/clinic-integration/entity-map`,
      headers: setHeader(),
      data,
    };
    
    const res = await axios(config);
    return res && res.data;
  },
};
