import * as types from "./types";
import api from "../../api";

export const getAllHospitals = (specializationID) => (dispatch) => {
  return api.hospitals.getAllHospitals(specializationID).then((data) => {
    return dispatch({
      type: types.GET_ALL_HOSPITALS,
      ...data,
    });
  });
};

export const getDepartmentsByHospitalId = (hospitalID, specializationID) => (
  dispatch
) => {
  return api.hospitals
    .getDepartmentsByHospitalId(hospitalID, specializationID)
    .then((res) => {
      return dispatch({
        type: types.GET_DEPARTMENT_BY_HOSPITAL_ID,
        ...(res && res.data),
      });
    });
};
