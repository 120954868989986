import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllBanners } from "../../../redux/banners/actions";
import { setInitalValues } from "../../../redux/formInitialValues/actions";

import Button from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";

import editPen from "../../../style/icons/Pen/pen.svg";
import emptyImage from "../../../style/icons/EmptyImg/emptyImg.svg";

import "./dashboard.scss";

const DashBoard = ({
  getAllBanners,
  items,
  setInitalValues,
  edit,
  setEdit,
  formInitialValues,
  token,
  setBlob,
  setAddingImg,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getAllBanners().then(() => setLoading(false));
  }, []);

  return (
    <div className="dashBoardContainer">
      <div className="title">{t("banners")}</div>
      <div
        className={`bannerItemsContainer ${
          items && items.length ? "" : "empty"
        }`}
      >
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}

        {!loading && items && items.length ? (
          items.map((banner) => {
            return (
              <div
                key={banner.id}
                className={`bannerItem ${
                  edit && banner.id === formInitialValues.id
                    ? "editItem"
                    : edit
                    ? "editMode"
                    : ""
                }`}
              >
                <div className="background">
                  <Button
                    className="edit"
                    withIcon
                    icon={editPen}
                    onClick={() => {
                      setEdit();
                      setAddingImg(false);
                      setBlob(null);
                      setInitalValues({
                        photo: { cropImg: banner.srcUrl },
                        link: banner.link,
                        text: banner.text,
                        ...banner,
                      });
                    }}
                  />
                  <img src={banner.srcUrl || emptyImage} alt="" />
                </div>
              </div>
            );
          })
        ) : (
          <>{!loading && <p>{t("emptyList")}</p>}</>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = { getAllBanners, setInitalValues };

const mapStateToProps = ({ banners, formInitialValues, user }) => ({
  banners,
  items: banners && banners.items,
  formInitialValues,
  token: user && user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
