import avatarPatient from "../style/icons/Avatar/avatar-patient.svg";
import SidorovIvan from "../style/icons/Avatar/SidorovIvan.svg";
import SidorovaMarie from "../style/icons/Avatar/SidorovaMarie.svg";
import SidorovArtem from "../style/icons/Avatar/SidorovArtem.svg";
import call from "../style/icons/Call/call2.svg";
import camera from "../style/icons/Camera/camera2.svg";
import chat from "../style/icons/Message/message3.svg";
import visitAtHome from "../style/icons/Home/home.svg";
import clinic from "../style/icons/Clinic/Clinic.svg";
import noPayment from "../style/icons/NoPayment/noPayment.svg";
import speceDoc from "../style/icons/FamilyDoctor/FamilyDoctorActive.svg";
import Fokin from "../style/icons/DocsAvatarsByName/Fokin.svg";
import Kudriashova from "../style/icons/DocsAvatarsByName/Kudriashova.svg";
import Maximov from "../style/icons/DocsAvatarsByName/Maximov.svg";
import Savina from "../style/icons/DocsAvatarsByName/Savina.svg";
import Sysoeva from "../style/icons/DocsAvatarsByName/Sysoeva.svg";
import Uvarov from "../style/icons/DocsAvatarsByName/Uvarov.svg";
export const formatDate = "YYYY-MM-DD";

export const specializations = [
  { title: "Кардиолог", imgUrl: speceDoc },
  { title: "Анастезиолог", imgUrl: speceDoc },
  { title: "Косметолог", imgUrl: speceDoc },
  { title: "Нарколог", imgUrl: speceDoc },
  { title: "Эндокринолог", imgUrl: speceDoc },
  { title: "Педиатр", imgUrl: speceDoc },
  { title: "Терапевт", imgUrl: speceDoc },
];
export const diagnosis = [
  { title: "A00-B99 — Некоторые инфекционные и паразитарные болезни" },
  { title: "C00-D48 — Новообразования" },
  {
    title:
      "D50-D89 — Болезни крови, кроветворных органов и отдельные нарушения вовлекающие иммунный механизм",
  },
  {
    title:
      "E00-E90 — Болезни эндокринной системы, расстройства питания и нарушения обмена веществ",
  },
];
export const examinations = [
  { title: "УЗИ щитовидной железы" },
  { title: "УЗИ желчного пузыря с определением функции" },
  { title: "УЗИ молочных желез" },
];
export const labs = [
  { title: "Adenovirus, антиген в кале (качеств. Определение)" },
  { title: "Ascaris lumbricoides, антитела IgG" },
  { title: "Astrovirus, антиген в кале (качеств. Определение)" },
];
export const preparation = [
  { title: "5-НОК, 50 табл. в / о 50 мг" },
  { title: "5-Фторурацил, 1 конц. д / инф. 500 мг амп. 10 мл" },
  { title: "5-Фторурацил, 1 конц. д / инф. 500 мг фл. 10 мл" },
];
export const medCardNames = [
  { title: "Сидорова Мальта Витальевна", imgUrl: avatarPatient },
  { title: "Сидоров Иван Михайлович", imgUrl: SidorovIvan },
  { title: "Сидорова Мария Ивановна", imgUrl: SidorovaMarie },
  { title: "Сидоров Артем Иванович", imgUrl: SidorovArtem },
];
export const doctors = [
  { title: "Любой доктор" },
  { title: "Уваров Валентин Егорович", imgUrl: Uvarov },
  { title: "Сысоева Инга Валерьевна", imgUrl: Sysoeva },
  { title: "Фокин Артур Вениаминович", imgUrl: Fokin },
  { title: "Савина Любава Максимовна", imgUrl: Savina },
  { title: "Кудряшова Анна Якововна", imgUrl: Kudriashova },
  { title: "Максимов Денис Егорович", imgUrl: Maximov },
];
export const format = [
  { value: "TYPE_CHAT", title: "В чате", imgUrl: chat, num_type: 0 },
  { value: "TYPE_PHONE", title: "Звонок", imgUrl: call, num_type: 1 },
  { value: "TYPE_VIDEO", title: "Видеозвонок", imgUrl: camera, num_type: 2 },
  {
    value: "TYPE_HOME",
    title: "Визит на дом",
    imgUrl: visitAtHome,
    num_type: 3,
  },
  {
    value: "TYPE_HOSPITAL",
    title: "Визит в клинку",
    imgUrl: clinic,
    num_type: 4,
  },
];
export const paymentMode = [{ title: "Не требует оплаты", imgUrl: noPayment }];
export const sex = [{ title: "Мужской" }, { title: "Женский" }];
export const relations = [
  { title: "Я" },
  { title: "Сын" },
  { title: "Дочка" },
  { title: "Муж" },
  { title: "Жена" },
  { title: "Мама" },
  { title: "Папа" },
  { title: "Бабушка" },
  { title: "Дедушка" },
  { title: "Брат" },
  { title: "Сестра" },
  { title: "Близкий" },
];

export const STATUS_NEW = "new";
export const STATUS_FILLED = "filled";
export const STATUS_WAITING = "waiting";
export const STATUS_IN_PROCESS = "in_process";
export const STATUS_CLOSED = "closed";
export const STATUS_CANCELLED = "cancelled";
