import * as types from "./type";

export const changeLang = (lang) => {
  localStorage.setItem("lang", lang);

  return {
    type: types.CHANGE_LANG,
    lang,
  };
};
