import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, Field, reduxForm, SubmissionError } from "redux-form";
import { useTranslation } from "react-i18next";

import logo from "../../../style/images/auth-logo.svg";

import Input from "../../components/formComponent/Input/Input";
import Button from "../../components/ui/Button/Button";

import "./auth.scss";
import { requiredLogin, requiredPassword } from "../../../helpers/validate";
import { signIn } from "../../../redux/auth/action";
import { NetworkErrorPopup } from "../../components/ui/NetworkErrorPopup/NetworkErrorPopup";

const Auth = ({ handleSubmit, invalid, dirty, signIn }) => {
  const { t } = useTranslation();
  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit((values) => {
      return signIn(values).then((res) => {
        if (res && res.data && res.data.message) {
          if (res.data.message.message.includes("name")) {
            throw new SubmissionError({ email: t("loginError") });
          }
          if (res.data.message.message.includes("password")) {
            throw new SubmissionError({ password: t("passwordError") });
          }
        }
      });
    })();
  };
  return (
    <div className="background">
      <div className="wrappLoginForm">
        <img src={logo} alt="logo-img" />
        <NetworkErrorPopup />
        <Form onSubmit={formSubmit} className="loginForm">
          <h1>{t("authorization")}</h1>
          <Field
            name="email"
            validate={[requiredLogin]}
            type="text"
            component={Input}
            placeholder={t("placeholderLogin")}
          />

          <Field
            name="password"
            validate={[requiredPassword]}
            type="password"
            component={Input}
            placeholder={t("placeholderPassword")}
          />

          <Button
            className="btn"
            freeTopSpace={true}
            disabled={!dirty || invalid}
            type="submit"
            // loading={loading}
            text={t("login")}
          />
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = { signIn };

const enhance = compose(
  connect(null, mapDispatchToProps),
  reduxForm({ form: "login" })
);

export default enhance(Auth);
