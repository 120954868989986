import * as types from "./types";
import creatrReduser from "../../utils/createReducer";

const initialState = {
  items: [],
  initialValues: {},
};

const bannersReducer = creatrReduser(initialState)({
  [types.GET_ALL_BANNERS]: (state, { data, maxId }) => {
    return {
      ...data,
      // maxId,
    };
  },
});

export default bannersReducer;
