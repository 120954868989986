import React, { useEffect, useState } from "react";
import "./NewClient.scss";
import Chat from "../../components/Chat/Chat";
import NewConsultation from "../../components/NewConsultation/NewConsultation";
import NewCardForm from "../../forms/NewCardForm/NewCardForm";
import { connect } from "react-redux";
import { getAllPatients } from "../../../redux/patients/actions";
import { setInitalValues } from "../../../redux/formInitialValues/actions";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Button from "../../components/ui/Button/Button";
import plus from "../../../style/icons/PlusInCircle/plus.svg";

const NewClient = ({ role }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  return (
    <div className="clientWrapper">
      {/*<Button*/}
      {/*    className={"btn alignCenter flex noBackground"}*/}
      {/*    withIcon*/}
      {/*    icon={plus}*/}
      {/*    type="button"*/}
      {/*    text="Добавить медкарту"*/}
      {/*/>*/}
      <div className="newClientContentContainer">
        <NewCardForm />
        {role !== "ROLE_INSURANCE_OPERATOR" && (
          <div className="doctorChat">
            <div className="DoctorChatTitle">
              <div className='chatTitle'>{t("chat")}</div>
            </div>
            <div className={"messagesArea"}></div>
            <div className="chatFooter"></div>
          </div>
        )}
        <NewConsultation loading={loading} setLoading={setLoading} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, clientId, user }) => ({
  patients,
  clientId,
  items: patients && patients.items,
  patientCard: patients && patients.patientCard,
  role: user && user.roles && user.roles[0],
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getAllPatients,
    setInitalValues,
  })
);

export default enhance(NewClient);
