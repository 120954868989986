import React, {useMemo, useState} from 'react';
import {Modal} from "@material-ui/core";
import ProgressCheck from "../../../style/icons/ProgressCheck/progress_check.svg";
import './ProgressModal.scss';
import {compose} from "redux";
import {connect} from "react-redux";
import {getAllProcesses} from "../../../redux/processes/selectors";
import ProgressBar from "../ProgressBar/ProgressBar";
import {getFormValues} from "redux-form";
import {useTranslation} from "react-i18next";

const processesMap = (process) => {

}

const ProgressModal = (
  {
    user,
    processes
  }) => {

  const {t} = useTranslation();

  const precessesMemo = useMemo(() => {
    return processes.map((process) => {

      const filteredProcess = {
        status: process.status,
        isError: !!process.statistic.errors.length,
        errors: process.statistic.errors,
        message: process.statistic.message,
        percent: process.percentCompleted
      }

      switch (process.jobType) {
        case 'user_subscription': {
          filteredProcess.name = t('subscribeProcess');
          break;
        }
        case 'promocode_import': {
          filteredProcess.name = t('importPromoCodeProcess');
          break;
        }
        case 'push_send': {
          filteredProcess.name = t('pushNotificationProcess');
          break;
        }
      }
      return <ProgressItem process={filteredProcess}/>;
    })
  }, [processes])

  const [isOpen, setIsOpen] = useState(false);

  if (!user?.roles) return null;

  if (!user?.roles?.includes('ROLE_ADMIN')) {
    return null;
  }

  if (!processes.length) return null;

  const openHandler = () => {
    setIsOpen(true);
  }
  const closeHandler = () => {
    setIsOpen(false);
  }


  return (
    <div>
      <img
        className='progress-modal_img'
        onClick={openHandler}
        src={ProgressCheck}
        alt=""
      />
      <Modal
        open={isOpen}
        onClose={closeHandler}
      >
        <div className='progress-modal_body'>
          <div className="progress-modal_content">
            <div className='progress-modal_content_text'>{t('titleProcess')}</div>
            <div className='progress-modal_content_list'>
              {precessesMemo}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};


const ProgressItem = ({process}) => {
  return (
    <div className='progress-modal_item'>
      <div className='progress-modal_item_name'>{process.name}</div>
      <ProgressBar stopAnimation={true} percent={process.percent}/>
      <div className='progress-modal_item_message'>{process.message} ~ {process.percent}%</div>
      {process.isError ?
        process.errors.map(e => (
          <div className='progress-modal_item_error'>
            {e}
          </div>
        )) : null
      }
    </div>
  )
}

const mapStateToProps = ({user, ...state}) => ({
  processes: getAllProcesses(state),
  user
});


const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(ProgressModal);