import moment from "moment";
import io from "socket.io-client";
import * as ACTION_TYPES from "./chat/types";
import { SIGNALING_SERVER, PORT_CALL_SOCKET } from "../api/index";
import i18n from "../i18n";
import { setIsNeedScrolling, setRejected } from "./chat/actions";
import {
  getIsAudioCall,
  getIsNeedScrolling,
  getIsVideoCall,
} from "./chat/selectors";
const CALL_URL = `${SIGNALING_SERVER}${PORT_CALL_SOCKET}/call`;

const configuration = {
  iceServers: [
    { urls: "stun:relay1.gooddoc.fun:80" },

    {
      urls: "turn:relay1.gooddoc.fun:80?transport=udp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=tcp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
  ],
};

let localStream = null;
// const peerConnection = new RTCPeerConnection(configuration);

const socketMiddleware = () => {
  let socket = null;
  let callSocket = null;
  let peerConnection = new RTCPeerConnection(configuration);
  let iceCandidates = [];
  let myStream = null;
  let isReadyStatus = false; // we need to delete this
  let reserveOptions = {};
  let isVideoCall = false;
  let isMessageConnect = false;

  const createPeerconnect = () => {
    peerConnection = new RTCPeerConnection(configuration);
    iceCandidates = [];
    peerConnection.onicecandidate = (rtcp) => {
      if (rtcp.candidate) {
        iceCandidates.push(rtcp.candidate);
        callSocket.send(
          JSON.stringify({
            type: "candidate",
            candidate: rtcp.candidate,
          })
        );
      }
    };

    const connectionStateChange = (e) => {
      console.log("connectionstatechange e =", e.currentTarget.connectionState);
      if (e.currentTarget.connectionState === "failed") {
        isReadyStatus = false;
        peerConnection.removeEventListener(
          "connectionstatechange",
          connectionStateChange
        );
        peerConnection.close();
        peerConnection = null;
        createChatCall(reserveOptions);
      }
    };
    peerConnection.addEventListener(
      "connectionstatechange",
      connectionStateChange
    );
  };

  const roomLeave = (store) => (data) => {
    store.dispatch({
      type: ACTION_TYPES.SET_TOTAL_CHAT_MEMBERS,
      countMembersInRoom: data,
    });
  };

  const joinRoom = (store) => (data) => {
    store.dispatch({
      type: ACTION_TYPES.SET_TOTAL_CHAT_MEMBERS,
      countMembersInRoom: data,
    });
  };

  const onNewMessage = (store) => (message) => {
    if (
      message.type === "message" ||
      message.type === "voice" ||
      message.type === "file" ||
      message.type === "image" ||
      message.type === "video" ||
      message.type === "chatBot" ||
      message.type === "finished" ||
      message.type === "cancelation"
    ) {
      const currentState = getIsNeedScrolling(store.getState());
      store.dispatch({ type: ACTION_TYPES.SET_NEW_MESSAGE, message });
      store.dispatch(setIsNeedScrolling(!currentState));
    }

    if (message.type === "answer") {
      catchAnswer(message);
    }

    if (message.type === "candidate") {
      const tmp = message.candidate;

      try {
        peerConnection.addIceCandidate(new RTCIceCandidate(tmp));
      } catch (er) {
        console.error(er);
      }
    }

    if (message.type === "ready") {
      //makeCall(true);
    }

    if (message.type === "reject") {
      //stopCall({ rejectFromOpponent: true });
    }

    if (message.type === "ended") {
      // stopCall({ clearClose: true });
    }
  };

  const mutedVideoChatCall = () => {
    myStream && myStream
      .getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const mutedAudioChatCall = () => {
    myStream
      .getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const createChatCall = ({
    partnerVideo,
    consultId,
    doctor,
    isVideo,
    title,
    userVideo,
    userAudio,
  }) => {
    console.log("createPeerconnect");
    reserveOptions = {
      partnerVideo,
      consultId,
      doctor,
      isVideo,
      title,
      userVideo,
      userAudio,
    };
    createPeerconnect();
    navigator.mediaDevices
      .getUserMedia({ video: isVideo, audio: true })
      .then((stream) => {
        myStream = stream;
        const streamClone = new MediaStream();
        const remoteStream = new MediaStream();
        stream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, stream);
          try {
            peerConnection.addEventListener("track", (event) => {
              remoteStream.addTrack(event.track, remoteStream);
              try {
                if (isVideo) {
                  console.log(1);
                  userVideo.current.srcObject = remoteStream;
                } else {
                  console.log(2);
                  userAudio.current.srcObject = remoteStream;
                }
              } catch (e) {
                console.log("<<<<<<<<<TRACK _______ E R O R R>>>>>>>>", e);
              }
            });

            // peerConnection.addTrack(track, stream);

            if (track.kind === "video") {
              streamClone.addTrack(track, stream);
            }
            streamClone.getAudioTracks();
            // myStream = streamClone
          } catch (e) {
            console.log(e);
          }

          if (partnerVideo.current) {
            console.log(partnerVideo);
            partnerVideo.current.srcObject = streamClone;
          }
        });
      })
      .then(() => {
        callSocket.send(
          JSON.stringify({
            type: "call",
            customParameters: {
              call_type: isVideo ? "video" : "audio",
              doctor_id: `${doctor.doctor.id}`,
              doctor_name: doctor.fullName,
              doctor_photo: doctor.photoUrl,
              doctor_specialty: title,
              appointment_id: consultId,
            },
          })
        );
      });
  };

  const makeCall = async (isVideo) => {
    const offer = await peerConnection.createOffer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: isVideo,
    });

    await peerConnection.setLocalDescription(offer);
    callSocket.send(
      JSON.stringify({
        type: "offer",
        offer: offer.sdp,
      })
    );
  };

  const catchAnswer = async ({ answer }) => {
    const remoteDesc = new RTCSessionDescription({
      type: "answer",
      sdp: answer || answer.nameValuePairs.answer,
    });
    await peerConnection.setRemoteDescription(remoteDesc);
    console.log("answer", answer);
    iceCandidates.forEach((candidate) => {
      peerConnection.addIceCandidate(candidate);
    });
  };

  const sendRejectMessage = (me) => {
    const messageBody = {
      type: "message",
      message: me ? i18n.t("missedCall") : i18n.t("canceledCall"),
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      isSentByUser: !me,
      customParameters: {
        some: "param",
      },
    };
    socket && socket.send(JSON.stringify(messageBody));
  };

  const stopCall = (state) => (isMyEnded) => {
    console.log("rejecting");
    console.log("end signal");

    try {
      myStream &&
        myStream.getTracks().forEach((track) => {
          track.stop();
          myStream.removeTrack(track);
        });
    } catch (err) {
      console.log({ err });
    }

    peerConnection && peerConnection.close();
    peerConnection = null;
    // peerConnection = new RTCPeerConnection(configuration);
    iceCandidates = [];
    const isAudioCall = getIsAudioCall(state.getState());
    const isVideoCall = getIsVideoCall(state.getState());
    if (callSocket && isMyEnded) {
      callSocket.send(JSON.stringify({ type: "ended", customParameters: {} }));
    }
    if (isAudioCall || isVideoCall) {
      sendChatMessage({
        type: "message",
        text: "Завершенный звонок",
      });
    }
    if (isVideoCall) state.dispatch({ type: ACTION_TYPES.END_VIDEO_CALL });
    if (isAudioCall) state.dispatch({ type: ACTION_TYPES.END_AUDIO_CALL });
    isReadyStatus = false;
    myStream = null;
  };

  const sendChatMessage = ({ type, appointmentId, userId, text, ...options }) =>
    socket &&
    socket.send(
      JSON.stringify({
        type,
        userId,
        appointmentId,
        status: 1,
        message: text,
        isSentByUser: false,
        dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        customParameters: {
          ...options,
        },
      })
    );

  // the middleware part of this function
  return (store) => (next) => (action) => {
    switch (action.type) {
      case ACTION_TYPES.WS_CONNECT:
        const { query, host } = action.payload;
        if (socket !== null) {
          socket.close();
        }
        store.dispatch({
          type: ACTION_TYPES.CHANGE_CONNECT_STATUS_CHAT,
          payload: { status: "connecting" },
        });
        socket = io.connect(host, { query });
        socket.on("connect", () =>
          store.dispatch({
            type: ACTION_TYPES.CHANGE_CONNECT_STATUS_CHAT,
            payload: { status: "connected" },
          })
        );
        socket.on("connect_error", () =>
          store.dispatch({
            type: ACTION_TYPES.CHANGE_CONNECT_STATUS_CHAT,
            payload: { status: "disconnected" },
          })
        );
        socket.on("disconnect", () =>
          store.dispatch({
            type: ACTION_TYPES.CHANGE_CONNECT_STATUS_CHAT,
            payload: { status: "disconnected" },
          })
        );
        socket.on("message", onNewMessage(store));
        socket.on("joinRoom", joinRoom(store));
        socket.on("chatRoomLeave", roomLeave(store));
        socket.on("patientIsReadyToCall", () => {
          createChatCall();
        });
        socket.on("busy", () => socket.disconnect());
        socket.on("isReadMessageIds", ({ idsList }) => {
          store.dispatch({ type: ACTION_TYPES.IS_READ_MESSAGES_LIST, idsList });
        });
        break;
      case ACTION_TYPES.READ_MESSAGES_LIST:
        // socket.emit("readMessagesList", { idsList: action.list });
        socket.send(JSON.stringify({
          type: "readMessagesList",
          idsList: action.list
        }));
        break;
      case ACTION_TYPES.WS_DISCONNECT:
        if (socket !== null) {
          socket.send(JSON.stringify({type: "chatRoomLeave"}))
          socket.close();
        }
        socket = null;
        break;

      case ACTION_TYPES.WS_CALL_CONNECT:
        if (callSocket) {
          callSocket.close();
          callSocket = null;
        }
        callSocket = io.connect(CALL_URL, {
          query: action.payload.query,
          forceNew: true,
          rejectUnauthorized: false,
        });
        console.log("WS_CALL_CONNECT");

        callSocket.on("message", (msg) => {
          // const _stopCall = stopCall(store);

          if (msg.type === "answer") {
            console.log("answer signal");
            if (isVideoCall) {
              console.log("STARTED VIDEO CALL");
              store.dispatch({ type: ACTION_TYPES.START_VIDEO_CALL });
            } else {
              store.dispatch({ type: ACTION_TYPES.START_AUDIO_CALL });
              console.log("STARTED AUDIO CALL");
            }
            catchAnswer(msg);
          }
          if (msg.type === "candidate") {
            const tmp = msg.candidate;
            try {
              const condidate = new RTCIceCandidate(msg.candidate);
              //peerConnection.addIceCandidate(new RTCIceCandidate(tmp));
              iceCandidates.push(condidate);
            } catch (er) {
              console.error(er);
            }
          }
          if (msg.type === "ready") {
            if (!isReadyStatus) {
              console.log("ready signal");
              makeCall(true);
              isReadyStatus = true;
            }
          }
          if (msg.type === "reject") {
            console.log("debugger lisen signal reject");
            stopCall(store)(false);
            sendRejectMessage(false);
            store.dispatch(setRejected(true));
          }
          if (msg.type === "ended") {
            console.log("debugger lisen signal ended");
            stopCall(store)(false);
            store.dispatch(setRejected(true));
            store.dispatch({ type: ACTION_TYPES.RESET_CALL });
          }
        });

        break;
      case ACTION_TYPES.MAKE_CHAT_CALL:
        store.dispatch(setRejected(false));
        isVideoCall = action.payload.isVideo;
        createChatCall(action.payload);
        break;

      case ACTION_TYPES.SEND_CHAT_MESSAGE:
        const currentState = getIsNeedScrolling(store.getState());
        store.dispatch(setIsNeedScrolling(!currentState));
        sendChatMessage(action.payload);
        break;
      case ACTION_TYPES.SEND_REJECT_MESSAGE:
        sendRejectMessage(true);
        break;

      case ACTION_TYPES.MUTE_AUDIO_CHAT_CALL:
        mutedAudioChatCall();
        break;

      case ACTION_TYPES.MUTE_VIDEO_CHAT_CALL:
        mutedVideoChatCall();
        break;

      case ACTION_TYPES.STOP_CHAT_CALL:
        stopCall(store)(true);
        break;

      case ACTION_TYPES.WS_CALL_DISCONNECT:
        console.log("WS_CALL_DISCONNECT");
        if (callSocket) {
          callSocket.close();
          callSocket = null;
        }
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
