import React, {useRef, useState, } from 'react';
import {genereteKey} from '../../../../utils/common';
import {getIncorectField} from '../../../../utils/parserForm';

export const SimpleForm = ({handleSubmit = () => {}, children}) => {
  const formRef = useRef();
  const [key, setKey] = useState(genereteKey());

  const _handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation()
    const incorectElem = getIncorectField(evt)


    if (incorectElem) {
      incorectElem.focus();
      
      return;
    }

    new Promise((resolve, reject) =>{
      handleSubmit(evt, resolve, reject);
    }).then(()=>{
      formRef.current.reset();
      setKey(genereteKey());
    }).catch(err=>console.log('err', err))
  }
  
  return(
    <form onSubmit={_handleSubmit} ref={formRef} key={key} noValidate>
      {children}
    </form>
  )
}