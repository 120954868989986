import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import moment from 'moment'
import api from "../../../api";
import Toggle from "../formComponent/Toggle/Toggle";
import Button from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import DatePicker from "../formComponent/DatePicker/DatePicker";
import Input from "../formComponent/Input/Input";

import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import clinic from "../../../style/icons/Doc/doc-blue-in-grey.svg";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  getAllConsultations,
  getFilteredConsultations,
  resetIsItemsFiltered,
} from "../../../redux/consultations/actions";
import { getFilteredPromocodes } from "../../../redux/promocodes/actions";
import { setPopUpSuccess } from "../../../redux/networkError/actions";

import "./filter.scss";
import { useHistory } from "react-router-dom";
import { resetPatientsList } from "../../../redux/patients/actions";
import { validDateEnd, validDateStart } from "../../../utils/date";
import { getServerTimeFormat } from "../../../helpers/dateFormats";
import { createHistoryPush } from "../../../helpers/functions";


const Filter = ({
  loading,
  setLoading,
  getFilteredConsultations,
  getFilteredPromocodes,
  getAllConsultations,
  resetInitialValues,
  formValues = {},
  setInitalValues,
  filterMode,
  mode,
  specializations,
  role,
  handleSubmit,
  reset,
  setFilterParams,
  language,
  setFilterParametres,
  filter_params,
  isItemsFiltered,
  insuranceOptions = [],
  setPopUpSuccess,
  getAllPromoCodes,
  setItemsFiltered,
  resetIsItemsFiltered,
  resetPatientsList,
  filter,
  paramsFromURL,
  filterLoading,
  setFilterLoading,
  // buttonLoading,
  // setButtonLoading = () => {},
  period,
  dateFrom,
  dateTo,
  ...rest
}) => {
  console.log({rest});
  const [buttonLoading, setButtonLoading] = useState(false)
  const { t } = useTranslation();
  const history = useHistory()


  console.log('FILTER_PARAMS', filter_params);
  
  const statusOptions = [
    { title: t("newConsultation"), value: "new" },
    { title: t("filledConsultation"), value: "filled" },
    { title: t("closedConsultation"), value: "closed" },
    { title: t("inProgressConsultation"), value: "in_process" },
    { title: t("cancelledConsultation"), value: "cancelled" },
  ];

  const serviceFormats = [
    {
      imgUrl: support,
      title: t("optionsServiceTypeOnline"),
      type: "0",
    },
    {
      imgUrl: home,
      title: t("optionsServiceTypeAtHome"),
      type: "1",
    },
    {
      imgUrl: clinic,
      title: t("optionsServiceTypeInHospital"),
      type: "2",
    },
  ]

  useEffect(() => {
    const specialtyArray = specializations.filter(el => paramsFromURL?.specialty?.includes(`${el.id}`))
    setInitalValues({
      serviceFormat: serviceFormats.find(el => el.type == paramsFromURL?.type) || null,
      status: statusOptions.find(el => el.value === paramsFromURL?.status) || null,
      specialization: specialtyArray.length ? specialtyArray : null,
      dateStart: moment(dateFrom, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      dateEnd: moment(dateTo, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    })
  }, [specializations, paramsFromURL])

  // const [change, setChange] = useState(false);
  const [isDisableButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (mode === "promocodes") {
      if (
        formValues &&
        formValues.promocodeToggle &&
        formValues.promocodeToggle.value === "Filter"
      ) {
        if (
          !!formValues.promocodeStatus ||
          !!formValues.promocodeType ||
          !!formValues.dateStart ||
          !!formValues.dateEnd ||
          !!formValues.insuranceCompany
        ) {
          setDisabledButton(false);
        }
      } else {
        if (
          formValues &&
          !!formValues.promocodeCount &&
          formValues &&
          !!formValues.promocodeType
        ) {
          setDisabledButton(false);
        }
      }
    }
  }, [formValues]);

  useEffect(() => {
    if (filter_params && Object.keys(filter_params).length > 0) {
      setFilterParams({ ...filter_params });
      //setInitalValues({ ...filter_params })
    }
  }, [])
  console.log(formValues);

  const formSubmit = (e) => {
    if (role === 'doctor' && mode != "promocodes") {
      resetPatientsList()
      // history.push('/home/doctor/monitoring')
    }
    e && e.preventDefault();
    // setLoading(true);
    if (!history.location.pathname.includes("monitoring")) {
      setButtonLoading(true)
    }
    handleSubmit((values) => {
      const filterType =
        formValues &&
        formValues.promocodeToggle &&
        formValues.promocodeToggle.value;
      if (mode === "promocodes" && filterType === "addPromocode") {
        let typeRequest = {}
        if (formValues.promocodeType.value === 0) {
          typeRequest = {
            specialtyScope: 0,
          }
        }
        if (formValues.promocodeType.value === 1) {
          if (formValues.specialization?.length === specializations.length) {
            typeRequest = {
              specialtyScope: 0,
            }
          } else {
            typeRequest = {
              specialty: formValues.specialization.map(el => el.id),
              specialtyScope: 1,
            }
          }
        }
        
        const data = {
          count: formValues && formValues.promocodeCount,
          promocode: {
            discount: formValues.promocodeDiscount ? +formValues.promocodeDiscount : 100,
            validFrom: formValues.dateStart
              ? moment(formValues.dateStart, 'DD/MM/YYYY').format("YYYY-MM-DD")
              : moment(Date.now()).format("YYYY-MM-DD"),
            validTill: formValues.dateEnd
              ? moment(formValues.dateEnd, 'DD/MM/YYYY').format("YYYY-MM-DD")
              : moment(Date.now()).add(1, 'y').format("YYYY-MM-DD"),
            type:
              formValues &&
              formValues.promocodeType &&
              formValues.promocodeType.value,
            insuranceCompany:
              formValues &&
              formValues.insuranceCompany &&
              formValues.insuranceCompany.id,
            ...typeRequest
          },
        };
        return api.promocodes
          .generateNewPromocodes(data)
          .then(() =>
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("promocodeGenerateSuccess"),
                },
              },
            })
          )
          .then(() => {
            resetFormHandler();
            getAllPromoCodes();
            setLoading(false);
            setButtonLoading(false)
          });
      } else if (mode === "promocodes" && filterType === "Filter") {
        setItemsFiltered(true);
        setFilterParams({ ...formValues });
        getFilteredPromocodes(
          formValues &&
            formValues.promocodeStatus &&
            formValues.promocodeStatus.value,
          formValues &&
            formValues.promocodeType &&
            formValues.promocodeType.value,
          formValues && formValues.dateStart,
          formValues && formValues.dateEnd,
          formValues &&
            formValues.insuranceCompany &&
            formValues.insuranceCompany.id
        ).then(() => {
          setButtonLoading(false)
          // setLoading()
        })
        setLoading(false);
        // setButtonLoading(false)
      } else {
        setFilterParams({ ...values });
        const { status, serviceFormat, specialization, dateStart, dateEnd } = values;
        const [dateStartFormat, dateEndFormat] = getServerTimeFormat([dateStart, dateEnd]);
        const isSpecPicked = !!specialization?.length
        createHistoryPush({
          history,
          period,
          status: status?.value || "",
          type: serviceFormat?.type || "",
          spec: isSpecPicked ? specialization.map(el => el.id).join(',') : "",
          from: dateStartFormat,
          to: dateEndFormat,
          page: 1,
        })
      }
    })();
  };
  
  const resetFormHandler = () => {
    // getAllConsultations(role, filterMode);
    reset();
    resetInitialValues();
    setFilterParams({});
    setDisabledButton(true);
    setButtonLoading(true)
    // setItemsFiltered(false);
    resetIsItemsFiltered();
    if (mode === 'monitoring') {
      history.push(`${history.location.pathname}?page=1&period=All`)
    }
    if (mode === "promocodes") {
      setInitalValues({
        promocodeToggle: { value: "Filter", text: t("filterFormTitle") },
      });
      getAllPromoCodes().then(() => setButtonLoading(false));
    } else {
      setButtonLoading(false)
    }
  };

  const handleClearDate = async () => {
    await rest.change("dateStart", null)
    await rest.change("dateEnd", null)
    if(mode === "promocodes") return
    const isDate = history.location.search.includes('from') || history.location.search.includes('to');
    if (isDate) {
      formSubmit();
    }
  }


  let chosenDateStart = formValues && formValues.dateStart;
  const withDatePicker = (mode === "promocodes" && formValues?.promocodeToggle)
    || ((role === 'operator' || role === 'doctor') && period === 'All');

  return (
    <div className="wrappFilterForm">
      {loading ? (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="formTitle">
            {formValues &&
            formValues.promocodeToggle &&
            formValues.promocodeToggle.value === "addPromocode"
              ? t("addPromoCodes")
              : t("filterFormTitle")}
            <div onClick={resetFormHandler} className="resetButtonBox">
                <div className="resetText">{t("reset")}</div>
                <img
                  style={{margin: "0px"}}
                  className="resetForm"
                  title="Очистить форму"
                  src={close}
                  alt="closeIcon"
                />  
            </div>
          </div>
          <Form className="monitoringFilters" onSubmit={formSubmit}>
            <div className="formContent">
              {mode === "shedule" ? (
                <>
                  <Field
                    name="allOrActive"
                    component={Toggle}
                    options={[
                      { value: "all", text: t("filterAllSchedules") },
                      { value: "active", text: t("filterActiveSchedules") },
                    ]}
                  />
                </>
              ) : mode === "promocodes" ? (
                <Field
                  name="promocodeToggle"
                  component={Toggle}
                  options={[
                    { value: "Filter", text: t("filterFormTitle") },
                    { value: "addPromocode", text: t("addPromoCodes") },
                  ]}
                />
              ) : (
                <Field
                  name="allOrInWork"
                  component={Toggle}
                  options={[
                    { value: "all", text: t("filterAllConsultations") },
                    { value: "inWork", text: t("filterActiveConsultations") },
                  ]}
                />
              )}

                {withDatePicker && (
                  <div className="dateRange">
                    <Field
                      name={`dateStart`}
                      inputProps={{
                        name: "dateStart",
                      }}
                      maxDate={2030}
                      component={DatePicker}
                      isValidDate={formValues?.promocodeToggle?.value == 'addPromocode' ? validDateStart : () => true}
                      placeholder="--/--/----"
                      locale={language.lang}
                    />
                    <Field
                      name={`dateEnd`}
                      inputProps={{
                        name: "dateEnd",
                      }}
                      maxDate={2030}
                      isValidDate={formValues?.promocodeToggle?.value == 'addPromocode' ? validDateEnd : () => true}
                      component={DatePicker}
                      placeholder="--/--/----"
                    />
                    <img
                      src={close}
                      alt="close"
                      onClick={handleClearDate}
                    />
                    <img src={calendar} alt="calendar" />
                  </div>
                  )}
                
                {mode !== "shedule" && mode !== "promocodes" && (
                <Field
                  name="status"
                  component={FilterSelect}
                  placeholder={t("status")}
                  options={statusOptions}
                  withIcon
                  withPreview={formValues && formValues.status}
                  icon={plus}
                  withIconInOptions
                />
                )}
                
              {mode === "promocodes" && role !== "insurance-admin" && (
                <Field
                  name="insuranceCompany"
                  component={FilterSelect}
                  options={insuranceOptions}
                  placeholder={t("insuranceCompany")}
                  withPreview
                />
              )}
              {/*Form for FILTER promocodes*/}
              {mode === "promocodes" &&
                formValues &&
                formValues.promocodeToggle &&
                formValues.promocodeToggle.value === "Filter" && (
                  <>
                    <Field
                      name="promocodeType"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeType_0"), value: 0, id: 0 },
                        { title: t("promocodeType_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeType")}
                      withPreview
                    />
                    <Field
                      name="promocodeStatus"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeStatus_0"), value: 0, id: 0 },
                        { title: t("promocodeStatus_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeStatus")}
                      withPreview
                    />
                  </>
                )}

              {/*Form for ADDICTED promocodes*/}
              {mode === "promocodes" &&
                formValues &&
                formValues.promocodeToggle &&
                formValues.promocodeToggle.value === "addPromocode" && (
                  <>
                    <Field
                      name="promocodeType"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeType_0"), value: 0, id: 0 },
                        { title: t("promocodeType_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeType")}
                      withPreview
                    />
                    {formValues?.promocodeType?.value == 1 && (
                      <Field
                        name="specialization"
                        component={FilterSelect}
                        placeholder={t("placeholderSpecialty")}
                        options={specializations}
                        withIcon
                        multipleInTitle={true}
                        withPreview={formValues && formValues.specialization}
                        icon={plus}
                      />
                    )}
                    <Field
                      name="promocodeDiscount"
                      component={Input}
                      freeTopSpace
                      type="number"
                      min={0}
                      max={100}
                      placeholder={t("percentileDiscount")}
                    />
                    <Field
                      name="promocodeCount"
                      component={Input}
                      freeTopSpace
                      type="number"
                      min={0}
                      max={100}
                      placeholder={t("promocodeCount")}
                    />
                  </>
                )}
              {(role === "doctor" ||
                role === "operator" ||
                role === "insurance-operator") &&
                mode !== "promocodes" && (
                  <Field
                    name="serviceFormat"
                    component={FilterSelect}
                    placeholder={t("filterTypeOfService")}
                    options={serviceFormats}
                    withPreview
                    withIconInOptions
                    previewIcon={plus}
                    icon={plus}
                    withIcon
                  />
                )}

              {mode !== "shedule" &&
                role !== "doctor" &&
                mode !== "promocodes" && (
                  <Field
                    name="specialization"
                    component={FilterSelect}
                    placeholder={t("placeholderSpecialty")}
                    options={specializations}
                    withIcon
                    multipleInTitle={true}
                    withPreview={formValues && formValues.specialization}
                    icon={plus}
                  />
                )}

              {mode === "shedule" && mode !== "promocodes" && (
                <>
                  <Field
                    name="specialization"
                    component={FilterSelect}
                    placeholder={t("placeholderSpecialty")}
                    options={specializations}
                    withSearch
                    multiple
                    withPreview={formValues && formValues.specialization}
                    previewIcon={docAvatar}
                  />
                  <Field
                    name="serviceFormat"
                    component={FilterSelect}
                    placeholder={t("filterTypeOfService")}
                    options={[
                      { imgUrl: support, title: "Онлайн" },
                      { imgUrl: home, title: "Визит на дом" },
                    ]}
                    withPreview
                    withIconInOptions
                  />
                </>
              )}
            </div>
            <div className="formFooter">
              <Button
                className="btn"
                disabled={
                  mode === "promocodes"
                    ? (isDisableButton || buttonLoading)
                    : formValues &&
                      !formValues.serviceFormat &&
                      !(formValues.dateStart || formValues.dateEnd) &&
                      !formValues.status &&
                      !formValues.specialization
                }
                type="submit"
                loading={buttonLoading || filterLoading}  
                text={t("acceptButton")}
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  getFilteredConsultations,
  getFilteredPromocodes,
  getAllConsultations,
  resetInitialValues,
  setPopUpSuccess,
  resetIsItemsFiltered,
  resetPatientsList,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("monitoringFilters")(state),
  initialValues: state.formInitialValues,
  filterMode: state?.monitoring?.consultations?.filterMode,
  language: state?.language,
  filter_params: state?.monitoring?.consultations?.filter_params,
  isItemsFiltered: state?.monitoring?.consultations?.isItemsFiltered,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "monitoringFilters", enableReinitialize: true })
);

export default enhance(Filter);

