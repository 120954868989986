import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getExactDayAndHours } from './formats';
import checkSign from "../../../style/icons/CheckSign/CheckSign-green.svg";
import Button from "../ui/Button/Button"
import { getNewConsultEntity } from '../../../redux/consultations/selectors';

const Done = ({ handleSetDone }) => {
  const { t } = useTranslation()
  const consultEntity = useSelector(getNewConsultEntity)
  const { day, hours } = getExactDayAndHours(consultEntity?.start)
  
  return (
    <div className={"newConsultationWrapper"}>
      <div className="doneContainer">
        <div className="contentWrapper">
          <div className="doneImageContainer">
            <img width="44" src={checkSign} alt="sign-done" />
          </div>
          <div className={"created"}>{t("requestCreated")}</div>
          <div className="doneContentContainer">
            <div className={"doneContent"}>
              <p>{t("consultConfirmation")}</p>
              <p>{`${day} ${t("at")} ${hours}`}</p>
              <p>{t("doctorConfirmation")}</p>
              <p>{consultEntity?.doctor?.employee?.fullName}</p>
            </div>
          </div>
        </div>
        <div className="buttonWrapper">
          <Button
            text={t("goodButton")}
            className={"btn"}
            onClick={handleSetDone}
          />
        </div>
      </div>
    </div>
  );
}

export default Done;
