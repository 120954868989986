import moment from "moment";
import React from "react";
import patientLogo from "../../../style/icons/Subscription/patient_active.svg";
import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";
import Pagination from "@material-ui/lab/Pagination";
import "./FavoritesPatientsList.scss";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";

const FavoritePatientsList = ({
  handleChangePage = () => { },
  handleOpenChat = () => { },
  listItems = [Array(Object)],
  loading = Boolean,
  role = String,
  currentPage = '',
  pageCount = Number,
  t,
}) => {
  const classes = useStyles();

  return (
    <div className="favoritesItemsWrapper">
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      <div className="titleWithFilters">
        <div className="title">{t("personalPatient")}</div>
        <div className="count">{`${t("filterAll")} ${listItems.length}`}</div>
      </div>
      <div className="favoritesItemsContainer">
        {!loading && listItems.map((item = Object) => {
          return (
            <div
              key={item.id}
              className="itemContainer"
              onClick={handleOpenChat(item)}
            >
              <div className="avatarBox">
                <img
                  className="patientFavorite"
                  src={item.photoUrl === "" ? patientLogo : item.photoUrl}
                  alt="user-avatar"
                />
              </div>
              <div className="titleBox">
                <p className="sectionTitle">Пациент</p>
                <p className="sectionText">{item.fullName}</p>
              </div>
              <div className="idBox">
                <p className="sectionTitle">ID</p>
                <p className="sectionText">#{item.id}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.root}>
        <Pagination
          classes={{ ul: classes.ul }}
          count={pageCount}
          color="primary"
          page={+currentPage}
          defaultPage={1}
          size="large"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default FavoritePatientsList;
