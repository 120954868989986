import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Field, Form, getFormValues, reduxForm, reset } from "redux-form";

import api from "../../../api";
import Button from "../ui/Button/Button";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import Spinner from "../ui/Spinner/Spinner";

import { getInsuranceCompanies } from "../../../redux/employees/actions";
import { setPopUpSuccess } from "../../../redux/networkError/actions";

import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import ShevronRight from "../../../style/icons/ShevronRight/shevron.svg";
import online_consult from "../../../style/icons/Chat/online_consult.svg";
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";

import "./HistoryItem.scss";

const TextField = ({ item, title }) => {
  const { t } = useTranslation();

  const isFieldGray =
    title === t("tests") ||
    title === t("examinations") ||
    title === t("medications") ||
    title === t("presumptiveDiagnosis") ||
    title === t("consultationWithDoctor")
      ? "text-field gray"
      : "text-field";

  const isTextEmpty =
    item === null || (item && item.length === 0) ? t("emptyList") : item;

  const isItem = !!(Array.isArray(item) && item.length > 0);

  return (
    <>
      <div className={isFieldGray}>
        <p className="title">{title}</p>
        <div className="text">
          {isItem &&
            item.map((field, itemId) =>
              field.imageUrl ? (
                <div key={itemId} className="optionBox">
                  <div className="imageBox">
                    <img
                      width="40"
                      height="40"
                      src={field.imageUrl}
                      alt="specialty-icon"
                    />
                  </div>
                  <div>{field.title}</div>
                </div>
              ) : (
                <p key={itemId}>{field.title}</p>
              )
            )}
          {!isItem && (item || t("emptyList"))}
        </div>
        {title === "План лечения" && (
          <div className="text-separator separator" />
        )}
      </div>
    </>
  );
};

const HistoryItemDetails = ({ item, setDetails, mounths, ...props }) => {
  const { t } = useTranslation();

  const item_date = new Date(item.start);
  const date = `${item_date.getDate()} ${mounths[item_date.getMonth()]}`;

  return (
    <div className="history-item-page details">
      <div className="header">
        <img
          src={arrowLeft}
          alt=""
          onClick={() => setDetails(false)}
          className="to-back"
        />
        <div className="text">{t("consultationDetails")}</div>
      </div>
      <div className="doctor">
        <div className="docAvatar">
          {<img src={item.doctor.employee.photoUrl} alt="doc-photo" />}
        </div>
        <div className="docinfo">
          <div className="name">{item.doctor.employee.fullName}</div>
          <div className="type">{item.specialty.title}</div>
        </div>
      </div>
      <div className="separator" />
      <div className="string date-cost">
        <b>{date}</b>
      </div>
      <div className="separator" />
      <div className="string id-status">
        <span className="id">#{item.id}</span>
        <span className="status">{t("newConsultation")}</span>
      </div>
      <div className="pad-left">
        <img
          src={
            (item.patientCard && item.patientCard.photoUrl) || patient_active
          }
          alt="user photo"
        />
        {item.patientCard.fullName}
      </div>
      <div className="icon-text">
        <img src={online_consult} alt="" />
        <span>{t("onlineConsultation")}</span>
      </div>
    </div>
  );
};

const HistoryItem = ({
  item,
  getInsuranceCompanies,
  formValues = { insuranceCompanies: [] },
  insuranceCompanies = [],
  setPopUpSuccess,
  setDetails,
  details,
  ...props
}) => {
  // const [details, setDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mounths = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  if (details)
    return (
      <HistoryItemDetails
        {...props}
        item={item}
        setDetails={setDetails}
        mounths={mounths}
      />
    );
  const item_date = new Date(item.start);
  const date = `${item_date.getDate()} ${mounths[item_date.getMonth()]}`;

  const itemKeys = [
    t("complaints"),
    t("presumptiveDiagnosis"),
    t("TreatmentPlan"),
    t("conclusion"),
    t("medications"),
    t("examinations"),
    t("tests"),
    t("consultationWithDoctor"),
  ];

  const itemValues = [
    item.complaintsText,
    item.disease,
    item.treatmentPlanText,
    item.conclusionText,
    item.drugs,
    item.diagnostic,
    item.analysis,
    item.specialtyRecommend,
  ];

  const textFileObj = itemKeys.reduce(
    (acc, n, i) => ({ ...acc, [n]: itemValues[i] }),
    {}
  );

  const downloadPDFConsultation = () => {
    setLoading(true);
    api.patients
      .downloadPDFConsultation(item.id, localStorage.getItem("token"))
      .then(() => setLoading(false));
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formValues.insuranceCompanies.length === 0) {
      window.alert(t("sendPDF"));
      setLoading(false);
    } else {
      const data = { companies: [] };
      formValues.insuranceCompanies.map((company) =>
        data.companies.push(company.id)
      );
      if (formValues.insuranceCompanies.length > 0)
        api.patients
          .sendPatientConsultationToInsuranse(item.id, data)
          .then(() =>
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("sendSuccess"),
                },
              },
            })
          )
          .then(() => setLoading(false))
          .then(() => dispatch(reset("sendConsultationResults")));
    }
  };

  console.log("afaFfaef", textFileObj, itemKeys);

  return (
    <div className="history-item-page">
      {loading ? (
        <div className="absolute">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="header">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => props.setHistoryItem(undefined)}
              className="to-back"
            />
            <div className="main-info">
              <img
                className="doctorImage"
                src={item.specialty.imageUrl}
                alt=""
              />
              <p className="doctor-full-name">
                {item.doctor.employee.fullName}
              </p>
              <p className="doctor">{item.specialty.title}</p>
              <p className="date">{date}</p>
            </div>
          </div>
          <div className="show-history">
            <div className="history-item" onClick={() => setDetails(true)}>
              <div className="info">
                <div className="doctor">{t("consultationDetails")}</div>
              </div>
              <img src={ShevronRight} alt="" />
            </div>
          </div>
          <div className="item-content">
            <strong>{t("anamnez")}</strong>
            <div className="separator" />
            {itemKeys.map((item, i) => {
              return (
                <TextField key={i} title={item} item={textFileObj[item]} />
              );
            })}
            <div className="separator" />
            <div className="text-field">
              <Form
                className="sendToInsurance"
                onSubmit={formSubmit}
                autoComplete="off"
              >
                <Field
                  name="insuranceCompanies"
                  options={
                    insuranceCompanies &&
                    insuranceCompanies.map((company) => ({
                      title: company.title,
                      id: company.id,
                    }))
                  }
                  multiple={true}
                  icon={plus}
                  withPreview
                  component={FilterSelect}
                  placeholder={t("insuranceCompany")}
                />
                <Button
                  className="btn send"
                  text={t("sendToEmail")}
                  disabled={!formValues.insuranceCompanies.length}
                />
              </Form>
              <Button
                onClick={() => downloadPDFConsultation()}
                className="btn"
                text={t("exportButton")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getInsuranceCompanies,
  setPopUpSuccess,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("sendConsultationResults")(state),
  insuranceCompanies: state.employees.insuranceCompanies,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "sendConsultationResults", enableReinitialize: true })
);

export default enhance(HistoryItem);
