import React from "react";
import close from "../../../style/icons/Close/close-icon.svg";
import uah from "../../../style/icons/Uah/uah.svg";
import company from '../../../style/icons/Subscription/company.svg'
import './subscriptionBanner.scss'
import editIcon from "../../../style/icons/PersonEdit/person-edit.svg";
import Spinner from "../ui/Spinner/Spinner";
import defaultSubscriptionImage from '../../../style/images/default-company.jpg'

const SubscriptionBanner = ({
  bannerInfo,
  setBannerInfo,
  t,
  setEditBannerOpen,
  setInitalValues,
  handleEditBannerInfo,
  bannerLoading,
}) => {
  
  const { subscription } = bannerInfo
  let lang = localStorage.getItem("i18nextLng").slice(0, 2)
  if (lang === 'uk') lang = 'ua'
  console.log(bannerInfo);
  // useEffect(() => {
  // }, [bannerLoading])
  return (
    <div className='bannerContainer'>
      <div className='subscriptionBanner'>
      {bannerLoading ? (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      ) : (
        // <div className='subscriptionBanner'>
            <>
          <img
            className='closeBannerIcon'
            onClick={() => {
              setEditBannerOpen(false)
              setBannerInfo(null)
            }}
            width='20' src={close}
            alt="closeBanner"
          />
          <img
            className='editBannerIcon'
            src={editIcon}
            onClick={() => {
              handleEditBannerInfo(bannerInfo)
              // setEditBannerOpen(true)
            }}
            alt="editIcon"
          />
          <div className='bannerInfoContainer'>
            <div className="imageBox">
              <img
                width='100'
                height='100'
                style={{ borderRadius: "50%" }}
                src={bannerInfo.imageUrl || defaultSubscriptionImage}
                alt=""
              />
            </div>
            <div className='shortDescriptionContainer'>
            <div className='shortDescriptionTitle'>{t("shortDescription")}</div>
            <div className='textToCenter'>
              <p className='shortDescription'>{t(subscription.shortDescription ?? "noDescription")}</p>
            </div>
          </div>
            <div className='descriptionContainer'>
              <div className='descriptionTitle'>{t("description")}</div>
              <div className='textToCenter'>
                {/* <div>{t(subscription.descriptionTranslations[lang] ?? "noDescription")}</div> */}
                <p>{t(subscription.description || "noDescription")}</p>
              </div>
            </div>
            <div className='costContainer'>
              <div className='priceTitle'>{t("cost")}</div>
              <div className='textToCenter' style={{ marginTop: "10px", }}>
                <span className='bannerPrice'>{subscription.price}</span>
                <span>
                  <img src={uah} alt="uah-value" />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      </div>
    </div>
  )
}

export default SubscriptionBanner