import React, { useEffect, useRef, useState } from "react";

import CheckboxUI from "../../ui/Checkbox/CheckBox";

import "./simpleCheckbox.scss";

const SimpleCheckbox = ({
    id,
    name,
    defaultValue,
    label,
    setIsPrincipalCard
}) => {
    const [value, setValue] = useState(defaultValue)
    
    const handleChange = () => {
        setValue(!value)
        setIsPrincipalCard(!value)
    }

    return (
        <div className={"wrapper_checkbox"}>
        <div className={"wrap_checkbox"}>
                <input
                    onChange={handleChange}
                    type="checkbox"
                    name={name}
                    id={id}
                    className={"checkbox"}
                    value={value}
                    checked={value}
                />

            <label htmlFor={id}>{label}</label>
        </div>
        </div>
    );
};

export default SimpleCheckbox;