import * as types from "./type";
import createReducer from "../../utils/createReducer";

const initialState = {
  lang: window.localStorage.getItem("lang") || "uk",
};

const langReducer = createReducer(initialState)({
  [types.CHANGE_LANG]: (state, { lang }) => {
    return {
      lang,
    };
  },
});

export default langReducer;
