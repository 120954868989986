import React from "react";
import infoRed from "../../../../style/icons/InfoSign/info-sign-red.svg";
import close_icon from "../../../../style/icons/Chat/close-icon.svg";

export const HelpModalContent = (props) => {
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const { t } = props;
  return (
    <div className="templates-modal-content help-modal">
      <div className="templates-header modal-header">
        <div
          className="icon-button help-modal-icon-button"
          onClick={handleClose}
        >
          <img src={close_icon} alt="Закрыть" />
        </div>
      </div>
      <div className="templates-content help-modal-content">
        <img src={infoRed} alt="red-info-icon" className="red-info-icon" />
        <p className="help-modal-header">{t("help")}</p>
        <p className="help-modal-text">
          {t("infoText")}
          <br />
          <span className="help-modal-text-phone">080 033 00 36</span>
        </p>
        <p className="help-modal-text">
          {t("infoMedQueation")}
          <br />
          <span className="help-modal-text-phone">080 033 00 36</span>
        </p>
        <div className="help-modal-footer">
          <button className="help-modal-buttons red-button">
            {t("techSupport")}
          </button>
          <button className="help-modal-buttons blue-button">
            {t("medQuestion")}
          </button>
        </div>
      </div>
    </div>
  );
};
