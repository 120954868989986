import React from 'react';

const NewToggle = ({
  onChange,
  options,
  value,
}) => {
  return (
    <div className="toggleField">
      {options.map((option, i) => (
        <label
          className={`optionItem ${option.value === value ? "active" : ""}`}
          key={option.value}
          onClick={() => onChange(option.value)}
        >
          {option.text}
        </label>
      ))}
    </div>
  );
}

export default NewToggle;
