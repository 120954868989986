import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import clinic from "../../../style/icons/Doc/doc-blue-in-grey.svg";
import i18n from "../../../i18n";

export const promocodeTypes = [
  { title: i18n.t("promocodeType_0"), value: "0", id: 0 },
  { title: i18n.t("promocodeType_1"), value: "1", id: 1 },
]
export const togglePromocodeOptions = [
  { value: "Filter", text: i18n.t("filterFormTitle") },
  { value: "addPromocode", text: i18n.t("addPromoCodes") },
]

export const promocodeStatuses = [
  { title: i18n.t("promocodeStatus_0"), value: "0", id: 0 },
  { title: i18n.t("promocodeStatus_1"), value: "1", id: 1 },
]

export const statusOptions = [
  { title: i18n.t("newConsultation"), value: "new" },
  { title: i18n.t("filledConsultation"), value: "filled" },
  { title: i18n.t("closedConsultation"), value: "closed" },
  { title: i18n.t("inProgressConsultation"), value: "in_process" },
  { title: i18n.t("cancelledConsultation"), value: "cancelled" },
];

export const serviceFormats = [
  {
    imgUrl: support,
    title: i18n.t("optionsServiceTypeOnline"),
    type: "0",
  },
  {
    imgUrl: home,
    title: i18n.t("optionsServiceTypeAtHome"),
    type: "1",
  },
  {
    imgUrl: clinic,
    title: i18n.t("optionsServiceTypeInHospital"),
    type: "2",
  },
]