import React, { useState, useEffect, useRef } from "react";

import Button from "../../ui/Button/Button";
import Spinner from "../../ui/Spinner/Spinner";

import ShevronRight from "../../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";
import markDone from "../../../../style/icons/MarkDoneBlue/mark.svg";
import searchIcon from "../../../../style/icons/Search/search-icon.svg";

import api from "../../../../api";
import * as _ from "lodash";
import "./filterSelect.scss";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const FilterSelect = ({
  options,
  input,
  withSearch,
  withIcon,
  icon,
  withPreview,
  placeholder,
  previewIcon,
  multipleInTitle,
  withIconInOptions,
  initialValues,
  multiple,
  disabled,
  onChangeHandler,
  disabledIcon,
  optionAsList,
  apiSearchURL,
  startedOptions,
  setMadeChanges,
  customDisabled = false,
  selectAll = false,
  buttonLoading,
}) => {
  const { t } = useTranslation();
  const [localOptions, setLocalOptions] = useState(options);
  const [showOptions, setOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    multiple || multipleInTitle ? [] : ""
  );
  const [count, setCount] = useState(null);
  const [previewIconLocal, setPreviewIcon] = useState(previewIcon);
  const [x, setX] = useState(612);
  const [page, setPage] = useState(1);
  const [loading, isLoading] = useState(false);
  const ref = useRef(null);
  const searchRef = useRef();

  useEffect(() => {
    setX(ref.current.offsetHeight);
    setPage(1);
  }, []);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  useEffect(() => {
    // WIP : uncomment when u need to scroll to top after pick choise
    // ref.current.scrollTo(0, 0);
    if (Array.isArray(input.value) && input.value.some(el => el === undefined)) {
      return
    }
    setSelectedOption(input.value);
    setCount(input.value.length);
    if (input.value === "") {
      multiple || multipleInTitle
        ? setSelectedOption([])
        : setSelectedOption(null);
      setCount(input.value.length);
      setPreviewIcon(previewIcon);
    }
  }, [input.value]);

  const handleChangeInitialValues = (selectedValues) => {
    if (startedOptions && setMadeChanges) {
      const normalizedOptions = startedOptions.map((el) => {
        return { id: el.id, imgUrl: el.imageUrl, title: el.title };
      });
      const normalizedSelected = selectedValues.map((el) => {
        if (Object.keys(el).length > 3) {
          return { id: el.id, imgUrl: el.imageUrl, title: el.title };
        }
        return el;
      });
      return JSON.stringify(normalizedOptions) ==
        JSON.stringify(normalizedSelected)
        ? setMadeChanges(false)
        : setMadeChanges(true);
    }
  };

  const choiseHandler = (e, elem) => {
    let removeItemIndex;
    if (multiple || multipleInTitle) {
      if (
        selectedOption.includes(elem) ||
        selectedOption.map((el) => el.title).includes(elem.title)
      ) {
        selectedOption.forEach((el, i) => {
          if (el.id === elem.id) {
            removeItemIndex = i;
          }
        });
        selectedOption.splice(removeItemIndex, 1);

        setSelectedOption(selectedOption);
        setCount(selectedOption.length);
        if (onChangeHandler) {
          onChangeHandler(selectedOption);
        }
        handleChangeInitialValues(selectedOption);
        return input.onChange(selectedOption);
      }
      selectedOption.push(elem);
      setSelectedOption(selectedOption);
      setCount(selectedOption.length);
      if (onChangeHandler) {
        onChangeHandler(selectedOption);
      }
      handleChangeInitialValues(selectedOption);
      return input.onChange(selectedOption);
    } else {
      if (selectedOption?.title == elem.title) {
        setSelectedOption("");
        setPreviewIcon(previewIcon);
        return input.onChange("");
      }
      setSelectedOption(/* localOptions[index].title ||  */ elem);
      setPreviewIcon(
        elem.imgUrl ||
          (selectedOption && selectedOption.imageUrl) ||
          previewIcon
      );
      onChangeHandler && onChangeHandler(elem);
      return input.onChange(/* localOptions[index].title || */ elem);
    }
  };

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      ref.current.scrollTo(0, 0);
      if (value.length) {
        setX(ref.current.offsetHeight);
        setPage(1);
        if (apiSearchURL) {
          return api.consultations
            .getCardOptions(`${apiSearchURL}${value}`)
            .then((res) => setLocalOptions([...(res.data && res.data.items)]));
        } else {
          if (!withIconInOptions) {
            return setLocalOptions(
              options.filter((elem) => elem.title.toLowerCase().includes(value))
            );
          } else {
            return setLocalOptions(
              options.filter((elem) => elem.title.toLowerCase().includes(value))
            );
          }
        }
      } else {
        return setLocalOptions(options);
      }
    }, 1200),
    []
  );

  const searchInputHandler = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const onScrollHandler = () => {
    if (apiSearchURL) {
      if (ref.current.scrollTop > x - 150) {
        isLoading(true);
        setX(x + ref.current.offsetHeight);
        if (!loading) {
          return api.consultations
            .getCardOptions(
              `${apiSearchURL}${searchRef.current.value}`,
              page + 1
            )
            .then((res) => {
              localOptions.push(...res.data.items);
            })
            .then(() => setPage(page + 1))
            .then(() => isLoading(false));
        }
      }
    }
  };

  const handleSelectAll = () => {
    if (selectedOption.length === options.length) {
      setSelectedOption([]);
      input.onChange([]);
      return;
    }
    setSelectedOption([...options]);
    input.onChange([...options]);
  };

  return (
    <>
      <Button
        customDisabled={customDisabled}
        className="btn"
        secondary
        optionAsList={optionAsList}
        labelsOption={
          optionAsList &&
          selectedOption &&
          selectedOption.length > 0 &&
          selectedOption.map((el) => el && el.title)
        }
        text={
          (withPreview &&
            (multiple || multipleInTitle) &&
            ((selectedOption &&
              Array.isArray(selectedOption) &&
              selectedOption.map((el) => el && el.title).join(", ")) ||
              (Array.isArray(selectedOption) && selectedOption.join(", ")))) ||
          (withPreview &&
            withIconInOptions &&
            selectedOption &&
            selectedOption.length > 0 &&
            (selectedOption.title || selectedOption)) ||
          (withPreview && initialValues) ||
          (withPreview &&
            !multiple &&
            !multipleInTitle &&
            selectedOption &&
            (selectedOption.title || selectedOption)) ||
          "" ||
          placeholder
        }
        shevron={ShevronRight}
        withIcon={withIcon}
        icon={
          withPreview
            ? withIconInOptions
              ? selectedOption &&
                (selectedOption.imgUrl || selectedOption.imageUrl)
                ? selectedOption &&
                  (selectedOption.imgUrl || selectedOption.imageUrl)
                : previewIcon
              : previewIcon
            : icon
        }
        loading={buttonLoading}
        type="button"
        onClick={() => setOptions(true)}
        disabled={disabled || buttonLoading}
        disabledIcon={disabledIcon}
      />
      {/* {buttonLoading && (
        <div className="btnLoadingBox">
          <Spinner small/>
        </div>
      )} */}
      <div className={`optionSelectingContainer ${showOptions ? "show" : ""}`}>
        {loading && (
          <div className={"background"}>
            <Spinner />
          </div>
        )}
        <div className="formTitle">
          <img
            src={arrowLeft}
            alt="icon"
            onClick={() => {
              setOptions(false);
              setX(0);
              setPage(1);
            }}
          />
          {placeholder}
          {multipleInTitle && count > 0 && (
            <div className="countSpecializations">
              <span>{count}</span>
            </div>
          )}
        </div>
        {withSearch && (
          <div className="search">
            <img src={searchIcon} alt="icon" />
            <input
              ref={searchRef}
              className="search"
              type="text"
              placeholder={t("placeholderSearch")}
              onChange={searchInputHandler}
            />
            {multiple && count > 0 && (
              <div className="count">
                <span>{count}</span>
              </div>
            )}
          </div>
        )}
        <div className="filterOptions" onScroll={onScrollHandler} ref={ref}>
          {selectAll && (
            <div className="option center" onClick={handleSelectAll}>
              {selectedOption.length === options.length
                ? t("removeAll")
                : t("selectAll")}
            </div>
          )}
          {localOptions && localOptions.length ? (
            localOptions.map((elem, index) => (
              <div
                key={index}
                className="option"
                onClick={(e) => choiseHandler(e, elem)}
              >
                {elem && (elem.imgUrl || (elem && elem.title)) && (
                  <span>
                    {elem && elem.imgUrl && (
                      <img src={elem && elem.imgUrl} alt="" />
                    )}
                    {elem && ((elem && elem.title) || elem.value)}
                  </span>
                )}
                {(selectedOption &&
                  selectedOption.title === elem?.title) ||
                (selectedOption &&
                  selectedOption.length &&
                  selectedOption.length > 0 &&
                  selectedOption.includes(elem)) ||
                (selectedOption &&
                  selectedOption.length &&
                  selectedOption.length > 0 &&
                  [...selectedOption]
                    .map((el) => el && el.title)
                    .includes(elem && elem.title)) ? (
                  <img src={markDone} alt="icon" />
                ) : withIconInOptions &&
                  selectedOption === (elem && elem.title) ? (
                  <img src={markDone} alt="icon" />
                ) : undefined}
              </div>
            ))
          ) : (
            <div className="empty">{t("emptyList")}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterSelect;
