import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getAllSpecializations,
  setPagination,
} from "../../../redux/specializations/actions";
import { getSymptomsBySpecializationId } from "../../../redux/symptoms/actions";

import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";
import specIcon from "../../../style/icons/SpecialtyIcon/spec-icon.svg";

import { setInitalValues } from "../../../redux/formInitialValues/actions";

import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";

import "./symptomsList.scss";

const SymptomsList = ({
  getAllSpecializations,
  setEdit,
  setInitalValues,
  specialization,
  items = [],
  current_page,
  setPagination,
  page_count,
  edit,
  formInitialValues,
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllSpecializations(50, current_page && current_page).then(() => {
      getAllSpecializations(10).then(() => setLoading(false));
    });
  }, []);

  useEffect(() => {
    setPage(new Array(page_count).fill(0));
  }, [page_count]);

  return (
    <>
      <div className="symptomListContainer">
        <div className="titleWithFilters">
          <div className="title">{t("symptomsAndSpeciality")}</div>
        </div>
        {loading ? (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        ) : (
          <>
            <div
              className={`symptomItemsContainer ${
                items && items.length > 0 ? "" : "empty"
              }`}
            >
              {!loading && specialization && items && items.length > 0 ? (
                items.map((item) => (
                  <div
                    key={item.id}
                    className={`symptomItem ${
                      edit && item.id === formInitialValues.id
                        ? "editItem"
                        : edit
                        ? "editMode"
                        : ""
                    }`}
                  >
                    <div className="id">
                      <div className="itemTitle">ID</div>
                      <div className="idInfo">#{item.id}</div>
                    </div>
                    <div className="symptom">
                      <div className="itemTitle">
                        {t("placeholderSpecialty")}
                      </div>
                      <div className="symptomInfo">
                        <img src={item.imageUrl || specIcon} alt="" />
                        <span className="name">{item.title}</span>
                      </div>
                    </div>
                    <div className="actions">
                      <img
                        src={specEdit}
                        alt="edit-icon"
                        onClick={() => {
                          setEdit();
                          setInitalValues({
                            id: item.id,
                          });
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <>{!loading && <p>{t("emptyList")}</p>}</>
              )}
            </div>
            {page && page.length >= 1 && page_count !== 0 && (
              <Paginator
                current_page={current_page}
                setPagination={setPagination}
                setLoading={setLoading}
                page_count={page_count}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ specialization, symptoms, formInitialValues }) => ({
  specialization,
  items:
    symptoms && symptoms.allSpecialization && symptoms.allSpecialization.items,
  current_page: specialization && specialization.current_page,
  page_count: specialization && specialization.page_count,
  formInitialValues,
});

export default connect(mapStateToProps, {
  getAllSpecializations,
  getSymptomsBySpecializationId,
  setInitalValues,
  setPagination,
})(SymptomsList);
