import React from "react";
import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol,
} from "react-grid";

const styles = {
  breakpoints: { xs: 425, sm: 425, md: 992, lg: 992, xl: 1440 },
  containerMaxWidths: { xs: 1440, sm: 1440, md: 1440, lg: 1440, xl: 1440 },
  columns: 12,
};

export const Container = (props) => (
  <ReactContainer
    {...props}
    styles={{ ...styles, gutterWidth: props.gutter ? props.gutter : 20 }}
  />
);

export const Row = (props) => (
  <ReactRow
    {...props}
    styles={{ ...styles, gutterWidth: props.gutter ? props.gutter : 20 }}
  />
);

export const Col = (props) => (
  <ReactCol
    {...props}
    styles={{ ...styles, gutterWidth: props.gutter ? props.gutter : 20 }}
  />
);
