import * as types from "./types";
import api from "../../api";

export const getAllBanners = () => (dispatch) =>
  api.banners.getAllBanners().then((data) => {
    // let maxId = Math.max(...data.items.map((el) => el.id));
    return dispatch({
      type: types.GET_ALL_BANNERS,
      ...data,
      // maxId,
    });
  });

export const addNewBanner = (data) => (dispatch) =>
  api.banners.addNewBanner(data).then((res) => res);

export const updateBanner = (id, data) => (dispatch) =>
  api.banners.updateBanner(id, data).then((res) => res);

export const deleteBanner = (id) => (dispatch) =>
  api.banners.deleteBanner(id).then((res) => res);
