import React from "react";

import "./Splitter.scss";

const Splitter = ({ left = [React.Component], right = [React.Component] }) => (
  <>
    <div className="panel-left">{left}</div>
    {right && <div className="panel-right">{right}</div>}
  </>
);

export default Splitter;
