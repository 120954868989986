import React, { useState, useEffect } from "react";

import "./toggleImg.scss";

const ToggleImg = ({ options, input, changeMode, smallMode }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    input.onChange(selectedOption);
    // changeMode && changeMode(selectedOption)
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (!input.value) {
      setSelectedOption(options[0]);
      input.onChange(options[0]);
    }
  }, [input.value]);

  const changeHandler = (e) => {
    setSelectedOption((prev) => e);
  };

  // console.log({ options, selectedOption, value: input.value });
  return (
    <div className="toggleFieldImg">
      {options.map((element, index) => (
        <label
          className={`${
            element.value === selectedOption.value
              ? `optionItem ${smallMode ? 'small' : ''} active`
              : `optionItem ${smallMode ? 'small' : ''}`
          }`}
          key={index}
          htmlFor={`${input.name}${index + 1}`}
          onClick={() => {
            changeMode && changeMode(element.value)
            changeHandler(element)
          }}
        >
          <img
            className={`toggleImg ${smallMode ? 'small' : ''}`}
            src={
              element.value === selectedOption.value
                ? element.imgActive
                : element.imgInactive
            }
            alt=""
          />
          {element.text}
        </label>
      ))}
    </div>
  );
};

export default ToggleImg;
