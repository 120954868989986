import React, { useState } from "react";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { useTranslation } from "react-i18next";
import { HelpModalContent } from "./";

export const HelpModal = ({ toggleHelpModal, isOpenHelpModal }) => {
  const { t } = useTranslation();

  if (!isOpenHelpModal) {
    return null;
  }

  return (
    <ModalWindow
      isOpen={isOpenHelpModal}
      toggleOpen={() => toggleHelpModal(false)}
    >
      <HelpModalContent handleClose={() => toggleHelpModal(false)} t={t} />
    </ModalWindow>
  );
};
