import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import api from "../../../api";
import FileUploader from "../../components/formComponent/FIleUploader/FileUploader";
import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";

import { setNetworkError, setPopUpSuccess } from "../../../redux/networkError/actions";

const UpdateSubscriptionForm = ({
  formValues = {},
  setInitalValues,
  resetInitialValues,
  setPopUpSuccess,
  setNetworkError,
}) => {
  const [state, setState] = useState({
    fileList: [],
    file: null,
  });
  const { t } = useTranslation();
  const [disableButton, isDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (
      formValues &&
      !formValues.updateSubscriptionFile &&
      formValues.fillType &&
      formValues.fillType.value === "file"
    ) {
      isDisableButton(true);
    } else {
      isDisableButton(false);
    }
  }, [formValues]);

  const toClose = () => {
    isDisableButton(true);
    resetInitialValues();
    setInitalValues({ fillType: { value: "file", text: "Из файла" } });
    setLoading(false);
    setState({
      fileList: [],
      file: null,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formValues.fillType && formValues.fillType.value === "file") {
      if (formValues.updateSubscriptionFile && formValues.updateSubscriptionFile.file) {
        api.subscriptions
          .update(formValues.updateSubscriptionFile.file)
          .then(() => {
            setPopUpSuccess({
              response: {
                status: t("success"),
                succes: true,
                statusText: t("fileUploaded"),
                hold: true,
              },
            })
          })
          .catch((data) =>
            setNetworkError({
              response: {
                status: "Error",
                succes: false,
                statusText: data.message,
                erorrsArr: data.errors,
                hold: true,
              },
            })
          )
          .finally(toClose)
      }
    }
  };

  return (
    <div className='newSubscriberFormWrapper'>
      <p className="formTitle">{t("renewSubscription")}</p>
      <Form
        className="updateSubscriptionForm"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <Field
          name="fillType"
          component={Toggle}
          options={[
            { value: "file", text: t("fromFile") },
          ]}
        />
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
            <div className="formContent">
              <Field name="updateSubscriptionFile" component={FileUploader} props={{state, setState}}/>
            </div>
            <div className="formFooter">
              <Button
                text={t("renewSubscription")}
                className="btn"
                type="submit"
                disabled={disableButton || loading}
              />
            </div>
      </Form>
    </div>
  );
};
const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  setPopUpSuccess,
  setNetworkError,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("updateSubscription")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "updateSubscription", enableReinitialize: true })
);

export default enhance(UpdateSubscriptionForm);
