import * as types from "./types";
import createReduser from "../../utils/createReducer";
import { combineReducers } from "redux";
import { GET_RANDOM_DOCTOR, GET_RANDOM_DOCTOR_IN_HOSPITAL } from "../consultations/types";

const initialState = {
  defaultFilter: "all",
};

const sheduleReducer = createReduser(initialState)({
  [types.GET_SHEDULE]: (
    state,
    {
      filterMode,
      count,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    count,
    ...props,
  }),

  [types.SET_FILTER_MODE_SHEDULE]: (state, { filterMode }) => ({
    ...state,
    filterMode,
    isItemsFiltered: false,
  }),

  [types.GET_FILTERED_SHEDULE]: (state, { payload }) => ({
    ...state,
    ...payload,
    ...payload.data,
    isItemsFiltered: true,
  }),

  [types.GET_FREE_DOC_TIME_IN_HOSPITAL]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
  // [types.GET_FREE_DOC_TIME]: (state, { type, ...data }) => ({
  //   ...state,
  //   periods: data.entity.periods,
  // }),
});

const provinces = createReduser([])({
  [types.GET_PROVINCES]: (_, { data }) => data?.items || [],
});

const districts = createReduser([])({
  [types.GET_DISTRICTS]: (_, {data}) =>  data?.items || [],
});

const districtsPrices = createReduser([])({
  [types.GET_DISTRICTS_PRICES]: (_, { data }) => data?.entity?.details || [],
});

const doctorPeriods = createReduser([])({
  [types.GET_FREE_DOC_TIME]: (_, { type, ...data }) => data?.entity?.periods || [],
  [GET_RANDOM_DOCTOR_IN_HOSPITAL]: (_, { data }) => data?.entity?.periods || [],
  [GET_RANDOM_DOCTOR]: (_, {res}) => res?.entity?.periods || [],
  [types.GET_FREE_DOC_TIME_IN_HOSPITAL]: (_, {type, ...data}) => data?.entity?.periods || [],
  [types.RESET_PERIODS]: () => [],
});

const loading = createReduser(false)({
  [types.GET_DISTRICTS_REQUEST]: (_, __) => true,
  [types.GET_DISTRICTS_PRICES]: (_, __) => false,
});


export default combineReducers({
  shedule: sheduleReducer,
  periods: doctorPeriods,
  districts,
  provinces,
  districtsPrices,
  loading,
});
