import { createSelector } from "reselect";
const defaultState = {};

const getProcesses = (state = defaultState) =>
  state.processes;

export const getAllProcesses = createSelector(
  [getProcesses],
  (processesState) => processesState.processes.data
);
