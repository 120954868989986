import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#fce022",
      color: "black",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#fce022",
      },
    },
    "& > *": {
      marginTop: theme.spacing(1),
      fontSize: "16px",
      "& ul li button": {
        fontSize: "15px",
        fontWeight: "500",
        "&*": {
          backgroundColor: "#3288cd",
        },
      },
    },
  },
}));