import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Field,
  Form,
  getFormValues,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { formatBirthDate } from "../../../helpers/validate";
import api from "../../../api";

import {
  createDoctor,
  createOperator,
  deleteDoctor,
  deleteEmployee,
  getAllEmployees,
  getInsuranceCompanies,
  setPagination,
  updateDoctor,
  updateOperator,
} from "../../../redux/employees/actions";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  setNetworkError,
  setPopUpSuccess,
} from "../../../redux/networkError/actions";
import { getAllSpecializations } from "../../../redux/specializations/actions";

import close from "../../../style/icons/Close/close-icon.svg";
import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import inActiveDocIcon from "../../../style/icons/Doc/doc-grey.svg";
import activeOperatorIcon from "../../../style/icons/Operator/operator-blue.svg";
import inActiveOperatorIcon from "../../../style/icons/Operator/operator-grey.svg";

import FileUploader from "../../components/formComponent/FIleUploader/FileUploader";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import ToggleImg from "../../components/formComponent/ToggleImg/ToggleImg";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import Doc from "./Doc/Doc";
import Operator from "./Operator/Operator";
import "./newEmployeeForm.scss";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

const NewEmployeeForm = ({
  role = "",
  edit,
  setEditingOff,
  setInitalValues,
  createDoctor,
  formValues = {},
  createOperator,
  initialValues,
  reset,
  // specializations,
  updateDoctor,
  deleteDoctor,
  deleteEmployee,
  specialities,
  dirty,
  invalid,
  filterMode,
  getAllEmployees,
  addingImg,
  current_page,
  setAddingImg,
  setPagination,
  setBlob,
  blob,
  setNetworkError,
  setPopUpSuccess,
  getInsuranceCompanies,
  insuranceCompanies,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [locking, setLocking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);
  const [startedEditing, setStartedEditing] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingSpecialties, setEditingSpecialties] = useState(null);

  const employeeTypes = [
    {
      value: "doc",
      text: t("doctor"),
      imgActive: activeDocIcon,
      imgInactive: inActiveDocIcon,
    },
    {
      value: "operator",
      text:
        role === "ROLE_DIVISION_ADMIN" ? t("operator") : t("operatorAndAdmin"),
      imgActive: activeOperatorIcon,
      imgInactive: inActiveOperatorIcon,
    },
  ];

  useEffect(() => {
    getInsuranceCompanies();
    return () => closeEditing();
  }, []);

  useEffect(() => {
    if (startedEditing) {
      if (formValues?.specialty) {
        setEditingSpecialties([...formValues.specialty]);
      }
      setEditValues(formValues);
      setEditingId(formValues.editingId);
    }
  }, [startedEditing, editingId]);

  useUpdateEffect(() => {
    if (!editingId) {
      setEditingOff();
    }
  }, [editingId]);

  useEffect(() => {
    if (startedEditing && editValues) {
      if (JSON.stringify(editValues) != JSON.stringify(formValues)) {
        if (editingId !== formValues?.editingId) {
          setEditingId(formValues?.editingId);
          return;
        }
        setMadeChanges(true);
      } else {
        setMadeChanges(false);
      }
    }
  }, [formValues, startedEditing, editValues]);

  useEffect(() => {
    if (edit) {
      setTimeout(() => {
        setStartedEditing(true);
      }, 100);
    }
  }, [edit]);

  const closeEditing = () => {
    setMadeChanges(false);
    setStartedEditing(false);
    setEditingOff();
    setLocking(false);
    setDeleting(false);
    setLoading(false);
    resetInitialValues();
    reset();
    setInitalValues({
      fillType: { value: "hand", text: "Вручную" },
      birthDate: "",
    });
  };

  const changeMode = (mode = "") => {
    // closeEditing()
    resetInitialValues();
    setInitalValues({
      fillType: { value: "hand", text: "Вручную" },
      birthDate: "",
      employeeType: employeeTypes.find((el) => el.value === mode),
    });
  };

  const formSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (formValues.fillType && formValues.fillType.value === "file") {
      if (formValues.employeeFile && formValues.employeeFile.file) {
        api.employees
          .uploadFromFile(formValues.employeeFile.file)
          .then(
            (res) => res.entity && console.log("Фото успeшно загруженно", res)
          )
          .catch((err) =>
            console.log("Во время загрузки файла что-то пошло не так!", err)
          );
      } else {
        window.alert("Загрузите файл перед отправкой");
      }
    } else {
      formValues.birthDate = formValues.birthDate.split("T")[0];
      if (edit && !deleting && !locking) {
        console.log("edit");
        if (formValues.employeeType.value === "operator") {
          if (formValues.sex === t("maleSex")) {
            formValues.gender = 0;
          } else {
            formValues.gender = 1;
          }
          if (formValues.insuranceCompany) {
            insuranceCompanies &&
              insuranceCompanies.map((item) => {
                if (item.title === formValues.insuranceCompany) {
                  formValues.insuranceCompany = item.id;
                }
              });
          }
          return updateOperator(formValues.employeeID, formValues).then(
            async (res) => {
              if (res.code) {
                setLoading(false);
                for (let key in res.errors) {
                  const inputNameWithError = key.split(".").pop();
                  throw new SubmissionError({
                    [inputNameWithError]: res.errors[key],
                  });
                }
                return;
              }
              let {
                entity: { id },
              } = res;
              console.log("Update operator with id: " + id);
              if (blob) {
                await api.employees
                  .uploadPhoto(formValues.id, blob)
                  .then(({ entity: { id } }) => {
                    console.log(
                      "Upload photo in operator with id:" + id + " success..."
                    );
                    setBlob(null);
                  });
              }
              setPagination({ filterMode, numberOfPage: current_page });
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successOperatorUpdate"),
                  },
                },
              });
              closeEditing();
            }
          );
        }
        if (formValues.employeeType.value === "doc") {
          if (filterMode === "all") {
            if (formValues.sex === t("maleSex")) {
              formValues.gender = 0;
            } else {
              formValues.gender = 1;
            }
            updateDoctor(formValues.doctorId, formValues).then(
              async ({
                res: {
                  entity: {
                    employee: { id },
                  },
                },
              }) => {
                if (blob) {
                  await api.employees
                    .uploadPhoto(id || formValues.employeeID, blob)
                    .then((id) => {
                      setBlob(null);
                    });
                }
                setPagination({ filterMode, numberOfPage: current_page });
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successDoctorUpdate"),
                    },
                  },
                });
                closeEditing();
              }
            );
          }
          if (filterMode === "doctors") {
            if (formValues.sex === t("maleSex")) {
              formValues.gender = 0;
            } else {
              formValues.gender = 1;
            }
            updateDoctor(formValues.editingId, formValues).then(async (res) => {
              if (blob) {
                await api.employees
                  .uploadPhoto(formValues.employeeID, blob)
                  .then((id) => {
                    console.log(
                      "Upload photo in operator with id:" + id + " success..."
                    );
                    setBlob(null);
                  });
              }
              setPagination({ filterMode, numberOfPage: current_page });
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successDoctorUpdate"),
                  },
                },
              });
              closeEditing();
            });
          }
        }
      }
      if (edit && deleting) {
        console.log("deleting");
        if (formValues.employeeType.value === "doc") {
          filterMode === "all" &&
            deleteDoctor(formValues.id).then(() => {
              // setPagination({ filterMode, numberOfPage: current_page })
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successDoctorUpdate"),
                  },
                },
              });
              setPagination({ filterMode, numberOfPage: 1 });
            });
          filterMode === "doctors" &&
            deleteDoctor(formValues.editingId).then(() => {
              // setPagination({ filterMode, numberOfPage: current_page })
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successDoctorDelete"),
                  },
                },
              });
              setPagination({ filterMode, numberOfPage: 1 });
            });
        }
        if (formValues.employeeType.value === "operator") {
          deleteEmployee(formValues.editingId).then(() => {
            // setPagination({ filterMode, numberOfPage: current_page })
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("successOperatorDelete"),
                },
              },
            });
            setPagination({ filterMode, numberOfPage: 1 });
          });
        }
        setBlob(null);
        closeEditing();
      }
      if (edit && locking) {
        if (formValues.roles && formValues.roles.includes("ROLE_ADMIN")) {
          setNetworkError({
            response: {
              status: "Внимание!",
              statusText: "Админ не может быть заблокирован. Он слишком крут.",
            },
          });
          closeEditing();
          return;
        }
        if (formValues.isBlocked) {
          api.employees
            .toUnlockEmployee(
              initialValues.employee
                ? initialValues.employee.id
                : initialValues.editingId
            )
            .then(() => {
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successUnblockUser"),
                  },
                },
              });
              setPagination({ filterMode, numberOfPage: current_page });
            });
        } else {
          api.employees
            .toLockEmployee(
              initialValues.employee
                ? initialValues.employee.id
                : initialValues.editingId
            )
            .then((res) => {
              if (res && res.code) {
                setNetworkError({
                  response: {
                    succes: false,
                    status: res.code,
                    data: {
                      message: t("failedBlockUser"),
                    },
                  },
                });
              } else {
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successBlockUser"),
                    },
                  },
                });
                setPagination({ filterMode, numberOfPage: current_page });
              }
            });
        }
        closeEditing();
      }
      if (!edit) {
        if (formValues.employeeType.value === "doc") {
          if (formValues.sex === t("maleSex")) {
            formValues.gender = 0;
          } else {
            formValues.gender = 1;
          }

          formValues.birth = formatBirthDate(formValues.birthDate);

          return createDoctor(formValues).then(async ({ res }) => {
            if (res.code) {
              setLoading(false);
              for (let key in res.errors) {
                const inputNameWithError = key.split(".").pop();
                throw new SubmissionError({
                  [inputNameWithError]: res.errors[key],
                });
              }
              return;
            }
            let {
              entity: {
                employee: { id },
              },
            } = res;
            if (blob) {
              await api.employees.uploadPhoto(id, blob).then((res) => {
                console.log(
                  // res,
                  "Upload photo in employee with id:" + id + " success..."
                );
                setBlob(null);
              });
            }
            // api.user.sendPassLinkOnEmail(id).then(() => {
            setPagination({ filterMode, numberOfPage: current_page });
            setLoading(false);
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("successDoctorCreate"),
                },
              },
            });
            // });
            closeEditing();
          });
        }
        if (formValues.employeeType.value === "operator") {
          if (formValues.sex === t("maleSex")) {
            formValues.gender = 0;
          } else {
            formValues.gender = 1;
          }
          if (formValues.insuranceCompany) {
            insuranceCompanies &&
              insuranceCompanies.map((item) => {
                if (item.title === formValues.insuranceCompany) {
                  formValues.insuranceCompany = item.id;
                }
              });
          }
          return createOperator(formValues).then(({ res }) => {
            if (res.code) {
              setLoading(false);
              // for (let key in res.errors) {
              // const inputNameWithError = key.split(".").pop();
              // throw new SubmissionError({
              //   [inputNameWithError]: res.errors[key],
              // });
              // }
              return;
            }
            let {
              entity: { id },
            } = res;
            // console.log(res, id);
            if (blob) {
              api.employees.uploadPhoto(id, blob).then((res) => {
                console.log(
                  // res,
                  "Upload photo in employee with id:" + id + " success..."
                );
                setBlob(null);
                setLoading(false);
              });
            }
            // api.user.sendPassLinkOnEmail(id).then(() => {
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("successOperatorCreate"),
                },
              },
            });
            setLoading(false);
            setPagination({ filterMode, numberOfPage: current_page });
            // });
            closeEditing();
          });
        }
      }
    }
  };

  return (
    <div className="wrappNewEmployeeForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit ? t("newScheduleFilterFormTitleEdit") : t("newEmployee")} `}
        {edit && (
          <img src={close} alt="arrow-icon" onClick={() => closeEditing()} />
        )}
      </div>
      <Form
        className="newEmployeeForm"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <Field
          name="fillType"
          component={Toggle}
          options={[
            { value: "hand", text: t("manually") },
            { value: "file", text: t("fromFile") },
          ]}
        />

        <div className={`formContent ${loading ? "relative" : ""}`}>
          {formValues?.fillType && formValues.fillType?.value === "hand" ? (
            <>
              {!edit ? (
                <Field
                  name="employeeType"
                  component={ToggleImg}
                  options={employeeTypes}
                  changeMode={changeMode}
                />
              ) : (
                <div className="employeePreviewCard">
                  <div className="title">
                    {formValues?.employeeType?.value === "doc"
                      ? t("doctor")
                      : t("operator")}
                  </div>
                  <div className="flex alignCenter">
                    {formValues &&
                      formValues.photo &&
                      formValues.photo.cropImg && (
                        <img
                          className="cropPhoto"
                          src={formValues.photo.cropImg}
                          alt="avatar-icon"
                        />
                      )}
                    <div className="details">
                      <span className="name">
                        {formValues.fullName ||
                          (formValues.employee && formValues.employee.fullName)}
                      </span>
                      {((formValues.doctor &&
                        formValues.doctor.educationSpecialty) ||
                        (formValues && formValues.educationSpecialty)) && (
                        <span className="spec">
                          {(formValues.doctor &&
                            formValues.doctor.educationSpecialty) ||
                            formValues.educationSpecialty}
                        </span>
                      )}
                      {/*<div className="details">*/}
                      {/*  <span className="name">*/}
                      {/*    {formValues.fullName ||*/}
                      {/*      (formValues.employee && formValues.employee.fullName)}*/}
                      {/*  </span>*/}
                      {/*  {((formValues.doctor && formValues.doctor.educationSpecialty) ||*/}
                      {/*    (formValues && formValues.educationSpecialty)) && (*/}
                      {/*      <span className="spec">*/}
                      {/*        {(formValues.doctor &&*/}
                      {/*          formValues.doctor.educationSpecialty) ||*/}
                      {/*          formValues.educationSpecialty}*/}
                      {/*      </span>*/}
                      {/*    )}*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              )}
              {loading && (
                <div className="absoluteCenter">
                  <Spinner />
                </div>
              )}
              {!loading &&
                formValues.employeeType &&
                formValues.employeeType.value === "doc" && (
                  <Doc
                    setMadeChanges={setMadeChanges}
                    formValues={formValues}
                    setAddingImg={setAddingImg}
                    addingImg={addingImg}
                    setEditingOff={setEditingOff}
                    initialValues={initialValues}
                    reset={reset}
                    setInitalValues={setInitalValues}
                    fillType={formValues.fillType}
                    specializations={specialities.map((spec, i) => ({
                      imgUrl: spec.imageUrl,
                      title: spec.title,
                      id: spec.id,
                    }))}
                    editingSpecialties={editingSpecialties}
                    setBlob={setBlob}
                    isBlocked={formValues.isBlocked}
                    t={t}
                  />
                )}
              {!loading &&
                formValues.employeeType &&
                formValues.employeeType.value === "operator" && (
                  <Operator
                    formValues={formValues}
                    setAddingImg={setAddingImg}
                    addingImg={addingImg}
                    setEditingOff={setEditingOff}
                    initialValues={initialValues}
                    reset={reset}
                    setInitalValues={setInitalValues}
                    fillType={formValues.fillType}
                    setBlob={setBlob}
                    isBlocked={formValues.isBlocked}
                    insuranceCompanies={insuranceCompanies}
                    t={t}
                    role={role}
                  />
                )}
            </>
          ) : (
            <Field
              name="employeeFile"
              // validate={[]}
              component={FileUploader}
              // disabled={!doctors || (formValues && !formValues.specialty)}
            />
          )}
        </div>
        <div className="formFooter">
          {!edit ? (
            <Button
              className="btn"
              type="submit"
              disabled={!dirty || invalid}
              text={t("createButton")}
              loading={loading}
            />
          ) : (
            <>
              {!formValues?.isBlocked && (
                <Button
                  disabled={!madeChanges}
                  save
                  className="btn"
                  type="submit"
                  text={t("saveButton")}
                  loading={loading}
                />
              )}
              <div>
                <Button
                  className="btn"
                  danger
                  onClick={() => setDeleting(true)}
                  type="submit"
                  text={t("deleteButton")}
                  loading={loading}
                />
                <Button
                  className="btn"
                  secondary
                  type="submit"
                  text={
                    locking || formValues?.isBlocked
                      ? t("unLockButton")
                      : t("lockButton")
                  }
                  onClick={() => {
                    setLocking(true);
                    // api.employees.toLockEmployee();
                  }}
                  loading={loading}
                />
              </div>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  createOperator,
  resetInitialValues,
  updateOperator,
  createDoctor,
  getAllSpecializations,
  updateDoctor,
  deleteDoctor,
  deleteEmployee,
  getAllEmployees,
  setPagination,
  setNetworkError,
  getInsuranceCompanies,
  setPopUpSuccess,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("employee")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  insuranceCompanies: state.employees && state.employees.insuranceCompanies,
  filterMode: state.employees && state.employees.filterMode,
  current_page: state.employees && state.employees.current_page,
  role: state.user && state.user.roles && state.user.roles[0],
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "employee", enableReinitialize: true })
);

export default enhance(NewEmployeeForm);
