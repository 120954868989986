import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SimpleTextarea } from "../formComponent";
import { cardDataTextFields, statuses } from "../ConsultattionCard/values";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import Button from "../ui/Button/Button";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import DiagnosisActive from "../../../style/icons/Diagnosis/DiagnosisActive.svg";
import DiagnosisDisActive from "../../../style/icons/Diagnosis/DiagnosisDisActive.svg";
import FamilyDoctorActive from "../../../style/icons/FamilyDoctor/FamilyDoctorActive.svg";
import FamilyDoctorDisActive from "../../../style/icons/FamilyDoctor/FamilyDoctorDisActive.svg";
import ExaminationsActives from "../../../style/icons/Examinations/ExaminationsActive.svg";
import ExaminationsDisActives from "../../../style/icons/Examinations/ExaminationsDisActive.svg";
import LabsActive from "../../../style/icons/Labs/LabsActive.svg";
import LabsDisActive from "../../../style/icons/Labs/LabsDisActive.svg";
import PreparationsActive from "../../../style/icons/Preparations/PreparationsActive.svg";
import PreparationsDisActive from "../../../style/icons/Preparations/PreparationsDisActive.svg";

const NewCardData = ({
  status = "",
  diseaseOptions = [],
  specializationsOptions = [],
  diagnosticOptions = [],
  analysisOptions = [],
  drugsOptions = [],
  closedCardData,
  saveLoading,
  cancelLoading,
  handleCancelConsult = () => {},
  inputProps = {
    disease: {
      onChange: () => { },
      value: []
    },
    specialties: {
      onChange: () => { },
      value: []
    },
    diagnostic: {
      onChange: () => { },
      value: []
    },
    analysis: {
      onChange: () => { },
      value: []
    },
    drugs: {
      onChange: () => { },
      value: []
    },
  },
}) => {
  const { t } = useTranslation()
  const isDisabled = status === statuses.CANCELLED || status === statuses.CLOSED
  const isSaveDisabled = status !== statuses.IN_PROCESS

  return (
    <div className="buttonsAndInputsArea">
      {cardDataTextFields.map((field) => {
        let value = "";
        if (closedCardData && closedCardData[field.name]) value = closedCardData[field.name];
        return <SimpleTextarea
          key={field.name}
          disabled={isDisabled}
          defaultValue={value}
          {...field}
        />;
      })}
      <div className="buttonMultiChoiceArea">
        <FilterSelect
          input={inputProps.disease}
          options={diseaseOptions}
          optionAsList={Boolean(inputProps.disease.value)}
          placeholder={t("presumptiveDiagnosis")}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={inputProps.disease.value}
          previewIcon={DiagnosisActive}
          disabled={isDisabled}
          disabledIcon={DiagnosisDisActive}
          apiSearchURL="/admin/appointments/card/disease?filter[title]="
        />
      </div>
      <div className="recommend">{t("recommendations")}</div>
      <div className="buttonMultiChoiceArea">
        <FilterSelect
          input={inputProps.specialties}
          optionAsList={Boolean(inputProps.specialties.value)}
          placeholder={t("consultationWithDoctor")}
          options={specializationsOptions}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={inputProps.specialties.value}
          previewIcon={FamilyDoctorActive}
          disabled={isDisabled}
          disabledIcon={FamilyDoctorDisActive}
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <FilterSelect
          input={inputProps.diagnostic}
          optionAsList={Boolean(inputProps.diagnostic.value)}
          placeholder={t("examinations")}
          options={diagnosticOptions}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={inputProps.diagnostic.value}
          previewIcon={ExaminationsActives}
          disabled={isDisabled}
          disabledIcon={ExaminationsDisActives}
          apiSearchURL="/admin/appointments/card/diagnostic?filter[title]="
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <FilterSelect
          input={inputProps.analysis}
          optionAsList={Boolean(inputProps.analysis.value)}
          placeholder={t("tests")}
          options={analysisOptions}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={inputProps.analysis.value}
          previewIcon={LabsActive}
          disabled={isDisabled}
          disabledIcon={LabsDisActive}
          apiSearchURL="/admin/appointments/card/analysis?filter[title]="
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <FilterSelect
          input={inputProps.drugs}
          optionAsList={Boolean(inputProps.drugs.value)}
          placeholder={t("medications")}
          options={drugsOptions}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={inputProps.drugs.value}
          previewIcon={PreparationsActive}
          disabled={isDisabled}
          disabledIcon={PreparationsDisActive}
          apiSearchURL="/admin/appointments/card/drugs?filter[title]="
        />
      </div>
      <Button
        className="finishForClientActive"
        text={t("finishSaveButton")}
        type="submit"
        loading={saveLoading}
        disabled={isSaveDisabled}
      />
      <div className={"saveCancelBtns"}>
        <Button
          className={"cancel"}
          type="button"
          text={t("cancelButton")}
          onClick={handleCancelConsult}
          loading={cancelLoading}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default NewCardData;
