import React, { useState, useEffect } from "react";
import EmployeeList from "../../components/EmployeeList/EmployeeList";
import NewEmployeeForm from "../../forms/NewEmployeeForm/NewEmployeeForm";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { connect } from "react-redux";
import { getSpecialtiesSelector } from "../../../redux/specializationMapping/selectors";
import { useHistory, withRouter } from "react-router-dom"
import { getEmployeesByFilter } from "../../../redux/employees/actions";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { compose } from "redux";

const Employees = ({
  getAllSpecializations,
  getEmployeesByFilter,
  specialities,
}) => {
  const [edit, setEdit] = useState(false);
  const [blob, setBlob] = useState(null);
  const [addingImg, setAddingImg] = useState(false);
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const page = new URLSearchParams(history.location.search).get("page")
  const filter = new URLSearchParams(history.location.search).get("filter")
  const specialties = new URLSearchParams(history.location.search).get("specialties")
  
  useEffect(() => {
    getAllSpecializations(50)
    // eslint-disable-next-line
  }, []);

  const refreshEmployees = ({firstTime}) => {
    setLoading(true)
    getEmployeesByFilter({filter, page, specs: specialties, firstTime})
      .finally(() => setLoading(false))
  }

  useEffect(() => refreshEmployees({firstTime: true}), [])
  useUpdateEffect(() => refreshEmployees({firstTime: false}), [page, filter, specialties])

  return (
    <div className="employeeContainer flex justifyBeetwen">
      {specialities && specialities.length >= 1 && (
        <>
          <EmployeeList
            edit={edit}
            setEdit={() => setEdit(true)}
            specialities={specialities}
            setAddingImg={setAddingImg}
            setBlob={setBlob}
            currentPage={page}
            currentFilter={filter}
            currentTags={specialties ? specialties : ""}
            loading={loading}
          />
          <NewEmployeeForm
            edit={edit}
            setEditingOff={() => setEdit(false)}
            specialities={specialities}
            setAddingImg={setAddingImg}
            addingImg={addingImg}
            setBlob={setBlob}
            blob={blob}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  specialities: getSpecialtiesSelector(state),
})

const enhance = compose(withRouter, connect(mapStateToProps, {
  getAllSpecializations,
  getEmployeesByFilter,
}));

export default enhance(Employees);

// export default connect(mapStateToProps, )(Employees);
