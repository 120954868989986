import React, { useEffect } from "react";
import { useState } from "react";

import close from "../../../style/icons/CloseInBlueCircle/close.svg";
import shevron from "../../../style/icons/ShevronDown/shevron-down.svg";
import done from "../../../style/icons/MarkDoneBlue/mark.svg";
import undone from "../../../style/icons/MarkDoneBlue/undone.svg";
import "./tagsInput.scss";

const TagsInputNew = ({ disabled, options, onChange, placeholder, currentTags = [],}) => {
  const [showOption, setShowOptions] = useState(false);
  
  const handleToggleSelect = (id) => {
    return () => {
      const selectedItem = currentTags.some(el => el.id === id)
      const tagsIDS = currentTags.map(el => el.id)
      if (selectedItem) onChange(tagsIDS.filter(el => el !== id))
      if (!selectedItem) onChange([...tagsIDS, id])
    }
  }

  const toggleOptions = (payload) => setShowOptions(prev => payload ? payload : !prev);

  const handleDeleteAll = (e) => {
    e.stopPropagation()
    onChange([])
    toggleOptions(false)
  }

  return (
    <div className={`tagsInputContainer ${disabled ? "disabled" : ""}`}>
      <div className={`tagsInputField`} onClick={() => toggleOptions()}>
        {!currentTags.length && placeholder}
        {currentTags.map((tag) => <span className="activeTag" key={tag.id}>{tag.title},</span>)}
        <div className="iconToggle" onClick={handleDeleteAll}>
          <img src={currentTags.length >= 1 ? close : shevron} alt=""/>
        </div>
      </div>
      <div
        className={`tagList ${showOption ? "active" : ""}`}
        onMouseLeave={() => toggleOptions(false)}
      >
        {options && options.map((el, i) => (
          <div key={i} className="tag" onClick={handleToggleSelect(el.id)}>
            {el.title}
            <img src={currentTags.includes(el) ? done : undone} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagsInputNew;