import React, { useEffect, useState } from "react";
import "./NewCardForm.scss";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  Form,
  getFormValues,
  reduxForm,
  Field,
  SubmissionError,
} from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { SimpleInput, SimpleSelect, SimpleDateInput, SimpleForm, SimpleCheckbox } from '../../components/formComponent';
import Button from "../../components/ui/Button/Button";
import closeIcon from "../../../style/icons/Close/close-icon.svg";
import Address from "../../components/formComponent/Address/Address";
import Checkbox from "../../components/formComponent/Checkbox/Checkbox";
import NiceInput from "../../components/formComponent/Input/Input";
import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import Selecter from "../../components/formComponent/Select/Select";
import InputPhone from "../../components/formComponent/InputPhone/InputPhone";

import { useHistory, useRouteMatch } from "react-router-dom";
import { dd_mm_yyyy, inPast, required } from "../../../helpers/validate";
import { relationIs } from "../../../helpers/functions";
import {
  creteNewPatientCard,
  addNewPatientCard,
  getPatientCards,
  getPatientRelations,
} from "../../../redux/patients/actions";
import { setPopUpSuccess } from "../../../redux/networkError/actions";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useTranslation } from "react-i18next";

import InputDateCleave from "../../components/formComponent/InputDateCleave/InputDateCleave.jsx";
import moment from "moment";
import { parserForm } from '../../../utils/parserForm';
import DatePicker from "../../components/formComponent/DatePicker/DatePicker";
import { getProvinces } from "../../../redux/shedule/actions";

const NewCard = ({
  toClose,
  formValues = {},
  creteNewPatientCard,
  // resetInitialValues,
  addNewPatientCard,
  setNewCard,
  getPatientCards,
  getPatientRelations,
  setPopUpSuccess,
  relations,
  setInitalValues,
  lang,
  invalid,
  getProvinces,
  dirty,
  // valid,
}) => {
  const isDate = formValues && moment(formValues?.birthDate).format('ms')
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [localRel, setLocalRel] = useState();
  const [rel, setRel] = useState();
  const [lockSubmit, setLockSubmit] = useState(false);
  const [isPrincipalCard, setIsPrincipalCard] = useState(false)
  const [patientPhone, setPatientPhone] = useState(null)
  const [disable, setDisable] = useState(true)
  // const [dateDisabled, setDateDisabled] = useState(false)
  const [genderOptions, setGenderOptions] = useState([])

  useEffect(() => {
    getProvinces()
    setGenderOptions([
      { label: t("maleSex"), value: 0 },
      { label: t("femaleSex"), value: 1 }
    ])
  }, [])

  console.log(formValues);

  // useEffect(() => {
  //   if (formValues?.birthDate) {
  //     setDateDisabled(true)
  //   } else if(formValues?.birthDate === null) {
  //     setDateDisabled(false)
  //   }
  // }, [formValues])


  useEffect(() => {
    getPatientRelations().then(({ data }) => {
      setRel(data.items);
    });
    if (localStorage.getItem("numericFromSearch")) {
      setPatientPhone(localStorage.getItem("numericFromSearch"))
    }
    return () => {
      localStorage.removeItem("numericFromSearch");
    };
  }, []);

  useEffect(() => {
    if (formValues?.phone?.length === 12
      && formValues?.birthDate) {
      setDisable(false)
    }
  }, [formValues])

  useEffect(() => {
    if (isPrincipalCard) {
      setLocalRel(relations && relations
        .map(rel => ({ label: rel.title, value: rel.id }))
        .filter(rel => rel.label === 'я' || rel.label === 'me'))
    } else {
      setLocalRel(relations && relations
        .filter(rel => rel.title !== "я")
        .filter(rel => rel.title !== "me")
        .map(rel => ({ label: rel.title, value: rel.id })))
    }
  }, [isPrincipalCard, relations])


  const match = useRouteMatch();
  const patientId = match.params.patient;
  const history = useHistory();

  const fieldCheck = (obj) => {
    let stack = {}
    for (let key in obj) {
      if (obj[key]) {
        stack = { ...stack, [key]: obj[key] }
      }
    }
    return Object.keys(stack) ? stack : null
  }

  const formSubmition = (evt, resetForm, setError) => {
    const { ...fields } = parserForm(evt);
    // console.log(fields);
    // console.log(formValues);
    setLoading(true);
    // const address = {
    //   city: fields.city,
    //   thoroughfare: fields.thoroughfare,
    //   houseNumber: fields.houseNumber,
    //   flatNumber: fields.flatNumber,
    //   comment: fields.comment,
    // }
    const patientCard = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      patronymic: fields.patronymic,
      email: fields.email,
      // birthDate: moment(new Date(fields.birthDate)).format('YYYY-MM-DD'),
      birthDate: formValues.birthDate,
      gender: fields.gender,
      relationShip: fields.relationShip,
      policeNumber: fields.policeNumber,
    }
    
    let user = {
      phone: formValues.phone,
      patientCard: {
        ...fieldCheck(patientCard),
        // address: formValues.address,
      }
    }
    if (formValues.address) {
      user = {
        phone: formValues.phone,
        patientCard: {
          ...fieldCheck(patientCard),
          address: {
            ...formValues.address,
            city: formValues.address.province.title
          }
        }
      }
    }
    if (!patientId) {
      return creteNewPatientCard({
        user
        // user: {
        //   phone: formValues.phone,
        //   patientCard: {
        //     ...fieldCheck(patientCard),
        //     address: formValues.address,
        //   }
        // }
      }).then(({ res }) => {
        setLockSubmit(true);
        if (res.code || res.code === 0) {
          setLoading(false);
          for (let key in res.errors) {
            const inputNameWithError = key.split(".").pop();
            throw new SubmissionError({
              [inputNameWithError]: res.errors[key],
            });
          }
          return;
        }
        const { entity } = res;
        getPatientCards(entity.patientId || patientId);
        setPopUpSuccess({
          response: {
            succes: true,
            status: t("success"),
            data: {
              message: t("successCreatingPatient"),
            },
          },
        });
        setLoading(false);
        resetForm()
        // history.push("/");
        // пациент успешно создан
        // reset();
        // resetInitialValues();
        setNewCard && setNewCard(false);
        // document.getElementById('99999').value = '';
      });
    } else {
      return addNewPatientCard(patientId, {
        ...fieldCheck(patientCard),
        // phone: fields.phone.replace(/[^\d;]/g, ''),
        phone: formValues.phone,
        // address: { ...fieldCheck(address) },
        address: formValues.address,
      }).then(() => {
        // setLockSubmit(true);
        setLoading(false);
        getPatientCards(patientId);
        resetForm()
        // resetInitialValues();
        setNewCard && setNewCard(false);
      });
    }
  }
  const isDefaultValue = () => {
    const value = localStorage.getItem('numericFromSearch')
    if (!value) return ''
    localStorage.removeItem('numericFromSearch')
    return value
  }

  return (
    <div className={"newCardContainer"}>
      <div className={"newCardHeading"}>
        <div className={"heading"}>{t("newPacient")}</div>
        {setNewCard && (
          <img src={closeIcon} alt="close-icon" onClick={toClose} />
        )}
      </div>
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="newCardFormWrapper">
          <SimpleForm
            className="newCardFormWrapper"
            handleSubmit={formSubmition}
            autoComplete="off"
            novalidate
          >
            <SimpleCheckbox
              name="principalCard"
              label={t("mainCard")}
              id={"principal card"}
              defaultValue={false}
              // onChange={onChange}
              setIsPrincipalCard={setIsPrincipalCard}
            />
            <Field
              name={"phone"}
              // noLabel={true}
              // onChange={onChange}
              component={InputPhone}
              defaultValue={isDefaultValue()}
              // localization={localStorage.getItem('lang')}
              placeholder={t("phoneNumber")}
              required
            />

            {/* <SimpleInput
            name="phone"
            pattern="^\+[0-9]{12}$"
            errorMessage={t('phoneIsIncorrect')}
            errorRequire={t('phoneIsRequire')}
            placeholder={t("phoneNumber")}
            required
          /> */}
            <SimpleInput
              // onChange={onChange}
              name="firstName"
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              errorMessage={t('firstNameIsIncorrect')}
              errorRequire={t('firstNameRequire')}
              placeholder={t("name")}
              label={t("name")}
              required
            />

            <SimpleInput
              // onChange={onChange}
              name="lastName"
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              errorMessage={t('lastNameIsIncorrect')}
              errorRequire={t('lastNameIsRequire')}
              placeholder={t("lastName")}
              label={t("lastName")}
              required
            />

            <SimpleInput
              name="patronymic"
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              errorMessage={t('patronymicIsIncorrect')}
              errorRequire={t('patronymicIsRequire')}
              placeholder={t("patronymic")}
              label={t("patronymic")}
              required
            />
            {/* <SimpleDateInput
            type="date"
            required
            label={'Дата народження'}
            errorMessage={t("birthDateIsIncorrect")}
            errorRequire={t('birthDateIsRequire')}
            name="birthDate"
            placeholder={t("birthDate")}
            validDate={{ number: 16, type: "year", period: "isBefore" }}
          /> */}
            {/* <div style={ {position: "relative"}}>
              <Field
                name='birthDate'
                component={DatePicker}
                validate={[required, inPast]}
                placeholder={t("birthDate")}
                locale={localStorage.getItem('i18nextLng').slice(0, 2)}
                // disabled={dateDisabled}
              />
              <img
                style={{position: "absolute", top: "15px", right: "10px"}}
                src={close}
                alt="close"
                onClick={async () => {
                  await setInitalValues({
                    ...formValues,
                    birthDate: null,
                    phone: formValues.phone,
                  });
                  // setDateDisabled(false)
                  let picker = document.getElementsByClassName(
                    "form-control"
                  );
                  for (let i = 0; i < picker.length; i++) {
                    const element = picker[i];
                    element.value = "";
                  }
                }}
              />
          </div> */}
            <Field
              name={`birthDate`}
              validate={[required, inPast]}
              component={InputDateCleave}
              placeholder={t("birthDate")}
            />

            <div className="fieldWrapper">
              <SimpleSelect
                name="gender"
                options={genderOptions}
                placeholder={t("gender")}
                required
              />
            </div>
            <div className="fieldWrapper">
              {relations && relations.length && (
                <SimpleSelect
                  name="relationShip"
                  options={localRel}
                  placeholder={t("relations")}
                  label={t("relations")}
                  required
                />
              )}
            </div>
            <Field
              name={"address"}
              component={Address}
              placeholder={t("adress")}
              label={t("adress")}
              blur={false}
            />
            {/* <Address
              name={"address"}
              // component={Address}
              placeholder={t("adress")}
              label={t("adress")}
              blur={false}
            /> */}
            <SimpleInput
              name="insurance"
              // pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ]{1,}$"
              // errorMessage={'Некоректное имя страховой'}
              placeholder={t("insurance")}
            // label={t("insurance")}
            />
            <SimpleInput
              name="policeNumber"
              // pattern="[0-9.-]{16}$"
              // errorMessage={'Некоректный номер полиса'}
              placeholder={t("policeNumber")}
            // label={t("policeNumber")}
            />
            <SimpleInput
              name="email"
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              // pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              // errorMessage={t("emailIsIncorrect")}
              // errorRequire={t('emailIsRequire')}
              placeholder={t("email")}
              label={t("email")}
            />
            <Button
              loading={loading}
              disabled={invalid}
              text={t("createButton")}
              className="btn"
              type="submit"
            />
          </SimpleForm>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  creteNewPatientCard,
  addNewPatientCard,
  getPatientCards,
  getPatientRelations,
  setPopUpSuccess,
  getProvinces,
};

const mapStateToProps = ({ language, ...state }) => ({
  formValues: getFormValues("NewCardForm")(state),
  // initialValues: state.formInitialValues,
  relations: state.patients && state.patients.relations,
  lang: language.lang,
});

const NewCardForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewCardForm", enableReinitialize: true })
);

export default NewCardForm(NewCard);
