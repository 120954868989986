import Recorder from "recorder-js";
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext = null;
let isRecording = false;
let blob1 = null;
let streamRef = null;
let recorder = null;
let input = null;

export async function startRecording() {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  audioContext = new AudioContext();
  // input = audioContext.createMediaStreamSource(stream);
  recorder = new Recorder(audioContext, {});
  streamRef = stream;
  recorder.init(stream);
  recorder.start();
}

export async function stopRecording() {
  const { blob, buffer } = await recorder.stop();
  // console.log(smt);
  blob1 = blob;
  const audioUrl = window.URL.createObjectURL(blob1);
  // recorder = null
  isRecording = false;
  // blob = null;
  recorder = null;
  audioContext = null;
  stopAudioStream();
  streamRef = null;
  return {
    audioUrl,
    blob: blob1,
  };
}

const stopAudioStream = () => {
  streamRef.getTracks().forEach((tr) => tr.stop());
};
