import React from "react";
import { Field } from "redux-form";

import { cirillicResist, email, nameValidation, phoneFormat, voidRequired } from "../../../../helpers/validate";

import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";
import calendar from "../../../../style/icons/Calendar/calendar.svg";
import close from "../../../../style/icons/Close/close-icon.svg";
import plus from "../../../../style/icons/PlusInCircle/plus.svg";
import trash from "../../../../style/icons/Trash/trash.svg";

import Checkbox from "../../../components/formComponent/Checkbox/Checkbox";
import DatePicker from "../../../components/formComponent/DatePicker/DatePicker";
import Input from "../../../components/formComponent/Input/Input";
import FileUploader from "../../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import Selecter from "../../../components/formComponent/Select/Select";
import Button from "../../../components/ui/Button/Button";

const Operator = ({
  role,
  setAddingImg,
  formValues = {},
  addingImg,
  initialValues,
  setInitalValues,
  setEditingOff,
  reset,
  fillType,
  setBlob,
  isBlocked,
  insuranceCompanies = [],
  t,
}) => {
  const insuranceOptions = insuranceCompanies.map((item) => item.title);
  return (
    <>
      <div className="commonInfo">{t("common")}</div>
      <>
        {role !== "ROLE_DIVISION_ADMIN" && (
          <Field
            name="isAdmin"
            component={Checkbox}
            label={t("isAdmin")}
            id={"isAdmin"}
          />
        )}

        {addingImg && (
          <div className={`addingImg ${addingImg ? "show" : ""}`}>
            <div className="formTitle">
              <img
                src={arrowLeft}
                alt="arrow-icon"
                onClick={() => {
                  setAddingImg(false);
                  // setEditingOff();
                }}
              />
              {t("addImage")}
            </div>
            <div className="photoUploadFieldContainer">
              <Field
                name="photo"
                // validate={[voidRequired]}
                component={FileUploader}
                previewTrashIcon={trash}
                // uploadReq={() => console.log("action")}
                close={() => setAddingImg(false)}
                editingImgUrl={
                  initialValues &&
                  initialValues.photo &&
                  initialValues.photo.cropImg
                }
                setBlob={setBlob}
                setEditingOff={setEditingOff}
                disabled={isBlocked}
                t={t}
              />
            </div>
          </div>
        )}

        {formValues && formValues.photo && formValues.photo.cropImg ? (
          <div className="specAvatar">
            <div className="flex alignCenter">
              <img
                className="cropPhoto"
                src={formValues.photo.cropImg}
                alt="avatar-icon"
              />
              <div className="fileName">
                {formValues && formValues.photo && formValues.photo.fileName}
              </div>
            </div>
            <div
              className="trashIcon"
              onClick={() => {
                reset();
                setInitalValues({
                  ...formValues,
                  photo: null,
                });
              }}
            >
              <img src={trash} alt="" />
            </div>
          </div>
        ) : (
          <Button
            secondary
            withIcon
            icon={plus}
            // shevron={shevroRight}
            type="button"
            className="btn"
            text={t("addImage")}
            onClick={() => setAddingImg(true)}
            disabled={isBlocked}
          />
        )}

        <Field
          name="firstName"
          validate={[nameValidation]}
          erorrValidate={t("firstNameIsIncorrect")}
          errorRequire={t("requiredValidate")}
          component={Input}
          placeholder={t("name")}
          disabled={isBlocked}
        />

        <Field
          name="lastName"
          validate={[nameValidation]}
          erorrValidate={t("lastNameIsIncorrect")}
          errorRequire={t("requiredValidate")}
          component={Input}
          placeholder={t("lastName")}
          disabled={isBlocked}
        />

        <Field
          name="patronymic"
          validate={[nameValidation]}
          erorrValidate={t("patronymicIsIncorrect")}
          errorRequire={t("requiredValidate")}
          component={Input}
          placeholder={t("patronymic")}
          disabled={isBlocked}
        />

        <div className="dateOfBith">
          <Field
            validate={[voidRequired]}
            name={`birthDate`}
            inputProps={{
              name: "birthDate",
            }}
            // validate={[required, date, maxDateNow]}
            component={DatePicker}
            // placeholder="--/--/----"
            placeholder={t("birthDate")}
            validDate={{ number: 16, type: "year", period: "isBefore" }}
            disabled={isBlocked}
          />
          <img
            className="closeImg"
            src={close}
            alt="close"
            onClick={async () => {
              await setInitalValues({
                ...formValues,
                birthDate: null,
              });
              let picker = document.getElementsByClassName("form-control");
              for (let i = 0; i < picker.length; i++) {
                const element = picker[i];
                element.value = "";
              }
            }}
          />
          <img className="calendarImg" src={calendar} alt="calendar" />
        </div>
        <>
          <Field
            name="sex"
            validate={[voidRequired]}
            options={[t("maleSex"), t("femaleSex")]}
            component={Selecter}
            placeholder={t("gender")}
            disabled={isBlocked}
          />

          {role !== "ROLE_DIVISION_ADMIN" && (
            <Field
              name="insuranceCompany"
              options={insuranceOptions}
              component={Selecter}
              placeholder={t("insuranceCompany")}
              disabled={isBlocked}
            />
          )}

          <Field
            name="email"
            validate={[voidRequired, email]}
            errorValidate={t("emailIsIncorrect")}
            errorRequire={t('emailIsRequire')}
            component={Input}
            placeholder={t("email")}
            mask={cirillicResist}
            disabled={isBlocked}
          />

          <Field
            name="phone"
            validate={[phoneFormat]}
            erorrValidate={t('phoneIsIncorrect')}
            errorRequire={t('phoneIsRequire')}
            component={Input}
            placeholder={t("phoneNumber")}
            disabled={isBlocked}
          />
        </>
      </>
    </>
  );
};

export default Operator;
