import {store} from "../../index";
import {setProcessesStatus} from "../../redux/processes/actions";
import axios from "axios";
import {setHeader, TEST_API} from "../index";

export default {
  page: 1,
  isStarted: false,
  prevState: {},
  interval: null,
  updateProcessesData: function (){
    this.fetchProcessesData()
      .then(data => {
      const isEqual = JSON.stringify(this.prevState) === JSON.stringify(data)
      if(!isEqual){
        store.dispatch(setProcessesStatus(data.items))
        this.prevState = data;
      }
    })
      .catch(error => {
        console.error('Error update processes - ', error);
        clearInterval(this.interval);
      });

  },
  startCheckProcesses: function () {
    if(!this.isStarted){
      this.updateProcessesData();
      this.interval = setInterval(this.updateProcessesData.bind(this), 30000);
    }
    this.isStarted = true;
  },

  fetchProcessesData: async function () {
    const config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/jobs?page=${this.page}`,
      headers: setHeader()
    }
    return (await axios(config)).data.data;
  }
}