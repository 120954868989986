import React from "react";
import ShevronRight from "../../../style/icons/ShevronRight/shevron.svg";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/Spinner/Spinner"
import { useParams } from "react-router-dom";
import { getDateInHours, getUIdateFormat } from "../../../utils/date";

const History = (props) => {
  const params = useParams()

  if (!props.patients || !props.patients.appointments) return null;

  const list = props.patients.appointments.data.items;

  const isAppointments = props.patients.appointments.data.items.length
  const isActualCardData = isAppointments ? (list[0]?.patientCard?.id === Number(params.card)) : true

  if (!isActualCardData) {
    return (
      <div className="loadingBox">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="show-history">
      {list.map((item) => (
        <div
          className="history-item"
          key={item.id}
          onClick={() => props.setHistoryItem(item)}
        >
          <div className="info">
            <div className="doctor">{`${item.specialty.title} ${getDateInHours(item.start)}`}</div>
            <div>{getUIdateFormat(item.start)}</div>
          </div>
          <img src={ShevronRight} alt="" />
        </div>
      )
      )}
    </div>
  );
};

export default History;
