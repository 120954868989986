import React, { useEffect, useState } from "react";
import "./simpleTextarea.scss";

const TextArea = ({ label, errorRequire, defaultValue, onChange, ...rest }) => {
  const [isRequire, setIsRequire] = useState(false);

  const [currentValue, setCurrentValue] = useState( "");
  const handleBlur = (evt) => {
    const { value, required } = evt.target;

    if (required && !value.trim()) {
      setIsRequire(true);
    }
  };

  const handleFocus = () => {
    if (isRequire) {
      setIsRequire(false);
    }
  };

  const onChangeHandler = (e) => {
    setCurrentValue(e?.target?.value);
    if(onChange){
      onChange(e?.target?.value);
    }
  }

  useEffect( () => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const textareaId = `textarea-${rest.name}`;
  const requireClasseName = isRequire ? "errorRequire show" : "errorRequire";

  return (
    <>
      <div className="formField autoHeight">
        <div className="formFieldSimpleTextarea">
          <label className={requireClasseName} htmlFor={textareaId}>
            {errorRequire}
          </label>
          <textarea
            {...rest}
            className="simpleTextarea"
            onChange={onChangeHandler}
            value={currentValue || ""}
            id={textareaId}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <label className="inputLabel" htmlFor={textareaId}>
            {rest.placeholder}
          </label>
        </div>
      </div>
    </>
  );
};

export default TextArea;
