const MEMBERS_COUNT_FOR_CHAT = 2

export const getIsNeedScrolling = (state) => state.chat.isNeedScrolling;
export const getIsAudioCall = (state) => state.chat.isAudioCall;
export const getIsVideoCall = (state) => state.chat.isVideoCall;
export const getMessageList = (state) => state.chat.historyChat.items;
export const getTotalItemCount = (state) => state.chat.historyChat.total_item_count;
export const getCurrentPage = (state) => state.chat.historyChat.current_page;
export const getItemsPerPage = (state) => state.chat.items_per_page;
export const getTemplatesItems = (state) => state.chat.templatesItems;
export const getChatStatus = (state) => state.chat.connectChatStatus;
export const getIsTheEndPage = (state) => state.chat.historyChat.isTheEndPage;
export const getBothInChat = (state) => {
  if (state.chat.countMembersInRoom < MEMBERS_COUNT_FOR_CHAT) return false;
  return true
};