import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleInput, SimpleSelect, SimpleDateInput, SimpleForm } from '../../components/formComponent';
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import api from "../../../api";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllCompanies } from "../../../redux/сompanies/actions";
import Button from "../../components/ui/Button/Button";
import {parserForm} from '../../../utils/parserForm';
import "./NewDivisionForm.scss";
import moment from 'moment';
import InputPhone from "../../components/formComponent/InputPhone/InputPhone";

const NewDivision = () => {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formSubmit = (evt, resetForm, setError) => {
    const { title, ...fields } = parserForm(evt);
    setLoading(true)
    const data = {
      division: {
        title,
        employee: {
          plainPassword: fields.email,
          ...fields,
          birthDate: moment(new Date(fields.birthDate)).format('YYYY-MM-DD')
        },
      },
    };
    return api.companies
      .createCompany(data)
      .then(() => resetForm())
      .then(() => {
        dispatch(getAllCompanies());
      })
      .finally(() => setLoading(false))
  };

  return (
    <div className="new-division-form-container">
      <p className="form-title">{t("newDivisions")}</p>
      <SimpleForm
        className="new-division-form"
        handleSubmit={formSubmit}
        autoComplete="off"
        novalidate
      >
        <div className="form-content">
          <SimpleInput
            name="title"
            // pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ\s]{1,}$"
            errorMessage={t('divisionsNameIsIncorrect')}
            errorRequire={t('divisionsNameRequire')}
            placeholder={t("divisionsName")}
            label={t("divisionsName")}
            allowSpaces
            required
          />
          
          <SimpleInput
            name="firstName"
            pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
            errorMessage={t('firstNameIsIncorrect')}
            errorRequire={t('firstNameRequire')}
            placeholder={t("name")}
            label={t("name")}
            required
          />

          <SimpleInput
            name="lastName"
            pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
            errorMessage={t('lastNameIsIncorrect')}
            errorRequire={t('lastNameIsRequire')}
            placeholder={t("lastName")}
            label={t("lastName")}
            required
          />

          <SimpleInput
            name="patronymic"
            pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
            errorMessage={t('patronymicIsIncorrect')}
            errorRequire={t('patronymicIsRequire')}
            placeholder={t("patronymic")}
            label={t("patronymic")}
            required
          />

          <SimpleDateInput
            type="date"
            required
            label={'Дата народження'}
            errorMessage={t("birthDateIsIncorrect")}
            errorRequire={t('birthDateIsRequire')}
            name="birthDate"
            placeholder={t("birthDate")}
            validDate={{ number: 16, type: "year", period: "isBefore" }}
          />
            
          <SimpleSelect
            name="gender"
            options={[
              { label: t("maleSex"), value: 0 },
              { label: t("femaleSex"), value: 1 }
            ]}
            placeholder={t("gender")}
            required
          />

          <SimpleInput
            name="email"
            pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            errorMessage={t("emailIsIncorrect")}
            errorRequire={t('emailIsRequire')}
            placeholder={t("email")}
            label={t("email")}
            required
          />
          <SimpleInput
            name="phone"
            pattern="^\+[0-9]{12}$"
            errorMessage={t('phoneIsIncorrect')}
            errorRequire={t('phoneIsRequire')}
            placeholder={t("phoneNumber")}
            required
          />
        </div>
        <div className="form-footer">
          <Button
            loading={loading}
            text={t("createButton")}
            className="btn"
            type="submit"
          />
        </div>
      </SimpleForm>
    </div>
  );
};

const mapDispatchToProps = { setInitalValues, resetInitialValues };

const mapStateToProps = (state) => ({
  formValues: getFormValues("NewDivisionForm")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewDivisionForm", enableReinitialize: true })
);

export default enhance(NewDivision);
