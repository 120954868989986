import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import ua from "../../../../helpers/countriesUa.json";
// import en from 'react-phone-input-2/lang/es.json'

// import { getUserIp } from "../../../../helpers";

import "../formComponents.scss";

const InputPhone = ({
  onChange,
  name,
  initialValue,
  defaultValue,
  notification,
  input,
  placeholder,
  noLabel,
  meta: { touched, error },
  meta,
  type,
  label,
  disabled,
  required,
  handleError,
  // localization,
  ...props
}) => {
  const [code, setCode] = useState("ua");
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    initialValue && input.onChange(initialValue);
    !input.value && defaultValue && input.onChange(defaultValue);
  }, [initialValue]);

  // useEffect(() => {
  //   getUserIp().then((res) => {
  //     setCode(res);
  //   });
  // }, [code]);

  return (
    <div className="formField">
      <div
        className={`formField input-phone ${
          touched && error ? "fieldInput error" : focus ? "focus" : ""
        }`}
      >
        {/* {label === "empty" ? (
        <label>&nbsp;</label>
      ) : (
        <label className={required && "required"}>
          {label}:{" "}
          {notification && (
            <span className="notification">( {notification} )</span>
          )}
        </label>
      )} */}
        {!noLabel &&
          placeholder &&
          (meta.active || touched || input.value !== "") &&
          input.value && <label className="inputLabel">{placeholder}</label>}

        <PhoneInput
          country={code}
          inputProps={{ name: input.name }}
          disabled={disabled}
          onChange={input.onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          // localization={ua}
          value={input.value}
          placeholder={"+380XXXXXXX"}
          {...props}
        />
      </div>
      {touched && error && <p className="formItemError">{error && error}</p>}
      {handleError && <p className="formItemError">{handleError}</p>}
    </div>
  );
};

export default InputPhone;
