import React, { useEffect } from "react";
import { useState } from "react";

import close from "../../../style/icons/CloseInBlueCircle/close.svg";
import shevron from "../../../style/icons/ShevronDown/shevron-down.svg";
import done from "../../../style/icons/MarkDoneBlue/mark.svg";
import undone from "../../../style/icons/MarkDoneBlue/undone.svg";

import "./tagsInput.scss";
const TagsInput = ({ disabled, options, onChange, placeholder }) => {
  const [toggleShowingTagList, settoggleShowingTagList] = useState(false);
  const [state, setState] = useState({
    tags: [],
    suggestions: [],
  });

  useEffect(() => {
    if (options && options.length) {
      setState({ ...state, suggestions: options });
    } else {
      setState({ suggestions: [], tags: [] });
    }
    // eslint-disable-next-line
  }, [options && options.length]);

  const onDelete = (i) => {
    state.tags.splice(i, 1);
    setState({ ...state, tags: state.tags });
  };
  const toggle = () => {
    if (disabled) {
      return;
    }
    settoggleShowingTagList(!toggleShowingTagList);
  };
  const onAddition = (tag) => {
    if (state.tags.includes(tag)) {
      onDelete(state.tags.indexOf(tag));
      return;
    }
    const tags = [].concat(state.tags, tag);
    setState({ ...state, tags });
  };
  useEffect(() => {
    onChange(state.tags.map((el) => el.id));
  }, [state]);

  return (
    <div className={`tagsInputContainer ${disabled ? "disabled" : ""}`}>
      <div className={`tagsInputField`} onClick={toggle}>
        {state && !state.tags.length && placeholder}
        {state &&
          state.tags &&
          state.tags.map((tag, index) => (
            <span
              className="activeTag"
              key={index}
              onClick={() => onDelete(index)}
            >
              {tag.title},
            </span>
          ))}
        <div className="iconToggle" onClick={toggle}>
          <img
            src={state.tags && state.tags.length >= 1 ? close : shevron}
            alt=""
            onClick={() => setState({ ...state, tags: [] })}
          />
        </div>
      </div>
      <div
        className={`tagList ${toggleShowingTagList ? "active" : ""}`}
        onMouseLeave={() => settoggleShowingTagList(false)}
      >
        {state &&
          state.suggestions &&
          state.suggestions.map((el, itemIndex) => (
            <div key={itemIndex} className="tag" onClick={() => onAddition(el)}>
              {el.title}
              <img src={state.tags.includes(el) ? done : undone} alt="" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TagsInput;

