import React from 'react';
import './notificationProvider.scss'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const Notificationitem = ({ message, withButton }) => {
  return (
    <div className='notificationBox'>
      <div className="infoBox">
        <ErrorOutlineIcon style={{ width: "30px", height: "30px" }} />
        <div className="notificationMessage">{message}</div>
      </div>
      {withButton && (
        <button className='notificationButton'>Перейти к консультации</button>
      )}
    </div>
  );
}

export default Notificationitem;
