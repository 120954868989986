import React, { useState } from "react";

import Splitter from "../../components/ui/Splitter/Splitter";
import PushNotificationsForm from "../../forms/PushNotificationForm/PushNotificationsForm";

import "./PushNotifications.scss";

const PushNotifications = () => {
  const [loading, setLoading] = useState(true);
  return (
    <Splitter
      left={<PushNotificationsForm loading={loading} setLoading={setLoading} />}
      right={null}
    />
  );
};

export default PushNotifications;
