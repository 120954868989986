import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = {
  processes: {
    data: []
  }
};

const processesReducer = createReducer(initialState)({
  [types.ALL_PROCESSES]: (state, { data }) => ({
    ...state,
    processes: {
      data: [...data]
    }
  }),
});

export default processesReducer;