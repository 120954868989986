import React from 'react'
import { useTranslation } from "react-i18next";
import close from "../../../style/icons/Close/close-icon.svg";
import "./newStatusForm.scss";
import {connect, useDispatch} from 'react-redux'
import { SimpleSelect, SimpleForm } from '../../components/formComponent';
import Button from "../../components/ui/Button/Button";
import { parserForm } from '../../../utils/parserForm';
import moment from 'moment';
import {updateConsultationStatus} from '../../../redux/consultations/actions'
import { setPopUpSuccess } from '../../../redux/networkError/actions';


const NewStatusForm = ({
    setEdited,
    edit,
    setEdit,
    setPopUpSuccess,
    id,
    updateConsultationStatus,
}) => {

    const formSubmit = (evt, resetForm, setError) => {
        const { status } = parserForm(evt)
        updateConsultationStatus(id && id, status)
            .then((message) => {
                if(!message) return
                setPopUpSuccess({
                    response: {
                        succes: true,
                        status: t("success"),
                        data: {
                            message: message,
                        },
                    },
                })
            })
        setEdit(false)
        setEdited(true)
    };

    const {t} = useTranslation()
    return (
        <>
            <div className="wrappNewEmployeeForm">
                 <div className={`formTitle ${edit ? "edit" : ""}`}>
                    {`
                    ${edit ? t("newScheduleFilterFormTitleEdit") : t("newEmployee")} `}
                        {edit && (
                        <img src={close} alt="arrow-icon" onClick={() => setEdit(prev => !prev)} />
                    )}
                </div>
                <SimpleForm
                    className="new-division-form"
                    handleSubmit={formSubmit}
                    autoComplete="off"
                    novalidate
                >
                    <SimpleSelect
                        name="status"
                        options={[
                            { label: t('new'), value: 'new' },
                            { label: t('cancelled'), value: 'cancelled' },
                            { label: t('filled'), value: 'filled' },
                            { label: t('closed'), value: 'closed' },
                            { label: t('in_process'), value: 'in_process' },
                        ]}
                        placeholder={t("status")}
                        required
                    />
                    <div className="form-footer">
                        <Button text={t("editButton")} className="btn" type="submit" />
                    </div>
                </SimpleForm>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    updateConsultationStatus,
    setPopUpSuccess,
};

export default connect(null, mapDispatchToProps)(NewStatusForm)