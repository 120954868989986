import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, Field, getFormValues, reduxForm } from "redux-form";
import moment from "moment";
import { useTranslation } from "react-i18next";

import close from "../../../style/icons/Close/close-icon.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import greyPlus from "../../../style/icons/PlusInCircle/plus-grey.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import calendar from "../../../style/icons/Calendar/calendar.svg";

import Toggle from "../../components/formComponent/Toggle/Toggle";
import Button from "../../components/ui/Button/Button";
import FilterSelect from "../../components/formComponent/FilterSelect/FilterSelect";
import Input from "../../components/formComponent/Input/Input";
import DatePicker from "../../components/formComponent/DatePicker/DatePicker";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import {
  durationTimeOnline,
  durationTimeAtHome,
  maskTime,
  required,
  timeInMin,
  voidRequired,
  normalizeDigits,
} from "../../../helpers/validate";

import {
  getDoctorsByFilter,
  getDoctorsByHomeFilter,
  getDoctorsByFilterPrimary,
} from "../../../redux/employees/actions";
import {
  createShifts,
  deleteShift,
  getAllShifts,
  getDistricts,
  updateShift,
} from "../../../redux/shedule/actions";

import "./newShiftForm.scss";
import FileUploader from "../../components/formComponent/FIleUploader/FileUploader";
import api from "../../../api";
import Spinner from "../../components/ui/Spinner/Spinner";
import { getShiftDurationTime, validDateStart } from "../../../utils/date";

const NewShiftForm = ({
  edit,
  create,
  setEditingOff,
  invalid,
  setCreatingOff,
  resetInitialValues,
  formValues = {},
  specializations = [],
  getDoctorsByFilter,
  getDoctorsByFilterPrimary,
  setInitalValues,
  getDoctorsByHomeFilter,
  reset,
  handleSubmit,
  createShifts,
  doctors,
  setDoctors,
  deleteShift,
  getAllShifts,
  filterMode,
  updateShift,
  dirty,
  anyTouched,
  provinces,
  districts,
  getDistricts,
}) => {
  const { t } = useTranslation();
  const [filterTags, setFilterTags] = useState();
  const [deleting, setDelete] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [shiftDurationError, setShiftDurationError] = useState(false)
  const [disableButton, isDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);

  var yesterday = moment().subtract(1, "day");
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  useEffect(() => {
    if (formValues?.province?.id) {
      getDistricts(formValues.province.id)
    }
  }, [formValues?.province?.id])

  useEffect(() => {
    if (filterTags || (formValues && formValues.specialty)) {
      getDoctorsByFilterPrimary([filterTags || formValues.specialty.id]).then(
        ({ data }) =>
          setDoctors(
            data.items.map((doctor) => ({
              imgUrl: doctor.employee.photoUrl || docIcon,
              title: doctor.employee.fullName || " ",
              id: doctor.id,
              specialty: doctor.specialty,
            }))
          )
      );
    } else setDoctors(null);
    // eslint-disable-next-line
  }, [filterTags, formValues && formValues.specialty]);

  useEffect(() => {
    if (
      formValues &&
      !formValues.scheduleFile &&
      formValues.fillType &&
      formValues.fillType.value === "file"
    ) {
      isDisableButton(true);
    } else {
      isDisableButton(false);
    }
  }, [formValues]);

  const toClose = () => {
    isDisableButton(false);
    resetInitialValues();
    reset();
    setInitalValues({ fillType: { value: "hand", text: "Вручную" } });
    getAllShifts(filterMode);
    setEditingOff();
    setLoading(false);
    setDelete(false);
  };

  useEffect(() => {
    const {duration, startDate, endDate} = formValues || {}
    if (duration && startDate && endDate) {
      const x = getShiftDurationTime(startDate)
      const y = getShiftDurationTime(endDate)
      
      if (moment(x).format('HH:mm') === moment(y).format('HH:mm')) {
        setDurationError(true);
      } else {
        setDurationError(false);
      }

      if (y - x > 0 && (y - x) / 60000 < (+duration)) {
        setShiftDurationError(true)
      } else {
        setShiftDurationError(false);
      }
    }
  }, [formValues])

  const handleDeleteShift = () => {
    const confirm = window.confirm(t("deleteShiftConfirm"))
    if (confirm) {
      setLoading(true)
      deleteShift(formValues.deleteEditPeriodId).then((res) => {
        setLoading(false);
        toClose();
      });
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    if (formValues.fillType && formValues.fillType.value === "file") {
      setLoading(true);
      handleSubmit((_v) => {
        if (_v.scheduleFile && _v.scheduleFile.file) {
          isDisableButton(true);
          api.shedule
            .upload(_v.scheduleFile.file)
            .then((res) => console.log("Фото успeшно загруженно"))
            .then(() => {
              setLoading(false);
              toClose();
            })
            .catch((err) =>
              console.log("Во время загрузки файла что-то пошло не так!", err)
            );
        }
      })();
    }
    if (formValues.fillType && formValues.fillType.value === "hand") {
      // console.log("filltype - hand");
      let isNightShift = false
      let isEqual = false

      const x = formValues.startDate && getShiftDurationTime(formValues.startDate)
      const y = formValues.endDate && getShiftDurationTime(formValues.endDate)

      if (+formValues.duration > (y - x) / (60 * 1000)) {
        isNightShift = true
      }
        setDurationError((prev) => false);
        handleSubmit((values) => {
          // console.log(values);
          let homeRequestBody = {}
          if (values.serviceFormat.type == 1) {
            const districtScope = values?.district?.length == districts.length ? 0 : 1
            if (districtScope == 1) {
              homeRequestBody = {
                province: values.province.id,
                district: !deleting && values.district.map(el => el.id),
                districtScope,
              }
            }
            if (districtScope == 0) {
              homeRequestBody = {
                province: values.province.id,
                districtScope,
              }
            }
          }
          if (!edit) {
            setLoading(true);
            createShifts(values, isNightShift, homeRequestBody)
            .then(() => {
              setLoading(false);
              toClose();
            });
          }
          // if (edit && deleting) {
          //   setLoading(true);

          //   deleteShift(values.deleteEditPeriodId).then((res) => {
          //     // console.log(res);
          //     setLoading(false);
          //     toClose();
          //   });
          // }
          if (edit && !deleting) {
            setLoading(true);
            updateShift(values.itemId, values, homeRequestBody).then((res) => {
              // console.log(res);
              setLoading(false);
              toClose();
            });
          }
        })();
      // }
    }
  };
  
  return (
    <div className={`wrappNewShiftForm ${create || edit ? "show" : ""}`}>
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit
            ? t("newScheduleFilterFormTitleEdit")
            : t("newScheduleFilterFormTitle")
          } `}
        <img
          src={close}
          alt="arrow-icon"
          onClick={() => {
            toClose();
            setCreatingOff();
          }}
        />
      </div>
      <Form className="newShiftForm" onSubmit={formSubmit} autoComplete="off">
        <Field
          name="fillType"
          component={Toggle}
          options={[
            { value: "hand", text: t("manually") },
            { value: "file", text: t("fromFile") },
          ]}
        />
        <div className="formContent">
          {loading && (
            <div className="absoluteCenter">
              <Spinner />
            </div>
          )}
          {!loading &&
            formValues &&
            formValues.fillType &&
            formValues.fillType.value === "hand" && (
              <>
                {specializations &&
                  specializations.length &&
                  specializations.length > 0 && (
                    <Field
                      name="specialty"
                      validate={[voidRequired]}
                      component={FilterSelect}
                      placeholder={t("placeholderSpecialty")}
                      options={specializations}
                      icon={plus}
                      withIcon
                      // withPreview
                      previewIcon={docAvatar}
                      withIconInOptions
                      withPreview={formValues && formValues.specialty}
                      onChangeHandler={(state) => setFilterTags(state.id)}
                    />
                  )}

                <Field
                  name="doctor"
                  validate={[voidRequired]}
                  component={FilterSelect}
                  placeholder={t("specializationTitle")}
                  options={doctors}
                  icon={plus}
                  withIcon
                  withPreview
                  previewIcon={plus}
                  withIconInOptions
                  disabled={!doctors || (formValues && !formValues.specialty)}
                  disabledIcon={greyPlus}
                />

                <Field
                  name="serviceFormat"
                  validate={[voidRequired]}
                  component={FilterSelect}
                  placeholder={t("filterTypeOfService")}
                  options={[
                    {
                      imgUrl: support,
                      title: t("optionsServiceTypeOnline"),
                      type: 0,
                    },
                    {
                      imgUrl: home,
                      title: t("optionsServiceTypeAtHome"),
                      type: 1,
                    },
                  ]}
                  icon={plus}
                  withIcon
                  withPreview
                  previewIcon={plus}
                  withIconInOptions
                  disabled={!doctors || (formValues && !formValues.specialty)}
                  disabledIcon={greyPlus}
                />
              
              {formValues?.serviceFormat?.type === 1 && (
                <Field
                  name="province"
                  validate={[voidRequired]}
                  component={FilterSelect}
                  placeholder={t("province")}
                  options={provinces.map(el => ({...el, imgUrl: home}))}
                  icon={plus}
                  withIcon
                  withPreview
                  previewIcon={plus}
                  withIconInOptions
                  disabled={provinces?.length === 0}
                  disabledIcon={greyPlus}
                />
              )}

              {formValues?.province && formValues?.serviceFormat?.type === 1 &&(
                <Field
                  name="district"
                  validate={[voidRequired]}
                  component={FilterSelect}
                  placeholder={t("district")}
                  options={districts.map(el =>  ({...el, imgUrl: home}))}
                  multipleInTitle
                  multiple
                  icon={plus}
                  withIcon
                  withPreview
                  previewIcon={plus}
                  withIconInOptions
                  disabled={districts?.length === 0}
                  disabledIcon={greyPlus}
                  selectAll
                />
              )}

                <Field
                  name="duration"
                  validate={formValues?.serviceFormat?.type === 1
                    ? [required, durationTimeAtHome]
                    : [required, durationTimeOnline]
                  }
                  placeholder={t("durationTitle")}
                  component={Input}
                  type="number"
                  normalize={normalizeDigits}
                  disabled={!doctors || (formValues && !formValues.specialty)}
                  handleError={shiftDurationError ? t("shiftDurationError") : ""}
                />

                <div className="period">
                  <Field
                    name="period"
                    inputProps={{
                      name: "period",
                    }}
                    // validate={[required, date, maxDateNow]}
                    component={DatePicker}
                    // validDate={valid}
                    isValidDate={validDateStart}
                    // validDate={{ number: 18, type: "year", period: "isBefore" }}
                    validate={[required]}
                    placeholder={t("date")}
                    tagsMode={!edit}
                    disabled={!doctors || (formValues && !formValues.specialty)}
                  />
                  <img
                    src={close}
                    alt="close"
                    className="close"
                    onClick={async () => {
                      // reset();
                      // resetInitialValues();
                      await setInitalValues({
                        ...formValues,
                        period: null,
                      });
                      let picker =
                        document.getElementsByClassName("form-control");
                      for (let i = 0; i < picker.length; i++) {
                        const element = picker[i];
                        // console.log(element, element.name);
                        if (element.placeholder === "Дата смены") {
                          element.value = "";
                        }
                      }
                    }}
                  />
                  <img className="calendar" src={calendar} alt="calendar" />
                </div>

                <Field
                  name="startDate"
                  validate={[timeInMin, required]}
                  mask={maskTime}
                  placeholder={t("timeStart")}
                  type={"time"}
                  time
                  component={Input}
                  disabled={!doctors || (formValues && !formValues.specialty)}
                />

                <Field
                  name="endDate"
                  validate={[timeInMin, required]}
                  mask={maskTime}
                  placeholder={t("timeEnd")}
                  type={"time"}
                  time
                  component={Input}
                  disabled={!doctors || (formValues && !formValues.specialty)}
                  handleError={
                    durationError ? t("shiftTimeDifferenceError") : ""
                  }
                />
              </>
            )}

          {!loading &&
            formValues &&
            formValues.fillType &&
            formValues.fillType.value === "file" && (
              <Field
                name="scheduleFile"
                // validate={[]}
                component={FileUploader}
              // disabled={!doctors || (formValues && !formValues.specialty)}
              />
            )}
        </div>
        <div className="formFooter">
          {edit ? (
            <>
              <Button
                type="submit"
                text={t("saveButton")}
                className="btn"
                disabled={invalid}
                loading={loading}
              />
              <Button
                type="btn"
                text={t("deleteButton")}
                className="btn"
                danger
                disabled={invalid}
                loading={loading}
                onClick={handleDeleteShift}
              />
            </>
          ) : (
            <Button
              type="submit"
              text={t("createShiftButton")}
              className="btn"
              loading={loading}
              disabled={
                disableButton ||
                (invalid && !anyTouched && !dirty) ||
                durationError ||
                shiftDurationError
              }
            />
          )}
        </div>
      </Form>
    </div>
  );
}

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  getDoctorsByFilter,
  getDoctorsByHomeFilter,
  createShifts,
  deleteShift,
  getDoctorsByFilterPrimary,
  getAllShifts,
  updateShift,
  getDistricts,
};

const mapStateToProps = (state, { shedule }) => ({
  formValues: getFormValues("shifts")(state),
  initialValues: state.formInitialValues,
  filterMode: shedule && shedule.filterMode,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "shifts", enableReinitialize: true })
);

export default enhance(NewShiftForm);
