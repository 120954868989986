import React, { useState } from "react";
import ScrollArea from "react-scrollbar";
import cm from "classnames";

import shevron from "../../../../style/icons/ShevronDown/shevron-down.svg";
import close from "../../../../style/icons/CloseInGreyCircle/close.svg";

import "../formComponents.scss";

const Select = ({
  options = [],
  input,
  disabled,
  onChange,
  placeholder,
  meta = {},
  autoComplete,
}) => {
  const { touched, error } = meta;

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(null);

  const onChangeHandler = (option) => {
    if (onChange) {
      onChange(option);
      // return
    } else {
      input.onChange(option);
    }
    setActive(false);
    setSelected(option);
  };
  return (
    <div className="formField">
      <div className="fieldInput">
        <input
          type="input"
          readOnly
          disabled={disabled}
          placeholder={placeholder}
          onClick={() => setActive(!active)}
          value={selected || ""}
          onChange={onChange}
          autoComplete={autoComplete ?? 'on'}
          {...input}
        />

        <img
          className={!selected ? `shevron ${active ? "active" : ""}` : "circle"}
          src={!selected ? shevron : close}
          alt=""
          onClick={() => {
            if (!selected) {
              setActive(!active);
            } else {
              if (onChange) {
                onChange("");
                // return
              } else {
                input.onChange("");
              }
              setActive(false);
              setSelected(null);
            }
          }}
        />

        <div className={"wrapperOptions"}>
          {active && (
            <ScrollArea speed={0.1} className={"area"} horizontal={false}>
              <div className={"options"}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={cm("option", {
                      active: option === selected,
                    })}
                    onClick={() => onChangeHandler(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </ScrollArea>
          )}
        </div>
      </div>
      {touched && error && <p className="formItemError">{error && error}</p>}
    </div>
  );
};
export default Select;
