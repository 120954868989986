import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import { format } from "../helpers/values";
import momentTz from "moment-timezone";

export const getChatDate = (date) => {
  const lang = localStorage.getItem("i18nextLng").slice(0, 2);
  moment.locale(lang);
  return moment(date).format("DD MMM");
};

export const getUIdateFormat = (date) => {
  const lang = localStorage.getItem("i18nextLng").slice(0, 2);
  moment.locale(lang);
  return moment(date).format("DD MMMM");
};

export const getDateInHours = (date) => moment(date).format("HH:mm");

export const getChunkDate = (date) => {
  if (typeof date !== "string") {
    console.error("getSimpleDate: date is not string");
    return "";
  }

  const _date = date.split("T")[0];

  if (_date) {
    return _date;
  }
  console.error("getSimpleDate date is invalid string");
  return "";
};

export const getFormatDate = (date, format = "DD MMMM YYYY") =>
  moment(date).format(format);

const validForDateStart = moment().subtract(1, "day");
const validForDateEnd = moment();

export const validDateStart = function (current) {
  return current.isAfter(validForDateStart);
};

export const validDateEnd = function (current) {
  return current.isAfter(validForDateEnd);
};

export const getDateWithZone = (el, time, isAddedDay) => {
  const chunkDate = time.split(":");
  const zoneName = momentTz.tz.guess();
  let resultDate = moment(el, "DD/MM/YYYY")
    .hour(chunkDate[0])
    .minute(chunkDate[1])
    .format("YYYY-MM-DD T HH:mm")
    .split(" ")
    .join("")
    .concat(zoneName);
  return resultDate;
};

export const getShiftDurationTime = (time = "") => {
  return moment().hour(time.split(":")[0]).minute(time.split(":")[1]).second(0);
};
