import * as types from "./types";
import api from "../../api";

export const getAllCompanies = () => (dispatch) => {
  return api.companies.getAllCompanies().then((res) => {
    return dispatch({
      type: types.GET_ALL_COMPANIES,
      ...(res && res.data),
    });
  });
};

export const getAllDivisions = (page) => async dispatch => {
  try {
    const res = await api.companies.getAllCompanies(page)
    dispatch({
      type: types.GET_ALL_COMPANIES,
      ...(res && res.data),
    })
  } catch (e) {
    console.log(e.message);
  }
}

export const setPagination = ({ numberOfPage }) => (dispatch) => {
  return api.companies.getAllCompanies(numberOfPage).then((res) => {
    return dispatch({
      type: types.GET_ALL_COMPANIES,
      ...(res && res.data),
    });
  });
};

