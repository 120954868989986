import axios from "axios";

import { store } from "../";
import { setToken } from "../redux/auth/action";
import { setNetworkError } from "../redux/networkError/actions";
import banners from "./banners";
import chat from "./chat";
import companies from "./companies";
import consultations from "./consultations";
import employees from "./employees";
import notification from "./notification";
import onboarding from "./onboarding";
import patients from "./patients";
import promocodes from "./promocodes";
import shedule from "./shedule";
import specializations from "./specializations";
import subscriptions from "./subscriptions";
import symptoms from "./symptoms";
import hospitals from "./hospitals";
import specializationMapping from "./scpecializationMapping";

import user from "./users";
import { toggleVersionCheck } from "../redux/version/actions";

let refresh_times = 0;
let versionCheckCounter = 0;

axios.interceptors.response.use(
  (response) => {
    //CHECK IF IS ACTUAL APP VERSION
    if (response.headers["x-app-min-version-alert"]) {
      if (versionCheckCounter === 0) {
        store.dispatch(toggleVersionCheck(true))
        versionCheckCounter++
      }
    }
    return response;
  },
  (error) => {
    if(error?.response?.status === 423 && error?.response?.data){
      return error.response;
    }

    if (error?.response?.data?.message !== 'Expired JWT Token') {
      store.dispatch(setNetworkError(error));
    }
    if (
      error.response &&
      error.response.status === 401 &&
      refresh_times === 0
    ) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        refresh_times++;
        axios({
          method: "POST",
          baseURL: `${TEST_API}/token/refresh`,
          data: {
            refreshToken,
          },
        }).then((res) => {
          if (res.data.code) {
            localStorage.clear();
            refresh_times = 0;
            window.location.reload();
          } else {
            const { data } = res;
            if (data && data.token) {
              localStorage.setItem("token", data.token);
              localStorage.setItem("refreshToken", data.refreshToken);
              store.dispatch(setToken(data.token));
              window.location.reload();
            }
          }
        });
      }
    } else {
      return error.response;
      localStorage.clear();
      console.log({ refresh_times, e: "error" });
    }
  }
);

export const TEST_HOST = process.env.REACT_APP_TEST_HOST;
export const SIGNALING_SERVER = process.env.REACT_APP_SIGNAL_SERVER;
export const PORT_CHAT_SOCKET = process.env.REACT_APP_PORT_CHAT_SOCKET;
export const PORT_CALL_SOCKET = process.env.REACT_APP_PORT_CALL;
export const PORT_SUPPORT_SOCKET = process.env.REACT_APP_PORT_CHAT_SUPPORT;
export const PORT_ATTENDING_DOCTOR  = process.env.REACT_APP_PORT_CHAT_ATTENDING_DOCTOR;
export const APP_VERSION = process.env.REACT_APP_VERSION || "2.12"
export const TEST_API = `${TEST_HOST}/api`;

export const setHeader = () => {
  const token = localStorage.getItem("token");

  let header = {
    'Access-Control-Allow-Origin': '*',
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "X-App-Version": APP_VERSION,
  };
  let language =
    localStorage.getItem("lang") || localStorage.getItem("i18nextLng")?.slice(0,2) || 'ua';
  if (language) {
    if (language === "uk") language = "ua";
    header = { ...header, "Accept-Language": language };
  }

  return header;
};

// axios.defaults.baseURL = `${TEST_API}`
// axios.defaults.headers = setHeader()

export default {
  user,
  banners,
  specializations,
  shedule,
  employees,
  consultations,
  patients,
  onboarding,
  symptoms,
  notification,
  subscriptions,
  promocodes,
  chat,
  companies,
  hospitals,
  specializationMapping,
};
