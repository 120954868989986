import i18n from "../../../i18n";

export const modesValue = {
  cardData: "cardData",
  anamnez: "anamnez"
}

export const modes = [
  { value: modesValue.anamnez, text: i18n.t("anamnez") },
  { value: modesValue.cardData, text: i18n.t("cardData") },
]

export const statuses = {
  CLOSED: "closed",
  IN_PROCESS: "in_process",
  CANCELLED: "cancelled",
  NEW: "new",
}

export const cardDataTextFields = [
  {
    name: "complaintsText",
    required: true,
    placeholder: `${i18n.t("complaints")}*`,
    label: `${i18n.t("complaints")}*`,
  },
  {
    name: "currentAnamnesisText",
    required: false,
    placeholder: i18n.t("currentDiseaseHistory"),
    label: i18n.t("currentDiseaseHistory"),
  },
  {
    name: "allergicText",
    required: false,
    placeholder: i18n.t("allergicDiseases"),
    label: i18n.t("allergicDiseases"),
  },
  {
    name: "pastIllnessText",
    required: false,
    placeholder: i18n.t("medicalHistory"),
    label: i18n.t("medicalHistory"),
  },
  {
    name: "treatmentPlanText",
    required: true,
    placeholder: `${i18n.t("treatmentPlan")}*`,
    label: `${i18n.t("treatmentPlan")}*`,
  },
  {
    name: "conclusionText",
    required: true,
    placeholder: `${i18n.t("conclusion")}*`,
    label: `${i18n.t("conclusion")}*`,
  },
]