import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm, reset } from "redux-form";
import api from "../../../api";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import Input from "../../components/formComponent/Input/Input";
import Button from "../../components/ui/Button/Button";

import "./NewHospitalAddressForm.scss";

const NewHospitalsAddressForm = ({
  formValues = {},
  editItem = {},
  setInitalValues,
  setEditItem,
  setLoading,
  reset,
  getDepartmentsByHospitalId,
  params,
  loading,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInitalValues({
      hospitalAddress: editItem.hospitalDivisionAddress,
    });
  }, [editItem]);

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      hospital: {
        addressText: formValues.hospitalAddress,
      },
    };
    api.hospitals
      .changeHospitalsDivisionAddress(editItem.hospitalDivisionID, data)
      .then(() => {
        setEditItem({});
        setLoading(false);
        reset({ form: "hospitalsAddress" });
        getDepartmentsByHospitalId(params.id);
      });
  };

  return (
    <Form onSubmit={formSubmit} className="newHospitalAddressForm">
      <Field component={Input} name="hospitalAddress" placeholder="Адрес" />
      <Button
        className="btn"
        type="submit"
        text="Изменить адрес"
        loading={loading}
        disabled={!formValues.hospitalAddress}
      />
    </Form>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("hospitalsAddress")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, { setInitalValues, resetInitialValues }),
  reduxForm({ form: "hospitalsAddress", enableReinitialize: true })
);

export default enhance(NewHospitalsAddressForm);
