import React from "react";
import Spinner from "../Spinner/Spinner";

const Button = ({
  disabled,
  className,
  type,
  onClick,
  loading,
  secondary,
  shevron,
  freeTopSpace,
  text,
  withIcon,
  icon,
  filter,
  danger,
  dangerAnimation,
  disabledIcon,
  secondaryLock,
  optionAsList,
  labelsOption,
  save,
  customDisabled,
}) => {
  return (
    <button
      style={customDisabled ? {pointerEvents: "none", opacity: '0.5', background: "#d3d3d370"} : {}}
      disabled={disabled || loading}
      type={type}
      className={`${className} ${freeTopSpace ? " freeTopSpace" : ""}${
        filter ? " filter" : ""
      }${withIcon ? "withIcon" : ""}${secondary ? " secondary" : ""}${
        danger ? " danger" : ""
        }${dangerAnimation ? ' dangerAnimation' : ''}
        ${secondaryLock ? ' secondaryLock' : ''}
        ${save ? ' save' : ''}
      ${disabled ? " disabled" : ""}`}
      onClick={() => onClick && onClick()}
    >
      <span>
        {withIcon && icon && (
          <img
            className="btn-icon"
            src={disabled ? disabledIcon : icon}
            // alt="btn-icon"
          />
        )}
        {!optionAsList ? (
          text || ''
        ) : (
            <div className="selectedLabels">
              {(labelsOption && labelsOption.length === 0) ||
                labelsOption.length === undefined
                ? text || ""
                : labelsOption.map((el, idEl) => <span key={idEl}>{el || ''}</span>)}
            </div>
          )}
      </span>
      {shevron && !loading && <img className="btn-icon" src={shevron} alt="btn-icon" />}
      {loading && (
        <div className="spinnerBox">
          <Spinner extraSmall/>
        </div>
      )}
    </button>
  );
};

export default Button;
