import React, {useState, useRef} from 'react';
import { genereteKey } from '../../../../utils/common';
import { isValidDate, getCorrectDate } from '../../../../utils/parserForm'

const SimpleInput = ({
    errorMessage,
    errorRequire,
    type,
    label,
    pattern = 'DD.MM.YYYY',
    validDate,
    ...rest
  }) => {
  const [isIsError, setIsError] = useState(false);
  const [isRequire, setIsRequire] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [inputKey, setInputKey] = useState('');
  const inputRef = useRef();
  const prevValue = useRef('');

  const handleBlur = (evt) => {
    const { value, required } = evt.target;
    const date = new Date(getCorrectDate(value, pattern));
 
    if (required && !value.trim()) {
      setIsRequire(true);
      return;
    }

    if (date.toString() === 'Invalid Date') {
      setIsError(true);
      return;
    }
    
    if (validDate) {
      if (!isValidDate(validDate, date)) {
        setIsError(true);
       };
    }
  }
  
  const handleChange = (evt) => {
    const {selectionStart, value} = evt.target;
    let indexPosition = null;
    const reqex = /[.:\/\s-]/g;
    indexPosition = selectionStart - 1;
    const patternChunks = pattern.split(reqex);
    const date = value.
      split(reqex)
      .map((d = '', i) => {
        if (d.length !== patternChunks[i].length) {
          if (d[0] !== '_') {
            indexPosition = selectionStart ? selectionStart : indexPosition;
          }

          if(/_$|^_/.test(d)){
            return d.replace(/_$|^_/, '')
          }
        }
        
        return d;
      })
      .join('.');

      if (prevValue.current && prevValue.current.length < date.length) {
        prevValue.current = value;
        return;
      }

    if (/[\W]/.test(date[indexPosition])) {
      indexPosition = indexPosition + 1;
    }

    inputRef.current.value = date;
    setTimeout(()=>{
      inputRef.current.setSelectionRange(indexPosition, indexPosition);
      prevValue.current = date; 
    });
  }

  const handelClick = (evt) => {
    const {value} = evt.target;

    if (!pattern.replace(/[A-Za-z]/g, '_') === value) {
      inputRef.current.setSelectionRange(0, 0);
    }
  }

  const handleFocus = (evt) => {
    if (isIsError) {
      setIsError(false);
    }

    if (isRequire) {
      setIsRequire(false)
    }

    if (!inputRef.current.value || !pattern.replace(/[A-Za-z]/g, '_')) {
      setInputKey(genereteKey());
      setDefaultValue(pattern.replace(/[A-Za-z]/g, '_'));
      setTimeout(()=>{
        inputRef.current.setSelectionRange(0, 0);
      }, 100);
    }
  }

  const errorClassName = isIsError ? 'errorMessage show' : 'errorMessage';
  const requireClasseName = isRequire ? 'errorRequire show' : 'errorRequire';
  const inputId =`input${rest.name}`;

  return (
    <div className="formField">  
      <div className="formFieldSimpleInput">       
        <label className={requireClasseName} htmlFor={inputId}>{errorRequire}</label>
        <label className={errorClassName} htmlFor={inputId}>{errorMessage}</label>
        <input
          key={inputKey}
          onFocus={handleFocus}
          className="simpleInput"
          onClick={handelClick}
          onBlur={handleBlur}
          id={inputId}
          defaultValue={defaultValue}
          ref={inputRef}
          pattern={isIsError? 'true' : ''}
          onChange={handleChange}
          {...rest}
        />
        <label className="inputLabel" htmlFor={inputId}>{label}</label>
      </div>
    </div>
  );
};

export default SimpleInput;
