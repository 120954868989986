import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import { relationIs } from "../../../helpers/functions";
import { phoneFormat } from "../../../helpers/validate";
import { useTranslation } from "react-i18next";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  getPatientCards,
  getThePatient,
  updatePatientCard,
} from "../../../redux/patients/actions";

import DatePicker from "../formComponent/DatePicker/DatePicker";
import Input from "../formComponent/Input/Input";
import Select from "../formComponent/Select/Select";
import Button from "../ui/Button/Button";
import moment from 'moment'
import { getPatientSelector, getPatientsRelations } from "../../../redux/patients/selectors";

const EditMedCardForm = ({
  formValues,
  handleSubmit,
  setEdit,
  reset,
  setInitalValues,
  patientCard,
  updatePatientCard,
  patient,
  getPatientCards,
  getThePatient,
  relations,
}) => {
  const [loading, setLoading] = React.useState(false);
  const match = useRouteMatch();
  const cardId = match.params.card;
  const patientId = match.params.patient;
  const { t } = useTranslation();

  useEffect(() => {
    getThePatient(match.params.patient, match.params.card);
  }, []);

  useEffect(() => {
    setInitalValues({
      firstName: patientCard.firstName,
      lastName: patientCard.lastName,
      patronymic: patientCard.patronymic,
      birthDate: moment(patientCard.birthDate).format('DD/MM/YYYY'),
      sex: patientCard.gender === 1 ? t("femaleSex") : t("maleSex"),
      relationShip: patientCard.relationShip.title,
      company: patientCard.company,
      policeNumber: patientCard.policeNumber,
      email: patientCard.email,
      phone: patientCard.phone,
    });
  }, [patientCard]);

  // console.log(patient);

  
  const formSubmit = (e) => {
    // console.log(">>>>>>>>>>>>>>>>>>", "submit");
    e.preventDefault();
    setLoading(true)
    handleSubmit((f_values) => {
      // console.log({ f_values });
      if (f_values.sex === t("maleSex")) {
        f_values.gender = 0;
      } else {
        f_values.gender = 1;
      }
      console.log(f_values);
      updatePatientCard(cardId, {
        patientCard: {
          birthDate: moment(f_values.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          gender: f_values.gender,
          policeNumber: f_values.policeNumber,
          firstName: f_values.firstName,
          lastName: f_values.lastName,
          patronymic: f_values.patronymic,
          email: f_values.email,
          phone: f_values.phone,
          // relationShip: relationIs(f_values.relationShip) || 1,
          relationShip: relations.find(el => f_values.relationShip === el.title).id
        },
      }).then((res) => {
        getPatientCards(patientId);
        reset();
        setEdit(false);
        getThePatient(match.params.patient, match.params.card);
      })
        .finally(() => setLoading(false));
    })();
  };

  return (
    <>
      <Form
        className="medCardEditForm"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <div className="medCardEditForm-content">
          <Field
              name={"firstName"}
              // validate={[required]}
              component={Input}
              placeholder={t("name")}
            />
          <Field
            name={"lastName"}
            // validate={[required]}
            component={Input}
            placeholder={t("lastName")}
          />
          <Field
            name={"patronymic"}
            // validate={[required]}
            component={Input}
            placeholder={t("patronymic")}
          />
          <Field
            name="birthDate"
            component={DatePicker}
            placeholder={t("birthDate")}
            validDate={{ number: 0, type: "day", period: "isBefore" }}
          />

          <Field
            name="sex"
            options={[t("femaleSex"), t("maleSex")]}
            component={Select}
            placeholder={t("sex")}
          />

          <Field
            name={"relationShip"}
            component={Select}
            options={relations.map((el) => el.title)}
            title={t("relations")}
            withPreview
            placeholder={t("relations")}
          />
          {/* <Field
          name={"company"}
          component={Input}
          placeholder={"Компания/страховка"}
        /> */}
          <Field
            name={"policeNumber"}
            // validate={[required]}
            component={Input}
            placeholder={t("policeNumber")}
          />
          <Field
            name={"email"}
            // validate={[required]}
            component={Input}
            placeholder={t("email")}
          />
          <Field
            name="phone"
            //   validate={[phoneFormat]}
            component={Input}
            placeholder={t("phoneNumber")}
          />
        </div>

        <div className="medCardEditForm-footer">
          <Button
            loading={loading}
            type="submit"
            text={t("saveButton")}
            className="btn"
            // onClick={() => setEdit(false)}
          />
        </div>
      </Form>
    </>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  updatePatientCard,
  getPatientCards,
  getThePatient,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("med_card_edit")(state),
  initialValues: state.formInitialValues,
  relations: getPatientsRelations(state),
  patient: getPatientSelector(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "med_card_edit", enableReinitialize: true })
);

export default enhance(EditMedCardForm);
