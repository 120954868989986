import React from "react";
import clearField from "../../../../style/icons/PlusInCircle/field-clear.svg";
import "../Input/input.scss";

const SearchInput = ({
  input,
  disabled,
  placeholder,
  noLabel,
  meta: { touched, error },
  meta,
  icon,
  type,
  label,
  clearInput,
  handleError,
  mask,
}) => {
  const clearInputField = () => {
    input.onChange("");
  };

  return (
    <>
      <div className="formField">
        <div className={touched && error ? "fieldInput error" : "fieldInput"}>
          {!noLabel &&
            placeholder &&
            (meta.active || touched || input.value !== "") &&
            input.value && <label className="inputLabel">{placeholder}</label>}
          {icon && <img src={icon} alt="" />}
          <input
            type={type || "text"}
            {...input}
            disabled={disabled}
            placeholder={placeholder}
            onKeyUp={mask && ((e) => mask(e, input))}
          />
          {input.value && clearInput && (
            <img
              className="clearInputFieldBtn"
              src={clearField}
              alt="clear-field"
              onClick={clearInputField}
            />
          )}
        </div>
        {touched && error && <p className="formItemError">{error && error}</p>}
        {handleError && <p className="formItemError">{handleError}</p>}
      </div>
    </>
  );
};

export default SearchInput;
