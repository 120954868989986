import api from "../../api";
import * as types from "./types";
import moment from "moment"
import { getServerTimeFormat } from "../../helpers/dateFormats";
import { setNetworkError, setPopUpSuccess } from "../networkError/actions";
import i18n from "../../i18n";

export const getPromocodesWithParams = (params, page) => async dispatch => {
  const filterString = Object.keys(params)
    .map((el) => params[el] && el !== "page" ? `filter[${el}]=${params[el]}` : "")
    .filter(el => el)
    .join("&").concat(`&page=${page || "1"}`);
  
  try {
    const data = await api.promocodes.getPromocodesWithFilters(filterString)
    dispatch({
      type: types.GET_ALL_PROMO_CODES,
      promocodes: data.data,
    })
  } catch (e) {
    console.log(e);
  }
}

export const downloadPromocodesCSV = (data) => async dispatch => {
  await api.promocodes.downloadPromocodesSCV(data, localStorage.getItem("token"))
}

export const generatePromocodes = (body) => async dispatch => {
  try {
    const data = await api.promocodes.generateNewPromocodes(body)
    console.log(data);
    if (data.code || data.status === 500) {
      dispatch(setNetworkError({
        response: {
          status: "Error",
          succes: false,
          statusText: data.message || "",
          erorrsArr: data.errors,
          hold: true,
        },
      }))
    } else {
      dispatch(setPopUpSuccess({
        response: {
          succes: true,
          status: i18n.t("success"),
          data: {
            message: i18n.t("promocodeGenerateSuccess"),
          },
        },
      }))
    }
    
  } catch (error) {
    console.log(error);
  }
}

export const getAllPromoCodes = () => (dispatch) => {
  return api.promocodes.getAllPromoCodes().then((res) =>
    dispatch({
      type: types.GET_ALL_PROMO_CODES,
      promocodes: res.data,
    })
  );
};

export const deletePromocode = id => async (dispatch) => {
  return api.promocodes.deletePromocode(id)
    .then((data) => {
      if (data.code) return
      dispatch({
        type: types.DELETE_PROMOCODE,
        id,
      })
    }
  )
};

export const getFilteredPromocodes = (
  status,
  type,
  dateStart,
  dateEnd,
  companyId
) => (dispatch) => {
  const [startFormat, endFormat] = getServerTimeFormat([dateStart, dateEnd])
  return api.promocodes
    .getFilteredPromocodes(status, type, startFormat, endFormat, companyId)
    .then((res) =>
      dispatch({
        type: types.GET_FILTERED_PROMOCODES,
        promocodes: res.data,
      })
    );
};

export const setPagination = ({
  numberOfPage,
  isItemsFiltered,
  filterParams,
}) => (dispatch) => {
  const { promocodeStatus, promocodeType, dateStart, dateEnd, insuranceCompany } = filterParams;
  const [startFormat, endFormat] = getServerTimeFormat([dateStart, dateEnd])
  if (isItemsFiltered) {
    return api.promocodes
      .getFilteredPromocodes(
        promocodeStatus && promocodeStatus.value,
        promocodeType && promocodeType.value,
        startFormat,
        endFormat,
        insuranceCompany && insuranceCompany.id,
        numberOfPage
      )
      .then((res) =>
        dispatch({
          type: types.GET_FILTERED_PROMOCODES,
          promocodes: res.data,
        })
      );
  } else {
    return api.promocodes.getAllPromoCodes(numberOfPage).then((res) => {
      return dispatch({
        type: types.GET_ALL_PROMO_CODES,
        promocodes: res.data,
      });
    });
  }
};
