import React from "react";
import { getDefaultMaxLength } from "../../../../utils/length";

import "./textarea.scss";
const TextArea = ({
  input,
  meta: { touched, error },
  meta,
  type,
  label,
  required,
  placeholder,
  notification,
  disabled,
  rows = 2,
  withCounters,
  maxLength,
}) => {
  return (
    <>
      <div className={`formField textArea ${input.value ? "fill" : ""}`}>
        {label && <label className={required && "required"}>{label} :</label>}
        {(meta.active || touched || input.value !== "") && input.value && (
          <label className="inputLabel">{placeholder}</label>
        )}
        <textarea
          {...input}
          placeholder={placeholder}
          onChange={input.onChange}
          type={type}
          value={input.value}
          disabled={disabled}
          className='areaWithCounters'
          // maxLength={getDefaultMaxLength(maxLength)}
          rows={rows}
        />
        {withCounters && <div className='symbolCounters'>{input.value.length} / {maxLength}</div>}
        {touched && error && (
          <p className="formItemError">{touched && error}</p>
        )}
      </div>
    </>
  );
};

export default TextArea;
