import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = null;

const specializationReducer = createReducer(initialState)({
  [types.GET_ALL_SPECIALIZATIONS]: (state, data) => ({
    ...state,
    ...data,
  }),
});

export default specializationReducer;
