import React from 'react';
import "./carousel.scss"

const Carousel = ({width}) => {
  return (
    <div style={{width: width}} className="dot-carousel"></div>
  );
}

export default Carousel;
