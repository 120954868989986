import moment from "moment";
import { createSelector } from "reselect";
import shedule from "../../api/shedule";
import { getListsWithDates } from "../../utils/common";
import visitAtHome from "../../style/icons/Home/home.svg";

const defaultShedules = {
  shedule: {
    defaultFilter: "all",
    filterMode: "all",
    current_page: 1,
    page_count: 1,
    items_per_page: 20,
    total_item_count: 0,
    isItemsFiltered: false,
    items: [],
  },
  provinces: [],
  districts: [],
  districtsPrices: [],
  loading: false,
};

const defaultState = { shedule: { ...defaultShedules }};

const getShedule = (shedule = defaultState) =>
  shedule || defaultShedules;

const getOtherShedule = (state = defaultState) =>
  state.shedule || defaultShedules;

export const getSheduleListsByDate = createSelector(
  [getShedule],
  ({ shedule: { items } }) => getListsByDate(items)
);

// export const getDoctorPeriodsSelect = createSelector(
//   [getShedule],
//   ({ shedule }) => {
//     console.log(shedule);
//     return shedule?.periods ? shedule.periods.map((_) => _.start || _) : []
//   }
// );

export const getProvincesSelector = createSelector(
  [getOtherShedule],
  ({provinces}) => provinces.map(el => ({...el, imgUrl: visitAtHome}))
);

export const getDoctorPeriodsSelect = createSelector(
  [getOtherShedule],
  ({periods}) => periods ? periods.map((_) => _.start || _) : []
);

export const getPricesSelector = createSelector(
  [getOtherShedule],
  ({districtsPrices}) => districtsPrices
);

export const getDistrictsSelector = createSelector(
  [getOtherShedule],
  ({ districts }) => districts.map(el => ({...el, imgUrl: visitAtHome}))
);

export const getLoading = createSelector(
  [getOtherShedule],
  ({ loading }) => loading
);

const getDate = (date) => {
  const testDateUtc = moment.utc(date);
  const localDate = moment(testDateUtc).local();
  return localDate.format("DD MMMM");
}

const getListsByDate = (items) => {
  if (!items) return null
  if(!items?.length) return null
  const dateList = getDateList(items)
  return dateList.reduce((acc, date, i, arr) => {
    acc[date] = getArrayByDate(items, date).map(el => ({
      ...el,
      period: el.period.find(el => getDate(el.startDate) == date)
    }))
    return acc
  }, {})
}

const getArrayByDate = (items, date) => {
  return items
    .filter(el => el.period.find(el => getDate(el.startDate) == date))
}
const getDateList = (items, date) => {
  const newItems = items
    .map(el => el.period)
    .flat()
    .map(el => getDate(el.startDate))
  const uniqueItems = [...new Set(newItems)]
  return uniqueItems
}
