import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import DefaultRoute from "./default";
import GuestRoute from "./hoc/GuestRoute";
import PrivateRoute from "./hoc/PrivateRoute";
import SetPassword from "../views/pages/SetPassword/SetPassword";
import Auth from "../views/pages/Auth/Auth";
import Home from "../views/pages/Home/Home";
import { connect } from "react-redux";
import { me } from "../redux/auth/action";
import firebase from "firebase/app";
import processes from "../api/process";
import VersionCheck from "../views/components/VersionCheck/VersionCheck";
import i18n from "i18next";

const App = ({ me, user }) => {

  const [loading, setLoading] = useState(true);

  const getMe = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      await me()
        .then(() => {
          processes.startCheckProcesses();
          console.log("Get user -> success")
        })
        .catch(() => localStorage.removeItem("token"));
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();

    const firebaseConfig = {
      apiKey: "AIzaSyB6_Dpzsy22DATLAiUwoy2HMFeXQomBRdM",
      authDomain: "dobrodoc-392f5.firebaseapp.com",
      databaseURL: "https://dobrodoc-392f5.firebaseio.com",
      projectId: "dobrodoc-392f5",
      storageBucket: "dobrodoc-392f5.appspot.com",
      messagingSenderId: "895113743631",
      appId: "1:895113743631:web:96b9d0283b5172b396c27a",
      measurementId: "G-W96KLKL1Z8",
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang") || "uk";
    i18n.changeLanguage(lang)
    if (user && user.isLogged && !user.email) {
      setLoading(true);
      getMe();
    }
  }, [user]);

  return (
    <>
      {!loading && (
        <Switch>
          <Route path="/" exact component={DefaultRoute} />
          <GuestRoute path="/login" exact component={Auth} />
          <Route path="/setpassword:token?" exact component={SetPassword} />
          <PrivateRoute
            path="/home/:role?/:category?/:patientId?/:cardId?"
            exact
            component={Home}
          />
        </Switch>
      )}
      <VersionCheck/>
    </>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { me };

export default connect(mapStateToProps, mapDispatchToProps)(App);
