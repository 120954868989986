import React, { useState } from "react";
import arrow_right from "../../../../style/icons/Chat/arrow-right.svg";
import close_icon from "../../../../style/icons/Chat/close-icon.svg";
import Spinner from '../../ui/Spinner/Spinner';

export const TemplatesModalContent = ({
  t,
  templates = [],
  handleClose = () => {},
  handleMessageClick = () => {},
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  React.useEffect(() => {
    if (templates?.length) {
      const firstGroup = templates[0].id;
      setSelectedGroup(firstGroup);
    }
  }, [templates])

  if (!templates.length || !selectedGroup) {
    return (
      <div className="templates-modal-content">
        <div className="absoluteCenter">
          <Spinner/>
        </div>
      </div>
    )
  }

  const currentGroupMessages = templates.find((item) => item.id === selectedGroup).templates;

  return (
    <div className="templates-modal-content">
      <div className="templates-header">
        <span>{t("templateTitle")}</span>
        <div className="icon-button" onClick={handleClose}>
          <img src={close_icon} alt="Закрыть" />
        </div>
      </div>
      <div className="templates-content">
        <div className="groups">
          {templates.map((item) => {
            const className =
              item.id === selectedGroup
                ? "templates-modal-item selected"
                : "templates-modal-item";
            return (
              <div
                className={className}
                key={item.id}
                onClick={() => setSelectedGroup(item.id)}
              >
                <span>{item.title}</span>
                <img src={arrow_right} alt="" />
              </div>
            );
          })}
        </div>
        <div className="messages-list">
          {currentGroupMessages.map((item) => {
            return (
              <div
                className="templates-modal-item"
                onClick={() => handleMessageClick(item.text)}
                key={item.id}
              >
                <span>{item.text}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
