import axios from "axios";
import { setHeader, TEST_API } from "../index";

export default {
  sendPushNotification: (data) => {
    let config = {
      method: "POST",
      // baseURL: `${TEST_API}/admin/devices/push`,
      baseURL: `${TEST_API}/admin/devices/message/push`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  sendPushNotificationFromOperatorRole: (userId) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/operator/consultation/push`,
      headers: setHeader(),
      data: {
        userId,
        text: "Вам надійшло нове повідомлення від оператора",
      },
    };
    return axios(config).then((res) => res && res.data);
  },

  sendPushNotificationOnUserNotAnswer: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/appointment/missed/${id}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  getNotificationsList: (page) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/operator/consultations?page=${page}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  readNotification: (consultationId) => {
    const config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/operator/consultations/${consultationId}`,
      data: {
        status: 1,
      },
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
};
