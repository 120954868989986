import { setHeader, TEST_API } from "../index";
import axios from "axios";

export default {
  getAllHospitals: (specializationID) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/hospitals/specialty/${specializationID}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getDepartmentsByHospitalId: (hospitalID, specializationID) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/hospitals/${hospitalID}/specialty/${specializationID}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  changeHospitalsDivisionAddress: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/hospitals/hospital/${id}`,
      headers: setHeader(),
      data,
    };
    
    return axios(config).then((res) => res && { ...res.data });
  }
};
