import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = null;

const consultationReducer = createReducer(initialState)({
  [types.SEARCH_BY_FILTER]: (state, value) => ({
    ...state,
    value,
  }),
});

export default consultationReducer;
