import React from "react";
import moment from "moment";
import "./PromocodesList.scss";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import { getDateFormat } from "../../../helpers/dateFormats";

const PromocodesList = ({
  items = [],
  t,
  handleDelete,
  role,
  copyToClipboard,
  copySuccessCode,
}) => {
  // const isEmpty = Array.isArray(items) && items.length === 0
  // return (
  //   <div className="promoCodeItemsContainer">
  //     {isEmpty && (
  //       <p className="errorMsg">{t("emptyList")}</p>
  //     )}
  //     {!isEmpty && (<>
  //       {items.map((item, i) => (
  //         <div style={item.status === 1 ? {opacity: "0.7"} : {}} key={i} className="newPromocodeItem">
  //           {copySuccessCode === item.code && (
  //             <div className="successBox">
  //               <DoneIcon style={{color: "green"}}/>
  //             </div>
  //           )}
  //           {item.status === 0 && <div className="status green"></div>}
  //           {item.status === 1 && <div className="status red"></div>}
  //           <div className="mainInfoBox">
  //             <div className="codeBox">
  //               <div className="promocode">{item.code}</div>
  //               <div onClick={() => copyToClipboard(item.code)} className="copyToClipboard">
  //                 <FileCopyIcon style={{ color: "white" }} />
  //               </div>
  //             </div>
  //             <div className="type">{item.type === 0 ? t("promocodeType_0") : t("promocodeType_1")}</div>
  //           </div>
  //           <div className="sectionBox">
  //             <div className="sectionTitle">{t("promocodeFrom")}</div>
  //             <div className="sectionText">{item.insuranceCompany?.title}</div>
  //           </div>
  //           <div className="sectionBox">
  //             <div className="sectionTitle">{t("promocodeCreated")}</div>
  //             <div className="sectionText">{getDateFormat(item.createdAt, "DD.MM.YYYY")}</div>
  //           </div>
  //           <div className="sectionBox wide">
  //             <div className="sectionTitle">{t("activePeriod")}</div>
  //             <div className="sectionText">{`${getDateFormat(item.validFrom, "DD.MM.YYYY")}`}</div>
  //             -
  //             <div className="sectionText">{`${getDateFormat(item.validTill, "DD.MM.YYYY")}`}</div>
  //           </div>
  //           <div className="sectionBox small">
  //             <div className="sectionTitle">{t("discount")}</div>
  //             <div className="sectionText">{`${item.discount}%`}</div>
  //           </div>
  //         </div>
  //       ))}
  //     </>)}
  return (
    <div className="promoCodeItemsContainer">
      {items && items.length ? (
        items.map((item, i) => (
          <div key={i} className="promocodeItem">
            <div>
              <p>
                {t("promocodeName")}: {item.code}
              </p>
              {item.insuranceCompany && item.insuranceCompany.title && (
                <p>
                  {t("promocodeFrom")}: {item.insuranceCompany.title}
                </p>
              )}
              <p>
                {t("promocodeCreated")}: {moment(item.createdAt).format("L")}
              </p>
              <div className='expiration'>
                <div style={{ marginBottom: "3px" }}>{`${t("activePeriod")}`}</div>
                <div>{moment(item.validFrom).format("L")} - {moment(item.validTill).format("L")}</div>
              </div>
          
              <div className='promocodeDiscount'>{t("percentileDiscount")}: {item?.discount}%</div>
              {item.type === 1 && item?.specialty && (
                <div className='promocodesSpecializations'>{t("specializations")}:
                  {item?.specialty.length > 0 && item.specialty.map((el, i) =>
                    i != item.specialty.length - 1
                      ? <span key={el.id} className='specialtyTitle'> {el.title},</span>
                      : <span key={el.id} className='specialtyTitle'> {el.title}</span>)}
                </div>
              )}
              <p>{item.type === 0 ? t("promocodeType_0") : t("promocodeType_1")}</p>
              <p className={`item-status ${item.status === 0 ? "green" : "red"}`}>
                {item.status === 0
                  ? t("promocodeStatus_0")
                  : t("promocodeStatus_1")}
              </p>
              {item.patientCard && item.patientCard.fullName && item.usesTotal === 1 ? (
                <p>{item.patientCard.fullName}</p>
              ) : (
                <p>-</p>
              )}
              {item.usesTotal > 1 && (<>
                <p>{`${t("usesTotal")}: ${item.usesMade}`}</p>
                <p>{`${t("usesLeft")}: ${item.usesLeft}`}</p>
              </>)}
            </div>
            <div className='buttonContainer'>
              {role === 'admin' && (
                <button
                  className='deletePromocodeButton' onClick={() => handleDelete(item.id)}>
                  {t("deletePromocode")}
                </button>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="errorMsg">{t("emptyList")}</p>
      )}
    </div>
  );
}

export default PromocodesList
