import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import Paginator from "../Paginator/Paginator";
import Star from '../../../style/icons/Stars/star.svg'
import disStar from '../../../style/icons/Stars/starnon.svg'
import {
  getAllFeedbacks,
  setFilterMode,
  setPagination,
} from "../../../redux/consultations/actions";

import { withRouter } from "react-router-dom";
import "moment/locale/uk";
import "moment/locale/ru";
import "./feedbacksList.scss"
import { compose } from "redux";
import Spinner from "../ui/Spinner/Spinner";
import { getFormatDate } from "../../../utils/date";
import { getConsultationsListsByDate, getCurrentPage, getLoadingState, getPageCount } from "../../../redux/consultations/selectors";
import { PaginatorPrimary } from "../PaginatorPrimary/PaginatorPrimary";


const FeedbacksList = ({
  items = [],
  role,
  current_page,
  setPagination,
  getAllFeedbacks,
  page_count,
  loading,
}) => {
  const currLang = localStorage.getItem("lang");
  moment.locale(currLang);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const handleChangePage = (numberOfPage) => {
    setPage(numberOfPage);
  };

  useEffect(() => {
    getAllFeedbacks(current_page)
  }, [])

  useEffect(() => {
    getAllFeedbacks(page)
  }, [page]);

  return (
    <>
      <div className="feedbacksListContainer">
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
        <div className="titleContainer">
          <div className="title">{t("placeholderFeedbacks")}</div>
        </div>
        {!items.length && !loading && (
          <div className='noFeedbacks'>
            <h1>Пока что нет отзывов</h1>
          </div>
        )}
        {items.length > 0 && !loading && (
          <div className='feedbacksItemsContainer'>
            {items.map(([date, list]) => (
              <div className="consultationPeriod" key={date}>
                <div className="date">{getFormatDate(date)}</div>
                {list.map((item, index) => (
                  <div className='feedbacksItem' key={item.id}>
                    <div className="info-box">
                      <div className='id'>
                        <div className='title'>ID</div>
                        <div className="idInfo">#{item.id}</div>
                      </div>
                      <div className="doctor-box">
                        <div>
                          {item.specialty.title}
                        </div>
                        <div className="fullName">
                          {item.doctor.fullName}
                        </div>
                      </div>
                      <div className="time-box">
                        <div className="time-desc">
                          <p>{t("consultationDate")}</p>
                        </div>
                        <div className="time">
                          {moment(item.start).format('HH:mm')}
                        </div>
                      </div>
                      <div className='patient-box'>
                        <div>{t("patient")}</div>
                        <div className="fullName">
                          {item.patientCard.fullName}
                        </div>
                      </div>
                      <div className="stars">
                        {Array(5).fill(1).map((el, idx) => {
                          if (idx < item.rating) {
                            return <img width='20' src={Star} alt="active star"></img>
                          } else {
                            return <img width='20' src={disStar} alt="none star"></img>
                          }
                        })}
                      </div>
                    </div>
                    <div className='comment-text'>
                      {item.review}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        <div className='paginatorContainer'>
          <PaginatorPrimary
            currentPage={page}
            changePage={handleChangePage}
            totalPage={page_count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ patients, ...state }) => {
  return {
    items: getConsultationsListsByDate(state),
    page_count: getPageCount(state),
    current_page: getCurrentPage(state),
    loading: getLoadingState(state)
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getAllFeedbacks,
    setFilterMode,
    setPagination,
  })
);

export default enhance(FeedbacksList);

