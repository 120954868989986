import * as types from "./types";
import api from "../../api";
import moment from "moment";
import { getDateWithZone, getStartDate } from "../../utils/date";

export const getAllShifts = (filterMode) => (dispatch) => {
  if (filterMode === "all" || filterMode === undefined) {
    return api.shedule.getShedule({}).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode: "all",
      });
    });
  }
  if (filterMode === "today") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "tomorrow") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "week") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
};

export const resetDates = () => dispatch => dispatch({
  type: types.RESET_PERIODS,
})

export const createShifts = (values, isNightShift = false, homeRequestBody) => (dispatch) => {
  const requestBody = {
    doctorSchedule: {
      doctor: values.doctor.id,
      specialty: values.specialty.id,
      districtScope: 0,
      ...homeRequestBody,
      period: values.period.selectedDates
        ? values.period.selectedDates.map((el, i) => ({
          // id: i,
          type: values.serviceFormat.type,
          duration: +values.duration,
          startDate: getDateWithZone(el, values.startDate),
          endDate: getDateWithZone(isNightShift ? moment(el, "DD/MM/YYYY").add(1, 'd').format("DD/MM/YYYY") : el, values.endDate, isNightShift),
        }))
        : [
          {
            type: values.serviceFormat.type,
            duration: +values.duration,
            startDate: getDateWithZone(values.period, values.startDate),
            endDate: getDateWithZone(isNightShift ? moment(values.period, "DD/MM/YYYY").add(1, 'd').format("DD/MM/YYYY") : values.period, values.endDate, isNightShift),
          },
        ],
    },
  }
  console.log(requestBody);
  return api.shedule
    .addNewShedule(requestBody)
    .then((res) => dispatch({ type: "CREATE_NEW_SHIFT", res }));
};


export const updateShift = (id, values, homeRequestBody) => (dispatch) => {
  return api.shedule
    .updateShedule(id, {
      doctorSchedule: {
        doctor: values.scheduleItem.doc.id,
        specialty: values.specialty.id,
        districtScope: 0,
        ...homeRequestBody,
        period:
          values.scheduleItem &&
          values.scheduleItem.period &&
          values.scheduleItem.period.map((el) => {
            if (el.id === values.deleteEditPeriodId) {
              return (el = {
                // id: values.deleteEditPeriodId,
                duration: +values.duration,
                type: values.serviceFormat.type,
                startDate: moment(`${values.period}`, "DD/MM/YYYY")
                  .hour(values.startDate.split(":")[0])
                  .minute(values.startDate.split(":")[1])
                  .format("YYYY-MM-DD T HH:mm Z")
                  .split(" ")
                  .join("") /* `T${values.startDate}` */,
                endDate: moment(`${values.period}`, "DD/MM/YYYY")
                  .hour(values.endDate.split(":")[0])
                  .minute(values.endDate.split(":")[1])
                  .format("YYYY-MM-DD T HH:mm Z")
                  .split(" ")
                  .join(""),
              });
            }
            // type: values.scheduleItem.type,

            return el;
          }),
      },
    })
    .then((res) => {
      // api.shedule.updateOnlyOneShift(id, {
      //   doctorSchedule: {
      //     doctor: values.doctor.id,
      //     type: values.serviceFormat.type,
      //     specialty: values.specialty.id,
      //     period: {
      //       id: values.deleteEditPeriodId,
      //       startDate: `${values.period.split("T")[0]}T${values.startDate}Z`,
      //       endDate: `${values.period.split("T")[0]}T${values.endDate}Z`,
      //     },
      //   },
      // });
      return dispatch({ type: "UPDATE_SHIFT" });
    });
};

export const filterSet = ({
  filterMode,
  specialization,
  serviceFormat,
  dateStart,
  dateEnd,
}) => (dispatch) => {
  return api.shedule
    .getFilteredShedule({
      specialization,
      serviceFormat,
      dateStart,
      dateEnd,
      filterMode,
    })
    .then((res) =>
      dispatch({
        type: types.GET_FILTERED_SHEDULE,
        payload: { filterMode, ...res },
      })
    );
};

export const deleteShift = (id) => (dispatch) =>
  api.shedule
    .deleteSchedule(id)
    .then((res) => dispatch({ type: "DELETE_SCHEDULE", res })); // TYPE_ONLINE = 0;
// TYPE_HOME = 1;

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_SHEDULE,
    filterMode,
  };
};

export const setPagination = ({
  filterMode,
  numberOfPage,
  isItemsFiltered,
  specialization,
  dateStart,
  dateEnd,
  serviceFormat,
}) => (dispatch) => {
  if (isItemsFiltered) {
    return api.shedule
      .getFilteredShedule({
        specialization,
        serviceFormat,
        numberOfPage,
        dateStart,
        dateEnd,
        filterMode,
      })
      .then((res) =>
        dispatch({
          type: types.GET_FILTERED_SHEDULE,
          payload: { filterMode, ...res },
        })
      );
  }
  if (filterMode === "all" || filterMode === undefined) {
    return api.shedule.getShedule({ numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode: "all",
      });
    });
  }
  if (filterMode === "today") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "tomorrow") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "week") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
};

export const getFreeDoctorTime = ({ doctor, specialty, type, district }) => (
  dispatch
) => {
  return api.shedule
    .getFreeDoctorTime(doctor, specialty, type, district)
    .then((res) => dispatch({ type: types.GET_FREE_DOC_TIME, ...res }));
};

export const getFreeDoctorTimeInHospital = ({
  hospital,
  doctor,
  specialty,
}) => (dispatch) => {
  return api.shedule
    .getFreeDoctorTimeInHospital(hospital, doctor, specialty)
    .then((data) =>
      dispatch({ type: types.GET_FREE_DOC_TIME_IN_HOSPITAL, ...data })
    );
};

export const getProvinces = () => async dispatch => {
  const {data} = await api.shedule.getProvinces()
  dispatch({ type: types.GET_PROVINCES, data })
}

export const getDistricts = (id) => async dispatch => {
  const {data} = await api.shedule.getDistricts(id)
  dispatch({ type: types.GET_DISTRICTS, data })
}

export const getDistrictsPrices = (specialtyId, provinceId) => async dispatch => {
  dispatch({type: types.GET_DISTRICTS_REQUEST})
  const data = await api.shedule.getDistrictsPrices(specialtyId, provinceId);
  dispatch({ type: types.GET_DISTRICTS_PRICES, data })
}