import axios from "axios";
import { getServerTimeFormat } from "../../helpers/dateFormats";
import { setHeader, TEST_API } from "../index";

export default {
  getShedule: async ({ filterMode, numberOfPage }) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/doctors/schedules${
        filterMode
          ? numberOfPage
            ? `?filter[${filterMode}]=true&page=${numberOfPage}`
            : `?filter[${filterMode}]=true`
          : numberOfPage
          ? `?page=${numberOfPage}`
          : ""
      }`,
      headers: setHeader(),
    };
    const res = await axios(config);
    return res && res.data;
  },

  getFilteredShedule: async ({
    specialization = {
      id: "",
    },
    numberOfPage,
    serviceFormat = {
      type: "",
    },
    dateStart = "",
    dateEnd = "",
    filterMode,
  }) => {
    if (serviceFormat.type === 3) serviceFormat.type = 1
    const [start, end] = getServerTimeFormat([dateStart, dateEnd])

    const URL =
      filterMode !== "all"
        ? `${TEST_API}/admin/doctors/schedules?filter[${filterMode}]=true&filter[type]=${serviceFormat.type}&filter[specialty]=${specialization.id}&page=${numberOfPage ?? 1}`
        : `${TEST_API}/admin/doctors/schedules?filter[from]=${start}&filter[to]=${end}&filter[type]=${serviceFormat.type}&filter[specialty]=${specialization.id}&page=${numberOfPage ?? 1}`;
    let config = {
      method: "GET",
      baseURL: URL,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  addNewShedule: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/doctors/schedules`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  updateShedule: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/doctors/schedules/${id}`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  getFreeDoctorTime: (doctor, specialty, type, district) => {
    let config = {}
    if (type === 0) {
      config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/consultations/doctor/${doctor}/specialty/${specialty}/type/${type}`,
        headers: setHeader(),
      };
    } else if (type === 1) {
      config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/consultations/home/doctor/${doctor}/specialty/${specialty}/district/${district}`,
        headers: setHeader(),
      };
    }

    return axios(config).then((res) => res && res.data);
  },

  getFreeDoctorTimeInHospital: (hospital, doctor, specialty) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/consultations/hospital/${hospital}/doctor/${doctor}/specialty/${specialty}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  deleteSchedule: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/doctors/schedules/period/${id}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  upload: (file) => {
    let formData = new FormData();
    formData.append("scheduleFile", file);
    return axios
      .post(`${TEST_API}/admin/doctors/schedules/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },
  getProvinces: (id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/provinces`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
  getDistricts: (provinceId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/provinces/${provinceId}/districts`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
  
  getDistrictsPrices: (specialtyId, provinceId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/specialities/${specialtyId}/province/${provinceId}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
};
