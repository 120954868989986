import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = null;

const onboardingReducer = createReducer(initialState)({
  [types.GET_ALL_ONBOARDING_TIPS]: (state, { data }) => ({ ...data }),
});

export default onboardingReducer;
