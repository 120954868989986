import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const DefaultRoute = ({ user, isLogged, roles }) => {
  let path;
  if (isLogged) {
    path = "home";
  } else {
    path = "login";
  }


  if (isLogged && roles) {
    if (roles && roles.includes("ROLE_ADMIN")) {
      path = "home/admin/shedule";
    } else if (roles && roles.includes("ROLE_INSURANCE_ADMIN")) {
      path = "/home/insurance-admin/promocodes";
    }else if (roles && roles.includes("ROLE_DIVISION_ADMIN")) {
      path = "/home/division-admin/shedule";
    } else if (roles && roles.includes("ROLE_OPERATOR")) {
      console.log('OPERATOR');
      path = "home/operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_INSURANCE_OPERATOR")) {
      path = "home/insurance-operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_DOCTOR")) {
      path = "home/doctor/monitoring?page=1&period=All";
    }
  }

  return <Redirect to={path} />;
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogged: user && user.isLogged,
  roles: user && user.roles,
});

export default connect(mapStateToProps)(DefaultRoute);
