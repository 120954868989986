import api from "../../api";
import * as types from "./types";
import {GET_USER} from "./types";

export const getAllPatients = () => (dispatch) => {
  api.patients.getAllPatients().then((res) =>
    dispatch({
      type: types.GET_ALL_PATIENTS,
      ...res,
    })
  );
};
export const getPatientCard = (patientCard) => ({
  type: types.GET_PATIENT_CARD,
  patientCard,
});

export const getThePatient = (patientId, cardId) => (dispatch) =>
  api.patients.getThePatient(patientId, cardId).then((data) =>
    dispatch({
      type: types.GET_THE_PATIENT,
      patient: { ...data },
    })
  );

export const getThePatientAnamnesis = (patientId, cardId) => (dispatch) => {
  api.patients.getThePatientAnamnesis(patientId, cardId).then((res) =>
    dispatch({
      type: types.GET_THE_PATIENT_ANAMNESIS,
      anamnesis: { ...(res && res.data) },
    })
  );
};
export const getUser = (userId) => (dispatch) =>
    api.patients.getUser(userId).then((res) =>
        dispatch({ type: types.GET_USER, user: res })
    );
export const getPatientCards = (patientId, cardId) => (dispatch) =>
  api.patients.getPatientCards(patientId, cardId).then((res) =>
    dispatch({
      type: types.GET_PATIENT_CARDS,
      cards: { ...(res && res.data) },
    })
  );

export const addNewPatientCard = (id, values) => (dispatch) => {
  return api.patients
    .createPatientCard(id, {
      patientCard: { ...values },
    })
    .then((res) => dispatch({ type: "CREATE_PATIENT_CARD", res }));
};

export const creteNewPatientCard = (values) => (dispatch) => {
  return api.patients
    .createNewPatientCard({ ...values })
    .then((res) => dispatch({ type: "CREATE_NEW_PATIENT_CARD", res }));
};

export const updatePatientCard = (id, data) => (dispatch) =>
  api.patients
    .updatePatientCard(id, data)
    .then(() => dispatch({ type: "UPDATE_PATIENT_CARD" }));

export const getThePatientAppointments = (cardId) => (dispatch) => {
  api.patients.getThePatientAppointments(cardId).then((res) =>
    dispatch({
      type: types.GET_THE_PATIENT_APPOINTMENTS,
      appointments: { ...res },
    })
  );
};

export const searchPatients = (role, searchKey) => (dispatch) => {
  api.patients.searchPatients(role, searchKey).then((res) => {
    // let searchInputValue = searchKey
    // if (res?.data?.items?.length === 0) {
    //   searchInputValue = '';
    // }
    dispatch({
      type: types.SEARCH_PATIENTS,
      searchList: res && res.data && res.data.items,
      searchKey,
    })
  }
  );
};

export const resetPatientsList = () => ({
  type: types.RESET_PATIENTS,
});

export const getPatientRelations = () => (dispatch) =>
  api.patients
    .getPatientRelations()
    .then((res) =>
      dispatch({ type: types.GET_RELATIONS, data: { ...(res && res.data) } })
    );

export const clearPatient = () => ({ type: types.RESET_PATIENT });

export const getAllFavoritePatients = (page = 1) => async dispatch => {
  try {
    const {data} = await api.patients.getAllFavoritePatients(page)
    dispatch({
      type: types.GET_ALL_FAVORITE_PATIENTS,
      favoritePatients: { ...data },
    })
  } catch (error) {
    console.log(error);
  }
};

export const setPatientAsFavourite = (cardId) => async dispatch => {
  try {
    const res = await api.patients.setPatientAsFavorite(cardId)
    dispatch({
      type: types.SET_PATIENT_AS_FAVOURITE,
      payload: res.data.entity
    })
    dispatch(getAllFavoritePatients())
  } catch (error) {
    console.log({error});
  }
}

export const setPagination =
  ({ numberOfPage }) =>
  (dispatch) => {
    console.log(numberOfPage);
    return api.patients.getAllFavoritePatients(numberOfPage).then((res) => {
      dispatch({
        type: types.GET_ALL_FAVORITE_PATIENTS,
        favoritePatients: { ...(res && res.data) },
      });
    });
  };
