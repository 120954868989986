import React, { useEffect, useRef } from "react";
import Cleave from "cleave.js/react";
import moment, { isMoment } from "moment";
import { useTranslation } from "react-i18next";

const InputDateCleave = ({
  input,
  meta: { dirty, error },
  meta,
  label,
  name,
  value,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const cleaveEl = useRef();
  useEffect(() => {
    if (input.value === "") {
      input.onChange("");
    }
  }, [input.value]);

  const onChangeHandler = (e) => {
    // console.log({ meta });
    // console.log(e.target.value, e.target.value.length);
    input.onChange(
      moment(e.target.value, "DD MM YYYY").format("YYYY-MM-DD T HH:mm Z")
    );
  };

  return (
    <div className={"formField picker"}>
      {label ? <label>{label}</label> : ""}
      <div className="fieldInput">
        <Cleave
          name={name}
          id={99999}
          // value={input.value}
          options={{ date: true, datePattern: ["d", "m", "Y"] }}
          onChange={onChangeHandler}
          disabled={disabled}
          {...props}
          ref={cleaveEl}
        />
      </div>

      {dirty && error && <p className="formItemError">{dirty && t(error)}</p>}
    </div>
  );
};

export default InputDateCleave;
