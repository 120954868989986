import React, { useState } from "react";

import SubscribersList from "../../components/SubscribersList/SubscribersList";
import NewSubscriberForm from "../../forms/NewSubscriberForm/NewSubscriberForm";

import "./Subscribers.scss";
import UpdateSubscriptionForm from "../../forms/UpdateSubcriptionForm/UpdateSubscriptionForm";

const Subscribers = ({}) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <SubscribersList edit={edit} setEdit={() => setEdit(true)} />
      <div className='subscribersOptionsList'>
        <NewSubscriberForm />
        <UpdateSubscriptionForm/>
      </div>
    </>
  );
};

export default Subscribers;
