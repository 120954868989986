import * as types from "./types";
import api from "../../api";
import { actionTypeIs } from "../../helpers/functions";
import { setPopUpSuccess } from "../networkError/actions";
import i18n from "../../i18n";

export const getAllFeedbacks = (numberOfPage) => (dispatch) => {
  dispatch({ type: types.CONSULTATIONS_REQUEST });
  return api.consultations.getAllFeedbacks(numberOfPage).then((data) => {
    dispatch({
      type: types.GET_ALL_CONSULTATIONS,
      ...data,
      filterMode: "all",
    });
  });
};

export const getConsultationsPrimary = (options) => (dispatch) => {
  console.log(2);
  dispatch({ type: types.CONSULTATIONS_REQUEST });
  return api.consultations.getConsultationPrimary(options).then((res) =>
    dispatch({
      type: types.GET_ALL_CONSULTATIONS,
      ...res,
    })
  );
};

export const getConsultationsWithParams = (role, params, page) => async dispatch => {
  const patientId = params.patientId;

  const filterString = Object.keys(params)
    .map((el) => {
      if (params[el]) {
        if (el === "period") {
          return `filter[${params[el].toLowerCase()}]=true`
        }
        return `filter[${el}]=${params[el]}`
      } else {
        return null
      }
    })
    .filter(el => el !== null)
    .join("&").concat(`&page=${page || "1"}`);
  
  dispatch({ type: types.CONSULTATIONS_REQUEST });
  try {
    let data = {}
    console.log(patientId);
    if (patientId) {
      data = await api.consultations.getConsultationsByPationId({patientId, page, filter: params.filterPeriod})
    } else {
      data = await api.consultations.getConsultationsWithFilters(role, filterString)
    }
    dispatch({
      type: types.GET_ALL_CONSULTATIONS,
      ...data,
    })
  } catch(e) {
    console.log(e);
  }
  
}

export const getAllConsultations =
  (role, filterMode, numberOfPage) => (dispatch) => {
    dispatch({ type: types.CONSULTATIONS_REQUEST });
    console.log(3);
    if (role === "doctor") {
      if (
        filterMode === "all" ||
        filterMode === undefined ||
        filterMode === null
      ) {
        return api.consultations
          .getAllDoctorConsultations(numberOfPage)
          .then((res) => {
            dispatch({
              type: types.GET_ALL_CONSULTATIONS,
              ...res,
              filterMode: filterMode || "all",
            });
          });
      }
      if (filterMode === "today") {
        return api.consultations
          .getTodayDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TODAY_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "tomorrow") {
        return api.consultations
          .getTomorrowDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TOMORROW_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "week") {
        return api.consultations
          .getWeekDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_WEEK_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
    }
    if (role === "operator" || role === "insurance-operator") {
      if (
        filterMode === "all" ||
        filterMode === undefined ||
        filterMode === null
      ) {
        return api.consultations
          .getAllConsultations(role, numberOfPage)
          .then((res) => {
            // console.log({ res });
            return dispatch({
              type: types.GET_ALL_CONSULTATIONS,
              ...res,
              filterMode: filterMode || "all",
            });
          });
      }
      if (filterMode === "today") {
        return api.consultations
          .getTodayConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TODAY_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "tomorrow") {
        return api.consultations
          .getTomorrowConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TOMORROW_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "week") {
        return api.consultations
          .getWeekConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_WEEK_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
    }
  };

export const getConsultationsByPationId = (patientId) => (dispatch) => {
  dispatch({ type: types.CONSULTATIONS_REQUEST });
  return api.consultations.getConsultationsByPationId(patientId).then((res) =>
    dispatch({
      type: types.GET_ALL_CONSULTATIONS,
      ...res,
      filterMode: "pationId",
    })
  );
};

export const getFilteredConsultations =
  ({
    allOrInWork,
    specialization,
    dateStart,
    dateEnd,
    serviceFormat,
    status,
    filterMode,
    role,
    isItemsFiltered,
    numberOfPage,
  }) =>
    (dispatch) => {
    console.log(4);
    dispatch({ type: types.CONSULTATIONS_REQUEST });
    console.log(filterMode);
    return api.consultations
      .getFilteredConsultations({
        allOrInWork,
        specialization,
        dateStart,
        dateEnd,
        serviceFormat,
        status,
        role,
        isItemsFiltered,
        numberOfPage,
        filterMode,
      })
      .then((res) =>
        dispatch({
          type: types.GET_FILTERED_CONSULTATIONS,
          payload: { filterMode, ...res },
        })
      );
  };

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_MONITORING,
    filterMode,
  };
};

export const setPagination =
  ({
    role,
    filterMode,
    numberOfPage,
    isItemsFiltered,
    allOrInWork,
    specialization,
    dateStart,
    serviceFormat,
    dateEnd,
    status,
  }) =>
  (dispatch) => {
    dispatch({ type: types.CONSULTATIONS_REQUEST });
    if (role === "admin") {
      return api.consultations.getAllFeedbacks(numberOfPage).then((data) => {
        dispatch({
          type: types.GET_ALL_CONSULTATIONS,
          ...data,
          filterMode: "all",
        });
      });
    }
    if (role === "doctor") {
      if (isItemsFiltered) {
        return api.consultations
          .getFilteredConsultations({
            allOrInWork,
            specialization,
            dateStart,
            dateEnd,
            status,
            filterMode,
            role,
            numberOfPage,
          })
          .then((res) =>
            dispatch({
              type: types.GET_FILTERED_CONSULTATIONS,
              payload: { filterMode, ...res },
            })
          );
      } else {
        if (
          filterMode === "all" ||
          filterMode === undefined ||
          filterMode === null
        ) {
          return api.consultations
            .getAllDoctorConsultations(numberOfPage)
            .then((res) => {
              return dispatch({
                type: types.GET_ALL_CONSULTATIONS,
                ...res,
                filterMode: filterMode || "all",
              });
            });
        }
        if (filterMode === "today") {
          return api.consultations
            .getTodayDoctorConsultations(numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_TODAY_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
        if (filterMode === "tomorrow") {
          return api.consultations
            .getTomorrowDoctorConsultations(numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_TOMORROW_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
        if (filterMode === "week") {
          return api.consultations
            .getWeekDoctorConsultations(numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_WEEK_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
      }
    }
    if (role === "operator" || role === "insurance-operator") {
      if (isItemsFiltered) {
        return api.consultations
          .getFilteredConsultations({
            allOrInWork,
            specialization,
            dateStart,
            dateEnd,
            status,
            filterMode,
            role,
            numberOfPage,
            serviceFormat,
          })
          .then((res) =>
            dispatch({
              type: types.GET_FILTERED_CONSULTATIONS,
              payload: { filterMode, ...res },
            })
          );
      } else {
        if (
          filterMode === "all" ||
          filterMode === undefined ||
          filterMode === null
        ) {
          return api.consultations
            .getAllConsultations(role, numberOfPage)
            .then((res) => {
              return dispatch({
                type: types.GET_ALL_CONSULTATIONS,
                ...res,
                filterMode: filterMode || "all",
              });
            });
        }
        if (filterMode === "today") {
          return api.consultations
            .getTodayConsultations(role, numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_TODAY_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
        if (filterMode === "tomorrow") {
          return api.consultations
            .getTomorrowConsultations(role, numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_TOMORROW_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
        if (filterMode === "week") {
          return api.consultations
            .getWeekConsultations(role, numberOfPage)
            .then((res) =>
              dispatch({
                type: types.GET_WEEK_CONSULTATIONS,
                ...res,
                filterMode,
              })
            );
        }
      }
    }
  };

export const createConsultation = (id, data) => (dispatch) =>
  api.consultations
    .createConsultation(id, data)
    .then((res) => dispatch({ type: types.CREATE_CONSULTATION, payload: res?.entity }))
    .catch((err) => err);

export const updateConsultation = (id, data) => (dispatch) =>
  api.consultations
    .updateConsultation(id, data)
    .then((res) => dispatch({ type: types.UPDATE_CONSULTATION, payload: res?.entity }));

export const updateConsultationStatus = (id, status) => async (dispatch) => {
  dispatch({ type: types.CONSULTATIONS_REQUEST });
  try {
    await api.consultations.updateConsultationStatus(id, status);
    dispatch({ type: types.UPDATE_CONSULTATION_STATUS });
  } catch (e) {
    console.log(e);
  }
  // api.consultations
  //   .updateConsultationStatus(id, status)
  //   .then(res => dispatch({type: types.UPDATE_CONSULTATION_STATUS}))
};
export const fillingCard =
  ({ id, data }) =>
  (dispatch) =>
    api.consultations.fillingCard(id, data);

export const canselConsultation =
  ({ id }) =>
  (dispatch) =>
    api.consultations.canselConsultation(id).then((res) => {
      dispatch({ type: types.CANCEL_CONSULTATION });
    });

export const getConsultationAnamnesis = (consultId) => async dispatch => {
  try {
    const res = await api.consultations.getConsultationAnamnesis(consultId)
    dispatch({ type: types.GET_CONSULTATION_ANAMNESIS_QUESTIONS, payload: res && res.data.items })
  } catch (e) {
    console.log(e.message);
  }
}

export const resetAnamnesis = () => dispatch =>
  dispatch({ type: types.RESET_ANAMNESIS })
  
export const getCardDataOptions = () => async (dispatch) => {
  try {
    const res = await api.consultations.getCardOptions()
    dispatch({ type: types.GET_DATA_CARD_OPTIONS, payload: res })
  } catch (e) {
    console.log(e.message);
  }
}

export const postConsultationAnamnesis =
  ({ appointment_id, anamnezField, values = {} }) =>
  (dispatch) => {
    let formData = {};
    anamnezField.map((el, i) => {
      formData[el.id] = values[el.id] ? values[el.id] : "";
    });
    return api.consultations.createConsultationAnamnesis({
      appointment_id,
      data: {
        anamnesis: {
          ...formData,
        },
      },
    });
  };

export const finishConsultation =
  ({ id }) =>
  (dispatch) =>
    api.consultations
      .finish(id)
      .then(() => dispatch({ type: "FINISH_CONSULTATION" }));

export const getCurrentConsultation = (id) => async (dispatch) => {
  dispatch({ type: types.CURRENT_CONSULT_REQUEST })
  try {
    const res = await api.consultations.getCurrentConsultation(id)
    dispatch({
      type: types.GET_CURRENT_CONSULTATION,
      currentConsultation: res && res.entity,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
}

export const resetCurrentConsultation = () => ({
  type: types.RESET_CURR_CONSULTATION,
});

export const resetRandomDoctor = () => dispatch => {
  dispatch({type: types.RESET_RANDOM_DOCTOR})
}

export const getRandomDoctor =
  (specialty, type, district = "") =>
  (dispatch) =>
    api.consultations
      .getRandomDoctor(specialty, type, district)
      .then((res) => dispatch({ type: types.GET_RANDOM_DOCTOR, res }));

export const getRandomDoctorInHospital = (specialty, type) => (dispatch) =>
  // dispatch({type: types.CONSULTATIONS_REQUEST})
  api.consultations
    .getRandomDoctorInHospital(specialty, type)
    .then((data) =>
      dispatch({ type: types.GET_RANDOM_DOCTOR_IN_HOSPITAL, data })
    );

export const resetIsItemsFiltered = () => ({
  type: types.RESET_IS_ITEMS_FILTERED,
});
