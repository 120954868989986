import * as types from "./types";
import createReducer from "../../utils/createReducer";
import { GET_RANDOM_DOCTOR, GET_RANDOM_DOCTOR_IN_HOSPITAL, RESET_RANDOM_DOCTOR } from "../consultations/types";

const initialState = {
  employeeList: [],
  filterMode: "all",
};

const employeeReducer = createReducer(initialState)({
  [types.GET_ALL_EMPLOYEES]: (
    state,
    {
      items,
      current_page,
      page_count,
      items_per_page,
      filterMode,
      ...props
    }
  ) => {
    return {
      ...state,
      items,
      filterMode,
      current_page,
      page_count,
      items_per_page,
      ...props,
    };
  },

  [types.GET_ALL_DOCTORS]: (
    state,
    { items, filterMode, total_item_count, page_count, current_page }
  ) => {
    return {
      ...state,
      countDoctors: total_item_count,
      items,
      filterMode,
      page_count,
      current_page,
    };
  },

  [types.GET_ALL_OPERATORS]: (
    state,
    { items, filterMode, page_count, current_page }
  ) => {
    return {
      ...state,
      items,
      filterMode,
      current_page,
      page_count,
    };
  },
  [types.GET_DOCTORS_BY_FILTER]: (
    state,
    { data: { total_item_count, ...props } }
  ) => {
    return {
      ...state,
      countDoctors: total_item_count,
      ...props,
    };
  },

  [types.SET_FILTER_MODE_EMPLOYEE]: (state, { filterMode }) => ({
    ...state,
    filterMode,
  }),

  [types.GET_DOCTOR_BY_SEARCH]: (state, { data }) => ({
    ...state,
    data,
  }),

  [types.GET_INSURANCE_COMPANIES]: (state, { data }) => ({
    ...state,
    insuranceCompanies: data.items,
  }),
  [GET_RANDOM_DOCTOR_IN_HOSPITAL]: (state, { data }) => ({
    ...state,
    randomDoctor: data?.entity?.doctor || null
  }),
  [GET_RANDOM_DOCTOR]: (state, { res }) => ({
    ...state,
    randomDoctor: res?.entity?.doctor || null
  }),
  [RESET_RANDOM_DOCTOR]: (state) => ({
    ...state,
    randomDoctor: null,
  })
});

export default employeeReducer;
