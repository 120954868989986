import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  symptomsById: [],
};

const symptomsReducer = createReducer(initialState)({
  [types.GET_ALL_SPECIALIZATIONS]: (state, { type, ...data }) => ({
    ...state,
    allSpecialization: { ...data },
  }),

  [types.GET_SYMPTOMS_BY_SPECIALIZATION_ID]: (state, { items }) => ({
    ...state,
    symptomsById: { items },
  }),
});

export default symptomsReducer;
