import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = { items: [] };

const promoCodesReducer = createReducer(initialState)({
  [types.GET_ALL_PROMO_CODES]: (state, { promocodes }) => ({
    ...state,
    ...promocodes,
  }),
  [types.GET_FILTERED_PROMOCODES]: (state, { promocodes }) => ({
    ...state,
    ...promocodes,
  }),
  [types.DELETE_PROMOCODE]: (state, { id }) => ({
      ...state,
      items: state.items.filter(el => el.id !== id)
    })
});

export default promoCodesReducer;
