import React, { useState, useEffect } from "react";
import Button from "../../ui/Button/Button";
import ShevronRight from "../../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";
import moment from "moment";

import "./DateTimePicker.scss";
import { consultationDate } from "../../../../helpers/functions";
import { connect } from "react-redux";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
const DateTimePicker = ({
  input,
  withIcon,
  icon,
  withPreview,
  placeholder,
  previewIcon,
  disabled,
  disabledIcon,
  options,
  withIconInOptions,
  defaultValue,
  language: { lang },
  refresh,
  buttonLoading,
}) => {
  const [showOptions, setOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [dates, setDates] = useState();
  const [activeDate, setActiveDate] = useState();
  const [activeHour, setActiveHour] = useState();
  const [defaultPrev, setDefaultPrev] = useState();
  const [isFirstTimeDone, setIsFirstTimeDone] = useState(false)

  useEffect(() => {
    if (refresh) {
      setSelectedOption({});
      setActiveHour("");
      setActiveDate("");
      setDates(null);
    }
  }, [refresh])

  useEffect(() => {
    return function () {
      setSelectedOption({});
      setActiveHour("");
      setActiveDate("");
      setDates(null);
    };
  }, []);

  useEffect(() => {
    setDates(
      () =>
        options &&
        options.reduce((acc, elem, i, arr) => {
          moment.locale(lang);
          let date = moment(elem).format("YYYY-MM-DD");
          /* console.log(
            duration,
            moment(elem).format("HH:mm"),
            moment(elem).add(duration, "millisecond").format("HH:mm")
          ); */
          if (acc[date]) {
            acc[date].push(`${moment(elem).format("HH:mm")}`);
          } else {
            acc[date] = [`${moment(elem).format("HH:mm")}`];
          }
          return acc;
        }, {})
    );
  }, [options]);



  useEffect(() => {
    moment.locale(lang);
  }, [dates, activeDate, activeHour, options, lang]);

  useEffect(() => {
    if (
      input.value &&
      input.value.day === defaultValue &&
      defaultValue.day &&
      input.value &&
      input.value.hours === defaultValue &&
      defaultValue.hours
    ) {
      console.log("----------------------------------------------------------");
      input.onChange(defaultValue);
      setDefaultPrev(true);
      setActiveDate(Object.keys(dates)[0]);
      setSelectedOption({ ...defaultValue });
      return;
    } else {
      if (dates) {
        if(isFirstTimeDone) return
        if (activeDate && activeHour) {
          setIsFirstTimeDone(true)
        }
        setActiveDate(Object.keys(dates)[0]);
      }
    }
  }, [dates]);

  useEffect(() => {
    if (activeDate) {
      if (defaultPrev || defaultValue) {
        return;
      }
      setActiveHour(dates[activeDate][0]);
      setSelectedOption({ day: activeDate, hours: activeHour });
      input.onChange({ day: activeDate, hours: activeHour });
    }
  }, [activeDate]);

  useEffect(() => {
    if (activeDate && activeHour) {
      setSelectedOption({ day: activeDate, hours: activeHour });
      input.onChange({ day: activeDate, hours: activeHour });
    }
  }, [activeHour]);

  useEffect(() => {
    if (defaultValue && (!input.value || input.value === "")) {
      setTimeout(() => {
        input.onChange({...defaultValue});
        setActiveDate(defaultValue.day);
        setActiveHour(defaultValue.hours)
        setSelectedOption({...defaultValue})
      }, 0)
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (!defaultValue) {
      setTimeout(() => {
        input.onChange("");
        setSelectedOption(null);
        setActiveHour("");
        setActiveDate("");
      }, 0)
    }
  }, [defaultValue])

  return (
    <>
      <Button
        className="btn"
        secondary
        text={
          selectedOption && selectedOption.day && selectedOption.hours
            ? `${moment(selectedOption.day).format("DD MMMM")} ${selectedOption.hours
            }`
            : placeholder
        }
        shevron={ShevronRight}
        withIcon={withIcon}
        icon={
          withPreview
            ? withIconInOptions
              ? selectedOption &&
                (selectedOption.imgUrl || selectedOption.imageUrl)
                ? selectedOption &&
                (selectedOption.imgUrl || selectedOption.imageUrl)
                : previewIcon
              : previewIcon
            : icon
        }
        type="button"
        onClick={() => setOptions(true)}
        disabled={disabled}
        loading={buttonLoading}
        disabledIcon={disabledIcon}
      />
      <div className={`optionsContainer ${showOptions ? "shown" : ""}`}>
        <div className="formTitleDate">
          <img src={arrowLeft} alt="icon" onClick={() => setOptions(false)} />
          {placeholder}
        </div>
        {dates ? (
          <div className="periods">
            {Object.keys(dates).length ? (
              <>
                <div className="dates">
                  {Object.keys(dates).map((el, i) => (
                    <div
                      className={`date ${activeDate === el ? "active" : ""}`}
                      key={i}
                      onClick={() => setActiveDate(el)}
                    >
                      <span>{moment(el).format("DD MMMM")}</span>
                    </div>
                  ))}
                </div>
                <div className="hours">
                  {activeDate &&
                    dates &&
                    dates[activeDate] &&
                    dates[activeDate].map((el, i) => (
                      <div
                        className={`hour ${activeHour === el ? "active" : ""}`}
                        key={i}
                        onClick={() => {
                          setActiveHour(el);
                          setDefaultPrev(false);
                        }}
                      >
                        <span>{`${el}`}</span>
                      </div>
                    ))}
                </div>
              </>
            ) : (
                <div className="emptyDateList">Нет доступных дат </div>
              )}
          </div>
        ) : (
            <div className="emptyDateList">Нет доступных дат </div>
          )}
      </div>
    </>
  );
};

const mapStateToProps = ({ language }) => ({ language });

export default connect(mapStateToProps, null)(DateTimePicker);
